import { Dto } from "@varos/rdm-common";
import { useCallback, useMemo } from "react";
import useApiResource from "./useApiResource";
import { useTheme } from "@mui/material";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import ApiResourceCache from "services/api-resource-cache.service";
import Api from "services/api.service";

interface StyleConfig {
    name: string | null;
    icon: IconName;
    color: string;
    badge?: string;
}

export interface DeviceTypeSpec {
    type?: Dto.RdmsApiV1.DeviceType;
    style: StyleConfig;
}

export const useDeviceTypes = () => {
    const theme = useTheme();

    const defaultDeviceStyleConfig: StyleConfig = useMemo(() => ({ name: null, icon: "question-circle", color: theme.palette.text.disabled }), [theme]);
    const defaultDeviceTypeSpec: DeviceTypeSpec = useMemo(() => ({ style: defaultDeviceStyleConfig }), [defaultDeviceStyleConfig]);

    const deviceTypes = useApiResource<Dto.RdmsApiV1.DeviceType[]>("/v1/device-types", { cache: true });
    const deviceTypeStyles = useMemo<StyleConfig[]>(() => [
        { name: "eft5000", icon: "print", color: theme.palette.primary.main },
        { name: "eft5000b", icon: "print", color: theme.palette.primary.main },
        { name: "varos-rdm-fsd", icon: "screwdriver-wrench", color: theme.palette.text.secondary },
        { name: "chdu5000", icon: "microchip", color: theme.palette.secondary.main },
        { name: "bowa-chdu-a1", icon: "microchip", color: theme.palette.secondary.main, badge: "BOWA" }
    ], [theme]);
    const deviceTypeSpecs = useMemo<DeviceTypeSpec[]>(() => deviceTypes.data?.map(type => ({
        type,
        style: deviceTypeStyles.find(style => style.name === type.name) || defaultDeviceStyleConfig
    })) || [], [defaultDeviceStyleConfig, deviceTypeStyles, deviceTypes.data]);
    const deviceTypeSpecByIdMap = useMemo(() => new Map(deviceTypeSpecs.map(s => [s.type?.id, s])), [deviceTypeSpecs]);
    const deviceTypeSpecByNameMap = useMemo(() => new Map(deviceTypeSpecs.map(s => [s.type?.name, s])), [deviceTypeSpecs]);

    const getById = useCallback((id: string) => deviceTypeSpecByIdMap.get(id) || defaultDeviceTypeSpec, [defaultDeviceTypeSpec, deviceTypeSpecByIdMap]);
    const getByName = useCallback((name: string) => deviceTypeSpecByNameMap.get(name) || defaultDeviceTypeSpec, [defaultDeviceTypeSpec, deviceTypeSpecByNameMap]);
    const getForDevice = useCallback((device?: Dto.RdmsApiV1.Device) => deviceTypeSpecByIdMap.get(device?.deviceTypeId) || defaultDeviceTypeSpec, [defaultDeviceTypeSpec, deviceTypeSpecByIdMap]);

    const getForDeviceAsync = async (device: Dto.RdmsApiV1.Device) => {
        const type = await ApiResourceCache.resolve<Dto.RdmsApiV1.DeviceType>(`deviceType:${device.deviceTypeId}`, async () => Api.invoke("get", `/v1/device-types/${device.deviceTypeId}`).response$);
        return {
            type,
            style: deviceTypeStyles.find(style => style.name === type.name) || defaultDeviceStyleConfig
        };
    };

    return {
        getById,
        getByName,
        getForDevice,
        getForDeviceAsync
    };
};
