import { ActionDef } from "components/Action";
import { Action } from "./EditUserAction";

const CreateUserAction: ActionDef<void> = {
    id: "user-create",
    title: "Create User",
    component: Action,
    dialog: {
        confirmButtonCaption: "Create"
    },
    menu: {
        title: "Create User",
        subtitle: "Create a new system user"
    }
};

export default CreateUserAction;
