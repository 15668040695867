import { Alert, CardContent, Grid, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Slide, SxProps, Typography, useTheme } from "@mui/material";
import { PieChart, PieChartProps } from "./PieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatumId } from "@nivo/pie";
import DefaultCard from "components/DefaultCard";
import { getChartColor } from "constants/chart-colors";
import { useState, useMemo } from "react";

export interface PieChartCardProps extends PieChartProps {
    title: string;
    description?: string;
    legend?: boolean;
    legendHeader?: string;
    loading?: boolean;
    noDataText?: string;
    sx?: SxProps;
}

export const PieChartCard: React.FC<PieChartCardProps> = props => {
    const theme = useTheme();

    const [legendActiveId, setLegendActiveId] = useState<DatumId | undefined>(undefined);
    const [legendHighlightId, setLegendHighlightId] = useState<DatumId | undefined>(undefined);
    const activeId = useMemo(() => props.activeId || legendActiveId || undefined, [legendActiveId, props.activeId]);

    const data = useMemo(() => {
        let output = props.data;
        if (props.sortByValue) {
            output = output.sort((a, b) => a.value < b.value ? 1 : a.value > b.value ? -1 : 0);
        }
        if (props.data && ((props.colors && props.colors.mode === "default") || !props.colors)) {
            output = output.map((d, i) => ({...d, color: d.color || getChartColor(theme, i, (props.colors && ((props.colors) as any).scheme) || "all") }));
        }
        return output;
    }, [props.colors, props.data, props.sortByValue, theme]);


    return (
        <DefaultCard raw sx={props.sx} loading={props.loading} skeletonHeight={(props.height || props.minHeight || 300) + 50}>
            <CardContent>
                <Typography variant="h5">{props.title}</Typography>
                {props.description && <Typography variant="body2" color="text.disabled">{props.description}</Typography>}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {props.maxItems && data.length > props.maxItems && 
                        <Grid item xs={12}>
                            <Alert color="info" icon={<FontAwesomeIcon icon={["fas", "chart-pie"]} fixedWidth />}>
                                Only showing top {props.maxItems} items out of {data.length}.{props.legend && <> All items are listed in legend.</>}
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} md={props.legend && data && data.length > 0 ? 6 : undefined}>
                        <PieChart
                            {
                                ...{
                                    ...props,
                                    activeId,
                                    data,
                                    onMouseEnter: !props.legend ? undefined : (d) => setLegendHighlightId(d.id),
                                    onMouseLeave: !props.legend ? undefined : (d) => setLegendHighlightId(undefined)
                                }
                            }
                        />
                    </Grid>
                    <Slide
                        in={props.legend && data && data.length > 0}
                        direction="left"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Grid item xs={12} md={6}>
                            <Paper elevation={0} sx={{ maxHeight: `calc(38px * 8)`, overflowY: "auto"}}>
                                <List dense subheader={<ListSubheader component="div">{props.legendHeader}</ListSubheader>}>
                                    {data.map((v, i) => 
                                        <ListItemButton
                                            key={`${i}:${v.id}`}
                                            selected={legendHighlightId === v.id}
                                            onMouseEnter={() => setLegendActiveId(v.id)}
                                            onMouseLeave={() => setLegendActiveId(undefined)}
                                        >
                                            <ListItemIcon sx={{ color: v.color }}>
                                                <FontAwesomeIcon icon={["fas", v.legendIcon || "square"]} fixedWidth />
                                            </ListItemIcon>
                                            <ListItemText primary={v.label} />
                                            <Typography color="text.secondary" sx={{ ml: 1 }}>{v.value}</Typography>
                                        </ListItemButton>
                                    )}
                                </List>
                            </Paper>
                        </Grid>
                    </Slide>
                </Grid>
            </CardContent>
        </DefaultCard>
    );
};
