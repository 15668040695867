import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbConfig } from "./Breadcrumb";
import { Link as RouterLink } from "react-router-dom";
import { Subject, tap } from "rxjs";

interface Props {
    breadcrumbs?: BreadcrumbConfig[];
    maxItems?: number;
}

export const refreshBreadcrumbs$ = new Subject<void>();

export const Breadcrumbs: React.FC<Props> = props => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState<React.ReactElement<typeof Breadcrumb>[]>([]);

    useEffect(() => {
        const subscription = refreshBreadcrumbs$.pipe(
            tap(async () => {
                const split = location.pathname.split("/");
                const partials: string[] = [];
                const breadcrumbs: any[] = [];
                for (const part of split) {
                    const previous = partials.length > 0 ? partials[partials.length - 1] : "";
                    partials.push(`${previous}${previous === "/" ? "" : "/"}${part}`);
                }
                for (const partial of partials) {
                    const config = props.breadcrumbs?.find(bc => bc.match.test(partial));
                    if (config) {
                        const matches = config.match.exec(partial);
                        breadcrumbs.push(
                            <Breadcrumb
                                key={partial}
                                component={RouterLink}
                                to={partial}
                                label={typeof config.label === "string" ? config.label : await config.label(matches)}
                                icon={config.icon ? <FontAwesomeIcon icon={config.icon} fixedWidth /> : undefined}
                            />
                        );
                    }
                }
                setBreadcrumbs(breadcrumbs);
            })
        ).subscribe();

        refreshBreadcrumbs$.next();
        return () => subscription.unsubscribe();
    }, [location.pathname, props.breadcrumbs]);

    return <MuiBreadcrumbs maxItems={props.maxItems} separator={<FontAwesomeIcon icon={["fad", "caret-right"]} />}>{breadcrumbs}</MuiBreadcrumbs>;
};