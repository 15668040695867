import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Divider, TextField, Typography } from "@mui/material";
import { GridSortModel, GridPaginationModel } from "@mui/x-data-grid-pro";
import { Dto } from "@varos/rdm-common";
import { ActionMenuItem } from "components/Action";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import DataGrid, { DataGridColDef, DataGridSelectActionButton } from "components/DataGrid";
import DefaultContainer from "components/DefaultContainer";
import Heading from "components/Heading";
import DeleteUserRoleAction from "components/ObjectActions/UserRole/DeleteUserRoleAction";
import DisableUserRoleAction from "components/ObjectActions/UserRole/DisableUserRoleAction";
import EnableUserRoleAction from "components/ObjectActions/UserRole/EnableUserRoleAction";
import ObjectIndexActionBar from "components/ObjectIndexActionBar";
import { RequireAuthorization } from "components/Authorization";
import useApiResource from "hooks/useApiResource";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { useUserRoleActions } from "hooks/useUserRoleActions";
import { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Subject, debounceTime, distinctUntilChanged, map, tap } from "rxjs";

export const UserRoleIndexView: React.FC = props => {
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "title", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const userRolesUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const url = `/v1/system/user-roles?search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
        return url;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, sortModel]);

    const userRoles = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.SystemUserRole>>(userRolesUrl);

    const userRoleActions = useUserRoleActions({ onUpdate: () => userRoles.reload() });

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => userRoles.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.SystemUserRole[], [userRoles?.data, selectedIds]);

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const columns: DataGridColDef<Dto.RdmsApiV1.SystemUserRole>[] = useMemo(() => [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "user-shield"]} />
                    <Typography variant="body2" color="primary" sx={{ textDecoration: "none", ml: .5 }} component={Link} to={`/user-roles/${params.row.id}`}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        {
            field: "enabled",
            headerName: "Status",
            flex: 1,
            hideOnSmallScreen: true,
            renderCell: params => {
                if (params.value) {
                    return <Chip variant="filled" color="success" size="small" label="Enabled" />;
                } else {
                    return <Chip variant="filled" color="default" size="small" label="Disabled" />;
                }
            }
        },
        {
            field: "_ruleCount",
            headerName: "Rules",
            sortable: false,
            flex: 1,
            valueGetter: params => params.row.rules?.length || 0
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            defaultVisibility: "hidden"
        }
    ], []);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        factory
            .section("User Roles")
            .if(auth.hasAccess("sys:user-role", "create")).action("user-role-index-create", "Create User Role").priority(9000).do(() => userRoleActions.create());
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected User Roles`)
                .if(auth.hasAccess("sys:user-role", "update")).action(EnableUserRoleAction).priority(8100).do(() => userRoleActions.enable({ userRoles: selectedObjects }))
                .if(auth.hasAccess("sys:user-role", "update")).action(DisableUserRoleAction).priority(8200).do(() => userRoleActions.disable({ userRoles: selectedObjects }))
                .if(auth.hasAccess("sys:user-role", "delete")).action(DeleteUserRoleAction).priority(8900).do(() => userRoleActions.delete({ userRoles: selectedObjects }));
        }
        return factory;
    }, [selectedObjects, userRoleActions, auth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Heading>User Roles</Heading>
            <DefaultContainer loading={userRoles.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        <RequireAuthorization resource="sys:user-role" operation="update">
                            <ActionMenuItem def={EnableUserRoleAction} action={() => userRoleActions.enable({ userRoles: selectedObjects })} />
                            <ActionMenuItem def={DisableUserRoleAction} action={() => userRoleActions.disable({ userRoles: selectedObjects })} />
                        </RequireAuthorization>
                        <Divider />
                        <RequireAuthorization resource="sys:user-role" operation="delete">
                            <ActionMenuItem def={DeleteUserRoleAction} action={() => userRoleActions.delete({ userRoles: selectedObjects })} />
                        </RequireAuthorization>
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                    <RequireAuthorization resource="sys:user-role" operation="create">
                        <Button variant="contained" disableElevation onClick={() => userRoleActions.create()}>New</Button>
                    </RequireAuthorization>
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="userRoleGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={userRoles.data?.data || []}
                    rowCount={userRoles.data?.total || 0}
                    loading={userRoles.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    checkboxSelection={auth.hasAccess("sys:user-role", "update") || auth.hasAccess("sys:user-role", "delete")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={model => setSelectedIds(model as string[])}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting
                />
            </DefaultContainer>
        </>
    );
};
export default UserRoleIndexView;