import React from "react";
import { InfoListItem } from "./InfoListItem";
import { Box, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
    title: string;
    text: string;
    subtext?: string;
    icon?: IconProp;
    color?: string;
}

export const InfoListStatusItem: React.FC<Props> = props => {
    return (
        <InfoListItem
            title={props.title}
            tail={props.icon ? <Box sx={{ ml: 2, color: props.color }}><FontAwesomeIcon icon={props.icon} size="lg" /></Box> : undefined}
        >
            <Stack>
                <Typography variant="body2" sx={{ color: props.color || undefined }}>{props.text}</Typography>
                {props.subtext && <Typography variant="body2">{props.subtext}</Typography>}
            </Stack>
        </InfoListItem>
    );
};

export default InfoListStatusItem;