import {Grid} from "@mui/material";
import {styled} from "@mui/system";

const ObjectIndexGrid = styled(Grid)(({ theme }) => ({
    alignItems: "stretch",
    ".MuiCard-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    ".MuiCardActionArea-root": {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "start"
    },
    ".MuiCardContent-root": {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column"
    }
}));

export default ObjectIndexGrid;
