import { faHourglass, faHandshakeAltSlash, faCheckCircle, faClock, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

const RpsBindingStatusDisplay: React.FC<{ status: undefined | string, variant: "body2" | "h5" }> = (props: { status: undefined | string, variant: "h5" | "body2" }) => (
    <>
        {props.status === "verifyPending" &&
            <Box sx={{ color: t => t.palette.info.main }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={faHourglass} />
                <Typography variant={props.variant} sx={{ display: "inline", textDecoration: "none", ml: .5 }}>
                    Verification Pending
                </Typography>
            </Box>
        }
        {props.status === "verifyTimeout" &&
            <Box sx={{ color: t => t.palette.warning.main }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={faHandshakeAltSlash} />
                <Typography variant={props.variant} sx={{ display: "inline", textDecoration: "none", ml: .5 }}>
                    Verification Timeout
                </Typography>
            </Box>
        }
        {props.status === "active" &&
            <Box sx={{ color: t => t.palette.success.main }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={faCheckCircle} />
                <Typography variant={props.variant} sx={{ display: "inline", textDecoration: "none", ml: .5 }}>
                    Active
                </Typography>
            </Box>
        }
        {props.status === "expired" &&
            <Box sx={{ color: t => t.palette.text.disabled }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={faClock} />
                <Typography variant={props.variant} sx={{ display: "inline", textDecoration: "none", ml: .5 }}>
                    Expired
                </Typography>
            </Box>
        }
        {props.status === "revoked" &&
            <Box sx={{ color: t => t.palette.text.disabled }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={faTimesCircle} />
                <Typography variant={props.variant} sx={{ display: "inline", textDecoration: "none", ml: .5 }}>
                    Revoked
                </Typography>
            </Box>
        }
    </>
);

export default RpsBindingStatusDisplay;