import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { BusinessSubjectAutocomplete } from "components/Autocomplete";
import { CustomerSetIcon } from "components/Icons";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const [selected, setSelected] = useState<Dto.RdmsApiV1.BusinessSubject | null>(null);
    const [loading, setLoading] = useState(true);

    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });

    const state =useActionState({
        ...props,
        loading,
        animationDelay: 500,
        process: async setProgress => {
            if (!selected) {
                throw new Error("No customer has been selected.");
            }
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { customerBusinessSubjectId: selected.id });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Update customer for selected devices. Devices are logically grouped within customers.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Configured customer may be overwritten by owner information received from device.
            </Alert>
            <ActionDeviceTargetList devices={props.devices} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose customer to assign selected devices to:</Typography>
            <BusinessSubjectAutocomplete autoFocus disabled={state.blocked} value={selected} onChange={setSelected} onInitialLoadingChange={setLoading} />
        </Box>
    );
};

const SetDeviceCustomerAction: ActionDef<Props> = {
    id: "device-set-customer",
    title: "Assign Device To Customer",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Customer",
    },
    menu: {
        title: "Set customer",
        subtitle: "Assign device to customer",
        icon: <CustomerSetIcon />
    }
};

export default SetDeviceCustomerAction;