import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit, Settings } from "@mui/icons-material";
import { Box, Button, Chip, Grid, IconButton, Menu, Stack, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import Center from "components/Center";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import { ActionMenuItem } from "components/Action";
import ClearSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultDcmProfileAction";
import ClearSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultRpsProfileAction";
import DeleteSubjectAction from "components/ObjectActions/Subject/DeleteSubjectAction";
import SetSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultDcmProfileAction";
import SetSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultRpsProfileAction";
import ObjectReference from "components/ObjectReference";
import useObjectAction from "hooks/useObjectAction";
import { useSubjectActions } from "hooks/useSubjectActions";
import { useCallback, useState } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import EditSubjectDataAction from "components/ObjectActions/Subject/EditSubjectDataAction";
import SetSubjectNoteAction from "components/ObjectActions/Subject/SetSubjectNoteAction";
import SetSubjectRolesAction from "components/ObjectActions/Subject/SetSubjectRolesAction";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import { RequireAuthorization } from "components/Authorization";

const BusinessSubjectDetailGeneralView: React.FC<{ subject: Dto.RdmsApiV1.BusinessSubject | undefined, onUpdate?: () => void }> = props => {
    const history = useHistory();
    const objectAuth = useObjectAuthorizationInfo(props.subject);

    const [dcmProfileMenuAnchorEl, setDcmProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [rpsProfileMenuAnchorEl, setRpsProfileMenuAnchorEl] = useState<HTMLElement | null>(null);

    const subjectActions = useSubjectActions({onUpdate: props.onUpdate});
    const deleteSubjectAction = useObjectAction(DeleteSubjectAction, () => history.push("/business-subjects"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!props.subject) {
            return factory;
        }
        factory
            .section(props.subject.name)
            .if(objectAuth.hasAccess("update")).action(SetSubjectRolesAction).priority(8200).do(() => subjectActions.setRoles({ subjects: props.subject ? [props.subject] : [], customer: props.subject?.isCustomer || undefined, servicePartner: props.subject?.isServicePartner || undefined  }))
            .if(objectAuth.hasAccess("update")).action(SetSubjectNoteAction).priority(8210).do(() => subjectActions.setNote({ subjects: props.subject ? [props.subject] : [], note: props.subject?.note || undefined  }))
            .if(objectAuth.hasAccess("update")).action(EditSubjectDataAction).priority(8240).do(() => subjectActions.edit({ subject: props.subject }))
            .if(objectAuth.hasAccess("update")).action(SetSubjectDefaultDcmProfileAction).priority(8220).do(() => subjectActions.setDefaultDcmProfile({ subjects: props.subject ? [props.subject] : []  }))
            .if(objectAuth.hasAccess("update")).action(SetSubjectDefaultRpsProfileAction).priority(8230).do(() => subjectActions.setDefaultRpsProfile({ subjects: props.subject ? [props.subject] : []  }))
            .if(objectAuth.hasAccess("delete")).action(DeleteSubjectAction).priority(8290).do(() => deleteSubjectAction({ subjects: props.subject ? [props.subject] : [] }));
        
        if (props.subject.defaultDcmProfileId) {
            factory.if(objectAuth.hasAccess("update")).action(ClearSubjectDefaultDcmProfileAction).priority(8220).do(() => subjectActions.clearDefaultDcmProfile({ subjects: props.subject ? [props.subject] : []  }))
        }
        if (props.subject.defaultRpsProfileId) {
            factory.if(objectAuth.hasAccess("update")).action(ClearSubjectDefaultRpsProfileAction).priority(8230).do(() => subjectActions.clearDefaultRpsProfile({ subjects: props.subject ? [props.subject] : []  }))
        }
        return factory;
    }, [deleteSubjectAction, objectAuth, props.subject, subjectActions]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultCard title="General" loading={!props.subject} skeletonHeight={595}>
                        <InfoList>
                            <InfoListItem title="ID">{props.subject?.id}</InfoListItem>
                            <InfoListItem title="Name">{props.subject?.name}</InfoListItem>
                            <InfoListItem
                                title="Roles"
                                tail={objectAuth.hasAccess("update") && <IconButton onClick={() => props.subject && subjectActions.setRoles({ subjects: [props.subject], customer: props.subject.isCustomer, servicePartner: props.subject.isServicePartner })}><Edit /></IconButton>}
                            >
                                <Box sx={{ pt: .5 }}>
                                    {props.subject?.isCustomer && <Chip sx={{ mr: .5 }} variant="outlined" size="small" label="Customer" color="primary" />}
                                    {props.subject?.isServicePartner && <Chip variant="outlined" size="small" label="Service Partner" color="secondary" />}
                                </Box>
                            </InfoListItem>
                            <InfoListItem title="Business ID">{props.subject?.businessId}</InfoListItem>
                            <InfoListItem title="Tax ID">{props.subject?.taxId}</InfoListItem>
                            <InfoListItem title="VAT ID">{props.subject?.vatId}</InfoListItem>
                            <InfoListItem title="Address">{props.subject?.address}</InfoListItem>
                            <InfoListItem title="Created"><Moment fromNow>{props.subject?.createDate}</Moment></InfoListItem>
                            <InfoListItem title="Updated"><Moment fromNow>{props.subject?.updateDate}</Moment></InfoListItem>
                        </InfoList>
                    </DefaultCard>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultCard title="Note" loading={!props.subject} skeletonHeight={595}>
                        {props.subject?.note && (
                            <Typography variant="body1" sx={{ height: "100%", whiteSpace: "pre-wrap" }}>{props.subject?.note}</Typography>
                        )}
                        {!props.subject?.note && (
                            <Center>
                                <Stack sx={{ color: t => t.palette.text.disabled }}>
                                    <FontAwesomeIcon icon={["fas", "note-sticky"]} size="2x" />
                                    <Typography sx={{ mt: 1 }} variant="body1">Note is empty</Typography>
                                    <RequireAuthorization object={props.subject} operation="update">
                                        <Button variant="outlined" color="primary" sx={{ mt: 1 }} onClick={() => props.subject && subjectActions.setNote({ subjects: [props.subject], note: props.subject.note || undefined })}>Add Note</Button>
                                    </RequireAuthorization>
                                </Stack>
                            </Center>
                        )}
                        {props.subject?.note && (
                            <Stack direction="row" sx={{ mt: 1 }}>
                                <RequireAuthorization object={props.subject} operation="update">
                                    <Button variant="outlined" color="primary" onClick={() => props.subject && subjectActions.setNote({ subjects: [props.subject], note: props.subject.note || undefined })}>Edit Note</Button>
                                </RequireAuthorization>
                            </Stack>
                        )}
                    </DefaultCard>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultCard title="Settings" loading={!props.subject} skeletonHeight={595}>
                        <InfoList>
                            <InfoListItem title="Default DCM Profile" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setDcmProfileMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                                <ObjectReference type="dcm-profile" icon id={props.subject?.defaultDcmProfileId} />
                            </InfoListItem>
                            <InfoListItem title="Default RPS Profile" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setRpsProfileMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                                <ObjectReference type="rps-profile" icon id={props.subject?.defaultRpsProfileId} />
                            </InfoListItem>
                        </InfoList>
                    </DefaultCard>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                        <RequireAuthorization object={props.subject} operation="update">
                            <Button variant="outlined" onClick={() => props.subject && subjectActions.edit({ subject: props.subject })}>Modify Data</Button>
                        </RequireAuthorization>
                        <RequireAuthorization object={props.subject} operation="delete">
                            <Button variant="outlined" color="error" onClick={() => props.subject && deleteSubjectAction({ subjects: [props.subject] })}>Delete Subject</Button>
                        </RequireAuthorization>
                    </Stack>
                </Grid>
            </Grid>
            <Menu open={!!dcmProfileMenuAnchorEl} anchorEl={dcmProfileMenuAnchorEl} onClick={() => setDcmProfileMenuAnchorEl(null)} onClose={() => setDcmProfileMenuAnchorEl(null)}>
                <ActionMenuItem def={SetSubjectDefaultDcmProfileAction} action={() => props.subject && subjectActions.setDefaultDcmProfile({ subjects: [props.subject] })} />
                {props.subject?.defaultDcmProfileId &&
                <ActionMenuItem def={ClearSubjectDefaultDcmProfileAction} action={() => props.subject && subjectActions.clearDefaultDcmProfile({ subjects: [props.subject] })} />
                }
            </Menu>
            <Menu open={!!rpsProfileMenuAnchorEl} anchorEl={rpsProfileMenuAnchorEl} onClick={() => setRpsProfileMenuAnchorEl(null)} onClose={() => setRpsProfileMenuAnchorEl(null)}>
                <ActionMenuItem def={SetSubjectDefaultRpsProfileAction} action={() => props.subject && subjectActions.setDefaultRpsProfile({ subjects: [props.subject] })} />
                {props.subject?.defaultRpsProfileId &&
                  <ActionMenuItem def={ClearSubjectDefaultRpsProfileAction} action={() => props.subject && subjectActions.clearDefaultRpsProfile({ subjects: [props.subject] })} />
                }
            </Menu>
        </>
    );
};

export default BusinessSubjectDetailGeneralView;