import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Theme } from "@mui/material";
import { RdmComponent } from "@varos/rdm-common";

export interface PlatformComponentStyle {
    name: string;
    color: string;
    icon: IconName;
    order: number;
}

export const getPlatformComponentStyle = (theme: Theme, component: RdmComponent.Type): PlatformComponentStyle => {
    const styles = {
        rdms: {
            name: "RDM Server",
            color: theme.palette.primary.main,
            icon: "database" as IconName,
            order: 100
        },
        "dcm-edge": {
            name: "DCM Edge",
            color: theme.palette.primary.main,
            icon: "circle-nodes" as IconName,
            order: 200
        },
        "vpn-gateway": {
            name: "VPN Gateway",
            color: theme.palette.primary.main,
            icon: "shield-keyhole" as IconName,
            order: 300
        },
        "rps-server": {
            name: "RPS Server",
            color: theme.palette.secondary.main,
            icon: "print" as IconName,
            order: 1000
        },
        "rdm-stats-exporter": {
            name: "RDM Stats Exporter",
            color: theme.palette.text.secondary,
            icon: "chart-simple" as IconName,
            order: 2000
        }
    };
    return styles[component as keyof typeof styles] || { color: theme.palette.text.disabled, icon: "question", order: 10000 };
};
