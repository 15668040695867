import ClearDeviceDcmProfileAction from "components/ObjectActions/Device/ClearDeviceDcmProfileAction";
import ClearDeviceRpsProfileAction from "components/ObjectActions/Device/ClearDeviceRpsProfileAction";
import SetDeviceCustomerAction from "components/ObjectActions/Device/SetDeviceCustomerAction";
import SetDeviceDcmProfileAction from "components/ObjectActions/Device/SetDeviceDcmProfileAction";
import SetDeviceRpsProfileAction from "components/ObjectActions/Device/SetDeviceRpsProfileAction";
import SetDeviceServicePartnerAction from "components/ObjectActions/Device/SetDeviceServicePartnerAction";
import useObjectAction from "./useObjectAction";
import ClearDeviceCustomerAction from "components/ObjectActions/Device/ClearDeviceCustomerAction";
import ClearDeviceServicePartnerAction from "components/ObjectActions/Device/ClearDeviceServicePartnerAction";
import EnableDeviceAction from "components/ObjectActions/Device/EnableDeviceAction";
import DisableDeviceAction from "components/ObjectActions/Device/DisableDeviceAction";
import GetDeviceRecoveryRdmaConfigAction from "components/ObjectActions/Device/GetDeviceRecoveryRdmaConfigAction";
import SetDeviceAliasAction from "components/ObjectActions/Device/SetDeviceAliasAction";

export const useDeviceActions = (opts: { onUpdate?: () => void }) => {
    const enable = useObjectAction(EnableDeviceAction, opts.onUpdate);
    const disable = useObjectAction(DisableDeviceAction, opts.onUpdate);

    const setAlias = useObjectAction(SetDeviceAliasAction, opts.onUpdate);
    const setCustomer = useObjectAction(SetDeviceCustomerAction, opts.onUpdate);
    const setServicePartner = useObjectAction(SetDeviceServicePartnerAction, opts.onUpdate);
    const setDcmProfile = useObjectAction(SetDeviceDcmProfileAction, opts.onUpdate);
    const setRpsProfile = useObjectAction(SetDeviceRpsProfileAction, opts.onUpdate);

    const clearCustomer = useObjectAction(ClearDeviceCustomerAction, opts.onUpdate);
    const clearServicePartner = useObjectAction(ClearDeviceServicePartnerAction, opts.onUpdate);
    const clearDcmProfile = useObjectAction(ClearDeviceDcmProfileAction, opts.onUpdate);
    const clearRpsProfile = useObjectAction(ClearDeviceRpsProfileAction, opts.onUpdate);

    const getRecoveryRdmAgentConfig = useObjectAction(GetDeviceRecoveryRdmaConfigAction, opts.onUpdate);

    return {
        enable,
        disable,

        setAlias,
        setCustomer,
        setServicePartner,
        setDcmProfile,
        setRpsProfile,

        clearCustomer,
        clearServicePartner,
        clearDcmProfile,
        clearRpsProfile,

        getRecoveryRdmAgentConfig
    };
};
