/* eslint-disable react/jsx-no-undef */
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useEffect, useState } from "react";
import useConfirmDialog from "../hooks/useConfirmDialog";
import { DetailGrid, DetailGridKeyValue } from "./Detail";
import RpsBindingStatusDisplay from "./RpsBindingStatusDisplay";
import useApiInvocation from "../hooks/useApiInvocation";
import { filter, take, tap } from "rxjs";

interface ComponentProps<T = Dto.RdmsApiV1.RpsBinding> {
    open: boolean;
    setOpen?: (open: boolean) => void;
    onUpdate?: (object: T) => void;
    object?: T;
}

const RpsBindingDialog: React.FC<ComponentProps> = <T extends Dto.RdmsApiV1.RpsBinding>(props: ComponentProps<T>) => {
    const [open, setOpen] = useState<boolean>(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const confirm = useConfirmDialog();
    const revokeBinding = useApiInvocation<{ id: string }, T>("post", "/v1/rps/bindings/:id/revoke");

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const handleRevoke = () => {
        if (!props.object) {
            return;
        }
        confirm({ title: "Revoke RPS Binding", text: `Are you sure want to revoke selected RPS binding? This operation is irreversible and binding will be rendered unusable.` }).pipe(
            take(1),
            filter(result => !!result),
            tap(async () => {
                try {
                    const response = await revokeBinding({ id: props.object?.id as string });
                    if (response && props.onUpdate && props.object) {
                        props.onUpdate(response);
                        props.setOpen?.(false);
                    }
                } catch (error) {
                    /* ignore */
                }
            })
        ).subscribe();
    };

    const handleClose = () => {
        setOpen(false);
        props.setOpen?.(false);
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>RPS Binding</DialogTitle>
            <DialogContent>
                <DetailGrid>
                    <DetailGridKeyValue title="Token" value={
                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                            {props.object?.token}
                        </Typography>
                    } />
                    <DetailGridKeyValue title="Status" value={<RpsBindingStatusDisplay variant="h5" status={props.object?.status} />} />
                    <DetailGridKeyValue title="Established On" value={props.object?.establishTime} />
                    <DetailGridKeyValue title="Verified On" value={props.object?.verificationTime} />
                    <DetailGridKeyValue title="Expired On" value={props.object?.expireTime ? <Typography variant="h5" sx={{ color: t => t.palette.warning.main }}>{props.object?.expireTime}</Typography> : undefined} />
                    <DetailGridKeyValue title="Revoked On" value={props.object?.revokeTime ? <Typography variant="h5" sx={{ color: t => t.palette.warning.main }}>{props.object?.revokeTime}</Typography> : undefined} />
                    <DetailGridKeyValue title="Verification Deadline On" value={props.object?.verificationDeadlineTime} />
                </DetailGrid>
            </DialogContent>
            <DialogActions>
                {props.object?.status === "active" && <Button color="warning" onClick={handleRevoke}>Revoke</Button>}
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
export default RpsBindingDialog;