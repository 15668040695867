import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, Fade, Skeleton, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";

export interface DefaultCardProps {
    title?: React.ReactNode | string;
    titleAppendix?: React.ReactNode;
    description?: string;
    loading?: boolean;
    skeletonHeight?: number;
    children?: React.ReactNode;
    sx?: SxProps;
    raw?: boolean;
}

export const DefaultCard: React.FC<DefaultCardProps> = props => {
    if (props.loading) {
        return <Skeleton variant="rounded" sx={{ height: "100%", minHeight: `${props.skeletonHeight || 100}px` }} />;
    }
    return (
        <Fade in={true} mountOnEnter unmountOnExit>
            <Card
                variant="outlined"
                sx={{
                    height: "100%",
                    transition: "transform 300ms ease",
                    ...props.sx
                }}
            >
                {props.raw && props.children}
                {!props.raw &&
                    <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        {typeof props.title === "string" &&
                        <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                            <Typography variant="h5" alignSelf="flex-start">{props.title}</Typography>
                            {props.titleAppendix}
                        </Stack>
                        }
                        {props.title && typeof props.title !== "string" && props.title}
                        {props.description && <Typography variant="body2" sx={{ opacity: .75 }}>{props.description}</Typography>}
                        {props.children}
                    </CardContent>
                }
            </Card>
        </Fade>
    );
};

export interface DefaultCardIconAppendixProps {
    icon: IconProp;
    tooltip?: string;
    color?: string;
}

export const DefaultCardIconAppendix: React.FC<DefaultCardIconAppendixProps> = props => {
    const iconEl = <FontAwesomeIcon icon={props.icon} />;

    return (
        <>
            <Box flex="1" />
            <Box sx={{ color: props.color, ml: 1, fontSize: 24 }}>
                {props.tooltip && <Tooltip title={props.tooltip}>{iconEl}</Tooltip>}
                {!props.tooltip && iconEl}
            </Box>
        </>
    );
};

export default DefaultCard;