import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Box, Collapse, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBaseProps, ActionDef } from "components/Action";
import { FormTextField } from "components/Form";
import useActionState from "hooks/useActionState";
import useApiInvocation from "hooks/useApiInvocation";
import React, { useCallback, useEffect, useState } from "react";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSS } from "@dnd-kit/utilities";
import { Add, Delete } from "@mui/icons-material";
import ObjectReference from "components/ObjectReference";
import { UserRoleAutocomplete } from "components/Autocomplete";

const SortableUserRole: React.FC<{ id: string, disabled?: boolean, onDelete?: () => void }> = props => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
      
    return (
        <ListItem
            disableGutters
            ref={setNodeRef}
            style={style}
            secondaryAction={
                <IconButton disabled={props.disabled} onClick={evt => props.onDelete?.()}>
                    <Delete />
                </IconButton>
            }
        >
            <ListItemIcon sx={{ outline: "none", cursor: "move", minWidth: "40px" }} {...attributes} {...listeners}>
                <FontAwesomeIcon size="lg" fixedWidth icon={["fas", "grip-dots-vertical"]} />
            </ListItemIcon>
            <ListItemIcon sx={{ color: "primary.main", minWidth: "40px" }}>
                <FontAwesomeIcon size="lg" icon={["fas", "user-shield"]} />
            </ListItemIcon>
            <ListItemText>
                <ObjectReference disableLink type="user-role" id={props.id} textVariant="body1" />
            </ListItemText>
        </ListItem>
    );
}


interface Props {
    user?: Dto.RdmsApiV1.SystemUser
}

export const Action: React.FC<ActionBaseProps & Props> = props => {
    const createUser = useApiInvocation<{}, Dto.RdmsApiV1.SystemUser>("post", "/v1/system/users", { silentError: true, throwError: true });
    const updateUser = useApiInvocation<{ id: string}, Dto.RdmsApiV1.SystemUser>("put", `/v1/system/users/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [user, setUser] = useState<Partial<Dto.RdmsApiV1.SystemUser>>();
    useEffect(() => setUser(props.user ? { ...props.user } : { enabled: true }), [props.user]);
    const [newRole, setNewRole] = useState<Dto.RdmsApiV1.SystemUserRole | null>(null);

    const [password, setPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");

    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            if (props.user) {
                try {
                    const data = { ...user, roleIds: user?.superAdmin ? [] : user?.roleIds };
                    delete data.id;
                    delete data.createDate;
                    delete data.updateDate;
                    delete data._meta;
                    await updateUser({ id: props.user.id }, data);
                } catch (error) {
                    throw new Error(`Failed to update user ${props.user.id} - ${error.message}`);
                }
            } else {
                try {
                    if (!password || !passwordRepeat) {
                        throw new Error(`Password needs to be set.`);
                    } else if (password.length !== password.trim().length) {
                        throw new Error(`Password cannot contain spaces at the beginning or at the end.`);
                    } else if (password.trim().length < 8) {
                        throw new Error(`Password needs to be at least 8 characters long.`);
                    } else if (!/[A-Z]+/.test(password) || !/[a-z]+/.test(password) || !/[0-9]+/.test(password)) {
                        throw new Error(`Password needs to contain uppercase char, lowercase char and a number.`);
                    } else if (password !== passwordRepeat) {
                        throw new Error(`Entered passwords do not match.`);
                    }
                    await createUser({}, { ...user, password, roleIds: user?.superAdmin ? [] : user?.roleIds });
                } catch (error) {
                    throw new Error(`Failed to create user - ${error.message}`);
                }
            }
        }
    });

    const dndSensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );
    
    const createSortDragEndHandler = useCallback((fieldName: keyof Dto.RdmsApiV1.SystemUser) => (event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const items = (user?.[fieldName] || []) as any[];
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const moved = arrayMove(items, oldIndex, newIndex);
            setUser(u => ({ ...u, [fieldName]: moved }));
        }
    }, [user]);

    const handleAddNewRole = useCallback(() => {
        if (newRole) {
            if (!user?.roleIds?.find(id => id === newRole.id)) {
                setUser(u => ({ ...u, roleIds: [...(u?.roleIds || []), newRole.id] }));
            }
            setNewRole(null);
        }
    }, [newRole, user?.roleIds]);

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Users are represented by username and may log into the system. Their permissions and security classification is determined by assigned roles.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField
                        autoFocus
                        title="Username"
                        placeholder="admin"
                        disabled={state.blocked}
                        value={user?.username || ""}
                        onChange={value => setUser(u => ({ ...u, username: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="Display Name"
                        placeholder="John Doe"
                        disabled={state.blocked}
                        value={user?.displayName || ""}
                        onChange={value => setUser(u => ({ ...u, displayName: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="E-mail"
                        placeholder="john.doe@hogwarts.com"
                        disabled={state.blocked}
                        value={user?.email || ""}
                        onChange={value => setUser(u => ({ ...u, email: value as string }))}
                    />
                </Grid>
                {!props.user && (
                    <>
                        <Grid item xs={12}>
                            <FormTextField
                                title="Password"
                                type="password"
                                disabled={state.blocked}
                                value={password}
                                onChange={value => setPassword(value as string)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                title="Repeat Password"
                                type="password"
                                disabled={state.blocked}
                                value={passwordRepeat}
                                onChange={value => setPasswordRepeat(value as string)}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Enable user"
                        disabled={state.blocked}
                        control={<Switch checked={user?.enabled || false} onChange={(_, checked) => setUser(u => ({ ...u, enabled: checked }))} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Enable super administrator access"
                        disabled={state.blocked}
                        control={<Switch checked={user?.superAdmin || false} color="warning" onChange={(_, checked) => setUser(u => ({ ...u, superAdmin: checked }))} />}
                    />
                </Grid>
            </Grid>
            <Collapse in={user && !user?.superAdmin}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <List disablePadding subheader={<ListSubheader disableGutters sx={{ backgroundColor: "inherit" }}>Roles</ListSubheader>}>
                            <DndContext sensors={dndSensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={createSortDragEndHandler("roleIds")}>
                                <SortableContext items={user?.roleIds || []} strategy={verticalListSortingStrategy}>
                                    {(user?.roleIds || []).map((roleId, index) =>
                                        <SortableUserRole
                                            key={roleId}
                                            id={roleId}
                                            disabled={state.blocked}
                                            onDelete={() => setUser(u => ({ ...u, roleIds: (u?.roleIds || []).filter((_, i) => i !== index) }))}
                                        />
                                    )}
                                </SortableContext>
                            </DndContext>
                        </List>
                        <List disablePadding>
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <IconButton disabled={state.blocked} onClick={() => handleAddNewRole()}>
                                        <Add />
                                    </IconButton>
                                }
                            >
                                <ListItemIcon sx={{ minWidth: "40px" }}>
                                    <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "plus-circle"]} />
                                </ListItemIcon>
                                <UserRoleAutocomplete
                                    sx={{
                                        width: "100%",
                                    }}
                                    disabled={state.blocked}
                                    value={newRole}
                                    onChange={role => setNewRole(role || null)}
                                    onKeyDown={evt => {
                                        if (evt.key === "Enter") {
                                            evt.preventDefault();
                                            handleAddNewRole();
                                        }
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Collapse>
        </Box>
    );
};

const EditUserAction: ActionDef<Props> = {
    id: "user-edit",
    title: "Edit User",
    component: Action,
    dialog: {
        confirmButtonCaption: "Update",
    },
    menu: {
        title: "Edit User",
        subtitle: "Update user information and roles"
    }
};

export default EditUserAction;
