import { Settings } from "@mui/icons-material";
import { Button, Typography, Stack, Chip, Menu, IconButton, Grid } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import { ActionMenuItem } from "components/Action";
import ClearDeviceCustomerAction from "components/ObjectActions/Device/ClearDeviceCustomerAction";
import ClearDeviceDcmProfileAction from "components/ObjectActions/Device/ClearDeviceDcmProfileAction";
import ClearDeviceRpsProfileAction from "components/ObjectActions/Device/ClearDeviceRpsProfileAction";
import ClearDeviceServicePartnerAction from "components/ObjectActions/Device/ClearDeviceServicePartnerAction";
import SetDeviceCustomerAction from "components/ObjectActions/Device/SetDeviceCustomerAction";
import SetDeviceDcmProfileAction from "components/ObjectActions/Device/SetDeviceDcmProfileAction";
import SetDeviceRpsProfileAction from "components/ObjectActions/Device/SetDeviceRpsProfileAction";
import SetDeviceServicePartnerAction from "components/ObjectActions/Device/SetDeviceServicePartnerAction";
import ObjectReference from "components/ObjectReference";
import { useDeviceActions } from "hooks/useDeviceActions";
import { useState } from "react";
import { useDeviceType } from "hooks/useDeviceType";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";


interface SettingsCardProps {
    device?: Dto.RdmsApiV1.Device;
    loading?: boolean;
    onUpdate?: () => void;
}
export const SettingsCard: React.FC<SettingsCardProps> = props => {
    const objectAuth = useObjectAuthorizationInfo(props.device);

    const [customerMenuAnchorEl, setCustomerMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [servicePartnerMenuAnchorEl, setServicePartnerMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [dcmProfileMenuAnchorEl, setDcmProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [rpsProfileMenuAnchorEl, setRpsProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    
    const deviceType = useDeviceType(props.device);

    const deviceActions = useDeviceActions({ onUpdate: props.onUpdate });

    const toggleEnabled = () => {
        if (props.device) {
            if (props.device.enabled) {
                deviceActions.disable({ devices: [props.device] });
            } else {
                deviceActions.enable({ devices: [props.device] });
            }
        }
    };

    return (
        <Grid item xs={12} sm={6} lg={4}>
            <DefaultCard title="Settings" loading={props.loading} skeletonHeight={595}>
                <InfoList>
                    <InfoListItem title="Administrative Status" tail={objectAuth.hasAccess("update") && <Button size="small" onClick={() => toggleEnabled()}>{props.device?.enabled ? "Disable" : "Enable"}</Button>}>
                        <Typography variant="body2" color={props.device?.enabled ? "success.main" : "warning.main"}>{props.device?.enabled ? "Enabled" : "Disabled"}</Typography>
                    </InfoListItem>
                    <InfoListItem title="Customer" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setCustomerMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                        <ObjectReference icon type="business-subject" id={props.device?.customerBusinessSubjectId} />
                    </InfoListItem>
                    <InfoListItem title="Service Partner" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setServicePartnerMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                        <ObjectReference icon type="business-subject" id={props.device?.servicePartnerBusinessSubjectId} />
                    </InfoListItem>
                    {deviceType.type?.capabilities.supportsDcmProfile &&
                        <InfoListItem title="DCM Profile" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setDcmProfileMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                            <Stack direction="row">
                                <ObjectReference icon type="dcm-profile" id={props.device?._meta.configuredDcmProfile?.id} />
                                {props.device?._meta.configuredDcmProfile && props.device?._meta.configuredDcmProfile?.source !== "device" && <Chip sx={{ ml: .5 }} size="small" label={`via ${props.device?._meta.configuredDcmProfile?.source}`} />}
                            </Stack>
                        </InfoListItem>
                    }
                    {deviceType.type?.capabilities.supportsRpsProfile &&
                        <InfoListItem title="RPS Profile" tail={objectAuth.hasAccess("update") && <IconButton onClick={evt => setRpsProfileMenuAnchorEl(evt.currentTarget)}><Settings /></IconButton>}>
                            <Stack direction="row">
                                <ObjectReference icon type="rps-profile" id={props.device?._meta.configuredRpsProfile?.id} />
                                {props.device?._meta.configuredRpsProfile && props.device?._meta.configuredRpsProfile?.source !== "device" && <Chip sx={{ ml: .5 }} size="small" label={`via ${props.device?._meta.configuredRpsProfile?.source}`} />}
                            </Stack>
                        </InfoListItem>
                    }
                </InfoList>
            </DefaultCard>
            {objectAuth.hasAccess("update") && (
                <>
                    <Menu open={!!customerMenuAnchorEl} anchorEl={customerMenuAnchorEl} onClick={() => setCustomerMenuAnchorEl(null)} onClose={() => setCustomerMenuAnchorEl(null)}>
                        <ActionMenuItem def={SetDeviceCustomerAction} action={() => props.device && deviceActions.setCustomer({ devices: [props.device] })} />
                        {props.device?.customerBusinessSubjectId &&
                     <ActionMenuItem def={ClearDeviceCustomerAction} action={() => props.device && deviceActions.clearCustomer({ devices: [props.device] })} />
                        }
                    </Menu>
                    <Menu open={!!servicePartnerMenuAnchorEl} anchorEl={servicePartnerMenuAnchorEl} onClick={() => setServicePartnerMenuAnchorEl(null)} onClose={() => setServicePartnerMenuAnchorEl(null)}>
                        <ActionMenuItem def={SetDeviceServicePartnerAction} action={() => props.device && deviceActions.setServicePartner({ devices: [props.device] })} />
                        {props.device?.servicePartnerBusinessSubjectId &&
                   <ActionMenuItem def={ClearDeviceServicePartnerAction} action={() => props.device && deviceActions.clearServicePartner({ devices: [props.device] })} />
                        }
                    </Menu>
                    <Menu open={!!dcmProfileMenuAnchorEl} anchorEl={dcmProfileMenuAnchorEl} onClick={() => setDcmProfileMenuAnchorEl(null)} onClose={() => setDcmProfileMenuAnchorEl(null)}>
                        <ActionMenuItem def={SetDeviceDcmProfileAction} action={() => props.device && deviceActions.setDcmProfile({ devices: [props.device] })} />
                        {props.device?.dcmProfileId &&
                    <ActionMenuItem def={ClearDeviceDcmProfileAction} action={() => props.device && deviceActions.clearDcmProfile({ devices: [props.device] })} />
                        }
                    </Menu>
                    <Menu open={!!rpsProfileMenuAnchorEl} anchorEl={rpsProfileMenuAnchorEl} onClick={() => setRpsProfileMenuAnchorEl(null)} onClose={() => setRpsProfileMenuAnchorEl(null)}>
                        <ActionMenuItem def={SetDeviceRpsProfileAction} action={() => props.device && deviceActions.setRpsProfile({ devices: [props.device] })} />
                        {props.device?.rpsProfileId &&
                    <ActionMenuItem def={ClearDeviceRpsProfileAction} action={() => props.device && deviceActions.clearRpsProfile({ devices: [props.device] })} />
                        }
                    </Menu>
                </>
            )}
        </Grid>
    );
};