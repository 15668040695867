import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem, InfoListStatusItem } from "components/InfoList";
import { useMemo } from "react";

interface Props {
    title: string;
    loading?: boolean;
    updateProfile?: Dto.RdmsApiV1.DcmProfile["updateProfiles"][0];
    defaultBaseUrl?: string;
}

export const DcmProfileUpdateProfileCard: React.FC<Props> = props => {
    const theme = useTheme();

    const statusColor = useMemo(() => props.updateProfile ? props.updateProfile.enabled ? theme.palette.success.main : theme.palette.warning.main : theme.palette.text.disabled, [props.updateProfile, theme]);
    const statusIcon = useMemo<IconProp>(() => props.updateProfile ? props.updateProfile.enabled ? ["fas", "circle-check"] : ["fas", "power-off"] : ["fas", "check-circle"], [props.updateProfile]);
    const statusSubtext = useMemo(() => props.updateProfile ? props.updateProfile.enabled ? "Updates are managed by DCM profile and device may only update as configured." : "Updates are disabled for this package." : "No update limitations are configured.", [props.updateProfile]);
    const statusText = useMemo(() => props.updateProfile ? props.updateProfile.enabled ? "Enabled" : "Disabled" : "Unmanaged", [props.updateProfile]);

    return (
        <DefaultCard title={props.title} loading={props.loading} skeletonHeight={350}>
            <InfoList>
                <InfoListStatusItem title="Status" text={statusText} subtext={statusSubtext} color={statusColor} icon={statusIcon} />
                {props.updateProfile?.enabled !== false && (
                    <>
                        <InfoListItem title="Base URL">{props.updateProfile?.baseUrl || props.defaultBaseUrl}</InfoListItem>
                        <InfoListItem title="Channel">{props.updateProfile?.channel || "stable"}</InfoListItem>
                        <InfoListItem title="Version Selector">{props.updateProfile?.versionSelector || "*"}</InfoListItem>
                    </>
                )}
            </InfoList>
        </DefaultCard>
    );
};

export default DcmProfileUpdateProfileCard;