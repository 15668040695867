import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretUp, faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Collapse } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

export interface MainMenuItemProps {
    title: string;
    description?: string;
    icon: IconProp;
    iconSelected?: IconProp;
    to?: string;
    onClick?: () => void;
    children?: React.ReactElement[];
    disableCommandBar?: boolean;
    _isChild?: boolean;
    _onSelectChange?: (selected: boolean) => void;
}

export const MainMenuItem: React.FC<MainMenuItemProps> = props => {
    const routerLocation = useLocation();
    const [childrenOpen, setChildrenOpen] = useState(false);
    const [selectedChildren, setSelectedChildren] = useState<any[]>([]);
    const selected = useMemo(() => !!props.to && !!routerLocation.pathname.startsWith(props.to), [props.to, routerLocation.pathname]);

    useEffect(() => props._onSelectChange?.(selected), [props, selected]);
    useEffect(() => { selectedChildren.length > 0 && setChildrenOpen(true) }, [selectedChildren.length]);

    const children = useMemo(() => {
        const children = [];
        if (props.children) {
            let i = 0;
            for (const child of props.children) {
                children.push(
                    React.cloneElement(
                        child,
                        {
                            key: i,
                            _isChild: true,
                            _onSelectChange: (selected: boolean) => {
                                const index = selectedChildren.findIndex(c => c === child)
                                if (index > -1 && !selected) {
                                    setSelectedChildren(c => c.splice(index, 1));
                                } else if (index === -1 && selected) {
                                    setSelectedChildren(c => [...c, child]);
                                }
                            }
                        }
                    )
                );
                i++;
            }
        }
        return children;
    }, [props.children, selectedChildren]);

    const listItem = (
        <ListItem disablePadding>
            <ListItemButton
                sx={{
                    pl: props._isChild ? 6 : undefined,
                    "&.Mui-selected": {
                        borderRight: t => `2px solid ${t.palette.primary.main}`
                    },
                    "& .MuiListItemText-primary": {
                        color: t => selected ? t.palette.primary.main : t.palette.text.secondary,
                        fontWeight: selected || (selectedChildren.length > 0) ? 600 : undefined
                    }
                }}
                selected={selected}
                onClick={() => {
                    if (props.children) {
                        setChildrenOpen(v => !v);
                    } else {
                        props.onClick?.();
                    }
                }}
            >
                <ListItemIcon sx={{ color: t => t.palette.primary.main, minWidth: "36px" }}>
                    <FontAwesomeIcon icon={selected || (selectedChildren.length > 0) ? props.iconSelected || props.icon : props.icon} fixedWidth size="lg" />
                </ListItemIcon>
                <ListItemText primary={props.title} />
                {props.children &&
                <Box sx={{ color: t => t.palette.text.secondary }}>
                    {childrenOpen ? <FontAwesomeIcon icon={faCaretUp} fixedWidth /> : <FontAwesomeIcon icon={faCaretDown} fixedWidth />}
                </Box>
                }
            </ListItemButton>
        </ListItem>
    );

    let collapse = undefined;
    if (children) {
        collapse = (
            <Collapse in={childrenOpen}>
                {children}
            </Collapse>
        );
    }

    return props.to ?
        (
            <>
                <Link component={RouterLink} to={props.to || "/"} sx={{ textDecoration: "none" }}>
                    {listItem}
                </Link>
                {collapse}
            </>
        ) : 
        (
            <>
                {listItem}
                {collapse}
            </>
        )
};