import {useSortable} from "@dnd-kit/sortable";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListItem, IconButton, ListItemIcon, TextField} from "@mui/material";
import {CSS} from "@dnd-kit/utilities";
import { Delete } from "@mui/icons-material";

const SortableTextFieldListItem: React.FC<{ id: string, item: string, icon?: IconProp, disabled?: boolean, onChange: (value: string) => void, onDelete?: () => void }> = props => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.id});
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
      
    return (
        <ListItem
            disableGutters
            ref={setNodeRef}
            style={style}
            secondaryAction={
                <IconButton onClick={evt => props.onDelete?.()}>
                    <Delete />
                </IconButton>
            }
        >
            <ListItemIcon sx={{ outline: "none", cursor: "move", minWidth: "40px" }} {...attributes} {...listeners}>
                <FontAwesomeIcon size="lg" fixedWidth icon={["fas", "grip-dots-vertical"]} />
            </ListItemIcon>
            {props.icon && 
                <ListItemIcon sx={{ color: "primary.main", minWidth: "40px" }}>
                    <FontAwesomeIcon size="lg" icon={props.icon} />
                </ListItemIcon>
            }
            <TextField sx={{ width: "100%", m: t => t.spacing(0, 1, 0, 0) }} value={props.item} variant="standard" disabled={props.disabled} onChange={evt => props.onChange?.(evt.target.value)} />
        </ListItem>
    );
}

export default SortableTextFieldListItem;