import { CircularProgress, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

export const defaultStatsResolutions = [
    { label: "1 hour", lookbehind: 1 * 60 * 60 * 1000, step: 300, timeAxisGranularity: "15 minutes", timeAxisFormat: "%H:%M" },
    { label: "8 hours", lookbehind: 8 * 60 * 60 * 1000, step: 600, timeAxisGranularity: "1 hours", timeAxisFormat: "%H:%M" },
    { label: "1 day", lookbehind: 1 * 24 * 60 * 60 * 1000, step: 900, timeAxisGranularity: "4 hours", timeAxisFormat: "%H:%M" },
    { label: "3 days", lookbehind: 3 * 24 * 60 * 60 * 1000, step: 3600, timeAxisGranularity: "8 hours", timeAxisFormat: "%b %d" },
    { label: "7 days", lookbehind: 7 * 24 * 60 * 60 * 1000, step: 4 * 3600, timeAxisGranularity: "1 days", timeAxisFormat: "%b %d", default: true },
    { label: "14 days", lookbehind: 14 * 24 * 60 * 60 * 1000, step: 8 * 3600, timeAxisGranularity: "1 days", timeAxisFormat: "%b %d" },
    { label: "30 days", lookbehind: 30 * 24 * 60 * 60 * 1000, step: 12 * 3600, timeAxisGranularity: "3 days", timeAxisFormat: "%b %d" },
    { label: "60 days", lookbehind: 60 * 24 * 60 * 60 * 1000, step: 24 * 3600, timeAxisGranularity: "7 days", timeAxisFormat: "%b %d" },
    { label: "90 days", lookbehind: 90 * 24 * 60 * 60 * 1000, step: 48 * 3600, timeAxisGranularity: "14 days", timeAxisFormat: "%b %d" }
];
export type StatsResolution = typeof defaultStatsResolutions[0];

export interface StatsControlBarProps {
    title?: string;
    loading?: boolean;
    minLookbehind?: number;
    maxLookbehind?: number;
    default?: StatsResolution;
    onChange: (resolution: StatsResolution) => void;
}

export const StatsControlBar: React.FC<StatsControlBarProps> = props => {
    const theme = useTheme();

    const availableResolutions = useMemo(
        () =>
            defaultStatsResolutions
                .filter(resolution => !props.minLookbehind || resolution.lookbehind >= props.minLookbehind)
                .filter(resolution => !props.maxLookbehind || resolution.lookbehind <= props.maxLookbehind),
        [props.maxLookbehind, props.minLookbehind]
    );
    const [currentResolution, setCurrentResolution] = useState(defaultStatsResolutions.find(r => props.default?.lookbehind === r.lookbehind || (!props.default && r.default)) as StatsResolution);
    useEffect(() => props.onChange(currentResolution), [currentResolution, props]);

    return (
        <Stack direction="row" alignItems="center" flexWrap="wrap">
            {props.title === undefined && props.title !== null && props.title !== "" && <Typography sx={{ mr: 1 }} variant="overline" color="primary.main">{props.title || "Resolution"}</Typography>}
            <ToggleButtonGroup
                sx={{
                    overflowX: "auto",
                    borderRadius: theme.shape.borderRadius,
                    border: `1px ${theme.palette.divider} solid`,
                    p: 0,
                    "& .MuiToggleButtonGroup-grouped": {
                        border: 0,
                        "&:not(:first-of-type)": {
                            border: 0,
                            borderRadius: 0,
                        },
                        "&:first-of-type": {
                            border: 0,
                            borderRadius: 0,
                        },
                        "&.Mui-disabled": {
                            border: 0,
                            borderColor: "transparent",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                            color: theme.palette.background.default,
                            backgroundColor: theme.palette.primary.main,
                        }
                    },
                }}
                disabled={props.loading}
                value={currentResolution}
                exclusive
                onChange={(_, v) => setCurrentResolution(v || currentResolution)}
            >
                {availableResolutions.map(resolution =>
                    <ToggleButton key={resolution.label} value={resolution}>
                        {resolution.label}
                    </ToggleButton>)
                }
            </ToggleButtonGroup>
            {props.loading && <CircularProgress sx={{ display: { xs: "none", md: "block" }, m: 1 }} size={24} />}
        </Stack>
    );
};

export default StatsControlBar;