import { Box } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { NavigationTabs, NavigationTab } from "components/NavigationTabs";
import { ObjectHeader } from "components/ObjectHeader";
import useApiResource from "hooks/useApiResource";
import React, { useCallback } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import BusinessSubjectDetailGeneralView from "./BusinessSubjectDetailGeneralView";
import BusinessSubjectDetailDevicesView from "./BusinessSubjectDetailDevicesView";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";

export const BusinessSubjectDetailView: React.FC = () => {
    const params = useParams<{ subjectId?: string }>();
    const history = useHistory();

    const businessSubject = useApiResource<Dto.RdmsApiV1.BusinessSubject>(`/v1/business-subjects/${params.subjectId}`, { disable: !params.subjectId });

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!businessSubject.data) {
            return factory;
        }
        return factory
            .section(businessSubject.data.name)
            .action("subject-show-data", "Show Subject Information").priority(8100).do(() => history.push(`/business-subjects/${businessSubject.data?.id || ""}`))
            .action("subject-show-customer-devices", "Show Customer Devices").priority(8100).do(() => history.push(`/business-subjects/${businessSubject.data?.id || ""}/devices`))
    }, [businessSubject.data, history]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={businessSubject.initialOrLongLoading}
                icon="building"
                title={businessSubject.data?.name || ""}
                subtitle={businessSubject.data?.address}
            />
            <NavigationTabs prefix={`/business-subjects/${params.subjectId}`}>
                <NavigationTab label="Subject" path="" />
                <NavigationTab label="Devices" path="/devices" badgeContent={businessSubject.data?._meta.deviceCount || undefined} />
            </NavigationTabs>
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Switch>
                    <Route exact path="/business-subjects/:id" render={() => <BusinessSubjectDetailGeneralView subject={businessSubject.data} onUpdate={() => businessSubject.reload()} />} />
                    <Route exact path="/business-subjects/:id/devices" render={() => <BusinessSubjectDetailDevicesView subject={businessSubject.data} />} />
                </Switch>
            </Box>
        </>
    );
};

export default BusinessSubjectDetailView;