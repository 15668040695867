import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Dto } from "@varos/rdm-common";
import Center from "components/Center";
import DataGrid from "components/DataGrid";
import RpsBindingDialog from "components/RpsBindingDialog";
import RpsBindingStatusDisplay from "components/RpsBindingStatusDisplay";
import useApiResource from "hooks/useApiResource";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";


const DeviceDetailRpsBindingsView: React.FC<{ device: Dto.RdmsApiV1.Device | undefined, onUpdate?: () => Promise<void> }> = props => {
    const theme = useTheme();
    const hideExtraColumns = useMediaQuery(theme.breakpoints.down("md"));
    const rpsBindings = useApiResource<Dto.RdmsApiV1.RpsBinding[]>(`/v1/devices/${props.device?.id}/rps-bindings`, { disable: !props.device, ignoreNotFound: true });

    const [bindingDialogOpen, setBindingDialogOpen] = useState<boolean>(false);
    const [selectedRpsBinding, setSelectedRpsBinding] = useState<null | Dto.RdmsApiV1.RpsBinding>(null);

    const handleOpenRpsBinding = (evt: React.MouseEvent, binding: Dto.RdmsApiV1.RpsBinding) => {
        evt.preventDefault();
        setSelectedRpsBinding(binding);
        setBindingDialogOpen(true);
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: "token",
            headerName: "Token (first 24 chars)",
            flex: 1,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={faLink} />
                    <Typography variant="body2" color="primary" sx={{ textDecoration: "none", ml: .5 }} component={Link} to="#" onClick={evt => handleOpenRpsBinding(evt, params.row)}>
                        {params.value.substr(0, 24)}...
                    </Typography>
                </Box>
            )
        },
        {
            field: "establishTime",
            headerName: "Established On",
            hide: hideExtraColumns,
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: params => <RpsBindingStatusDisplay variant="body2" status={params.value} />
        },
        {
            field: "updateTime",
            headerName: "Last Update",
            flex: 1,
            hide: true
        }
    ], [hideExtraColumns]);

    if (!props.device || rpsBindings.loading) {
        return <Center><CircularProgress /></Center>;
    }
    return (
        <>
            <DataGrid
                columns={columns}
                rows={rpsBindings.data || []}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "establishTime", sort: "desc" }],
                    },
                }}
            />
            <RpsBindingDialog open={bindingDialogOpen} setOpen={setBindingDialogOpen} object={selectedRpsBinding || undefined} onUpdate={() => rpsBindings.reload()} />
        </>
    );
};

export default DeviceDetailRpsBindingsView;