import { ActionDef } from "components/Action";
import { Action } from "./EditRpsProfileAction";

const CreateRpsProfileAction: ActionDef<void> = {
    id: "rps-profile-create",
    title: "Create RPS Profile",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Create"
    }
};

export default CreateRpsProfileAction;