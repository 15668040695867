import useObjectAction from "./useObjectAction";
import SetGlobalDefaultDcmProfileAction from "components/ObjectActions/DcmProfile/SetGlobalDefaultDcmProfileAction";
import DeleteDcmProfileAction from "components/ObjectActions/DcmProfile/DeleteDcmProfileAction";
import CreateDcmProfileAction from "components/ObjectActions/DcmProfile/CreateDcmProfileAction";
import EditDcmProfileAction from "components/ObjectActions/DcmProfile/EditDcmProfileAction";

export const useDcmProfileActions = (opts: { onUpdate?: () => void }) => {
    const create = useObjectAction(CreateDcmProfileAction, opts.onUpdate);
    const edit = useObjectAction(EditDcmProfileAction, opts.onUpdate);
    const deleteProfile = useObjectAction(DeleteDcmProfileAction, opts.onUpdate);
    const setGlobalDefault = useObjectAction(SetGlobalDefaultDcmProfileAction, opts.onUpdate);

    return {
        create,
        edit,
        deleteProfile,
        setGlobalDefault
    };
};
