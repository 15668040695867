import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useApiResource from "hooks/useApiResource";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { DcmProfileSetIcon } from "components/Icons";
import { useDeviceTypes } from "hooks/useDeviceTypes";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deviceTypes = useDeviceTypes();
    const dcmProfiles = useApiResource<Dto.RdmsApiV1.DcmProfile[]>("/v1/dcm-profiles");
    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });
    const [selected, setSelected] = useState<Dto.RdmsApiV1.DcmProfile | null>(null);

    const state = useActionState({
        ...props,
        loading: dcmProfiles.loading,
        animationDelay: 500,
        prepare: async () => {
            const unsupported: Dto.RdmsApiV1.Device[] = [];
            for (const device of props.devices) {
                const typeSpec = await deviceTypes.getForDeviceAsync(device);
                if (!typeSpec.type?.capabilities.supportsDcmProfile) {
                    unsupported.push(device);
                }
            }
            if (unsupported.length > 0) {
                throw new Error(`Cannot proceed with action, because ${unsupported.length} device${unsupported.length > 1 ? "s do not" : " does not"} support DCM profile assignment.`);
            }
        },
        process: async setProgress => {
            if (!selected) {
                throw new Error("No DCM profile has been selected.");
            }
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { dcmProfileId: selected.id });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Setting a DCM profile to device will override any profiles assigned via customer or globally.
                DCM profile defines how the device interacts with Varos RDM platform and controls various device behaviours
                (updates, remote printing, data collection and other).
            </Typography>
            <ActionDeviceTargetList devices={props.devices} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose profile to apply to selected devices:</Typography>
            <Autocomplete
                autoHighlight
                disabled={state.blocked}
                sx={{ width: "100%" }}
                options={dcmProfiles.data || []}
                value={selected}
                onChange={(_, value) => setSelected(value)}
                getOptionLabel={option => option.title}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        <Stack direction="row">
                            <FontAwesomeIcon icon={["fad", "badge-check"]} fixedWidth />
                            <Typography sx={{ ml: .5 }}>{option.title}</Typography>
                        </Stack>
                    </Box>
                )}
                renderInput={params => (
                    <TextField autoFocus label="Select DCM profile" {...params} inputProps={{...params.inputProps, autoComplete: "new-password"}} />
                )}
            />
        </Box>
    );
};

const SetDeviceDcmProfileAction: ActionDef<Props> = {
    id: "device-set-dcm-profile",
    title: "Set Device DCM Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Profile",
    },
    menu: {
        title: "Set DCM profile",
        subtitle: "Assign individual DCM profile to device",
        icon: <DcmProfileSetIcon />
    }
};

export default SetDeviceDcmProfileAction;