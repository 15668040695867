import { Box, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useState } from "react";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { DeviceSetAliasIcon } from "components/Icons";

interface Props {
    device: Dto.RdmsApiV1.Device;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [alias, setAlias] = useState<string | null>(props.device.alias);

    const state = useActionState({
        ...props,
        process: async () => {
            try {
                await updateDevice({ id: props.device.id }, { alias });
            } catch (error) {
                throw new Error(`Failed to update device ${props.device.id} - ${error.message}`);
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Device alias can be used to internally distinguish devices for management purposes.
                Aliases are not exposed to clients.
            </Typography>
            <ActionDeviceTargetList devices={[props.device]} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Set an alias for the device</Typography>
            <TextField
                autoFocus
                disabled={state.blocked}
                sx={{ width: "100%" }}
                label="Set device alias"
                variant="outlined"
                value={alias}
                onChange={evt => setAlias(evt.target.value)}
            />
        </Box>
    );
};

const SetDeviceAliasAction: ActionDef<Props> = {
    id: "device-set-alias",
    title: "Set Device Alias",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Alias"
    },
    menu: {
        title: "Set Device Alias",
        subtitle: "Configure user-friendly management alias",
        icon: <DeviceSetAliasIcon />
    }
};

export default SetDeviceAliasAction;