import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDef, ActionUserRoleTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { UserRoleDeleteIcon } from "components/Icons";

interface Props {
    userRoles: Dto.RdmsApiV1.SystemUserRole[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deleteRole = useApiInvocation<{ id: string}>("delete", `/v1/system/user-roles/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, role] of props.userRoles.entries()) {
                try {
                    await deleteRole({ id: role.id });
                    setProgress((index + 1) / props.userRoles.length);
                } catch (error) {
                    throw new Error(`Failed to delete user role ${role.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Removing user role will unassign any permissions it assigns to users.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="error" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                This is a destructive action that cannot be undone. Proceed with caution!
            </Alert>
            <ActionUserRoleTargetList userRoles={props.userRoles} operation="deleted" />
        </Box>
    );
};

const DeleteUserRoleAction: ActionDef<Props> = {
    id: "user-role-delete",
    title: "Delete User Role",
    component: Action,
    dialog: {
        confirmButtonCaption: "Delete",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Delete User Role",
        subtitle: "Remove user role from system",
        icon: <UserRoleDeleteIcon />,
        color: "error.main"
    }
};

export default DeleteUserRoleAction;