import { Security } from "@varos/rdm-common";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import React, { useMemo } from "react";

interface ObjectProps {
    object: { _meta?: { currentAuthorization?: Security.Authorization.UserObjectAuthorizationInfo } } | undefined;
    operation: Security.Authorization.OperationName;
}
interface GlobalProps {
    resource: Security.Authorization.ResourceName;
    operation: Security.Authorization.OperationName;
}
type Props = { children: React.ReactNode; } & (GlobalProps | ObjectProps);

export const RequireAuthorization: React.FC<Props> = props => {
    const auth = useAuthenticationInfo();
    const objectAuth = useObjectAuthorizationInfo((props as ObjectProps).object);

    const access = useMemo(() => {
        if ((props as GlobalProps).resource) {
            return auth.hasAccess((props as GlobalProps).resource, props.operation);
        } else if ((props as ObjectProps).object) {
            return objectAuth.hasAccess(props.operation);
        }
        return false;
    }, [auth, objectAuth, props]);

    return (<>{access && props.children}</>);
};
