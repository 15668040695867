import React from "react";
import { Alert, AlertTitle, CircularProgress, CssBaseline, Stack, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { currentTheme$, lightTheme } from "constants/theme";
import useObservable from "hooks/useObservable";
import Api from "services/api.service";
import Center from "components/Center";
import LoginView from "views/Login";
import MainLayoutView from "./MainLayoutView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ModalProvider } from "mui-modal-provider";
import CommandBar from "components/CommandBar";
import { KBarProvider } from "kbar";
import * as semver from "semver";

const requiredServerVersion = ">=1.5.0-0";

const RootLayoutView: React.FC = () => {
    const apiState = useObservable(Api.state$);
    const theme = useObservable(currentTheme$);

    let layout: React.ReactNode;
    if (apiState === "init") {
        layout = (
            <Center>
                <CircularProgress />
            </Center>
        );
    } else if (apiState === "unavailable") {
        layout = (
            <Center>
                <Stack direction="column" spacing={2} alignItems="center">
                    <Alert sx={{ marginTop: "16px", maxWidth: 400 }} variant="outlined" severity="warning" icon={<FontAwesomeIcon icon={["fas", "chain-broken"]} />}>
                        <AlertTitle>Cannot connect to server</AlertTitle>
                        RDM Server API service seems to be unreachable. We will keep trying to connect.
                    </Alert>
                    <CircularProgress color="warning" />
                </Stack>
            </Center>
        );
    } else if (!!Api.serverVersion && !semver.satisfies(Api.serverVersion, requiredServerVersion, { includePrerelease: true, loose: true })) {
        layout = (
            <Center>
                <Alert sx={{ maxWidth: 400 }} variant="outlined" severity="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                    <AlertTitle>Application failed to start</AlertTitle>
                    Unsupported API server version <strong>{Api.serverVersion}</strong>.
                    This version of RDM Portal is not compatible with connected RDM server version. Required version: <strong>{requiredServerVersion}</strong>.
                </Alert>
            </Center>
        );
    } else if (apiState === "unauthenticated") {
        layout = <LoginView />;
    } else if (apiState === "authenticated") {
        layout = (
            <KBarProvider>
                <CommandBar />
                <MainLayoutView />
            </KBarProvider>
        );
    }
    return (
        <HelmetProvider>
            <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={5000}>
                <ThemeProvider theme={theme || lightTheme}>
                    <Helmet>
                        <meta name="theme-color" content={theme?.palette.mode === "dark" ? theme.palette.background.default : theme?.palette.primary.main} />
                        <meta name="background-color" content={theme?.palette.background.default} />
                    </Helmet>
                    <ModalProvider>
                        <CssBaseline />
                        <BrowserRouter>
                            {layout}
                        </BrowserRouter>
                    </ModalProvider>
                </ThemeProvider>
            </SnackbarProvider>
        </HelmetProvider>
    );
};
export default RootLayoutView;
