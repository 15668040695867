import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";

const defaultSmallIconTransform = {
    size: 14,
    x: 8,
    y: 6
};

const createSubscriptedIcon = (opts: {primary: IconProp, subscript: IconProp}) => () => {
    const theme = useTheme();

    return (
        <div className="fa-layers fa-fw">
            <FontAwesomeIcon icon={opts.primary} fixedWidth />
            <FontAwesomeIcon style={{ color: theme.palette.background.paper }} icon={["fas", "circle"]} transform={{...defaultSmallIconTransform, size: defaultSmallIconTransform.size + 3}}  />
            <FontAwesomeIcon icon={opts.subscript} transform={defaultSmallIconTransform} />
        </div>
    )
};

export const DeviceEnableIcon = createSubscriptedIcon({ primary: ["fas", "hdd"], subscript: ["fal", "circle-check"] });
export const DeviceDisableIcon = createSubscriptedIcon({ primary: ["fas", "hdd"], subscript: ["fal", "times-circle"] });
export const DeviceSetAliasIcon = createSubscriptedIcon({ primary: ["fas", "hdd"], subscript: ["fal", "tag"] });
export const DeviceRecoverIcon = createSubscriptedIcon({ primary: ["fas", "hdd"], subscript: ["fal", "file-code"] });
export const CustomerSetIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "circle-chevron-right"] });
export const CustomerClearIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "times-circle"] });
export const ServicePartnerSetIcon = createSubscriptedIcon({ primary: ["fas", "wrench"], subscript: ["fal", "circle-chevron-right"] });
export const ServicePartnerClearIcon = createSubscriptedIcon({ primary: ["fas", "wrench"], subscript: ["fal", "times-circle"] });
export const DcmProfileSetIcon = createSubscriptedIcon({ primary: ["fas", "badge-check"], subscript: ["fal", "circle-chevron-right"] });
export const DcmProfileClearIcon = createSubscriptedIcon({ primary: ["fas", "badge-check"], subscript: ["fal", "times-circle"] });
export const RpsProfileSetIcon = createSubscriptedIcon({ primary: ["fas", "print-search"], subscript: ["fal", "circle-chevron-right"] });
export const RpsProfileClearIcon = createSubscriptedIcon({ primary: ["fas", "print-search"], subscript: ["fal", "times-circle"] });

export const SubjectCreateIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "plus-circle"] });
export const SubjectEditIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "pencil"] });
export const SubjectSetNoteIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "circle-info"] });
export const SubjectDeleteIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "circle-trash"] });
export const SubjectSetRolesIcon = createSubscriptedIcon({ primary: ["fas", "building"], subscript: ["fal", "circle-user"] });

export const RpsProfileEditIcon = createSubscriptedIcon({ primary: ["fas", "print-search"], subscript: ["fal", "pencil"] });
export const RpsProfileDeleteIcon = createSubscriptedIcon({ primary: ["fas", "print-search"], subscript: ["fal", "circle-trash"] });
export const RpsProfileSetGlobalIcon = createSubscriptedIcon({ primary: ["fas", "print-search"], subscript: ["fal", "globe"] });

export const DcmProfileEditIcon = createSubscriptedIcon({ primary: ["fas", "badge-check"], subscript: ["fal", "pencil"] });
export const DcmProfileDeleteIcon = createSubscriptedIcon({ primary: ["fas", "badge-check"], subscript: ["fal", "circle-trash"] });
export const DcmProfileSetGlobalIcon = createSubscriptedIcon({ primary: ["fas", "badge-check"], subscript: ["fal", "globe"] });

export const UserRoleEditIcon = createSubscriptedIcon({ primary: ["fas", "shield"], subscript: ["fal", "pencil"] });
export const UserRoleDeleteIcon = createSubscriptedIcon({ primary: ["fas", "shield"], subscript: ["fal", "circle-trash"] });
export const UserRoleEnableIcon = createSubscriptedIcon({ primary: ["fas", "shield"], subscript: ["fal", "circle-check"] });
export const UserRoleDisableIcon = createSubscriptedIcon({ primary: ["fas", "shield"], subscript: ["fal", "times-circle"] });

export const UserEditIcon = createSubscriptedIcon({ primary: ["fas", "user"], subscript: ["fal", "pencil"] });
export const UserDeleteIcon = createSubscriptedIcon({ primary: ["fas", "user"], subscript: ["fal", "circle-trash"] });
export const UserEnableIcon = createSubscriptedIcon({ primary: ["fas", "user"], subscript: ["fal", "circle-check"] });
export const UserDisableIcon = createSubscriptedIcon({ primary: ["fas", "user"], subscript: ["fal", "times-circle"] });
export const UserChangePasswordIcon = createSubscriptedIcon({ primary: ["fas", "user"], subscript: ["fal", "key"] });
