import { Tabs, TabsProps } from "@mui/material";
import React, { ReactElement, useMemo } from "react";
import { useLocation  } from "react-router-dom";

export interface NavigationTabsProps {
    prefix?: string;
    enablePartialMatch?: boolean;
}

export const NavigationTabs: React.FC<NavigationTabsProps & TabsProps> = props => {
    const {prefix, enablePartialMatch, ...tabsProps} = props;
    const location = useLocation();
    const children = useMemo(() => {
        if (props.children) {
            const list = ((Array.isArray(props.children) ? props.children : [props.children]) as ReactElement[]);
            return list.filter(c => c).map((child, i) => React.cloneElement(child, { key: i, value: `${prefix || ""}${child.props.path}` }));
        }
        return props.children;
    } ,[props.children, prefix]);
    const value = useMemo(() => {
        const children = (Array.isArray(props.children) ? props.children : [props.children]) as ReactElement[];
        for (const child of children.filter(c => c)) {
            const path = `${prefix || ""}${child.props.path}`;
            if (location.pathname === path || (enablePartialMatch && location.pathname.startsWith(path))) {
                return path;
            }
        }
        return `${prefix || ""}${children[0].props.path}`;
    }, [props.children, prefix, location.pathname, enablePartialMatch]);

    return (
        <Tabs
            variant="scrollable"
            value={value}
            sx={{
                borderRadius: 1,
                backgroundColor: t => t.palette.mode === "light" ? t.palette.grey[100] : t.palette.grey[900]
            }}
            {...tabsProps}
        >
            {children}
        </Tabs>
    );
};
