import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import Heading from "./Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ObjectDetailProps {
    pageTitle?: string;
    icon: IconName;
    title: string;
    subtitle?: string;
    loading?: boolean;
    children?: React.ReactNode;
}

export const ObjectHeader: React.FC<ObjectDetailProps> = props => {
    return (
        <>
            {props.loading && props.pageTitle && <Skeleton variant="rounded" height={32} />}
            {!props.loading && props.pageTitle && <Heading>{props.pageTitle}</Heading>}
            <Stack direction="row" m={t => t.spacing(0, 0, 2, 0)} alignItems="flex-start">
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ color: t => t.palette.primary.main, fontSize: 48 }}>
                    {props.loading && <Skeleton variant="rounded" height={48} width={48} />}
                    {!props.loading && <FontAwesomeIcon icon={["fad", props.icon]} fixedWidth />}
                </Box>
                {props.loading &&
                    <Stack p={t => t.spacing(0, 2)} justifyContent="center">
                        <Skeleton width={128} />
                        <Skeleton width={192} />
                    </Stack>
                }
                {!props.loading &&
                    <Stack p={t => t.spacing(0, 2)} justifyContent="center">
                        <Typography variant="h5" fontWeight={200}>{props.title}</Typography>
                        <Typography variant="subtitle1" color="text.secondary">{props.subtitle}</Typography>
                    </Stack>
                }
                <Box flex="1 1 auto"></Box>
                {props.children}
            </Stack>
        </>
    );
};