import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Zoom, useMediaQuery, useTheme, LinearProgress, Alert, Collapse, Box } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ActionDef } from "components/Action";
import { ActionStatus } from "components/Action";
import { useModal } from "mui-modal-provider";
import React, { useCallback, useEffect, useState } from "react";

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => <Zoom ref={ref} {...props} />);

interface ActionDialogProps<P> {
    actionDef: ActionDef<P>;
    actionProps: P;
    onUpdate?: () => void;
}
const ActionDialog: React.FC<ActionDialogProps<any>> = props => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(true);
    
    const [actionStatus, setActionStatus] = useState<ActionStatus>({ state: "loading", blocked: true });
    const [shouldProcess, setShouldProcess] = useState(false);
    const [errorMessage, setErrorMessage ] = useState<string | null>(null);
    
    const handleClose = useCallback((updated: boolean = false) => {
        if (actionStatus.state === "processing") {
            return;
        }
        if (updated) {
            props.onUpdate?.();
        }
        setOpen(false);
    }, [actionStatus.state, props]);

    const handleProceed = useCallback(() => {
        if (["ready", "error"].indexOf(actionStatus.state) > -1 && !actionStatus.blocked) {
            setShouldProcess(true);
            setTimeout(() => setShouldProcess(false), 100);
        }
    }, [actionStatus]);

    useEffect(() => {
        if (actionStatus.state === "done") {
            handleClose(true);
        } else if (actionStatus.state === "error") {
            setErrorMessage(actionStatus.error || "Unknown error has occurred.");
        }
    }, [actionStatus.error, actionStatus.state, handleClose, shouldProcess]);

    return (
        <Dialog TransitionComponent={Transition} fullScreen={fullScreen} open={open} onClose={() => handleClose()} maxWidth={props.actionDef.dialog?.maxWidth || undefined}>
            <form onSubmit={evt => { evt.preventDefault(); handleProceed(); }}>
                <DialogTitle>{props.actionDef.title}</DialogTitle>
                <DialogContent>
                    <Collapse in={actionStatus.state === "error"}>
                        <Alert sx={{ mb: 2 }} variant="standard" color="error" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>{errorMessage}</Alert>
                    </Collapse>
                    <Box
                        sx={{
                            opacity: actionStatus.blocked ? .6 : 1,
                            filter: actionStatus.blocked ? `grayscale(100%)` : undefined,
                            pointerEvents: actionStatus.blocked ? "none" : undefined,
                            transition: "opacity 300ms ease, filter 300ms ease"
                        }}
                    >
                        {<props.actionDef.component onStatusChange={setActionStatus} shouldProcess={shouldProcess} {...props.actionProps as any} />}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={actionStatus.blocked} onClick={() => handleProceed()} color={props.actionDef.dialog?.confirmButtonColor || "primary"}>{props.actionDef.dialog?.confirmButtonCaption || "Proceed"}</Button>
                    <Button disabled={actionStatus.state === "processing"} onClick={() => handleClose()} color="inherit">Cancel</Button>
                </DialogActions>
                <LinearProgress
                    variant={actionStatus.progress !== undefined ? "determinate" : "indeterminate"}
                    value={(actionStatus.progress || 0) * 100}
                    sx={{
                        height: 6,
                        visibility: actionStatus.state === "loading" || actionStatus.state === "processing" ? "visible" : "hidden"
                    }}
                />
            </form>
        </Dialog>
    );
};

const useObjectAction = <P,>(actionDef: ActionDef<P>, onUpdate?: () => void) => {
    const { showModal } = useModal();

    return (props: P) => {
        showModal(ActionDialog, {actionDef, actionProps: props, onUpdate});
    };
};

export default useObjectAction;
