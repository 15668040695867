import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Card, CardMedia, Box, Button, styled, Tabs, Tab, CardContent, Divider, Grid, Badge, BadgeProps } from "@mui/material";
import React from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import ObjectIndexGrid from "./ObjectIndexGrid";

export const DetailGrid: React.FC<{ children: React.ReactNode }> = props => {
    return (
        <Box sx={{ m: t => t.spacing(2) }}>
            <ObjectIndexGrid container spacing={2}>
                {props.children}
            </ObjectIndexGrid>
        </Box>
    );
}

export const DetailGridKeyValue: React.FC<{ title: string, value: string | React.ReactNode | undefined, variant?: "body1" | "h5" }> = props => {
    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="body2" color="text.secondary" gutterBottom>{props.title}</Typography>
                    {props.value ?
                        (["string", "number"].includes(typeof props.value) ?
                            <Typography variant={props.variant || "h5"}>{props.value}</Typography> :
                            props.value
                        ) :
                        <Typography variant={props.variant || "h5"}>-</Typography>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
};

export const DetailGridActions: React.FC<{ children: React.ReactNode }> = props => {
    return (
        <Grid item xs={12}>
            {props.children}
        </Grid>
    );
};

export const DetailHeaderKeyValue: React.FC<{ title: string, value?: string }> = props => {
    return (
        <Typography variant="body1">
            <strong>{props.title}: </strong>
            <span>{props.value || "-"}</span>
        </Typography>
    );
};

export const DetailHeaderBackLink: React.FC<{ title: string, to: string }> = props => {
    return (
        <Button component={Link} to={props.to} color="inherit">
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: ".25rem" }} />
            {props.title}
        </Button>
    );
};

export const DetailHeaderStatusBox: React.FC<{
    icon: IconProp,
    primary: React.ReactNode,
    secondary?: React.ReactNode,
    tertiary?: React.ReactNode,
    disabled?: boolean,
    onClick?: (evt: React.MouseEvent<HTMLElement>) => void
}> = props => {
    return (
        <DetailHeaderVerticalContainer
            sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                flex: "0 0 auto",
                textTransform: "none",
                color: "inherit",
                "&:hover": {
                    backgroundColor: props.onClick ? "rgba(0, 0, 0, .1)" : "inherit"
                },
                m: t => t.spacing(0, 1)
            }}
            component={props.onClick ? Button : undefined}
            onClick={props.onClick ? (evt => props.onClick?.(evt)) : undefined}
        >
            <Typography
                sx={{
                    color: t => !props.disabled ? "white" : t.palette.text.disabled,
                    mb: t => t.spacing(1)
                }}
            >
                <FontAwesomeIcon icon={props.icon} size="3x" fixedWidth />
            </Typography>
            <Typography variant="body2">{props.primary}</Typography>
            {props.secondary && <Typography variant="body2" sx={{ opacity: .6 }}>{props.secondary}</Typography>}
            {props.tertiary && <Typography variant="body2" sx={{ opacity: .6 }}>{props.tertiary}</Typography>}
        </DetailHeaderVerticalContainer>
    );
};

export const DetailHeaderVerticalContainer = styled(Box)(({ theme }) => ({
    flex: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2)
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.primary.light,
        height: 4
    },
    marginLeft: theme.spacing(2)
}));

interface ComponentProps {
    icon: IconProp;
    title: string;
    tabs: {
        path: string;
        title: string;
        badge?: string;
        render: () => React.ReactNode;
        disabled?: boolean;
    }[];
    headerSubtitles?: React.ReactElement<typeof DetailHeaderKeyValue>[];
    headerBackLink?: React.ReactElement<typeof DetailHeaderBackLink>;
    headerStatusBoxes?: React.ReactElement<typeof DetailHeaderStatusBox>[];
    headerExtraContainers?: React.ReactElement<typeof DetailHeaderVerticalContainer>[];
}

const DetailContainerStyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
        position: "inherit",
        top: 8,
        right: 4
    },
}));

const DetailContainer: React.FC<ComponentProps> = props => {
    const routeMatch = useRouteMatch();
    const location = useLocation();

    return (
        <Card variant="outlined" sx={{ border: "none", flex: "1 1 auto", display: "flex", flexDirection: "column", borderRadius: 0 }}>
            <CardMedia
                sx={{
                    background: t => t.palette.primary.dark,
                    color: t => t.palette.getContrastText(t.palette.primary.dark),
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                {props.headerBackLink && <Box sx={{ p: t => t.spacing(1, 2) }}>{props.headerBackLink}</Box>}
                <Box sx={{ flex: 1, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", p: t => t.spacing(1, 2) }}>
                    <DetailHeaderVerticalContainer sx={{ flex: 0 }}>
                        <FontAwesomeIcon color="white" icon={props.icon} size="4x" />
                    </DetailHeaderVerticalContainer>
                    <DetailHeaderVerticalContainer sx={{ flex: 1 }}>
                        <Typography variant="h5">{props.title}</Typography>
                        {(props.headerSubtitles?.length || 0) > 0 && props.headerSubtitles?.map((node, index) => ({ ...node, key: index }))}
                    </DetailHeaderVerticalContainer>
                    {(props.headerStatusBoxes?.length || 0) > 0 && <>
                        <Box sx={{ flex: "0 0 auto", display: "grid", gridAutoFlow: "column" }}>
                            {props.headerStatusBoxes?.map((node, index) => {
                                return <React.Fragment key={index}>
                                    {node}
                                    {index !== (props.headerStatusBoxes?.length || 0) - 1 && <Divider orientation="vertical" />}
                                </React.Fragment>;
                            })}
                        </Box>
                    </>
                    }
                    {(props.headerExtraContainers?.length || 0) > 0 && props.headerExtraContainers?.map((node, index) => ({ ...node, key: index }))}
                </Box>
                <StyledTabs value={location.pathname} scrollButtons="auto" textColor="inherit">
                    {props.tabs && props.tabs.map(tab =>
                        <Tab
                            component={Link}
                            to={`${routeMatch.url}${tab.path}`}
                            key={tab.path}
                            value={`${routeMatch.url}${tab.path}`}
                            label={tab.badge ? <div style={{ "display": "flex", flexDirection: "row" }}>{tab.title} <DetailContainerStyledBadge color="primary" badgeContent={tab.badge} /></div> : tab.title}
                            disabled={tab.disabled}
                        />
                    )}
                </StyledTabs>
            </CardMedia>
            <CardContent
                sx={{
                    p: t => t.spacing(0, 0),
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    "&:last-child": {
                        paddingBottom: 0
                    }
                }}
            >
                <Switch>
                    {props.tabs && props.tabs.map(tab =>
                        <Route exact key={tab.path} path={`${routeMatch.path}${tab.path}`} render={tab.render} />
                    )}
                </Switch>
            </CardContent>
        </Card>
    );
};

export default DetailContainer;