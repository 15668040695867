import { Stack } from "@mui/material";

const ObjectIndexActionBar: React.FC<{ children: React.ReactNode }> = props => {
    return (
        <Stack direction="row" pb={1} spacing={.5} flexWrap="wrap" useFlexGap>
            {props.children}
        </Stack>
    );
};

export default ObjectIndexActionBar;