import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useMemo } from "react";
import { InfoListItem } from "../../components/InfoList";

export const PpekkApiVaultExtensionsStatusInfoListItem: React.FC<{ info: Dto.RdmsApiV1.DeviceInfo }> = props => {
    const theme = useTheme();
    const status = useMemo(() => props.info.info.ppekkApi?.vaultExtensions.state || "unsupported", [props.info.info.ppekkApi?.vaultExtensions.state]);
    const style = useMemo<{ icon: React.ReactElement, color: string, title: string, message?: string } | undefined>(() => {
        if (status === "unsupported") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "exclamation-circle"]} size="lg"  />,
                color: theme.palette.text.disabled,
                title: `Unsupported`,
                message: props.info.info.ppekkApi?.vaultExtensions.error
            };
        } else if (status === "disabled") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "power-off"]} size="lg" />,
                color: theme.palette.text.disabled,
                title: `Disabled`,
                message: props.info.info.ppekkApi?.vaultExtensions.error
            };
        } else if (status === "init" || status === "injecting" || status === "evaluating") {
            return {
                icon: <FontAwesomeIcon icon={["fas", "circle-notch"]} size="lg" spin />,
                color: theme.palette.info.main,
                title: status === "init" ? "Initializing" : status === "injecting" ? "Injecting" : "Evaluating"
            };
        } else if (status === "injected") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "vial"]} size="lg" />,
                color: theme.palette.warning.main,
                title: `Running in injected mode`
            };
        } else if (status === "native") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "check-circle"]} size="lg" />,
                color: theme.palette.success.main,
                title: `Running in native mode`
            };
        }
    }, [props.info, theme, status]);

    return (
        <InfoListItem
            title="PPEKK API Vault Extensions"
            tail={style ? <Box sx={{ ml: 1, color: style.color }}>{style?.icon || undefined}</Box> : undefined}
        >
            <Stack>
                <Typography variant="body2" sx={{ color: style?.color || undefined }}>{style?.title || "Unknown"}</Typography>
                {style?.message && <Typography variant="body2" color="text.disabled">{style.message}</Typography>}
            </Stack>
        </InfoListItem>
    );
};

export default PpekkApiVaultExtensionsStatusInfoListItem;