import { Grid, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import DefaultCard from "components/DefaultCard";
import { LineChartCard } from "components/Charts";
import StatsControlBar, { StatsResolution, defaultStatsResolutions } from "components/StatsControlBar";
import useApiResource from "hooks/useApiResource";
import { humanReadableSize } from "hooks/useHumanReadableSize";
import { useMemo, useState } from "react";
import { useDeviceType } from "hooks/useDeviceType";

export const DeviceDetailStatsView: React.FC<{ device?: Dto.RdmsApiV1.Device }> = props => {
    const theme = useTheme();

    const [statsResolution, setStatsResolution] = useState<StatsResolution>(defaultStatsResolutions.find(r => r.default) as StatsResolution);

    const deviceType = useDeviceType(props.device);
    const deviceStats = useApiResource<Dto.RdmsApiV1.DcmDeviceStats>(`/v1/stats/dcm-device/${props.device?.id}?lookbehind=${statsResolution?.lookbehind}&step=${statsResolution?.step}`, { disable: !props.device?.id || !deviceType.type?.capabilities.supportsDeviceInfo, ignoreNotFound: true, reloadInterval: 60000 });
    const fsStats = useApiResource<Dto.RdmsApiV1.DcmFiscalStorageStats>(`/v1/stats/dcm-fs/${props.device?.id}?lookbehind=${statsResolution?.lookbehind}&step=${statsResolution?.step}`, { disable: !props.device?.id || !deviceType.type?.capabilities.supportsFiscalStorageInfo, ignoreNotFound: true, reloadInterval: 60000 });

    /* stabilized stats resolution is only updated when data changes - change of time axis granularity for large data set before data change caused severe performance degradation */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stabilizedStatsResolution = useMemo(() => statsResolution ? ({...statsResolution}) : undefined, [deviceStats.data, fsStats.data]);

    const processHistoryData = (data?: [number, number][] | null, precision?: number, convert?: "bytesToMegabytes" | "bytesToGigabytes" | "bytesToBits" | "perSecondToPerMinute") => {
        if (!data) {
            return [];
        }
        return data.map(datum => {
            let value = datum[1];
            if (value === -1) {
                return { x: new Date(datum[0]), y: null}
            }
            if (convert === "bytesToMegabytes") {
                value = value / 1024 / 1024;
            } else if (convert === "bytesToGigabytes") {
                value = value / 1024 / 1024 / 1024; 
            } else if (convert === "bytesToBits") {
                value = value * 8;
            } else if (convert === "perSecondToPerMinute") {
                value = value * 60;
            }
            value = precision ? Math.round(value * (Math.pow(10, precision))) / Math.pow(10, precision) : value;
            return { x: new Date(datum[0]), y: value }
        });
    }

    if (!props.device?.id) {
        return (
            <Grid container spacing={2}>
                {Array(3).fill(0).map((_, i) =>
                    <Grid key={i} item xs={12} lg={6}>
                        <DefaultCard title="Dummy" loading={true} skeletonHeight={380} />
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StatsControlBar onChange={resolution => setStatsResolution(resolution)} loading={deviceStats.loading || fsStats.loading} />
            </Grid>

            {deviceType.type?.capabilities.supportsDeviceInfo &&
            <>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="CPU Usage"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMax={deviceStats.data?.latestInfo?.info.cpuCount ? 1.5 * deviceStats.data?.latestInfo.info.cpuCount : "auto"}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "CPU Load (1m)", data: processHistoryData(deviceStats.data?.history.cpuLoadAvg1m, 3) },
                            { id: "CPU Load (5m)", data: processHistoryData(deviceStats.data?.history.cpuLoadAvg5m, 3) },
                            { id: "CPU Load (15m)", data: processHistoryData(deviceStats.data?.history.cpuLoadAvg15m, 3) },
                        ]}
                        markers={deviceStats.data?.latestInfo?.info ? [
                            {
                                axis: "y",
                                value: deviceStats.data?.latestInfo.info.cpuCount || 0,
                                legend: `${deviceStats.data?.latestInfo.info.cpuCount} cores`,
                                legendPosition: "top-left",
                                lineStyle: { stroke: theme.palette.warning.main, strokeWidth: 1 },
                                textStyle: { fill: theme.palette.warning.light }
                            }
                        ] : undefined}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="Memory Usage"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${v} MB`}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Memory Used", data: processHistoryData(deviceStats.data?.history.memoryUsed, 1, "bytesToMegabytes") },
                            { id: "Memory Total", data: processHistoryData(deviceStats.data?.history.memoryTotal, 1, "bytesToMegabytes") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="Root Storage Usage"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${v} GB`}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Storage Used", data: processHistoryData(deviceStats.data?.history.rootStorageUsed, 2, "bytesToGigabytes") },
                            { id: "Storage Total", data: processHistoryData(deviceStats.data?.history.rootStorageTotal, 2, "bytesToGigabytes") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="DCM Message Rate"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${v} msg/s`}
                        curve="step"
                        colors={{ mode: "nivo", datum: "color" }}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Rx Rate", color: theme.palette.primary.main, data: processHistoryData(deviceStats.data?.history.dcmRxMessageRate, 2, "perSecondToPerMinute") },
                            { id: "Tx Rate", color: theme.palette.secondary.main, data: processHistoryData(deviceStats.data?.history.dcmTxMessageRate, 2, "perSecondToPerMinute") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="DCM Data Rate"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${humanReadableSize(Number(v), "b/s", 2)}`}
                        curve="step"
                        colors={{ mode: "nivo", datum: "color" }}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Rx Rate", color: theme.palette.primary.main, data: processHistoryData(deviceStats.data?.history.dcmRxByteRate, 2, "bytesToBits") },
                            { id: "Tx Rate", color: theme.palette.secondary.main, data: processHistoryData(deviceStats.data?.history.dcmTxByteRate, 2, "bytesToBits") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="VPN Data Rate"
                        disabled={deviceStats.loading}
                        loading={deviceStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${humanReadableSize(Number(v), "b/s", 2)}`}
                        curve="step"
                        colors={{ mode: "nivo", datum: "color" }}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Rx Rate", color: theme.palette.primary.main, data: processHistoryData(deviceStats.data?.history.vpnRxByteRate, 2, "bytesToBits") },
                            { id: "Tx Rate", color: theme.palette.secondary.main, data: processHistoryData(deviceStats.data?.history.vpnTxByteRate, 2, "bytesToBits") },
                        ]}
                    />
                </Grid>
            </>
            }
            {deviceType.type?.capabilities.supportsFiscalStorageInfo &&
            <>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="Storage Usage"
                        disabled={fsStats.loading}
                        loading={fsStats.initialOrLongLoading}
                        yScaleMin={0}
                        yFormat={v => `${v} GB`}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Storage Used", data: processHistoryData(fsStats.data?.history.storageUsed, 2, "bytesToGigabytes") },
                            { id: "Storage Total", data: processHistoryData(fsStats.data?.history.storageTotal, 2, "bytesToGigabytes") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LineChartCard
                        title="Unsent Bills"
                        disabled={fsStats.loading}
                        loading={fsStats.initialOrLongLoading}
                        yScaleMin={0}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        data={[
                            { id: "Unsent Bills", data: processHistoryData(fsStats.data?.history.unsentBillCount, 2) },
                        ]}
                    />
                </Grid>
            </>
            }
        </Grid>
    );
};