import { IconName, IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, Fade, Skeleton, Stack, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";

export interface StatusLineProps {
    icon: IconProp;
    color?: string;
    title?: string;
    size?: SizeProp;
    pulse?: boolean;
    loading?: boolean;
    tooltip?: React.ReactNode;
    sx?: SxProps;
    chips?: {
        label: React.ReactNode;
        icon?: IconName;
    }[];
}

export const StatusLine: React.FC<StatusLineProps> = props => {
    const theme = useTheme();
    const color = useMemo(() => props.color || theme.palette.text.secondary, [props.color, theme.palette.text.secondary]);
    
    const skeletonWidth = useMemo(() => 128 + 128 * Math.random(), []);

    if (props.loading) {
        return <Skeleton variant="rounded" height={26} width={skeletonWidth} />
    }

    const inner = (
        <Fade in={true} mountOnEnter unmountOnExit>
            <Stack direction="row" alignItems="center" flexWrap="wrap" sx={props.sx}>
                <Stack direction="row" alignItems="center">
                    <FontAwesomeIcon icon={props.icon} size={props.size} fixedWidth fade style={{ color, "--fa-animation-duration": "2s", "--fa-fade-opacity": .5 } as any} />
                    {props.title && <Typography variant="body2" sx={{ color, ml: .5 }}>{props.title}</Typography>}
                </Stack>
                {props.chips && props.chips.length > 0 && props.chips.map((chip, i) => 
                    <Chip
                        key={i}
                        variant="outlined"
                        sx={{ ml: 1 }}
                        size="small"
                        label={
                            <Stack direction="row" alignItems="center" sx={{ color: theme.palette.text.secondary }}>
                                {chip.icon && <FontAwesomeIcon icon={["fal", chip.icon]} fixedWidth />}
                                <Box sx={{ ml: chip.icon ? .5 : undefined }}>{chip.label}</Box>
                            </Stack>
                        }
                    />
                )}
            </Stack>
        </Fade>
    );

    return props.tooltip ? <Tooltip title={props.tooltip}>{inner}</Tooltip> : inner;
};
