import { BehaviorSubject, filter, first, firstValueFrom, Observable, switchMap, timer } from "rxjs";

export type ApiServiceState = "init" | "unavailable" | "unauthenticated" | "authenticated";

export interface ApiRequest<T> {
    response$: Promise<T>;
    abort: () => void
}

export class ApiError extends Error {
    constructor(message: string, public statusCode: number) {
        super(message);
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

class ApiService {
    public static readonly SESSION_TOKEN_STORAGE_KEY = "rdm-portal.session-token";

    public serverVersion: string | null = null;
    public environmentName: string | null = null;
    public environmentShowNotice: boolean = false;

    private _apiUrl: string | null = null;
    private _state$: BehaviorSubject<ApiServiceState> = new BehaviorSubject("init" as ApiServiceState);
    private _sessionToken: string | null = null;

    constructor() {
        this.initialize();
    }

    public get state$(): Observable<ApiServiceState> {
        return this._state$.asObservable();
    }

    public get state(): ApiServiceState {
        return this._state$.value;
    }

    public get url(): string | null {
        return this._apiUrl;
    }

    private async initialize(): Promise<void> {
        if (process.env.REACT_APP_API_URL) {
            this._apiUrl = process.env.REACT_APP_API_URL;
            this.environmentName = process.env.REACT_APP_ENVIRONMENT_NAME || "production";
            this.environmentShowNotice = process.env.REACT_APP_ENVIRONMENT_SHOW_NOTICE === "true";
        } else {
            const config = await fetch("/config.json");
            const configJson = await config.json();
            this._apiUrl = configJson["apiUrl"];
            this.environmentName = configJson["environmentName"] || null;
            this.environmentShowNotice = configJson["environmentShowNotice"] || false;
        }

        const connect$ = timer(0, 5000).pipe(
            switchMap(() => this.connect()),
            filter(r => r),
            first()
        );
        await firstValueFrom(connect$);

        const storedSessionKey = window.localStorage.getItem(ApiService.SESSION_TOKEN_STORAGE_KEY);
        if (storedSessionKey) {
            this._sessionToken = storedSessionKey;
            try {
                await this.invoke("get", "/v1/authentication").response$;
                this._state$.next("authenticated");
            } catch (error) {
                this._sessionToken = null;
                this._state$.next("unauthenticated");
            }
        } else {
            this._sessionToken = null;
            this._state$.next("unauthenticated");
        }
    }

    private async connect(): Promise<boolean> {
        try {
            await firstValueFrom(timer(1000));
            const versionResponse = await this.invoke("get", "/v1").response$;
            this.serverVersion = versionResponse.serverVersion;
            return true;
        } catch (error) {
            console.warn(`api unavailable: ${error}`);
            this._state$.next("unavailable");
        }
        return false;
    }

    public async authenticate(username: string, password: string): Promise<void> {
        const response = await this.invoke("post", "/v1/authentication", { username, password }, true).response$;
        if (response?.sessionToken) {
            this._sessionToken = response.sessionToken as string;
            window.localStorage.setItem(ApiService.SESSION_TOKEN_STORAGE_KEY, this._sessionToken);
            this._state$.next("authenticated");
        }
    }

    public async deauthenticate(): Promise<void> {
        await this.invoke("delete", "/v1/authentication").response$;
        this._sessionToken = null;
        window.localStorage.removeItem(ApiService.SESSION_TOKEN_STORAGE_KEY);
        this._state$.next("unauthenticated");
    }

    public invoke<T = any>(method: "get" | "post" | "put" | "delete", url: string, data?: any, noToken?: boolean): ApiRequest<T> {
        const headers = new Headers();
        if (method === "post" || method === "put") {
            headers.append("content-type", "application/json");
        }
        if (this._sessionToken && !noToken) {
            headers.append("x-session-token", this._sessionToken);
        }
        const abortController = new AbortController();
        const promise = fetch(`${this._apiUrl}${url}`, {
            method,
            headers,
            redirect: "follow",
            signal: abortController.signal,
            body: ["post", "put"].includes(method) && data ? JSON.stringify(data) : undefined
        });
        return {
            response$: (async () => {
                const response = await promise;
                const responseData = await response.json();
                if (response.status === 200 || response.status === 201) {
                    return responseData as T;
                } else {
                    throw new ApiError(responseData.errorMessage, response.status);
                }
            })(),
            abort: abortController.abort.bind(abortController)
        };
    }
}

const Api = new ApiService();
export default Api;