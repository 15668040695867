import { Redirect, Route, Switch } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import Api, { ApiError } from "services/api.service";
import { Dto } from "@varos/rdm-common";
import { Avatar, Box, Container, Link, ListItemButton, ListItemIcon, ListItemText, Switch as MuiSwitch } from "@mui/material";
import { MainMenu, MainMenuItem, MainMenuSection } from "components/MainNavigation";
import { DeviceDetailView } from "views/DeviceDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useObservable from "hooks/useObservable";
import { currentTheme$, darkTheme, lightTheme } from "constants/theme";
import BusinessSubjectDetailView from "views/BusinessSubjectDetail";
import BusinessSubjectIndexView from "views/BusinessSubjectIndex";
import DashboardView from "views/Dashboard";
import DcmProfileDetailView from "views/DcmProfileDetail";
import DcmProfileIndexView from "views/DcmProfileIndex";
import DeviceIndexView from "views/DeviceIndex";
import RpsProfileDetailView from "views/RpsProfileDetail";
import RpsProfileIndexView from "views/RpsProfileIndex";
import { CommandBarActionFactory, commandBarSearching$, useCommandBarActions } from "components/CommandBar";
import { useHistory } from "react-router-dom";
import useObjectAction from "hooks/useObjectAction";
import CreateSubjectAction from "components/ObjectActions/Subject/CreateSubjectAction";
import CreateDcmProfileAction from "components/ObjectActions/DcmProfile/CreateDcmProfileAction";
import CreateRpsProfileAction from "components/ObjectActions/RpsProfile/CreateRpsProfileAction";
import SetGlobalDefaultDcmProfileAction from "components/ObjectActions/DcmProfile/SetGlobalDefaultDcmProfileAction";
import SetGlobalDefaultRpsProfileAction from "components/ObjectActions/RpsProfile/SetGlobalDefaultRpsProfileAction";
import { useKBar } from "kbar";
import { BehaviorSubject, concatMap, debounceTime, distinctUntilChanged, map } from "rxjs";
import UserIndexView from "views/UserIndex";
import UserRoleIndexView from "views/UserRoleIndex";
import UserRoleDetailView from "views/UserRoleDetail";
import CreateUserRoleAction from "components/ObjectActions/UserRole/CreateUserRoleAction";
import UserDetailView from "views/UserDetail";
import { Link as RouterLink} from "react-router-dom";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import CreateUserAction from "components/ObjectActions/User/CreateUserAction";
import { RequireAuthorization } from "components/Authorization";

const MainLayoutView: React.FC = () => {
    const theme = useObservable(currentTheme$, true);
    const history = useHistory();
    const auth = useAuthenticationInfo();

    const [menuOpen, setMenuOpen] = useState(false);

    const [userDisplayName, userDisplayNameAvatar] = useMemo(() => {
        const source: string | null = auth.info?.user?.displayName || auth.info?.user?.username || null;
        let avatarText: string | null = auth.info?.user?.username?.[0].toUpperCase() || null;
        if (source) {
            const letters = [];
            for (const word of source.split(" ")) {
                letters.push(word[0].toUpperCase());
                if (letters.length >= 2) {
                    break;
                }
            }
            avatarText = letters.join("");
        }
        return [
            source || "User Profile",
            avatarText
        ];
    }, [auth.info?.user?.displayName, auth.info?.user?.username]);

    const handleLogout = () => Api.deauthenticate();
    const handleSwitchTheme = () => {
        if (theme === lightTheme) {
            currentTheme$.next(darkTheme);
        } else {
            currentTheme$.next(lightTheme);
        }
    };

    const createBusinessSubject = useObjectAction(CreateSubjectAction, () => history.push("/business-subjects"));
    const createDcmProfile = useObjectAction(CreateDcmProfileAction, () => history.push("/dcm-profiles"));
    const setGlobalDefaultDcmProfile = useObjectAction(SetGlobalDefaultDcmProfileAction, () => history.push("/dcm-profiles"));
    const createRpsProfile = useObjectAction(CreateRpsProfileAction, () => history.push("/rps-profiles"));
    const setGlobalDefaultRpsProfile = useObjectAction(SetGlobalDefaultRpsProfileAction, () => history.push("/rps-profiles"));
    const createUserRole = useObjectAction(CreateUserRoleAction, () => history.push("/user-roles"));
    const createUser = useObjectAction(CreateUserAction, () => history.push("/users"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => factory
        /* General Portal Actions */
        .section("Portal")
        .action("portal-logout", "Logout").icon("sign-out").priority(32900).do(handleLogout)
        .action("portal-change-theme", "Change Theme").icon("moon-over-sun").priority(32800).children(cb => cb
            .action("portal-change-theme-light", "Light Theme").icon("sun").priority(32810).do(() => currentTheme$.next(lightTheme))
            .action("portal-change-theme-dark", "Dark Theme").icon("moon").priority(32820).do(() => currentTheme$.next(darkTheme))
        )

        /* Main Menu: Observability */
        .section("Observability")
        .action("menu-dashboard", "Dashboard").icon("tachometer-alt").priority(30100).children(cb => cb
            .action("menu-dashboard-overview", "Show Overview").priority(32110).do(() => history.push("/dashboard"))
            .if(auth.hasAccess("sys:system", "system:read-platform-stats")).action("menu-dashboard-platform", "Show Platform Dashboard").priority(32120).do(() => history.push("/dashboard/platform"))
            .if(auth.hasAccess("sys:system", "system:read-client-connection-stats")).action("menu-dashboard-clients", "Show Clients Dashboard").priority(32130).do(() => history.push("/dashboard/clients"))
            .if(auth.hasAccess("sys:system", "system:read-client-software-stats")).action("menu-dashboard-software", "Show Software Versions Dashboard").priority(32140).do(() => history.push("/dashboard/software"))
        )

        /* Main Menu: Assets */
        .section("Assets")
        .if(auth.hasAccess("rdm:business-subject", "read")).action("menu-business-subjects", "Business Subjects").icon("building").priority(32200).children(cb => cb
            .if(auth.hasAccess("rdm:business-subject", "read")).action("menu-business-subjects-show", "Show Business Subjects").priority(32210).do(() => history.push("/business-subjects"))
            .if(auth.hasAccess("rdm:business-subject", "create")).action(CreateSubjectAction).id("menu-subject-create").icon().subtitle().priority(32220).do(() => createBusinessSubject())
        )
        .if(auth.hasAccess("rdm:device", "read")).action("menu-devices", "Devices").icon("hdd").priority(32300).children(cb => cb
            .if(auth.hasAccess("rdm:device", "read")).action("devices-show", "Show Devices").priority(32310).do(() => history.push("/devices"))
        )

        /* Main Menu: Configuration Management */
        .section("Configuration Management")
        .if(auth.hasAccess("rdm:dcm-profile", "read")).action("menu-dcm-profiles", "DCM Profiles").icon("badge-check").priority(32400).children(cb => cb
            .if(auth.hasAccess("rdm:dcm-profile", "read")).action("menu-dcm-profiles-show", "Show DCM Profiles").priority(32410).do(() => history.push("/dcm-profiles"))
            .if(auth.hasAccess("rdm:dcm-profile", "create")).action(CreateDcmProfileAction).id("menu-dcm-profile-create").icon().subtitle().priority(32420).do(() => createDcmProfile())
            .if(auth.hasAccess("rdm:dcm-profile", "update")).action(SetGlobalDefaultDcmProfileAction).id("menu-dcm-profile-set-global-default").icon().subtitle().priority(32430).do(() => setGlobalDefaultDcmProfile({}))
        )
        .if(auth.hasAccess("rdm:rps-profile", "read")).action("menu-rps-profiles", "RPS Profiles").icon("print-search").priority(32500).children(cb => cb
            .if(auth.hasAccess("rdm:rps-profile", "read")).action("menu-rps-profiles-show", "Show RPS Profiles").priority(32510).do(() => history.push("/rps-profiles"))
            .if(auth.hasAccess("rdm:rps-profile", "create")).action(CreateRpsProfileAction).id("menu-rps-profile-create").icon().subtitle().priority(32520).do(() => createRpsProfile())
            .if(auth.hasAccess("rdm:rps-profile", "update")).action(SetGlobalDefaultRpsProfileAction).id("menu-rps-profile-set-global-default").icon().subtitle().priority(32530).do(() => setGlobalDefaultRpsProfile({}))
        )

        /* Main Menu: System */
        .section("System")
        .if(auth.hasAccess("sys:user-role", "read")).action("menu-user-roles", "User Roles").icon("user-shield").priority(32600).children(cb => cb
            .if(auth.hasAccess("sys:user-role", "read")).action("menu-user-roles-show", "Show User Roles").priority(32610).do(() => history.push("/user-roles"))
            .if(auth.hasAccess("sys:user-role", "create")).action(CreateUserRoleAction).id("menu-user-roles-create").icon().subtitle().priority(32620).do(() => createUserRole())
        )
        .if(auth.hasAccess("sys:user", "read")).action("menu-users", "Users").icon("user").priority(32700).children(cb => cb
            .if(auth.hasAccess("sys:user", "read")).action("menu-users-show", "Show Users").priority(32710).do(() => history.push("/users"))
            .if(auth.hasAccess("sys:user", "create")).action(CreateUserAction).id("menu-users-create").icon().subtitle().priority(32720).do(() => createUser())
        )
    , [auth, createBusinessSubject, createDcmProfile, createRpsProfile, createUserRole, createUser, history, setGlobalDefaultDcmProfile, setGlobalDefaultRpsProfile]);
    useCommandBarActions(cbActionFactory);

    const { searchQuery } = useKBar(state => ({ searchQuery: state.searchQuery }));
    const searchQuery$ = useMemo(() => new BehaviorSubject<string>(""), []);
    useEffect(() => searchQuery$.next(searchQuery), [searchQuery, searchQuery$]);

    const [cbSearchActionFactory, setCbSearchActionFactory] = useState(() => () => new CommandBarActionFactory());
    useEffect(() => {
        async function searchIgnoringForbidden<T>(url: string): Promise<T[]> {
            try {
                return await Api.invoke<T[]>("get", url).response$;
            } catch (error) {
                if (error instanceof ApiError && error.statusCode === 403) {
                    return [];
                }
                throw error;
            }
        }
        const subscription = searchQuery$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            concatMap(async query => {
                commandBarSearching$.next(true);
                try {
                    const factory = new CommandBarActionFactory();
                    if (query && query.length >= 3) {
                        const subjects$ = searchIgnoringForbidden<Dto.RdmsApiV1.BusinessSubject>(`/v1/business-subjects?search=${query}&limit=25`);
                        const devices$ = searchIgnoringForbidden<Dto.RdmsApiV1.Device>(`/v1/devices?search=${query}&limit=25`);
                        const dcmProfiles$ = searchIgnoringForbidden<Dto.RdmsApiV1.DcmProfile>(`/v1/dcm-profiles?search=${query}&limit=25`);
                        const rpsProfiles$ = searchIgnoringForbidden<Dto.RdmsApiV1.RpsProfile>(`/v1/rps/profiles?search=${query}&limit=25`);
                        const userRoles$ = searchIgnoringForbidden<Dto.RdmsApiV1.SystemUserRole>(`/v1/system/user-roles?search=${query}&limit=25`);
                        const users$ = searchIgnoringForbidden<Dto.RdmsApiV1.SystemUser>(`/v1/system/users?search=${query}&limit=25`);
                        const [subjects, devices, dcmProfiles, rpsProfiles, userRoles, users] = await Promise.all([subjects$, devices$, dcmProfiles$, rpsProfiles$, userRoles$, users$]);
                        
                        factory.section("Search Results");
                        if (subjects.length > 0) {
                            factory.action("search-group-subjects", "Business Subjects");
                            for (const subject of subjects) {
                                factory.action(`search-result-subjects-${subject.id}`, subject.name).parent("search-group-subjects").icon(["fal", "building"]).priority(41000).do(() => history.push(`/business-subjects/${subject.id}`));
                            }
                        }
                        if (devices.length > 0) {
                            factory.action("search-group-devices", "Devices");
                            for (const device of devices) {
                                factory.action(`search-result-devices-${device.id}`, device.shortId).parent("search-group-devices").subtitle(device.alias || undefined).icon(["fal", "hdd"]).priority(42000).do(() => history.push(`/devices/${device.id}`));
                            }
                        }
                        if (dcmProfiles.length > 0) {
                            factory.action("search-group-dcm-profiles", "DCM Profiles");
                            for (const dcmProfile of dcmProfiles) {
                                factory.action(`search-result-dcm-profiles-${dcmProfile.id}`, dcmProfile.title).parent("search-group-dcm-profiles").icon(["fal", "badge-check"]).priority(43000).do(() => history.push(`/dcm-profiles/${dcmProfile.id}`));
                            }
                        }
                        if (rpsProfiles.length > 0) {
                            factory.action("search-group-rps-profiles", "RPS Profiles");
                            for (const rpsProfile of rpsProfiles) {
                                factory.action(`search-result-rps-profiles-${rpsProfile.id}`, rpsProfile.title).parent("search-group-rps-profiles").icon(["fal", "print-search"]).priority(43000).do(() => history.push(`/rps-profiles/${rpsProfile.id}`));
                            }
                        }
                        if (userRoles.length > 0) {
                            factory.action("search-group-user-roles", "User Roles");
                            for (const userRole of userRoles) {
                                factory.action(`search-result-user-roles-${userRole.id}`, userRole.title).parent("search-group-user-roles").icon(["fal", "user-shield"]).priority(43000).do(() => history.push(`/user-roles/${userRole.id}`));
                            }
                        }
                        if (users.length > 0) {
                            factory.action("search-group-users", "Users");
                            for (const user of users) {
                                factory.action(`search-result-users-${user.id}`, user.username).parent("search-group-users").icon(["fal", "user"]).priority(43000).do(() => history.push(`/users/${user.id}`));
                            }
                        }
                    }
                    setCbSearchActionFactory(() => () => factory);
                } catch (error) {
                    console.error(error);
                } finally {
                    commandBarSearching$.next(false);
                }
            })
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [history, searchQuery, searchQuery$]);
    useCommandBarActions(cbSearchActionFactory);

    return (
        <Box
            sx={{
                width: { xs: `100%`, md: menuOpen ? "calc(100% - 260px)" : "100%" },
                ml: { xs: "auto", md: menuOpen ? "260px" : "auto" },
                pb: 2,
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Container maxWidth="xl" sx={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                <MainMenu
                    onOpenChange={setMenuOpen}
                    breadcrumbs={[
                        { match: /^\/$/, label: "Home", icon: ["fas", "home"] },
                        { match: /^\/dashboard$/, label: "Dashboard" },
                        { match: /^\/dashboard\/overview$/, label: "Overview" },
                        { match: /^\/dashboard\/platform$/, label: "Platform" },
                        { match: /^\/dashboard\/clients$/, label: "Clients" },
                        { match: /^\/dashboard\/software$/, label: "Software" },
                        { match: /^\/business-subjects$/, label: "Business Subjects" },
                        { match: /^\/business-subjects\/([a-f0-9-]+)$/, label: async matches => matches ? (await Api.invoke<Dto.RdmsApiV1.BusinessSubject>("get", `/v1/business-subjects/${matches[1]}`).response$).name : "unknown" },
                        { match: /^\/business-subjects\/([a-f0-9-]+)\/devices$/, label: "Devices" },
                        { match: /^\/business-subjects\/([a-f0-9-]+)\/devices\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.Device>("get", `/v1/devices/${matches[2]}?flat=true`).response$).shortId) : "unknown" },
                        { match: /^\/business-subjects\/([a-f0-9-]+)\/devices\/([a-f0-9-]+)\/stats$/, label: "Stats" },
                        { match: /^\/business-subjects\/([a-f0-9-]+)\/devices\/([a-f0-9-]+)\/rps-bindings$/, label: "RPS Bindings" },
                        { match: /^\/devices$/, label: "Devices" },
                        { match: /^\/devices\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.Device>("get", `/v1/devices/${matches[1]}?flat=true`).response$).shortId) : "unknown" },
                        { match: /^\/devices\/([a-f0-9-]+)\/stats$/, label: "Stats" },
                        { match: /^\/devices\/([a-f0-9-]+)\/rps-bindings$/, label: "RPS Bindings" },
                        { match: /^\/dcm-profiles$/, label: "DCM Profiles" },
                        { match: /^\/dcm-profiles\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.DcmProfile>("get", `/v1/dcm-profiles/${matches[1]}?flat=true`).response$).title) : "unknown" },
                        { match: /^\/rps-profiles$/, label: "RPS Profiles" },
                        { match: /^\/rps-profiles\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.RpsProfile>("get", `/v1/rps/profiles/${matches[1]}?flat=true`).response$).title) : "unknown" },
                        { match: /^\/users$/, label: "Users" },
                        { match: /^\/users\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.SystemUser>("get", `/v1/system/users/${matches[1]}?flat=true`).response$).username) : "unknown" },
                        { match: /^\/user-roles$/, label: "User Roles" },
                        { match: /^\/user-roles\/([a-f0-9-]+)$/, label: async matches => matches ? ((await Api.invoke<Dto.RdmsApiV1.SystemUserRole>("get", `/v1/system/user-roles/${matches[1]}?flat=true`).response$).title) : "unknown" },
                    ]}
                >
                    <MainMenuSection title="Observability">
                        <MainMenuItem title="Dashboard" icon={["fal", "tachometer-alt"]} iconSelected={["fas", "tachometer-alt"]} to="/dashboard" />
                    </MainMenuSection>
                    {(auth.hasAccess("rdm:business-subject", "read") || auth.hasAccess("rdm:device", "read")) && (
                        <MainMenuSection title="Assets">
                            <RequireAuthorization resource="rdm:business-subject" operation="read">
                                <MainMenuItem title="Business Subjects" icon={["fal", "building"]} iconSelected={["fas", "building"]} to="/business-subjects" />
                            </RequireAuthorization>
                            <RequireAuthorization resource="rdm:device" operation="read">
                                <MainMenuItem title="Devices" icon={["fal", "hdd"]} iconSelected={["fas", "hdd"]} to="/devices" />
                            </RequireAuthorization>
                        </MainMenuSection>
                    )}
                    {(auth.hasAccess("rdm:dcm-profile", "read") || auth.hasAccess("rdm:rps-profile", "read")) && (
                        <MainMenuSection title="Configuration Management">
                            <RequireAuthorization resource="rdm:dcm-profile" operation="read">
                                <MainMenuItem title="DCM Profiles" icon={["fal", "badge-check"]} iconSelected={["fas", "badge-check"]} to="/dcm-profiles" />
                            </RequireAuthorization>
                            <RequireAuthorization resource="rdm:rps-profile" operation="read">
                                <MainMenuItem title="RPS Profiles" icon={["fal", "print-search"]} iconSelected={["fas", "print-search"]} to="/rps-profiles" />
                            </RequireAuthorization>
                        </MainMenuSection>
                    )}
                    {(auth.hasAccess("sys:user-role", "read") || auth.hasAccess("sys:user", "read")) && (
                        <MainMenuSection title="System">
                            <RequireAuthorization resource="sys:user" operation="read">
                                <MainMenuItem title="Users" icon={["fal", "user"]} iconSelected={["fas", "user"]} to="/users" />
                            </RequireAuthorization>
                            <RequireAuthorization resource="sys:user-role" operation="read">
                                <MainMenuItem title="User Roles" icon={["fal", "user-shield"]} iconSelected={["fas", "user-shield"]} to="/user-roles" />
                            </RequireAuthorization>
                        </MainMenuSection>
                    )}

                    <Box sx={{ flex: "1 1 auto" }} />

                    <MainMenuSection title="Account">
                        <Link component={RouterLink} to="/user-profile" sx={{ textDecoration: "none" }}>
                            <ListItemButton>
                                <ListItemIcon sx={{ color: t => t.palette.primary.main, minWidth: "36px" }}>
                                    <Avatar sx={{ width: 26, height: 26, backgroundColor: t => t.palette.primary.main, fontSize: 14 }}>{userDisplayNameAvatar}</Avatar>
                                </ListItemIcon>
                                <ListItemText sx={{ color: "text.secondary" }} primary={userDisplayName} />
                            </ListItemButton>
                        </Link>
                        <MainMenuItem title="Logout" icon={["fal", "sign-out"]} onClick={handleLogout} />
                        <ListItemButton onClick={handleSwitchTheme}>
                            <ListItemIcon sx={{ color: t => t.palette.primary.main, minWidth: "36px" }}>
                                <FontAwesomeIcon icon={["fal", "moon"]} fixedWidth size="lg" />
                            </ListItemIcon>
                            <ListItemText sx={{ color: "text.secondary" }} primary="Dark Mode" />
                            <MuiSwitch edge="end" size="small" checked={theme === darkTheme} />
                        </ListItemButton>
                    </MainMenuSection>
                </MainMenu>
                <Switch>
                    <Route path="/dashboard" render={() => <DashboardView />} />

                    <Route path="/business-subjects/:subjectId/devices/:deviceId" render={() => <DeviceDetailView />} />
                    <Route path="/business-subjects/:subjectId" render={() => <BusinessSubjectDetailView />} />
                    <Route path="/business-subjects" render={() => <BusinessSubjectIndexView />} />

                    <Route path="/dcm-profiles/:dcmProfileId" render={() => <DcmProfileDetailView />} />
                    <Route path="/dcm-profiles" render={() => <DcmProfileIndexView />} />

                    <Route path="/rps-profiles/:rpsProfileId" render={() => <RpsProfileDetailView />} />
                    <Route path="/rps-profiles" render={() => <RpsProfileIndexView />} />

                    <Route exact path="/devices/by-sid/:deviceShortId" render={() => <DeviceDetailView sidMode />}></Route>
                    <Route path="/devices/:deviceId" render={() => <DeviceDetailView />} />
                    <Route path="/devices" render={() => <DeviceIndexView />} />

                    <Route path="/users/:userId" render={() => <UserDetailView />} />
                    <Route path="/users" render={() => <UserIndexView />} />

                    <Route path="/user-roles/:userRoleId" render={() => <UserRoleDetailView />} />
                    <Route path="/user-roles" render={() => <UserRoleIndexView />} />

                    <Route path="/">
                        <Redirect to="/dashboard" />
                    </Route>
                </Switch>
            </Container>
        </Box>
    );
};

export default MainLayoutView;