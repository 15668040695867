import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { InfoListItem } from "components/InfoList";
import { useMemo } from "react";

export const PrinterStatusInfoListItem: React.FC<{ info: Dto.RdmsApiV1.DeviceInfo }> = props => {
    const theme = useTheme();
    const status = useMemo(() => props.info.info.printerInfo?.state || "unsupported", [props.info.info.printerInfo?.state]);
    const style = useMemo<{ icon: React.ReactElement, color: string, title: string, message?: string } | undefined>(() => {
        if (status === "unsupported") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "exclamation-circle"]} size="lg"  />,
                color: theme.palette.text.disabled,
                title: `Unsupported`
            };
        } else if (status === "busy") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "print"]} size="lg" />,
                color: theme.palette.warning.main,
                title: `Busy`
            };
        } else if (status === "readTimeout") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "print"]} size="lg" />,
                color: theme.palette.error.main,
                title: `Read Timeout`
            };
        } else if (status === "ready") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "print"]} size="lg" />,
                color: theme.palette.success.main,
                title: `Ready`
            };
        }
    }, [theme, status]);

    return (
        <InfoListItem
            title="Printer Status"
            tail={style ? <Box sx={{ ml: 1, color: style.color }}>{style?.icon || undefined}</Box> : undefined}
        >
            <Stack>
                <Typography variant="body2" sx={{ color: style?.color || undefined }}>{style?.title || "Unknown"}</Typography>
                {props.info.info.printerInfo?.errorStatus.autoCutter && <div>Auto-cutter Error</div>}
                {props.info.info.printerInfo?.errorStatus.autoRecoverable && <div>Auto-recoverable Error</div>}
                {props.info.info.printerInfo?.errorStatus.recoverable && <div>Recoverable Error</div>}
                {props.info.info.printerInfo?.errorStatus.unrecoverable && <div>Unrecoverable Error</div>}
                {props.info.info.printerInfo?.offlineStatus.coverOpen && <div>Cover Open</div>}
                {props.info.info.printerInfo?.offlineStatus.error && <div>Printer Error</div>}
                {props.info.info.printerInfo?.offlineStatus.paperEnd && <div>Paper End</div>}
                {props.info.info.printerInfo?.offlineStatus.paperFeed && <div>Paper Feed</div>}
                {props.info.info.printerInfo?.printerStatus.paperFeed && <div>Paper Feed</div>}
                {props.info.info.printerInfo?.sensorStatus.paperError && <div>Paper Error</div>}
                {props.info.info.printerInfo?.sensorStatus.paperNearEnd && <div>Paper Near End</div>}
            </Stack>
        </InfoListItem>
    );
};

export default PrinterStatusInfoListItem;