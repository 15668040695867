import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import DefaultCard from "components/DefaultCard";
import { useDeviceActions } from "hooks/useDeviceActions";
import { useDeviceType } from "hooks/useDeviceType";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import React from "react";
import { useMemo } from "react";

interface ToolsCardProps {
    device?: Dto.RdmsApiV1.Device;
    loading?: boolean;
    onUpdate?: () => void;
}
export const ToolsCard: React.FC<ToolsCardProps> = props => {
    const objectAuth = useObjectAuthorizationInfo(props.device);
    const deviceType = useDeviceType(props.device);
    const deviceActions = useDeviceActions({ onUpdate: props.onUpdate });

    const actionItems = useMemo(() => {
        const actions = [];

        if (objectAuth.hasAccess("device:recover-rdma-config") && deviceType.type?.capabilities.supportsPlatformConnection) {
            actions.push(
                <ListItemButton sx={{ color: t => t.palette.primary.main }} onClick={() => props.device && deviceActions.getRecoveryRdmAgentConfig({ device: props.device })}>
                    <ListItemIcon sx={{ color: t => t.palette.primary.main }}><FontAwesomeIcon icon={["fas", "file-code"]} fixedWidth size="lg" /></ListItemIcon>
                    <ListItemText primary="RDM Agent Config Recovery" secondary="Retrieve base RDM agent configuration for recovery purposes" />
                </ListItemButton>
            );
        }

        return actions;
    }, [deviceActions, props.device, deviceType, objectAuth]);

    if (actionItems.length > 0) {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <DefaultCard title="Tools" loading={props.loading} skeletonHeight={595}>
                    <List>
                        {actionItems.map((item, index) => React.cloneElement(item, { key: index }))}
                    </List>
                </DefaultCard>
            </Grid>
        );
    }

    return <></>;
};

export default ToolsCard;