import { GridProps, TextField, TextFieldProps} from "@mui/material";

interface ComponentProps {
    title: string;
    type?: "string" | "password" | "number";
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    defaultValue?: string | number | undefined;
    value?: string | number;
    onChange?: (value: string | number) => void;
    GridProps?: Partial<GridProps>;
    TextFieldProps?: Partial<TextFieldProps>;
}

export const FormTextField: React.FC<ComponentProps> = props => {
    return (
        <TextField
            sx={{ width: "100%", mt: 1 }}
            type={props.type}
            label={props.title}
            placeholder={props.placeholder || undefined}
            defaultValue={props.defaultValue || undefined}
            value={props.value}
            disabled={props.disabled}
            onChange={evt => props.onChange?.(evt.target.value)}
            autoFocus={props.autoFocus || undefined}
            {...props.TextFieldProps || {}}
        />
    );
};
