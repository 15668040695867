import { useSnackbar } from "notistack";
import { useCallback } from "react";
import Api from "../services/api.service";
import ApiResourceCache from "services/api-resource-cache.service";
import { refreshBreadcrumbs$ } from "components/MainNavigation/Breadcrumbs";

interface Opts {
    silentError?: boolean;
    throwError?: boolean;
    refreshBreadcrumbs?: boolean;
}

const useApiInvocation = <P extends {}, D = {}, R = D>(method: "get" | "post" | "put" | "delete", url: string, opts?: Opts) => {
    const { enqueueSnackbar } = useSnackbar();
    const { silentError, throwError, refreshBreadcrumbs } = opts || {};

    return useCallback(async (params: P, data?: Partial<D>): Promise<R> => {
        let finalUrl = url;
        Object.keys(params).forEach(key => {
            finalUrl = finalUrl.replace(`:${key}`, params[key as keyof P] as any);
        });
        try {
            const response = await Api.invoke(method, finalUrl, data).response$;
            await ApiResourceCache.invalidate();
            if (refreshBreadcrumbs) {
                refreshBreadcrumbs$.next();
            }
            return response;
        } catch (error) {
            if (!silentError) {
                enqueueSnackbar(`API invocation failed: ${error.message}.`, { variant: "error" });
            }
            if (throwError) {
                throw error;
            }
            return null as any as R;
        }
    }, [enqueueSnackbar, method, refreshBreadcrumbs, silentError, throwError, url]);
};
export default useApiInvocation;
