import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionUserRoleTargetList: React.FC<{ userRoles: Dto.RdmsApiV1.SystemUserRole[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.SystemUserRole>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.userRoles}
        singular="user role"
        plural="users roles"
        icon={["fas", "user-shield"]}
        getPrimary={(userRole: Dto.RdmsApiV1.SystemUserRole) => userRole.title}
    />;
