import { Box, Button, Chip, Divider, TextField, Typography } from "@mui/material";
import useApiResource from "hooks/useApiResource";
import { Dto } from "@varos/rdm-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import ObjectIndexActionBar from "components/ObjectIndexActionBar";
import DefaultContainer from "components/DefaultContainer";
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { debounceTime, distinctUntilChanged, map, Subject, tap } from "rxjs";
import { Link } from "react-router-dom";
import DataGrid, { DataGridColDef, DataGridSelectActionButton } from "components/DataGrid";
import Heading from "components/Heading";
import ObjectReference from "components/ObjectReference";
import { useSubjectActions } from "hooks/useSubjectActions";
import { ActionMenuItem } from "components/Action";
import SetSubjectNoteAction from "components/ObjectActions/Subject/SetSubjectNoteAction";
import SetSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultDcmProfileAction";
import SetSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultRpsProfileAction";
import ClearSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultDcmProfileAction";
import ClearSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultRpsProfileAction";
import DeleteSubjectAction from "components/ObjectActions/Subject/DeleteSubjectAction";
import SetSubjectRolesAction from "components/ObjectActions/Subject/SetSubjectRolesAction";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { RequireAuthorization } from "components/Authorization";

const BusinessSubjectIndexView: React.FC = () => {
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const subjectsUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const url = `/v1/business-subjects?search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
        return url;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, sortModel]);

    const subjects = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.BusinessSubject>>(subjectsUrl);

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => subjects.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.BusinessSubject[], [subjects?.data, selectedIds]);
    const subjectActions = useSubjectActions({ onUpdate: () => subjects.reload() });

    const columns = useMemo<DataGridColDef<Dto.RdmsApiV1.BusinessSubject>[]>(() => [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            filterable: false,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={faBuilding} />
                    <Typography variant="body2" color="primary" sx={{ textDecoration: "none", ml: .5 }} component={Link} to={`/business-subjects/${params.row.id}`}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        {
            field: "businessId",
            headerName: "Business ID",
            width: 125,
            filterable: false,
            hideOnSmallScreen: true
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
            filterable: false,
            hideOnSmallScreen: true
        },
        {
            field: "_deviceCount",
            headerName: "Devices",
            width: 100,
            filterable: false,
            sortable: false,
            hideOnSmallScreen: true,
            valueGetter: params => !Number.isNaN(params.row._meta.deviceCount) ? params.row._meta.deviceCount : "-"
        },
        {
            field: "_defaultDcmProfile",
            headerName: "Default DCM Profile",
            flex: 1,
            filterable: false,
            sortable: false,
            hideOnSmallScreen: true,
            renderCell: params => <ObjectReference type="dcm-profile" icon id={params.row.defaultDcmProfileId} />
        },
        {
            field: "_defaultRpsProfile",
            headerName: "Default RPS Profile",
            flex: 1,
            filterable: false,
            sortable: false,
            hideOnSmallScreen: true,
            renderCell: params => <ObjectReference type="rps-profile" icon id={params.row.defaultRpsProfileId} />
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            flex: 1,
            filterable: false,
            defaultVisibility: "hidden"
        },
        {
            field: "_roles",
            headerName: "Roles",
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: params => (
                <>
                    {params.row.isCustomer && <Chip sx={{ mr: .5 }} variant="outlined" size="small" label="Customer" color="primary" />}
                    {params.row.isServicePartner && <Chip variant="outlined" size="small" label="Service Partner" color="secondary" />}
                </>
            )
        },
    ], []);

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        factory
            .section("Business Subjects")
            .if(auth.hasAccess("rdm:business-subject", "create")).action("subject-index-create", "Create Business Subject").priority(9000).do(() => subjectActions.create());
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected Business Subjects`)
                .if(auth.hasAccess("rdm:business-subject", "update")).action(SetSubjectNoteAction).priority(8200).do(() => subjectActions.setNote({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "update")).action(SetSubjectRolesAction).priority(8210).do(() => subjectActions.setRoles({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "update")).action(SetSubjectDefaultDcmProfileAction).priority(8220).do(() => subjectActions.setDefaultDcmProfile({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "update")).action(SetSubjectDefaultRpsProfileAction).priority(8230).do(() => subjectActions.setDefaultRpsProfile({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "update")).action(ClearSubjectDefaultDcmProfileAction).priority(8240).do(() => subjectActions.clearDefaultDcmProfile({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "update")).action(ClearSubjectDefaultRpsProfileAction).priority(8250).do(() => subjectActions.clearDefaultRpsProfile({ subjects: selectedObjects }))
                .if(auth.hasAccess("rdm:business-subject", "delete")).action(DeleteSubjectAction).priority(8290).do(() => subjectActions.delete({ subjects: selectedObjects }));
        }
        return factory;
    }, [auth, selectedObjects, subjectActions]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Heading>Business Subjects</Heading>
            <DefaultContainer loading={subjects.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        <RequireAuthorization resource="rdm:business-subject" operation="update">
                            <ActionMenuItem def={SetSubjectNoteAction} action={() => subjectActions.setNote({ subjects: selectedObjects })} />
                            <ActionMenuItem def={SetSubjectRolesAction} action={() => subjectActions.setRoles({ subjects: selectedObjects })} />
                            <Divider />
                            <ActionMenuItem def={SetSubjectDefaultDcmProfileAction} action={() => subjectActions.setDefaultDcmProfile({ subjects: selectedObjects })} />
                            <ActionMenuItem def={SetSubjectDefaultRpsProfileAction} action={() => subjectActions.setDefaultRpsProfile({ subjects: selectedObjects })} />
                            <Divider />
                            <ActionMenuItem def={ClearSubjectDefaultDcmProfileAction} action={() => subjectActions.clearDefaultDcmProfile({ subjects: selectedObjects })} />
                            <ActionMenuItem def={ClearSubjectDefaultRpsProfileAction} action={() => subjectActions.clearDefaultRpsProfile({ subjects: selectedObjects })} />
                        </RequireAuthorization>
                        <Divider />
                        <RequireAuthorization resource="rdm:business-subject" operation="delete">
                            <ActionMenuItem def={DeleteSubjectAction} action={() => subjectActions.delete({ subjects: selectedObjects })} />
                        </RequireAuthorization>
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                    <RequireAuthorization resource="rdm:business-subject" operation="create">
                        <Button variant="contained" disableElevation onClick={() => subjectActions.create()}>New</Button>
                    </RequireAuthorization>
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="businessSubjectGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={subjects.data?.data || []}
                    rowCount={subjects.data?.total || 0}
                    loading={subjects.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    checkboxSelection={auth.hasAccess("rdm:business-subject", "update") || auth.hasAccess("rdm:business-subject", "delete")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={model => setSelectedIds(model as string[])}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting
                />
            </DefaultContainer>
        </>
    );
};
export default BusinessSubjectIndexView;
