import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Box, Link, ListItemButton, ListItemIcon, ListItemText, Skeleton, Stack, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import useApiResource from "hooks/useApiResource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ObjectType {
    icon: IconName;
    getApiUrl: (id: string) => string;
    getDisplayName: (obj: any) => string;
    getLink?: (obj: any) => string;
}

type objectTypeNames = "business-subject" | "device" | "device-type" | "dcm-profile" | "rps-profile" | "rps-binding" | "user" | "user-role";
const objectTypes: { [Property in objectTypeNames]: ObjectType | undefined } = {
    "business-subject": {
        icon: "building",
        getApiUrl: (id: string) => `/v1/business-subjects/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.BusinessSubject) => obj.name,
        getLink: (obj: Dto.RdmsApiV1.BusinessSubject) => `/business-subjects/${obj.id}`,
    },
    "device": {
        icon: "hdd",
        getApiUrl: (id: string) => `/v1/devices/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.Device) => obj.shortId + (obj.alias ? ` (${obj.alias})` : ``),
        getLink: (obj: Dto.RdmsApiV1.BusinessSubject) => `/devices/${obj.id}`,
    },
    "device-type": {
        icon: "grid-2",
        getApiUrl: (id: string) => `/v1/device-types/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.DeviceType) => obj.title,
    },
    "dcm-profile": {
        icon: "badge-check",
        getApiUrl: (id: string) => `/v1/dcm-profiles/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.DcmProfile) => obj.title,
        getLink: (obj: Dto.RdmsApiV1.DcmProfile) => `/dcm-profiles/${obj.id}`,
    },
    "rps-profile": {
        icon: "print-search",
        getApiUrl: (id: string) => `/v1/rps/profiles/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.RpsProfile) => obj.title,
        getLink: (obj: Dto.RdmsApiV1.RpsProfile) => `/rps-profiles/${obj.id}`,
    },
    "rps-binding": {
        icon: "link-horizontal",
        getApiUrl: (id: string) => `/v1/rps/bindings/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.RpsBinding) => `${obj.establishTime} (${obj.status})`,
    },
    "user": {
        icon: "user",
        getApiUrl: (id: string) => `/v1/system/users/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.SystemUser) => obj.username,
        getLink: (obj: Dto.RdmsApiV1.SystemUser) => `/users/${obj.id}`,
    },
    "user-role": {
        icon: "user-shield",
        getApiUrl: (id: string) => `/v1/system/user-roles/${id}`,
        getDisplayName: (obj: Dto.RdmsApiV1.SystemUserRole) => obj.title,
        getLink: (obj: Dto.RdmsApiV1.SystemUserRole) => `/user-roles/${obj.id}`,
    }
};

export interface ObjectLinkProps {
    variant?: "link" | "list-item-button";
    sx?: SxProps<Theme>;
    textVariant?: TypographyProps["variant"];
    type: keyof typeof objectTypes;
    id?: string | null;
    object?: any;
    icon?: boolean;
    empty?: string;
    tail?: React.ReactNode;
    disableLink?: boolean;
}

export const ObjectReference: React.FC<ObjectLinkProps> = props => {
    const type = useMemo(() => objectTypes[props.type], [props.type]);
    const apiUrl = useMemo(() => type && props.id ? type.getApiUrl(props.id) : undefined, [props.id, type]);
    const resolvedObject = useApiResource(apiUrl || "", { disable: !apiUrl, cache: true, ignoreForbidden: true });
    const object = useMemo(() => props.object || resolvedObject.data, [props.object, resolvedObject.data]);

    const displayName = useMemo(() => type && object ? type.getDisplayName(object) : undefined, [object, type]);
    const link = useMemo(() => type && !props.disableLink && object ? type.getLink?.(object) : undefined, [object, props.disableLink, type]);
    
    const skeletonWidth = useMemo(() => 64 + 128 * Math.random(), []);

    if (!props.id && !props.object) {
        return <Typography variant={props.textVariant}>{props.empty === undefined ? "-" : props.empty}</Typography>
    }

    if ((!props.object && !resolvedObject.loading && resolvedObject.error && resolvedObject.error.name !== "AbortError")) {
        if (!props.variant || props.variant === "link") {
            return (
                <Stack direction="row" alignItems="center" spacing={.5} color={resolvedObject.permissionDenied ? "text.disabled" : "error.main"} sx={{ opacity: .75 }}>
                    <FontAwesomeIcon icon={resolvedObject.permissionDenied ? ["fal", "lock"] : ["fas", "exclamation-triangle"]} fixedWidth />
                    <Typography variant={props.textVariant || "body2"}>{resolvedObject.error.message}</Typography>
                </Stack>
            );
        } else if (props.variant === "list-item-button") {
            return (
                <ListItemButton>
                    <ListItemIcon sx={{ color: t => t.palette.text.disabled, minWidth: "36px" }}>
                        <FontAwesomeIcon icon={resolvedObject.permissionDenied ? ["fal", "lock"] : ["fas", "exclamation-triangle"]} fixedWidth />
                    </ListItemIcon>
                    <ListItemText sx={{ color: t => t.palette.text.disabled }} primary={resolvedObject.error.message} />
                </ListItemButton>
            );
        }
    }

    if ((!props.object && resolvedObject.loading) || !type || !displayName) {
        return <Skeleton variant="rounded" height={props.variant === "list-item-button" ? 32 : 22} width={props.variant === "list-item-button" ? "100%" : skeletonWidth} sx={{ m: t => props.variant === "list-item-button" ? t.spacing(.5, 0) : undefined }} />
    }

    if (!props.variant || props.variant === "link") {
        const inner = (
            <>
                {props.icon && <FontAwesomeIcon icon={["fal", type.icon]} fixedWidth />}
                <Typography component="span" variant={props.textVariant || "body2"} sx={{ ml: props.icon ? .5 : undefined }}>{displayName}</Typography>
            </>
        );

        if (link) {
            return (
                <Box sx={{ ...props.sx }}>
                    <Link component={RouterLink} to={link} sx={{ textDecoration: "none" }}>
                        {inner}
                    </Link>
                    {props.tail}
                </Box>
            );
        } else {
            return (
                <Box sx={{ ...props.sx }}>
                    {inner}
                    {props.tail}
                </Box>
            );
        }
    } else if (props.variant === "list-item-button") {
        const inner = (
            <ListItemButton>
                {props.icon && <ListItemIcon sx={{ color: t => t.palette.primary.main, minWidth: "36px" }}><FontAwesomeIcon icon={["fas", type.icon]} fixedWidth /></ListItemIcon>}
                <ListItemText sx={{ color: t => t.palette.primary.main }} primary={displayName} />
            </ListItemButton>
        );
        if (link) {
            return (
                <Link sx={{ textDecoration: "none" }} component={RouterLink} to={link}>
                    {inner}
                </Link>
            );
        } else {
            return inner;
        }
    }
    return <></>;
};
