import { CardContent, Typography } from "@mui/material";
import DefaultCard from "components/DefaultCard";
import { LineChart, LineChartProps } from "./LineChart";

export const LineChartCard: React.FC<LineChartProps & { title: string, description?: string, loading?: boolean }> = props => {
    return (
        <DefaultCard raw loading={props.loading} skeletonHeight={80 + (props.height || 300)}>
            <CardContent>
                <Typography variant="h5">{props.title}</Typography>
                {props.description && <Typography variant="body2" color="text.disabled">{props.description}</Typography>}
                <LineChart {...props} />
            </CardContent>
        </DefaultCard>
    );
};
