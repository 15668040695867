import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionUserTargetList: React.FC<{ users: Dto.RdmsApiV1.SystemUser[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.SystemUser>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.users}
        singular="user"
        plural="users"
        icon={["fas", "user"]}
        getPrimary={(user: Dto.RdmsApiV1.SystemUser) => user.username}
    />;
