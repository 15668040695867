import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DataGridPro, DataGridProProps, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useUiStateStorage } from "hooks/useUiStateStorage";
import { useCallback, useMemo } from "react";
import { DataGridColDef } from "./DataGridColDef";

interface Props {
    uiStateId?: string
    columns: DataGridColDef[];
}

const DataGrid: React.FC<Props & DataGridProProps> = props => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const uiState = useUiStateStorage();
    const uiStateKey = useMemo(() => props.uiStateId ? `gridState.${props.uiStateId}:columnVisibilityModel` : undefined, [props.uiStateId]);

    const columns = useMemo(() => props.columns.filter(c => !smallScreen || !c.hideOnSmallScreen), [props.columns, smallScreen]);
    const columnVisibilityModel = useMemo(() => {
        const stored = (uiStateKey && uiState.state[uiStateKey]) || null;
        const model = stored ? {...stored} : {};
        for (const column of props.columns) {
            if (!stored && column.defaultVisibility === "hidden") {
                model[column.field] = false;
            }
        }
        return model;
    }, [props.columns, uiState.state, uiStateKey]);
    const handleColumnVisibilityModelChange = useCallback((model: GridColumnVisibilityModel) => {
        if (uiStateKey) {
            uiState.store(uiStateKey, model);
        }
    }, [uiState, uiStateKey]);

    return (
        <Box sx={{ flex: 1, position: "relative" }}>
            <Box sx={{ position: "absolute", inset: 0 }}>
                <DataGridPro
                    sx={{
                        background: t => t.palette.background.paper,
                        overflow: "hidden",
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                            outline: "none",
                        },
                    }}
                    {...props}
                    columns={columns as GridColDef[]}
                    columnVisibilityModel={props.columnVisibilityModel || columnVisibilityModel}
                    onColumnVisibilityModelChange={props.onColumnVisibilityModelChange || handleColumnVisibilityModelChange}
                    disableRowSelectionOnClick
                    disableDensitySelector
                    density="compact"
                />
            </Box>
        </Box>
    );
};
export default DataGrid;