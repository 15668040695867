import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import Center from "./Center";
import React from "react";

const DefaultContainer: React.FC<{ loading?: boolean, children: React.ReactNode }> = props => {
    return (
        <Box sx={{ flex: "1 1 auto", display: "flex", flexDirection: "column", p: t => t.spacing(0) }}>
            {props.loading !== undefined && props.loading && <Center><CircularProgress /></Center>}
            {(props.loading === undefined || !props.loading) && props.children}
        </Box>
    );
};
export default DefaultContainer;