import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDcmProfileTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { DcmProfileDeleteIcon } from "components/Icons";

interface Props {
    profiles: Dto.RdmsApiV1.DcmProfile[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deleteProfile = useApiInvocation<{ id: string}>("delete", `/v1/dcm-profiles/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, device] of props.profiles.entries()) {
                try {
                    await deleteProfile({ id: device.id });
                    setProgress((index + 1) / props.profiles.length);
                } catch (error) {
                    throw new Error(`Failed to delete DCM profile ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Removing DCM profile permanently destroys all its information and will unassign it from all customers and devices using it.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="error" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                This is a destructive action that cannot be undone. Proceed with caution!
            </Alert>
            <ActionDcmProfileTargetList profiles={props.profiles} operation="deleted" />
        </Box>
    );
};

const DeleteDcmProfileAction: ActionDef<Props> = {
    id: "dcm-profile-delete",
    title: "Delete DCM Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Delete",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Delete DCM profile",
        subtitle: "Remove and unassign device configuration & management profile",
        icon: <DcmProfileDeleteIcon />,
        color: "error.main"
    }
};

export default DeleteDcmProfileAction;