import { Grid } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { PieChartCard } from "components/Charts";
import useApiResource from "hooks/useApiResource";
import { useMemo } from "react";

export const DashboardSoftwareView: React.FC = props => {
    const applicationStats = useApiResource<Dto.RdmsApiV1.ClientSoftwareStats>(`/v1/stats/client/software?lookbehind=${90 * 24 * 60 * 60 * 1000}`, { reloadInterval: 60000 });

    const data = useMemo(() => ({
        rdma: applicationStats.data?.packageVersions.rdma.map((v, i) => ({ id: v.version, label: v.version, value: v.value })),
        app: applicationStats.data?.packageVersions.app.map((v, i) => ({ id: v.version, label: v.version, value: v.value })),
        environment: applicationStats.data?.packageVersions.environment.map((v, i) => ({ id: v.version, label: v.version, value: v.value })),
        fsFirmware: applicationStats.data?.fsFirmwareVersions.map((v, i) => ({ id: v.version, label: v.version, value: v.value })),
    }), [applicationStats.data]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="Varos RDM Agent"
                    description="Version distribution of Varos RDM agent for devices seen in the past 90 days."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Version"
                    maxItems={10}
                    sortByValue
                    data={data.rdma || []}
                    loading={applicationStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="Varos EFT5000 App"
                    description="Version distribution of Varos EFT5000 app for devices seen in the past 90 days."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Version"
                    maxItems={10}
                    sortByValue
                    data={data.app || []}
                    loading={applicationStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="Varos EFT5000 Environment"
                    description="Version distribution of Varos EFT5000 environment for devices seen in the past 90 days."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Version"
                    maxItems={10}
                    sortByValue
                    data={data.environment || []}
                    loading={applicationStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="Fiscal Storage Firmware"
                    description="Vendor, model and firmware version distribution of fiscal storage units for devices seen in the past 90 days."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Version"
                    maxItems={10}
                    sortByValue
                    data={data.fsFirmware || []}
                    loading={applicationStats.initialOrLongLoading}
                />
            </Grid>
        </Grid>
    );
};

export default DashboardSoftwareView;