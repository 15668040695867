import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Security } from "@varos/rdm-common";
import { ApiResourceAutocompletePreparedProps, BusinessSubjectAutocomplete, DcmProfileAutocomplete, DeviceAutocomplete, DeviceTypeAutocomplete, RpsProfileAutocomplete, UserAutocomplete, UserRoleAutocomplete } from "components/Autocomplete";

export const resourceStyles: { [key in Security.Authorization.ResourceName]: { singular: string, plural: string, icon: IconName, selectorApiUrlBase?: string, selectorAutocompleteComponent?: (props: ApiResourceAutocompletePreparedProps<any>) => JSX.Element } } = {
    "rdm:business-subject": {
        singular: "Business Subject",
        plural: "Business Subjects",
        icon: "building",
        selectorApiUrlBase: "/v1/business-subjects",
        selectorAutocompleteComponent: BusinessSubjectAutocomplete
    },
    "rdm:device": {
        singular: "Device",
        plural: "Devices",
        icon: "hdd",
        selectorApiUrlBase: "/v1/devices",
        selectorAutocompleteComponent: DeviceAutocomplete
    },
    "rdm:device-type": {
        singular: "Device Type",
        plural: "Device Types",
        icon: "layer-group",
        selectorApiUrlBase: "/v1/device-types",
        selectorAutocompleteComponent: DeviceTypeAutocomplete
    },
    "rdm:fsd-instance": {
        singular: "FSD Instance",
        plural: "FSD Instances",
        icon: "industry",
        selectorApiUrlBase: "/v1/fsd-instances",
    },
    "rdm:dcm-profile": {
        singular: "DCM Profile",
        plural: "DCM Profiles",
        icon: "badge-check",
        selectorApiUrlBase: "/v1/dcm-profiles",
        selectorAutocompleteComponent: DcmProfileAutocomplete
    },
    "rdm:rps-profile": {
        singular: "RPS Profile",
        plural: "RPS Profiles",
        icon: "print-search",
        selectorApiUrlBase: "/v1/rps/profiles",
        selectorAutocompleteComponent: RpsProfileAutocomplete
    },
    "sys:user": {
        singular: "User",
        plural: "Users",
        icon: "user",
        selectorApiUrlBase: "/v1/system/users",
        selectorAutocompleteComponent: UserAutocomplete
    },
    "sys:user-role": {
        singular: "User Role",
        plural: "User Roles",
        icon: "user-cog",
        selectorApiUrlBase: "/v1/system/user-roles",
        selectorAutocompleteComponent: UserRoleAutocomplete
    },
    "sys:system": {
        singular: "System",
        plural: "System",
        icon: "cogs"
    }
};

export const operationStyles: { [key in Security.Authorization.OperationName]: { title: string, icon: IconName } } = {
    create: { title: "Create", icon: "plus" },
    read: { title: "Read", icon: "eye" },
    update: { title: "Update", icon: "pencil" },
    delete: { title: "Delete", icon: "trash" },
    "device:read-info": { title: "Read Device Info", icon: "info" },
    "device:read-status": { title: "Read Device Status", icon: "signal" },
    "device:read-stats": { title: "Read Device Stats", icon: "chart-bar" },
    "device:read-rps-bindings": { title: "Read RPS Bindings", icon: "link-simple" },
    "device:revoke-rps-bindings": { title: "Revoke RPS Bindings", icon: "link-simple-slash" },
    "device:recover-rdma-config": { title: "Recover RDMA Config", icon: "file-shield" },
    "device:print-label": { title: "Print Device Label", icon: "tag" },
    "system:read-platform-stats": { title: "Read Platform Info", icon: "chart-pie" },
    "system:read-client-connection-stats": { title: "Read Client Connection Stats", icon: "network-wired" },
    "system:read-client-software-stats": { title: "Read Client Software Stats", icon: "boxes" },
};

export const selectorStyles: { [key in Security.Authorization.SelectorName]: { title: string, icon: IconName } } = {
    all: {
        title: "All Resources",
        icon: "grid-2"
    },
    specific: {
        title: "Specific Resources",
        icon: "ballot-check"
    },
    "assigned-customer": {
        title: "Assigned To Selected Customers",
        icon: "building"
    },
    "assigned-service-partner": {
        title: "Assigned To Selected Service Partners",
        icon: "wrench"
    }
};
