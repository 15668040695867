import { Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionUserTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { UserDisableIcon } from "components/Icons";

interface Props {
    users: Dto.RdmsApiV1.SystemUser[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateUser = useApiInvocation<{ id: string}, Dto.RdmsApiV1.SystemUser>("put", `/v1/system/users/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, user] of props.users.entries()) {
                try {
                    await updateUser({ id: user.id }, { enabled: false });
                    setProgress((index + 1) / props.users.length);
                } catch (error) {
                    throw new Error(`Failed to update user ${user.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Disabling a user will disallow them from using the platform.
            </Typography>
            <ActionUserTargetList users={props.users} />
        </Box>
    );
};

const DisableUserAction: ActionDef<Props> = {
    id: "user-disable",
    title: "Disable User",
    component: Action,
    dialog: {
        confirmButtonCaption: "Disable",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Disable",
        subtitle: "Disable user and allow them to log in",
        icon: <UserDisableIcon />,
        color: "warning.main"
    }
};

export default DisableUserAction;