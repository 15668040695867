import { Box, Button, Chip, Divider, Stack, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import useApiResource from "hooks/useApiResource";
import { Device, Dto } from "@varos/rdm-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import ObjectIndexActionBar from "components/ObjectIndexActionBar";
import DefaultContainer from "components/DefaultContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { debounceTime, distinctUntilChanged, map, Subject, tap } from "rxjs";
import { Link } from "react-router-dom";
import DataGrid, { DataGridColDef, DataGridSelectActionButton } from "components/DataGrid";
import Heading from "components/Heading";
import Moment from "react-moment";
import { useDcmProfileActions } from "hooks/useDcmProfileActions";
import DeleteDcmProfileAction from "components/ObjectActions/DcmProfile/DeleteDcmProfileAction";
import { ActionMenuItem } from "components/Action";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { RequireAuthorization } from "components/Authorization";
import SetGlobalDefaultDcmProfileAction from "components/ObjectActions/DcmProfile/SetGlobalDefaultDcmProfileAction";

const DcmProfileIndexView: React.FC = () => {
    const theme = useTheme();
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "title", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const dcmProfilesUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const url = `/v1/dcm-profiles?search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
        return url;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, sortModel]);

    const dcmProfiles = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.DcmProfile>>(dcmProfilesUrl);

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => dcmProfiles.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.DcmProfile[], [dcmProfiles?.data, selectedIds]);

    const dcmProfileActions = useDcmProfileActions({ onUpdate: () => dcmProfiles.reload() });

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const createUpdateProfileCellRenderer = (type: "app" | "rdma" | "environment") => (params: any) => {
        const updateProfile = (params.row.updateProfiles as Device.PackageUpdateProfile[]).find(p => p.type === type);
        if (updateProfile && !updateProfile?.enabled) {
            return <Chip variant="outlined" color="warning" size="small" label="Disabled" />
        } else if (updateProfile) {
            return (
                <Stack direction="row">
                    <Chip variant="filled" color="info" size="small" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} label={updateProfile.channel || "stable"} />
                    <Chip variant="outlined" color="info" size="small" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftStyle: "none" }}  label={updateProfile.versionSelector || "*"} />
                </Stack>
            );
        } else {
            return <Chip variant="filled" color="default" size="small" label="Unmanaged" />
        }
    };

    const columns: DataGridColDef<Dto.RdmsApiV1.DcmProfile>[] = useMemo(() => [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={[params.row.isGlobalDefault ? "fas" : "fad", "badge-check"]} />
                    <Typography variant="body2" color="primary" fontWeight={params.row.isGlobalDefault ? 600 : undefined} sx={{ textDecoration: "none", ml: .5 }} component={Link} to={`/dcm-profiles/${params.row.id}`}>
                        {params.value}
                    </Typography>
                    {params.row.isGlobalDefault && <Tooltip title="Global default"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "globe"]} fixedWidth /></Tooltip>}
                </Box>
            )
        },
        {
            field: "_infoReporting",
            headerName: "Info Reporting",
            flex: 1,
            hideOnSmallScreen: true,
            renderCell: params => {
                if (params.row.infoReportingEnabled) {
                    return (
                        <Stack direction="row">
                            <Chip variant="filled" color="success" size="small" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} label="Enabled" />
                            <Chip
                                variant="outlined"
                                color="success"
                                size="small"
                                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftStyle: "none" }}
                                label={
                                    <Moment utc format="HH:mm:ss">{params.row.infoReportingInterval}</Moment>
                                }
                            />
                        </Stack>
                    );
                } else {
                    return <Chip variant="filled" color="default" size="small" label="Disabled" />;
                }
            }
        },
        {
            field: "_dcmEndpoints",
            headerName: "DCM Endpoints",
            flex: 1,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true,
            renderCell: params => params.row.dcmAddresses.length > 0 ? params.row.dcmAddresses.length : <Chip variant="filled" color="default" size="small" label="Fallback only" />
        },
        {
            field: "_vpnEndpoints",
            headerName: "VPN Endpoints",
            flex: 1,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true,
            renderCell: params => params.row.vpnGatewayAddresses.length > 0 ? params.row.vpnGatewayAddresses.length : <Chip variant="filled" color="default" size="small" label="Fallback only" />
        },
        {
            field: "_appVersion",
            headerName: "App Updates",
            flex: 1,
            hideOnSmallScreen: true,
            renderCell: createUpdateProfileCellRenderer("app")
        },
        {
            field: "_environmentVersion",
            headerName: "Environment Updates",
            flex: 1,
            hideOnSmallScreen: true,
            renderCell: createUpdateProfileCellRenderer("environment")
        },
        {
            field: "_rdmaVersion",
            headerName: "RDMA Updates",
            flex: 1,
            hideOnSmallScreen: true,
            renderCell: createUpdateProfileCellRenderer("rdma")
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            defaultVisibility: "hidden"
        }
    ], [theme]);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        factory
            .section("DCM Profiles")
            .if(auth.hasAccess("rdm:dcm-profile", "create")).action("dcm-profile-index-create", "Create DCM Profile").priority(9000).do(() => dcmProfileActions.create())
            .if(auth.hasAccess("rdm:dcm-profile", "update")).action("dcm-profile-index-set-global-default", "Set Global Default DCM Profile").priority(9100).do(() => dcmProfileActions.setGlobalDefault({}));
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected DCM Profiles`)
                .if(auth.hasAccess("rdm:dcm-profile", "delete")).action(DeleteDcmProfileAction).priority(8900).do(() => dcmProfileActions.deleteProfile({ profiles: selectedObjects }));
            if (selectedObjects.length === 1) {
                factory.if(auth.hasAccess("rdm:dcm-profile", "update")).action(SetGlobalDefaultDcmProfileAction).priority(8100).do(() => dcmProfileActions.setGlobalDefault({ profile: selectedObjects[0] }));
            }
        }
        return factory;
    }, [dcmProfileActions, selectedObjects, auth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Heading>DCM Profiles</Heading>
            <DefaultContainer loading={dcmProfiles.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        {selectedObjects.length === 1 && (
                            <RequireAuthorization object={selectedObjects[0]} operation="update">
                                <ActionMenuItem def={SetGlobalDefaultDcmProfileAction} action={() => dcmProfileActions.setGlobalDefault({ profile: selectedObjects[0] })} />
                            </RequireAuthorization>
                        )}
                        <Divider />
                        <RequireAuthorization resource="rdm:dcm-profile" operation="delete">
                            <ActionMenuItem def={DeleteDcmProfileAction} action={() => dcmProfileActions.deleteProfile({ profiles: selectedObjects })} />
                        </RequireAuthorization>
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                    <RequireAuthorization resource="rdm:dcm-profile" operation="create">
                        <Button variant="contained" disableElevation onClick={() => dcmProfileActions.create()}>New</Button>
                    </RequireAuthorization>
                    <RequireAuthorization resource="rdm:dcm-profile" operation="update">
                        <Button variant="outlined" disableElevation onClick={() => dcmProfileActions.setGlobalDefault({})}>Set Global Default</Button>
                    </RequireAuthorization>
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="dcmProfileGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={dcmProfiles.data?.data || []}
                    rowCount={dcmProfiles.data?.total || 0}
                    loading={dcmProfiles.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    checkboxSelection={auth.hasAccess("rdm:dcm-profile", "update") || auth.hasAccess("rdm:dcm-profile", "delete")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={model => setSelectedIds(model as string[])}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting
                />
            </DefaultContainer>
        </>
    );
};
export default DcmProfileIndexView;
