import { KBarAnimator, KBarPortal, KBarPositioner, KBarSearch } from "kbar";
import { CommandBarResults } from "./CommandBarResults";
import { useMemo } from "react";
import { LinearProgress, useTheme } from "@mui/material";
import { BehaviorSubject } from "rxjs";
import useObservable from "hooks/useObservable";

export const commandBarSearching$ = new BehaviorSubject(false);

export const CommandBar: React.FC = props => {
    const theme = useTheme();

    const isSearching = useObservable(commandBarSearching$);

    const searchStyle = useMemo(() => ({
        padding: "12px 16px",
        fontSize: "18px",
        minHeight: "48px",
        width: "100%",
        boxSizing: "border-box" as React.CSSProperties["boxSizing"],
        outline: "none",
        border: "none",
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
    }), [theme]);

    const animatorStyle = useMemo(() => ({
        maxWidth: "600px",
        width: "100%",
        background: theme.palette.background.paper,
        color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.text.secondary,
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: theme.shadows[8]
    }), [theme]);

    const positionerStyle = useMemo(() => ({
        zIndex: 2000,
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    }), []);

    return (
        <KBarPortal>
            <KBarPositioner className="kbar-positioner" style={positionerStyle}>
                <KBarAnimator style={animatorStyle}>
                    <KBarSearch style={searchStyle} />
                    <CommandBarResults />
                    <LinearProgress sx={{ transition: "height 300ms ease", height: isSearching ? "6px" : "0px", display: isSearching ? "block" : "none" }} />
                </KBarAnimator>
            </KBarPositioner>
        </KBarPortal>
    );
};