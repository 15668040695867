import { Box } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardOverviewView from "./DashboardOverviewView";
import DashboardClientsView from "./DashboardClientsView";
import DashboardSoftwareView from "./DashboardSoftwareView";
import DashboardPlatformView from "./DashboardPlatformView";
import Heading from "components/Heading";
import { NavigationTabs, NavigationTab } from "components/NavigationTabs";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";

const DashboardView: React.FC = () => {
    const auth = useAuthenticationInfo();

    return (
        <>
            <Heading>Dashboard</Heading>
            <NavigationTabs prefix="/dashboard">
                <NavigationTab label="Overview" path="" />
                {auth.hasAccess("sys:system", "system:read-platform-stats") && <NavigationTab label="Platform" path="/platform" />}
                {auth.hasAccess("sys:system", "system:read-client-connection-stats") && <NavigationTab label="Clients" path="/clients" />}
                {auth.hasAccess("sys:system", "system:read-client-software-stats") && <NavigationTab label="Software" path="/software" />}
            </NavigationTabs>
            <Box sx={{ pt: 2 }}>
                <Switch>
                    <Route exact path="/dashboard" render={() => <DashboardOverviewView />} />
                    {auth.hasAccess("sys:system", "system:read-platform-stats") && <Route exact path="/dashboard/platform" render={() => <DashboardPlatformView />} />}
                    {auth.hasAccess("sys:system", "system:read-client-connection-stats") && <Route exact path="/dashboard/clients" render={() => <DashboardClientsView />} />}
                    {auth.hasAccess("sys:system", "system:read-client-software-stats") && <Route exact path="/dashboard/software" render={() => <DashboardSoftwareView />} />}
                </Switch>
            </Box>
        </>
    );
};

export default DashboardView;
