import useObjectAction from "./useObjectAction";
import CreateUserRoleAction from "components/ObjectActions/UserRole/CreateUserRoleAction";
import DeleteUserRoleAction from "components/ObjectActions/UserRole/DeleteUserRoleAction";
import DisableUserRoleAction from "components/ObjectActions/UserRole/DisableUserRoleAction";
import EditUserRoleAction from "components/ObjectActions/UserRole/EditUserRoleAction";
import EnableUserRoleAction from "components/ObjectActions/UserRole/EnableUserRoleAction";

export const useUserRoleActions = (opts: { onUpdate?: () => void }) => {
    const create = useObjectAction(CreateUserRoleAction, opts.onUpdate);
    const edit = useObjectAction(EditUserRoleAction, opts.onUpdate);
    const deleteRole = useObjectAction(DeleteUserRoleAction, opts.onUpdate);
    const enable = useObjectAction(EnableUserRoleAction, opts.onUpdate);
    const disable = useObjectAction(DisableUserRoleAction, opts.onUpdate);

    return {
        create,
        edit,
        delete: deleteRole,

        enable,
        disable
    };
};
