import { useSensors, useSensor, PointerSensor, KeyboardSensor, DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle, Box, Collapse, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListSubheader, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBaseProps, ActionDef } from "components/Action";
import { FormAccordion, FormTextField } from "components/Form";
import { DcmProfileEditIcon } from "components/Icons";
import SortableTextFieldListItem from "components/SortableTextFieldListItem";
import { useAccordionGroup } from "hooks/useAccordionGroup";
import useActionState from "hooks/useActionState";
import useApiInvocation from "hooks/useApiInvocation";
import { useCallback, useEffect, useMemo, useState } from "react";

interface UpdateProfileAccordionProps {
    type: string;
    title: string;
    description?: string;
    expanded?: boolean;
    onChange?: (expanded: boolean) => void;
    loading?: boolean;
    updateProfile?: Dto.RdmsApiV1.DcmProfile["updateProfiles"][0] | null;
    onUpdateProfileChange?: (profile: Dto.RdmsApiV1.DcmProfile["updateProfiles"][0] | null) => void;
}

const UpdateProfileAccordion: React.FC<UpdateProfileAccordionProps> = props => {
    
    const [updateProfile, setUpdateProfile] = useState<Partial<Dto.RdmsApiV1.DcmProfile["updateProfiles"][0]> | null>(null);
    const mode = useMemo<"unmanaged" | "enabled" | "disabled">(() => !updateProfile ? "unmanaged" : updateProfile.enabled ? "enabled" : "disabled", [updateProfile]);

    useEffect(() => setUpdateProfile(props.updateProfile || null), [props.updateProfile]);

    const handleFieldChange = (key: keyof Dto.RdmsApiV1.DcmProfile["updateProfiles"][0], value: any) => {
        const profile = { ...updateProfile, [key]: value };
        setUpdateProfile(profile);
        props.onUpdateProfileChange?.({ ...profile, type: props.type } as Dto.RdmsApiV1.DcmProfile["updateProfiles"][0]);
    };

    return (
        <FormAccordion title={props.title} description={props.description} icon={["fal", "box"]} expanded={props.expanded} onChange={props.onChange}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel>Update Mode</InputLabel>
                        <Select value={mode} label="Update Mode" onChange={evt => {
                            if (evt.target.value === "unmanaged") {
                                props.onUpdateProfileChange?.(null);
                            } else {
                                handleFieldChange("enabled", evt.target.value === "enabled");
                            }
                        }} >
                            <MenuItem value="unmanaged">Unmanaged</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                            <MenuItem value="enabled">Enabled</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Collapse in={mode === "enabled"}>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <FormTextField
                            title="Base URL"
                            placeholder="Keep empty for default"
                            disabled={props.loading}
                            value={updateProfile?.baseUrl || ""}
                            onChange={value => handleFieldChange("baseUrl", value as string)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            title="Channel"
                            placeholder="stable"
                            disabled={props.loading}
                            value={updateProfile?.channel || ""}
                            onChange={value => handleFieldChange("channel", value as string)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            title="Version Selector"
                            placeholder="*"
                            disabled={props.loading}
                            value={updateProfile?.versionSelector || ""}
                            onChange={value => handleFieldChange("versionSelector", value as string)}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        </FormAccordion>
    );
};

interface Props {
    profile?: Dto.RdmsApiV1.DcmProfile
}

export const Action: React.FC<ActionBaseProps & Props> = props => {
    const createProfile = useApiInvocation<{}, Dto.RdmsApiV1.DcmProfile>("post", "/v1/dcm-profiles", { silentError: true, throwError: true });
    const updateProfile = useApiInvocation<{ id: string}, Dto.RdmsApiV1.DcmProfile>("put", `/v1/dcm-profiles/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [profile, setProfile] = useState<Partial<Dto.RdmsApiV1.DcmProfile>>();
    useEffect(() => setProfile({ ...props.profile }), [props.profile]);

    const [rdmaUpdateProfile, setRdmaUpdateProfile] = useState<Dto.RdmsApiV1.DcmProfile["updateProfiles"][0] | null>(null);
    const [eft5000AppUpdateProfile, setEft5000AppUpdateProfile] = useState<Dto.RdmsApiV1.DcmProfile["updateProfiles"][0] | null>(null);
    const [eft5000EnvironmentUpdateProfile, setEf5000EnvironmentUpdateProfile] = useState<Dto.RdmsApiV1.DcmProfile["updateProfiles"][0] | null>(null);
    useEffect(() => {
        setRdmaUpdateProfile(props.profile?.updateProfiles?.find(p => p.type === "rdma") || null);
        setEft5000AppUpdateProfile(props.profile?.updateProfiles?.find(p => p.type === "app") || null);
        setEf5000EnvironmentUpdateProfile(props.profile?.updateProfiles?.find(p => p.type === "environment") || null);
    }, [props.profile?.updateProfiles]);

    const [newDcmAddress, setNewDcmAddress] = useState<string>("");
    const [newVpnGatewayAddress, setNewVpnGatewayAddress] = useState<string>("");
    const dndSensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );
    const createSortDragEndHandler = useCallback((fieldName: keyof Dto.RdmsApiV1.DcmProfile) => (event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const items = (profile?.[fieldName] || []) as any[];
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const moved = arrayMove(items, oldIndex, newIndex);
            setProfile(s => ({ ...s, [fieldName]: moved }));
        }
    }, [profile]);

    const accordionGroup = useAccordionGroup();
    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            const updateProfiles = [rdmaUpdateProfile, eft5000AppUpdateProfile, eft5000EnvironmentUpdateProfile].filter(v => !!v) as Dto.RdmsApiV1.DcmProfile["updateProfiles"];
            if (props.profile) {
                try {
                    const data = { ...profile, updateProfiles };
                    delete data.id;
                    delete data.createDate;
                    delete data.updateDate;
                    delete data._meta;
                    await updateProfile({ id: props.profile.id }, data);
                } catch (error) {
                    throw new Error(`Failed to DCM profile ${props.profile.id} - ${error.message}`);
                }
            } else {
                try {
                    await createProfile({}, {
                        ...profile, 
                        dcmAddresses: profile?.dcmAddresses || [],
                        vpnGatewayAddresses: profile?.vpnGatewayAddresses || [],
                        updateProfiles
                    });
                } catch (error) {
                    throw new Error(`Failed to create DCM profile - ${error.message}`);
                }
            }
        }
    });
    
    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                DCM profile defines device interaction with platform, updates, information reporting and other vital runtime parameters.
                Profiles are applied globally, per customer or per device.
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Collapse in={profile?.ppekkApiGatewayEnabled && profile?.ppekkApiVaultExtensionsInjectionEnabled}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Alert color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                                <AlertTitle>PPEKK Vault Extensions Injection Enabled!</AlertTitle>
                                Vault extensions are available within EFT5000 App v3.2.0 and newer.
                                When vault extensions injection is enabled, RDM Agent will attempt to patch the running PPEKK in order to allow this functionality.
                                This is, however, an invasive operation and should not be used unless necessary.
                            </Alert>
                        </Grid>
                    </Grid>
                </Collapse>
                <FormAccordion title="General" description="Main profile settings" icon={["fal", "info-circle"]} {...accordionGroup.control("general")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>General section allows only profile title configuration.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                autoFocus
                                title="Title"
                                placeholder="Profile #1"
                                disabled={state.blocked}
                                value={profile?.title || ""}
                                onChange={value => setProfile(s => ({ ...s, title: value as string }))}
                            />
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="DCM Connection" description="Primary management connection endpoints" icon={["fal", "cloud"]} {...accordionGroup.control("dcm-connection")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                DCM (device configuration &amp; management) protocol allows devices to be permanently connected with platform.
                                This connection is used for device information collection, remote control, remote printing and other services.
                                If no endpoints are configured in this section, device uses default ones in stock configuration.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List disablePadding subheader={<ListSubheader disableGutters sx={{ backgroundColor: "inherit" }}>DCM Endpoint Addresses</ListSubheader>}>
                                <DndContext sensors={dndSensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={createSortDragEndHandler("dcmAddresses")}>
                                    <SortableContext items={profile?.dcmAddresses || []} strategy={verticalListSortingStrategy}>
                                        {(profile?.dcmAddresses || []).map((address, index) =>
                                            <SortableTextFieldListItem
                                                key={index}
                                                id={address}
                                                item={address}
                                                onChange={value => {
                                                    setProfile(last => ({
                                                        ...last,
                                                        dcmAddresses: (profile?.dcmAddresses || []).map((v, i) => i === index ? value : v)
                                                    }));
                                                }}
                                                disabled={state.blocked}
                                                onDelete={() => setProfile(last => ({ ...last, dcmAddresses: (profile?.dcmAddresses || []).filter((_, i) => i !== index) }))}
                                            />
                                        )}
                                    </SortableContext>
                                </DndContext>
                            </List>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemIcon sx={{ minWidth: "40px" }}>
                                        <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "plus-circle"]} />
                                    </ListItemIcon>
                                    <TextField
                                        sx={{ width: "100%", m: t => t.spacing(0, 5, 0, 0) }}
                                        value={newDcmAddress}
                                        variant="standard"
                                        disabled={state.blocked}
                                        onChange={evt => setNewDcmAddress(evt.target.value)}
                                        onKeyDown={evt => {
                                            if (evt.key === "Enter") {
                                                evt.preventDefault();
                                                if (newDcmAddress) {
                                                    setProfile(s => ({ ...s, dcmAddresses: [...(s?.dcmAddresses || []), newDcmAddress] }));
                                                    setNewDcmAddress("");
                                                }
                                            }
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="VPN Connection" description="VPN for technical support" icon={["fal", "lock"]} {...accordionGroup.control("vpn-connection")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                VPN connection allows in-depth analysis and support for devices.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List disablePadding subheader={<ListSubheader disableGutters sx={{ backgroundColor: "inherit" }}>VPN Gateway Addresses</ListSubheader>}>
                                <DndContext sensors={dndSensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={createSortDragEndHandler("vpnGatewayAddresses")}>
                                    <SortableContext items={profile?.vpnGatewayAddresses || []} strategy={verticalListSortingStrategy}>
                                        {(profile?.vpnGatewayAddresses || []).map((address, index) =>
                                            <SortableTextFieldListItem
                                                key={index}
                                                id={address}
                                                item={address}
                                                disabled={state.blocked}
                                                onChange={value => {
                                                    setProfile(last => ({
                                                        ...last,
                                                        vpnGatewayAddresses: (profile?.vpnGatewayAddresses || []).map((v, i) => i === index ? value : v)
                                                    }));
                                                }}
                                                onDelete={() => setProfile(last => ({ ...last, vpnGatewayAddresses: (profile?.vpnGatewayAddresses || []).filter((_, i) => i !== index) }))}
                                            />
                                        )}
                                    </SortableContext>
                                </DndContext>
                            </List>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemIcon sx={{ minWidth: "40px" }}>
                                        <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "plus-circle"]} />
                                    </ListItemIcon>
                                    <TextField
                                        sx={{ width: "100%", m: t => t.spacing(0, 5, 0, 0) }}
                                        value={newVpnGatewayAddress}
                                        variant="standard"
                                        disabled={state.blocked}
                                        onChange={evt => setNewVpnGatewayAddress(evt.target.value)}
                                        onKeyDown={evt => {
                                            if (evt.key === "Enter") {
                                                evt.preventDefault();
                                                if (newVpnGatewayAddress) {
                                                    setProfile(s => ({ ...s, vpnGatewayAddresses: [...(s?.vpnGatewayAddresses || []), newVpnGatewayAddress] }));
                                                    setNewVpnGatewayAddress("");
                                                }
                                            }
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="Info Reporting" description="Device information collection" icon={["fal", "file-circle-info"]} {...accordionGroup.control("info-reporting")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                Devices can report basic vital information, such as software versions, CPU usage, memory usage and much more.
                                For newer applications with supported hardware also fiscal storage information and printer information can be reported.
                                Reports are sent regularly based on reporting interval configured below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Enable info reporting"
                                disabled={state.blocked}
                                control={<Switch checked={profile?.infoReportingEnabled || false} onChange={(_, checked) => setProfile(s => ({ ...s, infoReportingEnabled: checked }))} />}
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={profile?.infoReportingEnabled}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Enable fiscal storage info reporting"
                                    disabled={state.blocked}
                                    control={<Switch checked={profile?.fiscalStorageInfoReportingEnabled || false} onChange={(_, checked) => setProfile(s => ({ ...s, fiscalStorageInfoReportingEnabled: checked }))} />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Enable printer info reporting"
                                    disabled={state.blocked}
                                    control={<Switch checked={profile?.printerInfoReportingEnabled || false} onChange={(_, checked) => setProfile(s => ({ ...s, printerInfoReportingEnabled: checked }))} />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel>Reporting Interval</InputLabel>
                                    <Select
                                        value={profile?.infoReportingInterval || 300000}
                                        label="Reporting Interval"
                                        disabled={state.blocked}
                                        onChange={evt => setProfile(s => ({ ...s, infoReportingInterval: evt.target.value as number }))}
                                    >
                                        <MenuItem value={1 * 60 * 1000}>Every minute</MenuItem>
                                        <MenuItem value={2 * 60 * 1000}>Every 2 minutes</MenuItem>
                                        <MenuItem value={3 * 60 * 1000}>Every 3 minutes</MenuItem>
                                        <MenuItem value={5 * 60 * 1000}>Every 5 minutes</MenuItem>
                                        <MenuItem value={10 * 60 * 1000}>Every 10 minutes</MenuItem>
                                        <MenuItem value={15 * 60 * 1000}>Every 15 minutes</MenuItem>
                                        <MenuItem value={30 * 60 * 1000}>Every 30 minutes</MenuItem>
                                        <MenuItem value={45 * 60 * 1000}>Every 45 minutes</MenuItem>
                                        <MenuItem value={1 * 60 * 60 * 1000}>Every hour</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Collapse>
                </FormAccordion>
                <FormAccordion title="PPEKK API" description="Fiscal storage info access" icon={["fal", "cash-register"]} {...accordionGroup.control("ppekk-api")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                Access to PPEKK API allows RDM agent to collect information from fiscal storage and printer.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Enable PPEKK API Gateway"
                                disabled={state.blocked}
                                control={<Switch checked={profile?.ppekkApiGatewayEnabled || false} onChange={(_, checked) => setProfile(s => ({ ...s, ppekkApiGatewayEnabled: checked }))} />}
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={profile?.ppekkApiGatewayEnabled}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Enable PPEKK Vault Extensions Injection"
                                    disabled={state.blocked}
                                    control={<Switch color="warning" checked={profile?.ppekkApiVaultExtensionsInjectionEnabled || false} onChange={(_, checked) => setProfile(s => ({ ...s, ppekkApiVaultExtensionsInjectionEnabled: checked }))} />}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </FormAccordion>
                <UpdateProfileAccordion type="rdma" title="RDM Agent Updates" description="Management daemon update settings" loading={state.blocked} updateProfile={rdmaUpdateProfile} onUpdateProfileChange={setRdmaUpdateProfile}  {...accordionGroup.control("updates-rdma")} />
                <UpdateProfileAccordion type="app" title="EFT5000 App Updates" description="Main app update settings" loading={state.blocked} updateProfile={eft5000AppUpdateProfile} onUpdateProfileChange={setEft5000AppUpdateProfile}  {...accordionGroup.control("updates-eft5000-app")} />
                <UpdateProfileAccordion type="environment" title="EFT5000 Environment Updates" description="App environment update settings" loading={state.blocked} updateProfile={eft5000EnvironmentUpdateProfile} onUpdateProfileChange={setEf5000EnvironmentUpdateProfile}  {...accordionGroup.control("updates-eft5000-environment")} />
            </Box>
        </Box>
    );
};

const EditDcmProfileAction: ActionDef<Props> = {
    id: "dcm-profile-edit",
    title: "Edit DCM Profile",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Update"
    },
    menu: {
        title: "Edit DCM Profile",
        subtitle: "Update DCM profile parameters deployed to devices",
        icon: <DcmProfileEditIcon />
    }
};

export default EditDcmProfileAction;
