import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBaseProps, ActionDef } from "components/Action";
import { FormTextField } from "components/Form";
import useActionState from "hooks/useActionState";
import useApiInvocation from "hooks/useApiInvocation";
import React, { useCallback, useEffect, useState } from "react";
import { deepEquals } from "utils";
import { SortableAuthorizationRule, SortableAuthorizationRuleData } from "components/Authorization";
import { useAccordionGroup } from "hooks/useAccordionGroup";
import useConfirmDialog from "hooks/useConfirmDialog";
import { firstValueFrom } from "rxjs";


interface Props {
    userRole?: Dto.RdmsApiV1.SystemUserRole
}

export const Action: React.FC<ActionBaseProps & Props> = props => {
    const confirm = useConfirmDialog();

    const createRole = useApiInvocation<{}, Dto.RdmsApiV1.SystemUserRole>("post", "/v1/system/user-roles", { silentError: true, throwError: true });
    const updateRole = useApiInvocation<{ id: string}, Dto.RdmsApiV1.SystemUserRole>("put", `/v1/system/user-roles/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [userRole, setUserRole] = useState<Partial<Dto.RdmsApiV1.SystemUserRole>>();
    useEffect(() => setUserRole(props.userRole ? { ...props.userRole } : { enabled: true }), [props.userRole]);

    const [rules, setRules] = useState<SortableAuthorizationRuleData[]>([]);
    useEffect(() => setRules(userRole?.rules?.map((r, i) => ({ ...r, id: `rule-${i}` })) || []), [userRole?.rules]);

    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            if (props.userRole) {
                try {
                    const data = { ...userRole, rules };
                    delete data.id;
                    delete data.createDate;
                    delete data.updateDate;
                    delete data._meta;
                    await updateRole({ id: props.userRole.id }, data);
                } catch (error) {
                    throw new Error(`Failed to update user role ${props.userRole.id} - ${error.message}`);
                }
            } else {
                try {
                    await createRole({}, { ...userRole, rules });
                } catch (error) {
                    throw new Error(`Failed to create user role - ${error.message}`);
                }
            }
        }
    });

    const accordionGroup = useAccordionGroup();
    
    const dndSensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const handleRuleChange = useCallback((rule: SortableAuthorizationRuleData) => {
        const newRules = [...rules.map(r => r.id === rule.id ? rule : r)];
        if (deepEquals(rules, newRules)) {
            return;
        }
        setRules(newRules);
    }, [rules]);

    const handleRuleSortChange = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = rules.findIndex(i => i.id === active.id)
            const newIndex = rules.findIndex(i => i.id === over.id);
            const moved = arrayMove(rules, oldIndex, newIndex);
            setRules(moved);
        }
    }, [rules]);

    const handleRuleDelete = useCallback(async (rule: SortableAuthorizationRuleData) => {
        if (await firstValueFrom(confirm({ title: "Confirm rule removal", text: "Are you sure you want to remove this permission rule?" }))) {
            setRules(rules => rules.filter(r => r.id !== rule.id));
        }
    }, [confirm]);
    
    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                User roles define rules allowing or denying access to platform resources.
                Roles are applied to users.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField
                        autoFocus
                        title="Title"
                        placeholder="Platform Manager"
                        disabled={state.blocked}
                        value={userRole?.title || ""}
                        onChange={value => setUserRole(r => ({ ...r, title: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Enable role"
                        disabled={state.blocked}
                        control={<Switch checked={userRole?.enabled || false} onChange={(_, checked) => setUserRole(r => ({ ...r, enabled: checked }))} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            filter: !userRole?.enabled ? `grayscale(100%)` : undefined,
                            opacity: !userRole?.enabled ? .5 : undefined,
                            pointerEvents: !userRole?.enabled ? "none" : undefined,
                            transition: `filter 300ms ease, opacity 300ms ease`
                        }}
                    >
                        <Typography gutterBottom variant="h5" color="text.secondary">Rules</Typography>
                        <DndContext sensors={dndSensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={handleRuleSortChange}>
                            <SortableContext items={rules} strategy={verticalListSortingStrategy}>
                                {rules.map(rule => (
                                    <SortableAuthorizationRule
                                        key={rule.id}
                                        rule={rule}
                                        onChange={handleRuleChange}
                                        onDelete={() => handleRuleDelete(rule)}
                                        {...accordionGroup.control(rule.id, "onExpandChange")}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!userRole?.enabled} onClick={() => setRules(rules => [...rules, { id: `rule-${rules.length}`, resource: "rdm:business-subject", selector: "all", operations: ["read"], selectorObjectIds: [], action: "grant"}])}>Add Rule</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const EditUserRoleAction: ActionDef<Props> = {
    id: "user-role-edit",
    title: "Edit User Role",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Update",
    },
    menu: {
        title: "Edit User Role",
        subtitle: "Update user role base data and configuration"
    }
};

export default EditUserRoleAction;
