import { Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useState } from "react";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { BusinessSubjectAutocomplete } from "components/Autocomplete";
import { ServicePartnerSetIcon } from "components/Icons";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const [selected, setSelected] = useState<Dto.RdmsApiV1.BusinessSubject | null>(null);
    const [loading, setLoading] = useState(true);

    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });

    const state = useActionState({
        ...props,
        loading,
        animationDelay: 500,
        process: async setProgress => {
            if (!selected) {
                throw new Error("No service partner has been selected.");
            }
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { servicePartnerBusinessSubjectId: selected.id });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Service partners are responsible for installation and maintenance of devices.
                Changing device service partner may modify their access to device.
            </Typography>
            <ActionDeviceTargetList devices={props.devices} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose service partner to assign selected devices to:</Typography>
            <BusinessSubjectAutocomplete autoFocus disabled={state.blocked} value={selected} onChange={setSelected} onInitialLoadingChange={setLoading} />
        </Box>
    );
};

const SetDeviceServicePartnerAction: ActionDef<Props> = {
    id: "device-set-service-partner",
    title: "Assign Device To Service Partner",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Service Partner",
    },
    menu: {
        title: "Set service partner",
        subtitle: "Make device managed by given service partner",
        icon: <ServicePartnerSetIcon />
    }
};

export default SetDeviceServicePartnerAction;