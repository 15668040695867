import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useApiResource from "hooks/useApiResource";
import { ActionBusinessSubjectTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { RpsProfileSetIcon } from "components/Icons";

interface Props {
    subjects: Dto.RdmsApiV1.BusinessSubject[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const rpsProfiles = useApiResource<Dto.RdmsApiV1.RpsProfile[]>("/v1/rps/profiles");
    const updateSubject = useApiInvocation<{ id: string}, Dto.RdmsApiV1.BusinessSubject>("put", `/v1/business-subjects/:id`, { silentError: true, throwError: true });
    const [selected, setSelected] = useState<Dto.RdmsApiV1.RpsProfile | null>(null);

    const state = useActionState({
        ...props,
        loading: rpsProfiles.loading,
        animationDelay: 500,
        process: async setProgress => {
            if (!selected) {
                throw new Error("No RPS profile has been selected.");
            }
            for (const [index, subject] of props.subjects.entries()) {
                try {
                    await updateSubject({ id: subject.id }, { defaultRpsProfileId: selected.id });
                    setProgress((index + 1) / props.subjects.length);
                } catch (error) {
                    throw new Error(`Failed to update business subject ${subject.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Default RPS profile configured for subject will be applied to all customer devices without
                individual profile configuration.
            </Typography>
            <ActionBusinessSubjectTargetList subjects={props.subjects} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose profile to apply to selected business subjects:</Typography>
            <Autocomplete
                autoHighlight
                disabled={state.blocked}
                sx={{ width: "100%" }}
                options={rpsProfiles.data || []}
                value={selected}
                onChange={(_, value) => setSelected(value)}
                getOptionLabel={option => option.title}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        <Stack direction="row">
                            <FontAwesomeIcon icon={["fad", "print-search"]} fixedWidth />
                            <Typography sx={{ ml: .5 }}>{option.title}</Typography>
                        </Stack>
                    </Box>
                )}
                renderInput={params => (
                    <TextField autoFocus label="Select RPS profile" {...params} inputProps={{...params.inputProps, autoComplete: "new-password"}} />
                )}
            />
        </Box>
    );
};

const SetSubjectDefaultRpsProfileAction: ActionDef<Props> = {
    id: "subject-set-default-rps-profile",
    title: "Set Subject Default RPS Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Profile",
    },
    menu: {
        title: "Set default RPS profile",
        subtitle: "Configure RPS to use as default for subject devices",
        icon: <RpsProfileSetIcon />
    }
};

export default SetSubjectDefaultRpsProfileAction;