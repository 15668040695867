import { Box, Grid, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionUserTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { UserChangePasswordIcon } from "components/Icons";
import { FormTextField } from "components/Form";
import { useState } from "react";

interface Props {
    user: Dto.RdmsApiV1.SystemUser;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateUser = useApiInvocation<{ id: string}, Dto.RdmsApiV1.SystemUser>("put", `/v1/system/users/:id`, { silentError: true, throwError: true });

    const [password, setPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");

    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            try {
                if (!password || !passwordRepeat) {
                    throw new Error(`Password needs to be set.`);
                } else if (password.length !== password.trim().length) {
                    throw new Error(`Password cannot contain spaces at the beginning or at the end.`);
                } else if (password.trim().length < 8) {
                    throw new Error(`Password needs to be at least 8 characters long.`);
                } else if (!/[A-Z]+/.test(password) || !/[a-z]+/.test(password) || !/[0-9]+/.test(password)) {
                    throw new Error(`Password needs to contain uppercase char, lowercase char and a number.`);
                } else if (password !== passwordRepeat) {
                    throw new Error(`Entered passwords do not match.`);
                }
                await updateUser({ id: props.user.id }, { password });
            } catch (error) {
                throw new Error(`Failed to update user ${props.user.id} - ${error.message}`);
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                This action allows for user password change. All active sessions will be invalidated.
            </Typography>
            <ActionUserTargetList users={[props.user]} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Update user password</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField
                        autoFocus
                        title="Password"
                        type="password"
                        disabled={state.blocked}
                        value={password}
                        onChange={value => setPassword(value as string)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="Repeat Password"
                        type="password"
                        disabled={state.blocked}
                        value={passwordRepeat}
                        onChange={value => setPasswordRepeat(value as string)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const ChangeUserPasswordAction: ActionDef<Props> = {
    id: "user-change-password",
    title: "Change User Password",
    component: Action,
    dialog: {
        confirmButtonCaption: "Change",
    },
    menu: {
        title: "Change Password",
        subtitle: "Update user's password",
        icon: <UserChangePasswordIcon />
    }
};

export default ChangeUserPasswordAction;