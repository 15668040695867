import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle, Box, Checkbox, Collapse, FormControlLabel, Grid, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBaseProps, ActionDef } from "components/Action";
import { FormTextField } from "components/Form";
import { SubjectEditIcon } from "components/Icons";
import useActionState from "hooks/useActionState";
import useApiInvocation from "hooks/useApiInvocation";
import { useEffect, useMemo, useState } from "react";
import { BehaviorSubject, debounceTime, filter, tap } from "rxjs";

interface Props {
    subject?: Dto.RdmsApiV1.BusinessSubject
}

export const Action: React.FC<ActionBaseProps & Props> = props => {
    const businessId$ = useMemo(() => new BehaviorSubject<string | undefined>(undefined), []);
    const [autoBusinessLookup, setAutoBusinessLookup] = useState<boolean>(true);
    const [autoBusinessLookupState, setAutoBusinessLookupState] = useState<"idle" | "loading" | "ok" | "error">("idle");
    const lookupSkBusinessId = useApiInvocation<{ id: string }, {}, { name: string, businessId: string, taxId: string, address: string }>("get", "/v1/sk-business-id-lookup?businessId=:id", { silentError: true, throwError: true });

    const createSubject = useApiInvocation<{}, Dto.RdmsApiV1.BusinessSubject>("post", "/v1/business-subjects", { silentError: true, throwError: true });
    const updateSubject = useApiInvocation<{ id: string}, Dto.RdmsApiV1.BusinessSubject>("put", `/v1/business-subjects/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [subject, setSubject] = useState<Partial<Dto.RdmsApiV1.BusinessSubject>>();
    useEffect(() => setSubject({ ...props.subject }), [props.subject]);

    useEffect(() => {
        const subscription = businessId$.pipe(
            filter(() => !props.subject && autoBusinessLookup),
            debounceTime(250),
            filter(value => !!value && value.length > 5),
            tap(async value => {
                setAutoBusinessLookupState("loading");
                try {
                    const response = await lookupSkBusinessId({ id: (value || "").split(" ").join("") });
                    setSubject(s => ({
                        ...s,
                        name: response.name,
                        businessId: response.businessId,
                        taxId: response.taxId,
                        address: response.address
                    }));
                    setAutoBusinessLookupState("ok");
                } catch (error) {
                    setAutoBusinessLookupState("error");
                }
            })
        ).subscribe();
        return () => {
            subscription.unsubscribe();
        };
    }, [autoBusinessLookup, businessId$, lookupSkBusinessId, props.subject]);

    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            if (props.subject) {
                try {
                    const data = {...subject};
                    delete data.id;
                    delete data.createDate;
                    delete data.updateDate;
                    delete data._meta;
                    await updateSubject({ id: props.subject.id }, data);
                } catch (error) {
                    throw new Error(`Failed to update subject ${props.subject.id} - ${error.message}`);
                }
            } else {
                try {
                    await createSubject({}, { ...subject, isCustomer: true });
                } catch (error) {
                    throw new Error(`Failed to create subject - ${error.message}`);
                }
            }
        }
    });
    
    return (
        <Box>
            {props.subject &&
                <Typography color="text.disabled" gutterBottom>
                    Base business subject information is updated in this view.
                    This information may be automatically altered by data received from device owner information.
                </Typography>
            }
            {!props.subject &&
                <Typography color="text.disabled" gutterBottom>
                    Creating a new business subjects allows to introduce a new customer or service partner in the system.
                    These entities may be assigned owned or managed devices.
                </Typography>
            }
            <Grid container spacing={2}>
                {!props.subject &&
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={autoBusinessLookup} onChange={evt => setAutoBusinessLookup(evt.target.checked)} />} label="Automatically lookup SK subjects by business identifier" />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Collapse in={!props.subject && autoBusinessLookup}>
                        <Alert color="warning">
                            <AlertTitle>VAT ID must be entered manually!</AlertTitle>
                            Automated VAT ID pre-filling is currently not supported, please ensure that created entity has a VAT ID assigned and fill it in if necessary.
                        </Alert>
                    </Collapse>
                </Grid>

                <Grid item xs={!props.subject && autoBusinessLookup ? 11 : 12}>
                    <FormTextField
                        title="Business ID"
                        placeholder="01234567"
                        autoFocus
                        disabled={state.blocked}
                        value={subject?.businessId || ""}
                        onChange={value => {
                            setSubject(s => ({ ...s, businessId: value as string }));
                            businessId$.next(value as string);
                        }}
                    />
                </Grid>
                {!props.subject && autoBusinessLookup &&
                    <Grid
                        item
                        xs={1}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: t => t.spacing(3.5),
                            color:
                                autoBusinessLookupState === "ok" ? t => t.palette.success.main :
                                    autoBusinessLookupState === "error" ? t => t.palette.error.main :
                                        autoBusinessLookupState === "loading" ? t => t.palette.secondary.main :
                                            t => t.palette.text.secondary
                        }}
                    >
                        {autoBusinessLookupState === "idle" && <FontAwesomeIcon icon={["fal", "circle"]} />}
                        {autoBusinessLookupState === "loading" && <FontAwesomeIcon icon={["fal", "circle-notch"]} spin />}
                        {autoBusinessLookupState === "ok" && <FontAwesomeIcon icon={["fal", "circle-check"]} />}
                        {autoBusinessLookupState === "error" && <FontAwesomeIcon icon={["fal", "times-circle"]} />}
                    </Grid>
                }
                <Grid item xs={12}>
                    <FormTextField
                        title="Name"
                        placeholder="Example, ltd."
                        disabled={state.blocked || (!props.subject && autoBusinessLookup)}
                        value={subject?.name || ""}
                        onChange={value => setSubject(s => ({ ...s, name: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="Tax ID"
                        placeholder="202012345678"
                        disabled={state.blocked || (!props.subject && autoBusinessLookup)}
                        value={subject?.taxId || ""}
                        onChange={value => setSubject(s => ({ ...s, taxId: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="VAT ID"
                        placeholder="SK202012345678"
                        disabled={state.blocked}
                        value={subject?.vatId || ""}
                        onChange={value => setSubject(s => ({ ...s, vatId: value as string }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        title="Address"
                        placeholder="Ružinovská 31, 82108 Bratislava, Slovakia"
                        disabled={state.blocked || (!props.subject && autoBusinessLookup)}
                        value={subject?.address || ""}
                        onChange={value => setSubject(s => ({ ...s, address: value as string }))}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const EditSubjectDataAction: ActionDef<Props> = {
    id: "subject-edit-data",
    title: "Edit Business Subject Data",
    component: Action,
    dialog: {
        confirmButtonCaption: "Update"
    },
    menu: {
        title: "Edit Business Subject Data",
        subtitle: "Update base business data for a subject",
        icon: <SubjectEditIcon />
    }
};

export default EditSubjectDataAction;
