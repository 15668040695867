import { Chip, Skeleton, Stack, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useMemo } from "react";
import useApiResource from "../hooks/useApiResource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeviceStatusIcons: React.FC<{ device: Dto.RdmsApiV1.Device, enabled?: boolean, connection?: boolean, size?: "small" | "medium"}> = props => {
    const statusUrl = useMemo(() => `/v1/devices/${props.device.id}/status`, [props.device.id]);
    const statusResource = useApiResource<Dto.RdmsApiV1.DeviceStatus>(statusUrl, { disable: !!props.device._meta.status, ignoreForbidden: true });
    const { data } = statusResource;

    const status = useMemo(() => props.device._meta.status || data, [data, props.device._meta.status]);
    const connectionStatus = useMemo(() => props.connection ? (status?.dcmSessionInfo && status?.vpnSessionInfo) ? "online" : (status?.dcmSessionInfo || status?.vpnSessionInfo) ? "partially-online" : "offline" : "offline", [props.connection, status?.dcmSessionInfo, status?.vpnSessionInfo]);

    if (!props.device._meta.status && !statusResource.loading && statusResource.error && statusResource.error.name !== "AbortError") {
        return (
            <Stack direction="row" alignItems="center" spacing={.5} color={statusResource.permissionDenied ? "text.disabled" : "error.main"} sx={{ opacity: .75 }}>
                <FontAwesomeIcon icon={statusResource.permissionDenied ? ["fal", "lock"] : ["fas", "exclamation-triangle"]} fixedWidth />
                <Typography variant={"body2"}>{statusResource.error?.message}</Typography>
            </Stack>
        );
    }

    if (!props.device._meta.status && (statusResource.loading || !statusResource.data)) {
        return (
            <>
                <Skeleton variant="rounded" sx={{ mr: 1, width: 128, height: 27 }} />
            </>
        );
    }

    return (
        <>
            {props.enabled && <Chip variant="outlined" size={props.size} color={props.device.enabled ? "success" : "default"} label={props.device.enabled ? "Enabled" : "Disabled"} />}
            {props.connection &&
                <Chip
                    sx={{ ml: .5 }}
                    variant="filled"
                    size={props.size}
                    color={connectionStatus === "online" ? "success" : connectionStatus === "partially-online" ? "warning" : "default"}
                    label={connectionStatus === "online" ? "Online" : connectionStatus === "partially-online" ? "Partially Online" : "Offline"}
                />
            }
        </>
    );
};
export default DeviceStatusIcons;