import { Dto } from "@varos/rdm-common";
import { Grid, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import StatsControlBar, { defaultStatsResolutions } from "components/StatsControlBar";
import { LineChart, PieChartCard, MetricCard } from "components/Charts";
import useApiResource from "hooks/useApiResource";
import DefaultCard from "components/DefaultCard";

export const DashboardClientsView: React.FC = props => {
    const theme = useTheme();

    const [statsResolution, setStatsResolution] = useState(defaultStatsResolutions.find(r => r.default));

    const clientConnectionStats = useApiResource<Dto.RdmsApiV1.ClientConnectionStats>(`/v1/stats/client/connection?lookbehind=${60 * 1000}&step=900`, { reloadInterval: 5000 });
    const clientConnectionChartStats = useApiResource<Dto.RdmsApiV1.ClientConnectionStats>(`/v1/stats/client/connection?lookbehind=${statsResolution?.lookbehind}&step=${statsResolution?.step}`, { reloadInterval: 60000, longLoadingThreshold: 3000 });
    
    /* stabilized stats resolution is only updated when data changes - change of time axis granularity for large data set before data change caused severe performance degradation */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stabilizedStatsResolution = useMemo(() => statsResolution ? ({...statsResolution}) : undefined, [clientConnectionChartStats.data]);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <MetricCard
                    variant="filled"
                    title="DCM Clients Online"
                    description="Devices currently connected using DCM protocol."
                    value={clientConnectionStats.data?.dcm.current || null}
                    loading={clientConnectionStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <MetricCard
                    variant="filled"
                    title="VPN Clients Online"
                    description="Devices currently connected to management VPN network."
                    color={theme.palette.secondary.main}
                    value={clientConnectionStats.data?.vpn.current || null}
                    loading={clientConnectionStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="DCM Clients By Edge"
                    description="Number of clients connected using DCM protocol currently split by DCM edges they are served by."
                    enableArcLinkLabels={false}
                    legend
                    legendHeader="Edge"
                    height={300}
                    minHeight={250}
                    sortByValue
                    colors={{ mode: "default", scheme: "primary" }}
                    data={Object.entries(clientConnectionStats.data?.dcm.byEdge || {}).map(([key, value], i) => ({ id: key, label: key, value }))}
                    loading={clientConnectionStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} xl={6}>
                <PieChartCard
                    title="VPN Clients By Gateway"
                    description="Number of clients connected to management virtual private network by gateway serving these clients."
                    enableArcLinkLabels={false}
                    legend
                    legendHeader="Gateway"
                    height={300}
                    minHeight={250}
                    sortByValue
                    colors={{ mode: "default", scheme: "secondary" }}
                    data={Object.entries(clientConnectionStats.data?.vpn.byGateway || {}).map(([key, value], i) => ({ id: key, label: key, value }))}
                    loading={clientConnectionStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12}>
                <DefaultCard title="Connection Count History" loading={clientConnectionChartStats.initialLoading} skeletonHeight={480}>
                    <StatsControlBar loading={clientConnectionChartStats.loading} maxLookbehind={30 * 24 * 3600 * 1000} onChange={resolution => setStatsResolution(resolution)}  />
                    <LineChart
                        height={400}
                        minHeight={250}
                        timeAxisTickInterval={stabilizedStatsResolution?.timeAxisGranularity}
                        timeAxisTickFormat={stabilizedStatsResolution?.timeAxisFormat}
                        disabled={clientConnectionChartStats.initialOrLongLoading}
                        data={[
                            {
                                id: "DCM Clients Online",
                                color: theme.palette.primary.light,
                                data: clientConnectionChartStats.data?.dcm.history?.map(v => ({ x: new Date(v[0]), y: v[1] })) || []
                            },
                            {
                                id: "VPN Clients Online",
                                color: theme.palette.secondary.light,
                                data: clientConnectionChartStats.data?.vpn.history?.map(v => ({ x: new Date(v[0]), y: v[1] })) || []
                            }
                        ]}
                    />
                </DefaultCard>
            </Grid>
        </Grid>
    );
};

export default DashboardClientsView;