import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle, Box, Button, CircularProgress, Collapse, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { firstValueFrom, timer } from "rxjs";
import Api from "services/api.service";
import { faFlaskGear } from "@fortawesome/pro-light-svg-icons";

const LoginView: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const passwordInputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (isProcessing) {
            return;
        }
        try {
            setError("");
            setIsProcessing(true);
            await firstValueFrom(timer(500));

            if (!username || !password) {
                throw new Error("No credentials were entered. Please enter username and password to continue.");
            } else {
                try {
                    await Api.authenticate(username, password);
                } catch (error) {
                    console.error("Authentication failed due to remote error", error);
                    throw new Error(`${error.message}.`);
                }
            }
        } catch (error) {
            setError(error.message);
            setIsProcessing(false);
            passwordInputRef.current?.focus();
            passwordInputRef.current?.select();
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Paper
                sx={{
                    maxWidth: 400,
                    p: 2,
                    border: t => ({ xs: "none", sm: `1px ${t.palette.divider} solid` })
                }}
                variant="outlined"
                elevation={0}
            >
                <form onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                        <Stack direction="row" sx={{ color: t => t.palette.primary.main, p: t => t.spacing(2, 0) }} justifyContent="center">
                            <FontAwesomeIcon size="2x" icon={["fad", "dice-d20"]} />
                            <Typography sx={{ ml: 1 }} variant="h5">Varos RDM</Typography>
                        </Stack>
                        <Collapse in={Api.environmentShowNotice}>
                            <Alert icon={<FontAwesomeIcon icon={faFlaskGear} />} severity="warning">
                                <strong>{Api.environmentName}</strong> (isolated environment)
                            </Alert>
                        </Collapse>
                        <Collapse in={!!error}>
                            <Alert severity="error" onClose={() => setError("")}>
                                <AlertTitle>Login Failed</AlertTitle>
                                {error}
                            </Alert>
                        </Collapse>
                        
                        <Typography variant="body2">
                            Please enter your credentials to access Varos RDM Portal.
                            Only authorized personnel is allowed to access this application.
                        </Typography>
                        <Stack spacing={2} sx={{ pt: 2 }}>
                            <TextField
                                label="Username"
                                fullWidth
                                autoFocus
                                disabled={isProcessing}
                                onChange={evt => setUsername(evt.target.value)}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                fullWidth
                                disabled={isProcessing}
                                inputProps={{ ref: passwordInputRef }}
                                onChange={evt => setPassword(evt.target.value)}
                            />
                        </Stack>

                        <Box display="flex" alignItems="center" justifyContent="center" pt={1}>
                            {!isProcessing && <Button size="large" type="submit">Login</Button>}
                            {isProcessing && <CircularProgress />}
                        </Box>
                    </Stack>
                </form>
            </Paper>
        </Box>
    );
};

export default LoginView;