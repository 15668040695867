import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/material";
import { useKBar } from "kbar";
import { useMemo } from "react";

export const MainMenuSearchBox: React.FC<{ onOpen?: () => void }> = props => {
    const { query } = useKBar();
    const isMac = useMemo(() => window.navigator.platform.match(/^Mac/), []);

    return (
        <Box sx={{ flex: "0 0 auto", p: t => t.spacing(1, 2) }}>
            <Box
                role="button"
                tabIndex={0}
                sx={{
                    p: 1,
                    border: t => `1px solid ${t.palette.divider}`,
                    borderRadius: 1,
                    cursor: "pointer",
                    transition: "border-color 200ms ease",
                    "&:hover": {
                        border: t => `1px solid ${t.palette.primary.main}`
                    }
                }}
                onClick={() => { query.toggle(); props.onOpen?.(); } }
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <FontAwesomeIcon icon={["fad", "search"]} />
                    <Typography variant="body2" color="text.secondary">Search...</Typography>
                    <Box flex="1" />
                    <Stack direction="row" spacing={.5} alignItems="center" sx={{ "kbd": { fontSize: 12, p: t => t.spacing(.25, 1), backgroundColor: t => t.palette.divider, borderRadius: 1 } }}>
                        {!isMac && <kbd>Ctrl</kbd>}
                        {isMac && <kbd>⌘</kbd>}
                        <Typography>+</Typography>
                        <kbd>K</kbd>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};