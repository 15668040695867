import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { StatusLine } from "../../components/StatusLine";
import useApiResource from "../../hooks/useApiResource";
import Moment from "react-moment";
import { useDeviceTypes } from "hooks/useDeviceTypes";

export const AssociatedDeviceListItem: React.FC<{ deviceId: string, presenceRecord: Dto.RdmsApiV1.DeviceFiscalStoragePresence }> = props => {
    const theme = useTheme();
    const deviceTypes = useDeviceTypes();
    const device = useApiResource<Dto.RdmsApiV1.Device>(`/v1/devices/${props.deviceId}`);
    const isOnline = useMemo(() => props.presenceRecord.lastSeen && new Date().getTime() - new Date(props.presenceRecord.lastSeen).getTime() <= 900 * 1000, [props.presenceRecord.lastSeen]);
    
    return (
        <ListItemButton component={RouterLink} to={`/devices/${props.deviceId}`}>
            <ListItemIcon sx={{ color: isOnline ? theme.palette.primary.main : undefined }}>
                <FontAwesomeIcon icon={["fad", deviceTypes.getForDevice(device.data).style.icon]} fixedWidth size="lg" />
            </ListItemIcon>
            <ListItemText
                sx={{ color: isOnline ? theme.palette.primary.main : undefined }}
                primary={device.data?.shortId + (device.data?.alias ? ` (${device.data.alias})` : "")}
                secondary={
                    <>
                        {props.presenceRecord.lastSeen && <>Last seen <b><Moment ago from={new Date(props.presenceRecord.lastSeen)} /> ago</b>.<br /></>}
                        <>First seen <b><Moment ago from={new Date(props.presenceRecord.firstSeen)} /> ago</b></>
                    </>
                }
            />
            {isOnline && <StatusLine icon={["fas", "circle"]} color={theme.palette.success.main} size="sm" title="Online" pulse />}
        </ListItemButton>
    );
};

export default AssociatedDeviceListItem;
