import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { InfoListItem } from "../../components/InfoList";

export const SignatureInfoListItem: React.FC<{ info: Dto.RdmsApiV1.DeviceInfo | Dto.RdmsApiV1.FiscalStorageInfo }> = props => {
    const theme = useTheme();

    return (
        <InfoListItem
            title="Info Signature Status"
            tail={
                <Box sx={{ ml: 1, color: props.info.signatureStatus?.status === "unsupported" ? theme.palette.warning.main : props.info.signatureStatus?.status === "verified" ? theme.palette.success.main : theme.palette.error.main }}>
                    <FontAwesomeIcon icon={props.info.signatureStatus?.status === "unsupported" ? "exclamation-triangle" : props.info.signatureStatus?.status === "verified" ? ["fad", "shield-check"] : "exclamation-triangle"} size="lg" />
                </Box>
            }
        >
            {props.info.signatureStatus?.status === "unsupported" &&
        <Stack>
            <Typography variant="body2" color="warning.main">Unsupported</Typography>
            <Typography variant="body2" color="text.disabled">Device is not running supported software or is using a 3rd-party fiscal storage unit without trusted cryptography support.</Typography>
        </Stack>
            }
            {props.info.signatureStatus?.status === "verified" &&
        <Stack>
            <Typography variant="body2" color="success.main">Verified</Typography>
            <Typography variant="body2" color="text.disabled">Device is running genuine Varos fiscal storage unit, which authenticates device information for us.</Typography>
        </Stack>
            }
            {props.info.signatureStatus?.status === "error" && <Typography variant="body1" color="error.main">Error: {props.info.signatureStatus.error}</Typography>}
            {!props.info.signatureStatus && <Typography variant="body1" color="error.main">Signature Information Missing</Typography>}
        </InfoListItem>
    );
};

export default SignatureInfoListItem;