import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionRpsProfileTargetList: React.FC<{ profiles: Dto.RdmsApiV1.RpsProfile[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.RpsProfile>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.profiles}
        singular="profile"
        plural="profiles"
        icon={["fas", "print-search"]}
        getPrimary={(profile: Dto.RdmsApiV1.RpsProfile) => profile.title}
    />;
