import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDef } from "components/Action";
import { CommandBarAction } from "./CommandBarAction";

export class CommandBarActionFactory {

    private currentSection: string | null = null;
    private currentAction: CommandBarAction | null = null;
    private currentCondition: boolean | null = null;
    private actions: CommandBarAction[] = [];

    public section(name: string): this {
        this.currentSection = name;
        return this;
    }

    public if(condition: boolean): this {
        this.currentAction = null;
        this.currentCondition = condition;
        return this;
    }

    public action(id: string, name: string): this;
    public action<P>(actionDef: ActionDef<P>): this;
    public action<P>(idOrActionDef: string | ActionDef<P>, name?: string): this {
        if (this.currentCondition === false) {
            this.currentCondition = null;
            return this;
        }
        if (typeof idOrActionDef === "string") {
            if (!name) {
                throw new Error("action name is required");
            }
            this.currentAction = { section: this.currentSection || undefined, id: idOrActionDef, name };
        } else {
            const actionDef = idOrActionDef as ActionDef<P>;
            this.currentAction = {
                section: this.currentSection || undefined,
                id: actionDef.id,
                name: actionDef.title,
                subtitle: actionDef.menu?.subtitle || undefined,
                icon: actionDef.menu?.icon || undefined,
                color: actionDef.menu?.color || undefined
            };
        }
        this.actions.push(this.currentAction);
        return this;
    }

    public id(id: string): this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.id = id;
        return this;
    }

    public subtitle(subtitle?: string) : this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.subtitle = subtitle || undefined;
        return this;
    }

    public icon(icon?: IconProp): this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.icon = icon ? <FontAwesomeIcon fixedWidth icon={icon} /> : undefined;
        return this;
    }

    public color(color: string): this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.color = color;
        return this;
    }

    public priority(priority: number): this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.priority = Math.pow(2, 32) - priority;
        return this;
    }

    public parent(id: string): this {
        if (!this.currentAction) {
            return this;
        }
        this.currentAction.parent = id;
        return this;
    }

    public do(fn: () => void): this {
        if (!this.currentAction) {
            return this;
        }

        /* hotfix to prevent scroll block due to collision between MuiBackdrop and kbar (both manipulating style property on <body>) */
        this.currentAction.perform = () => setTimeout(fn, 200);
        return this;
    }

    public children(builder: (factory: CommandBarActionFactory) => CommandBarActionFactory): this {
        if (!this.currentAction) {
            return this;
        }
        const results = builder(new CommandBarActionFactory()).build().map(a => ({ ...a, section: this.currentAction?.section || undefined, parent: this.currentAction?.id || undefined }));
        this.actions.push(...results);
        return this;
    }

    public build(): CommandBarAction[] {
        return this.actions;
    }
}
