import CreateUserAction from "components/ObjectActions/User/CreateUserAction";
import useObjectAction from "./useObjectAction";
import EditUserAction from "components/ObjectActions/User/EditUserAction";
import DeleteUserAction from "components/ObjectActions/User/DeleteUserAction";
import ChangeUserPasswordAction from "components/ObjectActions/User/ChangeUserPasswordAction";
import EnableUserAction from "components/ObjectActions/User/EnableUserAction";
import DisableUserAction from "components/ObjectActions/User/DisableUserAction";

export const useUserActions = (opts: { onUpdate?: () => void }) => {
    const create = useObjectAction(CreateUserAction, opts.onUpdate);
    const edit = useObjectAction(EditUserAction, opts.onUpdate);
    const deleteUser = useObjectAction(DeleteUserAction, opts.onUpdate);
    const changePassword = useObjectAction(ChangeUserPasswordAction, opts.onUpdate);
    const enable = useObjectAction(EnableUserAction, opts.onUpdate);
    const disable = useObjectAction(DisableUserAction, opts.onUpdate);

    return {
        create,
        edit,
        delete: deleteUser,
        changePassword,
        enable,
        disable
    };
};
