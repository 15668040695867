import { List, ListSubheader } from "@mui/material";
import { useMemo } from "react";

interface Props {
    title?: string;
    children: React.ReactNode;
    disableAutoHide?: boolean;
}
export const MainMenuSection: React.FC<Props> = props => {
    const hasChildren = useMemo(() => (Array.isArray(props.children) ? props.children : [props.children]).filter(c => !!c).length > 0, [props.children]);
    if (!props.disableAutoHide && !hasChildren) {
        return <></>;
    }
    return (
        <List dense component="nav" subheader={props.title ? <ListSubheader component="div">{props.title}</ListSubheader> : undefined}>
            {props.children}
        </List>
    );
};
