import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeviceDisableIcon } from "components/Icons";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { enabled: false });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Disabled devices do not have access to platform and therefore cannot be managed or use online services such as remote printing.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Disabling device prevents the device from:
                <ul>
                    <li>being managed using DCM,</li>
                    <li>being able to connect to management virtual private network (VPN),</li>
                    <li>report information and device metrics,</li>
                    <li>use remote printing service.</li>
                </ul>
            </Alert>
            <ActionDeviceTargetList devices={props.devices} />
        </Box>
    );
};

const DisableDeviceAction: ActionDef<Props> = {
    id: "device-disable",
    title: "Disable Device",
    component: Action,
    dialog: {
        confirmButtonCaption: "Disable",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Disable",
        subtitle: "Disconnect device and disable its management",
        icon: <DeviceDisableIcon />,
        color: "warning.main"
    }
};

export default DisableDeviceAction;