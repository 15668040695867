import { BehaviorSubject, Subject, filter, firstValueFrom, interval, mergeMap, tap } from "rxjs";

interface CacheEntry {
    data?: any;
    error?: Error;
    expires: Date;
}

interface CacheResolveRequest<T = any> {
    key: string;
    fn: () => Promise<T>;
    expireIn?: number;
    data?: T;
    error?: Error;
}

class ApiResourceCacheService {

    public defaultEntryTimeout: number;

    private _data: Map<string, CacheEntry> = new Map();
    private _resolveQueue$: Subject<CacheResolveRequest> = new Subject();
    private _resolveResults$: Subject<CacheResolveRequest> = new Subject();
    private _cleanupLock: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(defaultEntryTimeout: number = 30000, resolveConcurrency: number = 8, cleanupInterval: number = 10000) {
        this.defaultEntryTimeout = defaultEntryTimeout;

        /* process resolutions */
        this._resolveQueue$.pipe(
            mergeMap(async request => {
                const cached = this._data.get(request.key);
                if (cached) {
                    this._resolveResults$.next({ ...request, data: cached.data, error: cached.error });
                } else {
                    try {
                        const data = await request.fn();
                        this.set(request.key, data, undefined, request.expireIn);
                        this._resolveResults$.next({ ...request, data });
                    } catch (error) {
                        this.set(request.key, undefined, error, request.expireIn);
                        this._resolveResults$.next({ ...request, error });
                    }
                }
            }, resolveConcurrency)
        ).subscribe();

        /* periodically clean up cache */
        interval(cleanupInterval).pipe(
            tap(async () => {
                await firstValueFrom(this._cleanupLock.pipe(filter(lock => !lock)));
                this._cleanupLock.next(true);
                try {
                    const ts = new Date().getTime();
                    const removeKeys = [];
                    for (const [key, entry] of this._data.entries()) {
                        if (entry.expires.getTime() <= ts) {
                            removeKeys.push(key);
                        }
                    }
                    for (const key of removeKeys) {
                        this._data.delete(key);
                    }
                } finally {
                    this._cleanupLock.next(false);
                }
            })
        ).subscribe();
    }

    public async resolve<T>(key: string, fn: () => Promise<T>, expireIn?: number): Promise<T> {
        const promise = (async () => {
            const result = await firstValueFrom(this._resolveResults$.pipe(filter(r => r.key === key)));
            if (result.error) {
                throw result.error;
            }
            return result.data;
        })();
        this._resolveQueue$.next({ key, fn, expireIn });
        return promise;
    }

    public get<T>(key: string): T | null {
        const entry = this._data.get(key);
        if (entry?.error) {
            throw entry.error;
        }
        return entry?.data || null;
    }

    public set<T>(key: string, data: T, error?: Error, expireIn?: number): void {
        this._data.set(key, {
            data,
            error,
            expires: new Date(new Date().getTime() + (expireIn || 30000))
        });
    }

    public async invalidate(key?: string): Promise<void> {
        await firstValueFrom(this._cleanupLock.pipe(filter(lock => !lock)));
        this._cleanupLock.next(true);
        try {
            if (key) {
                this._data.delete(key);
            } else {
                this._data.clear();
            }
        } finally {
            this._cleanupLock.next(false);
        }
    }
}

const ApiResourceCache = new ApiResourceCacheService();
export default ApiResourceCache;