import { Box } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { DeviceDetailStatsView } from "./DeviceDetailStatsView";
import DeviceDetailRpsBindingsView from "./DeviceDetailRpsBindingsView";
import { NavigationTabs, NavigationTab } from "components/NavigationTabs";
import { ObjectHeader } from "components/ObjectHeader";
import useApiResource from "hooks/useApiResource";
import { useMemo, useEffect, useCallback } from "react";
import DeviceDetailGeneralView from "views/DeviceDetail/DeviceDetailGeneralView";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useDeviceType } from "hooks/useDeviceType";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";

interface ComponentProps {
    sidMode?: boolean;
}
export const DeviceDetailView: React.FC<ComponentProps> = props => {
    const history = useHistory();
    const params = useParams<{ deviceId?: string, deviceShortId?: string }>();
    const location = useLocation();
    const navigationPrefix = useMemo(() => {
        if (location.pathname.startsWith("/business-subjects/")) {
            return {
                tabs: "/business-subjects/" + /^\/business-subjects\/([a-f0-9-]+)\//gi.exec(location.pathname)?.[1] || "",
                path: "/business-subjects/:businessSubjectId"
            };
        }
        return { tabs: "", path: "" };
    }, [location.pathname]);

    const device = useApiResource<Dto.RdmsApiV1.Device>(props.sidMode ? `/v1/devices-by-short-id/${params.deviceShortId}` : `/v1/devices/${params.deviceId}`);
    const deviceType = useDeviceType(device.data);
    const deviceRpsBindings = useApiResource<Dto.RdmsApiV1.RpsBinding[]>(`/v1/devices/${device.data?.id}/rps-bindings`, { disable: !device.data?.id || !deviceType.type?.capabilities.supportsRpsBindings, ignoreForbidden: true });
    const objectAuth = useObjectAuthorizationInfo(device.data);

    /* navigate to standard device link when by-sid mode is detected */
    useEffect(() => {
        if (props.sidMode && !device.initialLoading && device.data) {
            history.push(`/devices/${device.data.id}`);
        }
    }, [device, history, props.sidMode]);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!device.data) {
            return factory;
        }
        factory
            .section(device.data.shortId)
            .action("device-show-general", "Show Device Information").priority(8100).do(() => history.push(`/devices/${device.data?.id || ""}`))
            .action("device-show-stats", "Show Device Stats").priority(8200).do(() => history.push(`/devices/${device.data?.id || ""}/stats`));
        
        if (deviceType.type?.capabilities.supportsRpsBindings) {
            factory.if(objectAuth.hasAccess("device:read-rps-bindings")).action("device-show-rps-bindings", "Show Device RPS Bindings").priority(8300).do(() => history.push(`/devices/${device.data?.id || ""}/rps-bindings`))
        }

        return factory;
    }, [device.data, deviceType, history, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={device.initialOrLongLoading}
                icon={deviceType.style.icon}
                title={`${device.data?.shortId}` + (device.data?.alias ? ` (${device.data?.alias})` : ``)}
                subtitle={deviceType.type?.title}
            />
            <NavigationTabs prefix={`${navigationPrefix.tabs}/devices/${params.deviceId}`}>
                <NavigationTab label="Device" path="" />
                {objectAuth.hasAccess("device:read-stats") && (
                    <NavigationTab label="Stats" path="/stats" />
                )}
                {deviceType.type?.capabilities.supportsRpsBindings && objectAuth.hasAccess("device:read-rps-bindings") && (
                    <NavigationTab label="RPS Bindings" path="/rps-bindings" badgeContent={deviceRpsBindings.data?.length || undefined} />
                )}
            </NavigationTabs>
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Switch>
                    <Route exact path={`${navigationPrefix.path}/devices/:id`} render={() => <DeviceDetailGeneralView device={device.data} onUpdate={() => device.reload()} />} />
                    {objectAuth.hasAccess("device:read-stats") && (
                        <Route exact path={`${navigationPrefix.path}/devices/:id/stats`} render={() => <DeviceDetailStatsView device={device.data} />} />
                    )}
                    {deviceType.type?.capabilities.supportsRpsBindings && objectAuth.hasAccess("device:read-rps-bindings") && (
                        <Route exact path={`${navigationPrefix.path}/devices/:id/rps-bindings`} render={() => <DeviceDetailRpsBindingsView device={device.data} />} />
                    )}
                </Switch>
            </Box>
        </>
    );
};
export default DeviceDetailView;
