import { ActionDef } from "components/Action";
import { Action } from "./EditSubjectDataAction";
import { SubjectCreateIcon } from "components/Icons";

const CreateSubjectAction: ActionDef<void> = {
    id: "subject-create",
    title: "Create Business Subject",
    component: Action,
    dialog: {
        confirmButtonCaption: "Create"
    },
    menu: {
        title: "Create Business Subject",
        subtitle: "Create a new business subject",
        icon: <SubjectCreateIcon />
    }
};

export default CreateSubjectAction;
