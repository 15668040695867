import useObjectAction from "./useObjectAction";
import DeleteSubjectAction from "components/ObjectActions/Subject/DeleteSubjectAction";
import SetSubjectNoteAction from "components/ObjectActions/Subject/SetSubjectNoteAction";
import SetSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultDcmProfileAction";
import SetSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/SetSubjectDefaultRpsProfileAction";
import ClearSubjectDefaultDcmProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultDcmProfileAction";
import ClearSubjectDefaultRpsProfileAction from "components/ObjectActions/Subject/ClearSubjectDefaultRpsProfileAction";
import SetSubjectRolesAction from "components/ObjectActions/Subject/SetSubjectRolesAction";
import EditSubjectDataAction from "components/ObjectActions/Subject/EditSubjectDataAction";
import CreateSubjectAction from "components/ObjectActions/Subject/CreateSubjectAction";

export const useSubjectActions = (opts: { onUpdate?: () => void }) => {
    const create = useObjectAction(CreateSubjectAction, opts.onUpdate);
    const edit = useObjectAction(EditSubjectDataAction, opts.onUpdate);
    const deleteSubject = useObjectAction(DeleteSubjectAction, opts.onUpdate);
    const setNote = useObjectAction(SetSubjectNoteAction, opts.onUpdate);
    const setRoles = useObjectAction(SetSubjectRolesAction, opts.onUpdate);

    const setDefaultDcmProfile = useObjectAction(SetSubjectDefaultDcmProfileAction, opts.onUpdate);
    const setDefaultRpsProfile = useObjectAction(SetSubjectDefaultRpsProfileAction, opts.onUpdate);
    
    const clearDefaultDcmProfile = useObjectAction(ClearSubjectDefaultDcmProfileAction, opts.onUpdate);
    const clearDefaultRpsProfile = useObjectAction(ClearSubjectDefaultRpsProfileAction, opts.onUpdate);

    return {
        create,
        edit,
        delete: deleteSubject,
        setNote,
        setRoles,
        
        setDefaultDcmProfile,
        setDefaultRpsProfile,

        clearDefaultDcmProfile,
        clearDefaultRpsProfile
    };
};
