import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useState } from "react";
import { ActionBusinessSubjectTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { SubjectSetRolesIcon } from "components/Icons";

interface Props {
    subjects: Dto.RdmsApiV1.BusinessSubject[];
    customer?: boolean;
    servicePartner?: boolean;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateSubject = useApiInvocation<{ id: string}, Dto.RdmsApiV1.BusinessSubject>("put", `/v1/business-subjects/:id`, { silentError: true, throwError: true });
    const [customer, setCustomer] = useState(props.customer !== undefined ? props.customer : false);
    const [servicePartner, setServicePartner] = useState(props.servicePartner !== undefined ? props.servicePartner : false);

    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, subject] of props.subjects.entries()) {
                try {
                    await updateSubject({ id: subject.id }, { isCustomer: customer, isServicePartner: servicePartner });
                    setProgress((index + 1) / props.subjects.length);
                } catch (error) {
                    throw new Error(`Failed to update business subject ${subject.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Roles define what the subject represents. Subject can act in customer or service partner role.
                Both roles may be assigned simultaneously.
            </Typography>
            <ActionBusinessSubjectTargetList subjects={props.subjects} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose roles to apply to selected business subjects:</Typography>
            <Stack>
                <FormControlLabel control={<Checkbox disabled={state.blocked} checked={customer} onChange={(_, checked) => setCustomer(checked)} />} label="Customer" />
                <FormControlLabel control={<Checkbox disabled={state.blocked} checked={servicePartner} onChange={(_, checked) => setServicePartner(checked)} />} label="Service Partner" />
            </Stack>
        </Box>
    );
};

const SetSubjectRolesAction: ActionDef<Props> = {
    id: "subject-set-roles",
    title: "Set Subject Roles",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Roles",
    },
    menu: {
        title: "Set roles",
        subtitle: "Assign customer or service partner role",
        icon: <SubjectSetRolesIcon />
    }
};

export default SetSubjectRolesAction;