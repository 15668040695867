import { Dto } from "@varos/rdm-common";
import { useHistory, useParams } from "react-router-dom";
import React, { useCallback } from "react";
import useApiResource from "hooks/useApiResource";
import { ObjectHeader } from "components/ObjectHeader";
import { Box, Button, Grid, List, Stack, Typography } from "@mui/material";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserActions } from "hooks/useUserActions";
import useObjectAction from "hooks/useObjectAction";
import DeleteUserAction from "components/ObjectActions/User/DeleteUserAction";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import DisableUserAction from "components/ObjectActions/User/DisableUserAction";
import EnableUserAction from "components/ObjectActions/User/EnableUserAction";
import { RequireAuthorization } from "components/Authorization";
import ObjectReference from "components/ObjectReference";
import Center from "components/Center";

export const UserDetailView: React.FC<{}> = () => {
    const params = useParams<{ userId?: string }>();
    const history = useHistory();

    const user = useApiResource<Dto.RdmsApiV1.SystemUser>(`/v1/system/users/${params.userId}`);
    const objectAuth = useObjectAuthorizationInfo(user.data);

    const userActions = useUserActions({ onUpdate: () => user.reload() });
    const deleteUserAction = useObjectAction(DeleteUserAction, () => history.push("/users"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!user.data) {
            return factory;
        }
        factory
            .section(user.data.username)
            .if(objectAuth.hasAccess("delete")).action(DeleteUserAction).priority(8900).do(() => user.data && deleteUserAction({ users: [user.data] }));
        if (user.data.enabled) {
            factory.if(objectAuth.hasAccess("update")).action(DisableUserAction).priority(8100).do(() => user.data && userActions.disable({ users: [user.data] }));
        } else {
            factory.if(objectAuth.hasAccess("update")).action(EnableUserAction).priority(8100).do(() => user.data && userActions.enable({ users: [user.data] }));
        }
        return factory;
    }, [deleteUserAction, user.data, userActions, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={user.initialOrLongLoading}
                icon="user"
                title={user.data?.username || ""}
                subtitle={user.data?.displayName || "User"}
            />
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="General" loading={user.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem title="ID">{user.data?.id}</InfoListItem>
                                <InfoListItem title="Username">{user.data?.username}</InfoListItem>
                                <InfoListItem title="Display Name">{user.data?.displayName}</InfoListItem>
                                <InfoListItem title="E-mail">{user.data?.email}</InfoListItem>
                                <InfoListItem
                                    title="Status"
                                    tail={
                                        <>
                                            {user.data?.enabled && <Box sx={{ ml: 1, color: t=> t.palette.success.main }}><FontAwesomeIcon icon="circle-check" size="lg" /></Box>}
                                        </>
                                    }
                                >
                                    {user.data?.enabled && <Typography variant="body2" color="success.main">Enabled</Typography>}
                                    {!user.data?.enabled && <Typography variant="body2">Disabled</Typography>}
                                </InfoListItem>
                                <InfoListItem
                                    title="Super Administrator Status"
                                    tail={
                                        <>
                                            {user.data?.superAdmin && <Box sx={{ ml: 1, color: t=> t.palette.warning.main }}><FontAwesomeIcon icon="circle-check" size="lg" /></Box>}
                                        </>
                                    }
                                >
                                    {user.data?.superAdmin && <Typography variant="body2" color="warning.main">Yes</Typography>}
                                    {!user.data?.superAdmin && <Typography variant="body2">No</Typography>}
                                </InfoListItem>
                                <InfoListItem title="Last Login"><Moment fromNow>{user.data?.lastLoginDate}</Moment></InfoListItem>
                                <InfoListItem title="Created"><Moment fromNow>{user.data?.createDate}</Moment></InfoListItem>
                                <InfoListItem title="Updated"><Moment fromNow>{user.data?.updateDate}</Moment></InfoListItem>
                            </InfoList>
                            <Box flex="1" />
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="Roles" loading={user.initialOrLongLoading} skeletonHeight={595}>
                            {(user.data?.roleIds.length || 0) === 0 && (
                                <Center>
                                    <Stack sx={{ color: t => t.palette.text.disabled }}>
                                        <FontAwesomeIcon icon={["fas", "shield-slash"]} size="2x" />
                                        <Typography sx={{ mt: 1 }} variant="body1">User has no roles set</Typography>
                                    </Stack>
                                </Center>
                            )}
                            {(user.data?.roleIds.length || 0) > 0 && (
                                <>
                                    <Typography variant="body2" color="text.secondary">Roles are applied in the configured order.</Typography>
                                    <List dense>
                                        {user.data?.roleIds.map(id => (
                                            <ObjectReference variant="list-item-button" icon type="user-role" id={id} />
                                        ))}
                                    </List>
                                </>
                            )}
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <RequireAuthorization object={user.data} operation="update">
                                <Button variant="outlined" onClick={() => user.data && userActions.edit({ user: user.data })}>Modify User</Button>
                                <Button variant="outlined" onClick={() => user.data && userActions.changePassword({ user: user.data })}>Change Password</Button>
                            </RequireAuthorization>
                            <RequireAuthorization object={user.data} operation="delete">
                                <Button variant="outlined" color="error" onClick={() => user.data && deleteUserAction({ users: [user.data] })}>Delete User</Button>
                            </RequireAuthorization>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};