import { useSensors, useSensor, PointerSensor, KeyboardSensor, DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Collapse, FormControlLabel, Grid, List, ListItem, ListItemIcon, ListSubheader, Switch, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBaseProps, ActionDef } from "components/Action";
import { FormAccordion, FormTextField } from "components/Form";
import { RpsProfileEditIcon } from "components/Icons";
import SortableTextFieldListItem from "components/SortableTextFieldListItem";
import { useAccordionGroup } from "hooks/useAccordionGroup";
import useActionState from "hooks/useActionState";
import useApiInvocation from "hooks/useApiInvocation";
import { useCallback, useEffect, useState } from "react";

interface Props {
    profile?: Dto.RdmsApiV1.RpsProfile
}

export const Action: React.FC<ActionBaseProps & Props> = props => {
    const createProfile = useApiInvocation<{}, Dto.RdmsApiV1.RpsProfile>("post", "/v1/rps/profiles", { silentError: true, throwError: true });
    const updateProfile = useApiInvocation<{ id: string}, Dto.RdmsApiV1.RpsProfile>("put", `/v1/rps/profiles/:id`, { silentError: true, throwError: true, refreshBreadcrumbs: true });
    const [profile, setProfile] = useState<Partial<Dto.RdmsApiV1.RpsProfile>>();
    useEffect(() => setProfile({ ...props.profile }), [props.profile]);

    const [newAclAllowedSource, setNewAclAllowedSource] = useState<string>("");
    const dndSensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );
    const createSortDragEndHandler = useCallback((fieldName: keyof Dto.RdmsApiV1.RpsProfile) => (event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const items = (profile?.[fieldName] || []) as any[];
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const moved = arrayMove(items, oldIndex, newIndex);
            setProfile(s => ({ ...s, [fieldName]: moved }));
        }
    }, [profile]);

    const accordionGroup = useAccordionGroup();
    const state = useActionState({
        ...props,
        animationDelay: 500,
        process: async () => {
            if (props.profile) {
                try {
                    const data = {...profile};
                    delete data.id;
                    delete data.createDate;
                    delete data.updateDate;
                    delete data._meta;
                    await updateProfile({ id: props.profile.id }, data);
                } catch (error) {
                    throw new Error(`Failed to RPS profile ${props.profile.id} - ${error.message}`);
                }
            } else {
                try {
                    await createProfile({}, { ...profile, aclAllowedSources: profile?.aclAllowedSources || [] });
                } catch (error) {
                    throw new Error(`Failed to create RPS profile - ${error.message}`);
                }
            }
        }
    });
    
    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                RPS profile configure remote printing service settings for device. Profiles are applied globally, per customer or per device.
            </Typography>
            <Box sx={{ mt: 2 }}>
                <FormAccordion title="General" description="Main profile settings" icon={["fal", "info-circle"]} {...accordionGroup.control("general")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>General section allows only profile title configuration.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                autoFocus
                                title="Title"
                                placeholder="Profile #1"
                                disabled={state.blocked}
                                value={profile?.title || ""}
                                onChange={value => setProfile(s => ({ ...s, title: value as string }))}
                            />
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="Remote Print" description="Printing settings and limits" icon={["fal", "print"]} {...accordionGroup.control("remote-print")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                Remote printing can be enabled or disabled for a profile.
                                There is also a limitation on how many simultaneous active RPS bindings can a single device using the profile have.
                                Request limit enforces maximum number of requests per minute per RPS binding.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Enable remote printing"
                                disabled={state.blocked}
                                control={
                                    <Switch
                                        checked={profile?.remotePrintEnabled || false}
                                        onChange={(_, checked) => setProfile(s => ({ ...s, remotePrintEnabled: checked }))}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={profile?.remotePrintEnabled}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <FormTextField
                                            title="Max bindings per device"
                                            placeholder="2"
                                            type="number"
                                            disabled={state.blocked}
                                            value={profile?.maxBindingsPerDevice || 2}
                                            onChange={value => setProfile(s => ({ ...s, maxBindingsPerDevice: value as number }))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField
                                            title="Max requests per minute"
                                            placeholder="10"
                                            type="number"
                                            disabled={state.blocked}
                                            value={profile?.maxRequestsPerMinute || 10}
                                            onChange={value => setProfile(s => ({ ...s, maxRequestsPerMinute: value as number }))}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="Access Control" description="IP-based network security" icon={["fal", "lock"]} {...accordionGroup.control("acl")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" gutterBottom>
                                Access control offers application-level firewalling of requests.
                                If enabled, only requests coming from defined IPv4 CIDRS will be processed. Other requests will be denied.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Enable IP access control"
                                disabled={state.blocked}
                                control={
                                    <Switch
                                        checked={profile?.aclEnabled || false}
                                        onChange={(_, checked) => setProfile(s => ({ ...s, aclEnabled: checked }))}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={profile?.aclEnabled}>
                                <List disablePadding subheader={<ListSubheader disableGutters sx={{ backgroundColor: "inherit" }}>Allowed IPv4 CIDRs</ListSubheader>}>
                                    <DndContext sensors={dndSensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={createSortDragEndHandler("aclAllowedSources")}>
                                        <SortableContext items={profile?.aclAllowedSources || []} strategy={verticalListSortingStrategy}>
                                            {(profile?.aclAllowedSources || []).map((address, index) =>
                                                <SortableTextFieldListItem
                                                    key={index}
                                                    id={address}
                                                    item={address}
                                                    disabled={state.blocked}
                                                    onChange={value => {
                                                        setProfile(last => ({
                                                            ...last,
                                                            aclAllowedSources: (profile?.aclAllowedSources || []).map((v, i) => i === index ? value : v)
                                                        }));
                                                    }}
                                                    onDelete={() => setProfile(last => ({ ...last, aclAllowedSources: (profile?.aclAllowedSources || []).filter((_, i) => i !== index) }))}
                                                />
                                            )}
                                        </SortableContext>
                                    </DndContext>
                                </List>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemIcon sx={{ minWidth: "40px" }}>
                                            <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "plus-circle"]} />
                                        </ListItemIcon>
                                        <TextField
                                            sx={{ width: "100%", m: t => t.spacing(0, 5, 0, 0) }}
                                            value={newAclAllowedSource}
                                            variant="standard"
                                            disabled={state.blocked}
                                            onChange={evt => setNewAclAllowedSource(evt.target.value)}
                                            onKeyDown={evt => {
                                                if (evt.key === "Enter") {
                                                    evt.preventDefault();
                                                    if (newAclAllowedSource) {
                                                        setProfile(s => ({ ...s, aclAllowedSources: [...(s?.aclAllowedSources || []), newAclAllowedSource] }));
                                                        setNewAclAllowedSource("");
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </Grid>
                    </Grid>
                </FormAccordion>
                <FormAccordion title="Development" description="Development profile configuration" icon={["fal", "wrench"]} {...accordionGroup.control("development")}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                                Development mode alters internal platform behaviour and disables some checks resulting in possible security implications.
                                Profiles allowing development mode should not be used for production!
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Enable development mode"
                                disabled={state.blocked}
                                control={
                                    <Switch
                                        color="warning"
                                        checked={profile?.development || false}
                                        onChange={(_, checked) => setProfile(s => ({ ...s, development: checked }))}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </FormAccordion>
            </Box>
        </Box>
    );
};

const EditRpsProfileAction: ActionDef<Props> = {
    id: "rps-profile-edit",
    title: "Edit RPS Profile",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Update"
    },
    menu: {
        title: "Edit RPS Profile",
        subtitle: "Update RPS profile parameters",
        icon: <RpsProfileEditIcon />
    }
};

export default EditRpsProfileAction;
