import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.scss";

import RootLayoutView from "./views/Layout";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
library.add(fas, fal, fad);

/* TODO: This is like... nasty! I know :-( */
const origConsoleError = console.error;
window.console.error = (...stuff: any[]) => {
    if (stuff[0] && typeof stuff[0] === "string" && (stuff[0].includes("MUI: License key not found") || stuff[0].includes("MUI: Missing license key."))) {
        return;
    }
    origConsoleError(...stuff);
};

window.document.append();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);


root.render(
    <React.StrictMode>
        <RootLayoutView />
    </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
