import { useState, useEffect, useMemo } from "react";
import { firstValueFrom, timer } from "rxjs";
import { ActionStatus } from "components/Action/ActionStatus";

interface Opts {
    process: (setProgress: (progress: number) => void) => Promise<void>;
    prepare?: () => Promise<void>;
    onStatusChange: (status: ActionStatus) => void;
    shouldProcess: boolean;
    loading?: boolean | boolean[];
    animationDelay?: number;
}

export const useActionState = (opts: Opts, dependencies: React.DependencyList = []) => {
    const [status, setStatus] = useState<ActionStatus>({ state: "loading", blocked: true });
    const loading = useMemo(() => opts.loading !== undefined ? Array.isArray(opts.loading) ? opts.loading.every(c => c) : opts.loading : false, [opts.loading]);
    const [preparing, setPreparing] = useState(false);


    useEffect(() => opts.onStatusChange(status), [opts, status]);

    useEffect(() => {
        if (loading && status.state !== "loading") {
            setStatus({ state: "loading", blocked: true });
        } else if (!loading && !preparing && opts.shouldProcess && status.state !== "processing") {
            (async () => {
                setStatus({ state: "processing", blocked: true });
                if (opts.animationDelay) {
                    await firstValueFrom(timer(opts.animationDelay));
                }
                try {
                    await opts.process(p => setStatus({ state: "processing", blocked: true, progress: p }));
                    if (opts.animationDelay) {
                        await firstValueFrom(timer(opts.animationDelay));
                    }
                    setStatus({ state: "done" });
                } catch (error) {
                    setStatus({ state: "error", error: error.message});
                }
            })();
        } else if (!loading && !preparing && status.state === "loading" && opts.prepare) {
            (async () => {
                setPreparing(true);
                try {
                    await opts.prepare?.();
                    setPreparing(false);
                    setStatus({ state: "ready" });
                } catch (error) {
                    setPreparing(false);
                    setStatus({ state: "error", blocked: true, error: error.message});
                }
            })();
        } else if (!loading && !preparing && ["processing", "error", "ready"].indexOf(status.state) === -1) {
            setStatus({ state: "ready" });
        }
    }, [loading, preparing, opts, status.state]);

    useEffect(() => () => {
        setStatus({ state: "loading", blocked: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return status;
};

export default useActionState;