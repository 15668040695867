import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionBusinessSubjectTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { SubjectDeleteIcon } from "components/Icons";

interface Props {
    subjects: Dto.RdmsApiV1.BusinessSubject[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deleteSubject = useApiInvocation<{ id: string}>("delete", `/v1/business-subjects/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, device] of props.subjects.entries()) {
                try {
                    await deleteSubject({ id: device.id });
                    setProgress((index + 1) / props.subjects.length);
                } catch (error) {
                    throw new Error(`Failed to delete business subject ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Removing subject removes all information and configuration related to particular subject.
                This will also remove default profiles for their devices.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="error" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                This is a destructive action that cannot be undone. Proceed with caution!
            </Alert>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Customer record may get automatically created when their information is received from connected device.
            </Alert>
            <ActionBusinessSubjectTargetList subjects={props.subjects} operation="deleted" />
        </Box>
    );
};

const DeleteSubjectAction: ActionDef<Props> = {
    id: "subject-delete",
    title: "Delete Subject",
    component: Action,
    dialog: {
        confirmButtonCaption: "Delete",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Delete subject",
        subtitle: "Remove subject information and configuration",
        icon: <SubjectDeleteIcon />,
        color: "error.main"
    }
};

export default DeleteSubjectAction;