import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionDeviceTargetList: React.FC<{ devices: Dto.RdmsApiV1.Device[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.Device>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.devices}
        singular="device"
        plural="devices"
        icon={["fas", "hdd"]}
        getPrimary={(device: Dto.RdmsApiV1.Device) => `${device.shortId}` + (device.alias ? ` (${device.alias})` : "")}
    />;
