import { Badge, BadgeProps, Stack, Tab, TabProps, styled } from "@mui/material";
import React, { useMemo } from "react";
import { Link as RouterLink  } from "react-router-dom";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
        position: "inherit",
        top: 11,
        right: 4,
        backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[800]
    },
}));

export interface NavigationTabProps {
    label: string;
    path: string;
    value?: any;
    badgeContent?: number;
}

export const NavigationTab: React.FC<NavigationTabProps & TabProps> = props => {
    const label = useMemo(() => props.badgeContent ? <Stack alignItems="flex-end" direction="row">{props.label} <StyledBadge badgeContent={props.badgeContent} /></Stack> : props.label, [props.badgeContent, props.label]);
    return (
        <Tab
            {...{
                label,
                component: RouterLink,
                to: props.value,
                value: props.value
            }}
        />
    );
};
