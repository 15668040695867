import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionBusinessSubjectTargetList: React.FC<{ subjects: Dto.RdmsApiV1.BusinessSubject[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.BusinessSubject>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.subjects}
        singular="business subject"
        plural="business subjects"
        icon={["fas", "building"]}
        getPrimary={(subject: Dto.RdmsApiV1.BusinessSubject) => subject.name}
    />;
