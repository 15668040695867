import { Box, Button, Chip, Divider, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import useApiResource from "hooks/useApiResource";
import { Dto } from "@varos/rdm-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import ObjectIndexActionBar from "components/ObjectIndexActionBar";
import DefaultContainer from "components/DefaultContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounceTime, distinctUntilChanged, map, Subject, tap } from "rxjs";
import { Link } from "react-router-dom";
import DataGrid, { DataGridColDef, DataGridSelectActionButton } from "components/DataGrid";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import Heading from "components/Heading";
import { useRpsProfileActions } from "hooks/useRpsProfileActions";
import { ActionMenuItem } from "components/Action";
import DeleteRpsProfileAction from "components/ObjectActions/RpsProfile/DeleteRpsProfileAction";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { RequireAuthorization } from "components/Authorization";
import SetGlobalDefaultRpsProfileAction from "components/ObjectActions/RpsProfile/SetGlobalDefaultRpsProfileAction";

const RpsProfileIndexView: React.FC = () => {
    const theme = useTheme();
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "title", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const rpsProfilesUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const url = `/v1/rps/profiles?search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
        return url;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, sortModel]);

    const rpsProfiles = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.RpsProfile>>(rpsProfilesUrl);

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => rpsProfiles.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.RpsProfile[], [rpsProfiles?.data, selectedIds]);

    const rpsProfileActions = useRpsProfileActions({ onUpdate: () => rpsProfiles.reload() });

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const columns: DataGridColDef<Dto.RdmsApiV1.RpsProfile>[] = useMemo(() => [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={[params.row.isGlobalDefault ? "fas" : "fad", "print-search"]} />
                    <Typography variant="body2" color="primary" fontWeight={params.row.isGlobalDefault ? 600 : undefined} sx={{ textDecoration: "none", ml: .5 }} component={Link} to={`/rps-profiles/${params.row.id}`}>
                        {params.value}
                    </Typography>
                    {params.row.isGlobalDefault && <Tooltip title="Global default"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "globe"]} fixedWidth /></Tooltip>}
                </Box>
            )
        },
        {
            field: "remotePrintEnabled",
            headerName: "Print",
            sortable: false,
            filterable: false,
            hideOnSmallScreen: true,
            renderCell: params => params.value ? <Chip variant="outlined" size="small" color="success" label="Enabled" /> : <Chip variant="filled" size="small" color="default" label="Disabled" />
        },
        {
            field: "aclEnabled",
            headerName: "ACL",
            sortable: false,
            filterable: false,
            hideOnSmallScreen: true,
            renderCell: params => params.value ? <Chip variant="outlined" size="small" color="success" label="Enabled" /> : <Chip variant="filled" size="small" color="default" label="Disabled" />
        },
        {
            field: "development",
            headerName: "Development",
            sortable: false,
            filterable: false,
            hideOnSmallScreen: true,
            renderCell: params => params.value ? <Chip variant="outlined" size="small" color="warning" label="Enabled" /> : <Chip variant="outlined" size="small" color="primary" label="Disabled" />
        },
        {
            field: "maxBindingsPerDevice",
            headerName: "Max Bindings Per Device",
            flex: 1,
            hideOnSmallScreen: true,
        },
        {
            field: "maxRequestsPerMinute",
            headerName: "Max Requests Per Minute",
            flex: 1,
            hideOnSmallScreen: true,
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            flex: 1,
            defaultVisibility: "hidden"
        }
    ], [theme]);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        factory
            .if(auth.hasAccess("rdm:rps-profile", "create")).section("RPS Profiles").action("rps-profile-index-create", "Create RPS Profile").priority(9000).do(() => rpsProfileActions.create())
            .if(auth.hasAccess("rdm:rps-profile", "update")).action("rps-profile-index-set-global-default", "Set Global Default RPS Profile").priority(9100).do(() => rpsProfileActions.setGlobalDefault({}));
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected RPS Profiles`)
                .if(auth.hasAccess("rdm:rps-profile", "delete")).action(DeleteRpsProfileAction).priority(8900).do(() => rpsProfileActions.deleteProfile({ profiles: selectedObjects }));
            if (selectedObjects.length === 1) {
                factory.if(auth.hasAccess("rdm:rps-profile", "update")).action(SetGlobalDefaultRpsProfileAction).priority(8100).do(() => rpsProfileActions.setGlobalDefault({ profile: selectedObjects[0] }));
            }
        }
        return factory;
    }, [rpsProfileActions, selectedObjects, auth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Heading>RPS Profiles</Heading>
            <DefaultContainer loading={rpsProfiles.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        {selectedObjects.length === 1 && (
                            <RequireAuthorization object={selectedObjects[0]} operation="update">
                                <ActionMenuItem def={SetGlobalDefaultRpsProfileAction} action={() => rpsProfileActions.setGlobalDefault({ profile: selectedObjects[0] })} />
                            </RequireAuthorization>
                        )}
                        <Divider />
                        <RequireAuthorization resource="rdm:rps-profile" operation="delete">
                            <ActionMenuItem def={DeleteRpsProfileAction} action={() => rpsProfileActions.deleteProfile({ profiles: selectedObjects })} />
                        </RequireAuthorization>
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                    <RequireAuthorization resource="rdm:rps-profile" operation="create">
                        <Button variant="contained" disableElevation onClick={() => rpsProfileActions.create()}>New</Button>
                    </RequireAuthorization>
                    <RequireAuthorization resource="rdm:rps-profile" operation="update">
                        <Button variant="outlined" disableElevation onClick={() => rpsProfileActions.setGlobalDefault({})}>Set Global Default</Button>
                    </RequireAuthorization>
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="rpsProfileGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={rpsProfiles.data?.data || []}
                    rowCount={rpsProfiles.data?.total || 0}
                    loading={rpsProfiles.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    checkboxSelection={auth.hasAccess("rdm:rps-profile", "update") || auth.hasAccess("rdm:rps-profile", "delete")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={model => setSelectedIds(model as string[])}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting
                />
            </DefaultContainer>
        </>
    );
};
export default RpsProfileIndexView;
