import { ActionDef } from "components/Action";
import { Action } from "./EditDcmProfileAction";

const CreateDcmProfileAction: ActionDef<void> = {
    id: "dcm-profile-create",
    title: "Create DCM Profile",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Create"
    }
};

export default CreateDcmProfileAction;