import { useEffect, useState } from "react";
import { Observable } from "rxjs";

const useObservable = <T,>(source: Observable<T>, noCopy?: boolean): T | undefined => {
    const [data, setData] = useState<T | undefined>(undefined);

    useEffect(() => {
        const subscription = source.subscribe({
            next: data => {
                if (noCopy) {
                    setData(data);
                } else {
                    setData((Array.isArray(data) ? [...data] : typeof data === "object" ? Object.assign({}, data) : data) as T);
                }
            }
        });
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [noCopy, source]);

    return data as T;
};

export default useObservable;
