import { useCallback, useState } from "react";

export const useAccordionGroup = () => {
    const [active, setActive] = useState<string | null>(null);

    const control = useCallback((key: string, changeHandlerName: string = "onChange") => {
        return {
            expanded: active === key,
            [changeHandlerName]: (expanded: boolean) => {
                setActive(expanded || key !== active ? key : null);
            }
        };
    }, [active]);

    return {
        control
    };
};
