import { faFlaskGear } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Tooltip, Typography, Alert } from "@mui/material";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useCallback, useState } from "react";
import Api from "services/api.service";

export const MainMenuEnvironmentNoticeBox: React.FC = () => {
    const [visible, setVisible] = useState(true);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (Api.environmentShowNotice && visible) {
            factory.section("Portal").action("portal-hide-environment-notice", "Hide Environment Notice").icon("comment-alt-slash").priority(32950).do(() => setVisible(false));
        }
        return factory;
    }, [visible]);
    useCommandBarActions(cbActionFactory);

    return (
        <Collapse sx={{ flex: "0 0 auto" }} in={Api.environmentShowNotice && visible} mountOnEnter unmountOnExit>
            <Tooltip
                arrow
                title={<>
                    <Typography component="div" variant="h5" fontSize={16}>{Api.environmentName}</Typography>
                    <span>This is an isolated testing/development environment. Changes made here won't affect production customers or devices.</span>
                </>}
                placement="right"
                enterDelay={500}
            >
                <Alert variant="filled" color="warning" sx={{ cursor: "default", m: t => t.spacing(0, 2) }} onClose={() => setVisible(false)} icon={<FontAwesomeIcon fixedWidth icon={faFlaskGear} />}>
                    {Api.environmentName}
                </Alert>
            </Tooltip>
        </Collapse>
    );
};
