import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useMemo } from "react";
import { InfoListItem } from "../../components/InfoList";

export const PpekkApiGatewayStatusInfoListItem: React.FC<{ info: Dto.RdmsApiV1.DeviceInfo }> = props => {
    const theme = useTheme();
    const status = useMemo(() => props.info.info.ppekkApi?.gateway.state || "unsupported", [props.info.info.ppekkApi?.gateway.state]);
    const style = useMemo<{ icon: React.ReactElement, color: string, title: string, message?: string } | undefined>(() => {
        if (status === "unsupported") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "exclamation-circle"]} size="lg"  />,
                color: theme.palette.text.disabled,
                title: `Unsupported`,
                message: props.info.info.ppekkApi?.gateway.error
            };
        } else if (status === "disabled") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "power-off"]} size="lg" />,
                color: theme.palette.text.disabled,
                title: `Disabled`,
                message: props.info.info.ppekkApi?.gateway.error
            };
        } else if (status === "error") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "exclamation-triangle"]} size="lg" />,
                color: theme.palette.error.main,
                title: `Error`,
                message: props.info.info.ppekkApi?.gateway.error
            };
        } else if (status === "init") {
            return {
                icon: <FontAwesomeIcon icon={["fas", "circle-notch"]} size="lg" spin />,
                color: theme.palette.info.main,
                title: `Initializing`
            };
        } else if (status === "running") {
            return {
                icon: <FontAwesomeIcon icon={["fad", "check-circle"]} size="lg" />,
                color: theme.palette.success.main,
                title: `Running`
            };
        }
    }, [props.info, theme, status]);

    return (
        <InfoListItem
            title="PPEKK API Gateway"
            tail={style ? <Box sx={{ ml: 1, color: style.color }}>{style?.icon || undefined}</Box> : undefined}
        >
            <Stack>
                <Typography variant="body2" sx={{ color: style?.color || undefined }}>{style?.title || "Unknown"}</Typography>
                {style?.message && <Typography variant="body2" color="text.disabled">{style.message}</Typography>}
            </Stack>
        </InfoListItem>
    );
};

export default PpekkApiGatewayStatusInfoListItem;