import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Chip, emphasize, styled } from "@mui/material";

export interface BreadcrumbConfig {
    match: RegExp;
    label: string | ((matches: RegExpExecArray | null) => Promise<string>);
    icon?: IconProp;
}

export const Breadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800];
    return {
        backgroundColor,
        marginBottom: theme.spacing(.5),
        marginTop: theme.spacing(.5),
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        cursor: "pointer",
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip;
