import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useApiResource from "hooks/useApiResource";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { RpsProfileSetIcon } from "components/Icons";
import { useDeviceTypes } from "hooks/useDeviceTypes";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deviceTypes = useDeviceTypes();
    const rpsProfiles = useApiResource<Dto.RdmsApiV1.DcmProfile[]>("/v1/rps/profiles");
    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });
    const [selected, setSelected] = useState<Dto.RdmsApiV1.DcmProfile | null>(null);

    const state = useActionState({
        ...props,
        loading: rpsProfiles.loading,
        animationDelay: 500,
        prepare: async () => {
            const unsupported: Dto.RdmsApiV1.Device[] = [];
            for (const device of props.devices) {
                const typeSpec = await deviceTypes.getForDeviceAsync(device);
                if (!typeSpec.type?.capabilities.supportsRpsProfile) {
                    unsupported.push(device);
                }
            }
            if (unsupported.length > 0) {
                throw new Error(`Cannot proceed with action, because ${unsupported.length} device${unsupported.length > 1 ? "s do not" : " does not"} support DCM profile assignment.`);
            }
        },
        process: async setProgress => {
            if (!selected) {
                throw new Error("No RPS profile has been selected.");
            }
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { rpsProfileId: selected.id });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Setting a RPS profile to device individually will override profile set on customer or globally.
                RPS profiles control whether device can be used with remote printing service and define behaviours
                related to this service.
            </Typography>
            <ActionDeviceTargetList devices={props.devices} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose profile to apply to selected devices:</Typography>
            <Autocomplete
                autoHighlight
                disabled={state.blocked}
                sx={{ width: "100%" }}
                options={rpsProfiles.data || []}
                value={selected}
                onChange={(_, value) => setSelected(value)}
                getOptionLabel={option => option.title}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        <Stack direction="row">
                            <FontAwesomeIcon icon={["fad", "print-search"]} fixedWidth />
                            <Typography sx={{ ml: .5 }}>{option.title}</Typography>
                        </Stack>
                    </Box>
                )}
                renderInput={params => (
                    <TextField autoFocus label="Select RPS profile" {...params} inputProps={{...params.inputProps, autoComplete: "new-password"}} />
                )}
            />
        </Box>
    );
};

const SetDeviceRpsProfileAction: ActionDef<Props> = {
    id: "device-set-rps-profile",
    title: "Set Device RPS Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Profile",
    },
    menu: {
        title: "Set RPS profile",
        subtitle: "Configure individual RPS profile for device",
        icon: <RpsProfileSetIcon />
    }
};

export default SetDeviceRpsProfileAction;