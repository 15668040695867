import { Dto } from "@varos/rdm-common";
import { ApiResourceAutocomplete, ApiResourceAutocompletePreparedProps, ApiResourceAutocompleteProps } from "./ApiResourceAutocomplete";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function createPreparedAutocomplete<T extends { id: string}>(url: string, defaultLabel: string, defaultIcon: IconProp, getOptionLabel: ApiResourceAutocompleteProps<T>["getOptionLabel"], requireAuthorization?: ApiResourceAutocompleteProps<T>["requireAuthorization"]): ((props: ApiResourceAutocompletePreparedProps<T>) => JSX.Element) {
    return (props: ApiResourceAutocompletePreparedProps<T>) => <ApiResourceAutocomplete url={url} label={defaultLabel} icon={defaultIcon} getOptionLabel={getOptionLabel} requireAuthorization={requireAuthorization} {...props} />;
}

export const BusinessSubjectAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.BusinessSubject>("/v1/business-subjects", "Select business subject", ["fad", "building"], object => object.name, ["rdm:business-subject", "read"]);
export const DcmProfileAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.DcmProfile>("/v1/dcm-profiles", "Select DCM profile", ["fad", "badge-check"], object => object.title, ["rdm:dcm-profile", "read"]);
export const RpsProfileAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.RpsProfile>("/v1/rps/profiles", "Select RPS profile", ["fad", "print-search"], object => object.title, ["rdm:rps-profile", "read"]);
export const DeviceTypeAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.DeviceType>("/v1/device-types", "Select device type", ["fad", "layer-group"], object => object.title, ["rdm:device-type", "read"]);
export const DeviceAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.Device>("/v1/devices", "Select device", ["fad", "hdd"], object => object.shortId + (object.alias ? ` (${object.alias})` : ""), ["rdm:device", "read"]);
export const UserAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.SystemUser>("/v1/system/users", "Select user", ["fad", "user"], object => object.username + (object.displayName ? ` (${object.displayName})` : ""), ["sys:user", "read"]);
export const UserRoleAutocomplete = createPreparedAutocomplete<Dto.RdmsApiV1.SystemUserRole>("/v1/system/user-roles", "Select user role", ["fad", "user-shield"], object => object.title, ["sys:user-role", "read"]);
