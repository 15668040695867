import { useCallback, useEffect, useMemo, useState } from "react";

interface UiState {
    [key: string]: any;
}

export const useUiStateStorage = () => {

    const [state, setState] = useState<UiState | null>(null);
    const publishedState = useMemo(() => state || {}, [state]);

    useEffect(() => {
        const storedState = window.localStorage.getItem("rdm-portal.ui-state");
        try {
            if (storedState) {
                setState(JSON.parse(storedState));
            } else {
                setState({});
            }
        } catch (error) {
            console.error(`failed to restore ui state: ${error}`);
            setState({});
        }
    }, []);

    useEffect(() => {
        if (state !== null) {
            window.localStorage.setItem("rdm-portal.ui-state", JSON.stringify(state));
        }
    }, [state]);
    
    const store = useCallback((key: string, value: any) => {
        setState({...state, [key]: value});
    }, [state]);

    return {
        state: publishedState,
        store
    };
};
