import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Divider, Stack, TextField, Typography } from "@mui/material";
import { GridSortModel, GridPaginationModel } from "@mui/x-data-grid-pro";
import { Dto } from "@varos/rdm-common";
import { ActionMenuItem } from "components/Action";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import DataGrid, { DataGridColDef, DataGridSelectActionButton } from "components/DataGrid";
import DefaultContainer from "components/DefaultContainer";
import Heading from "components/Heading";
import ChangeUserPasswordAction from "components/ObjectActions/User/ChangeUserPasswordAction";
import DeleteUserAction from "components/ObjectActions/User/DeleteUserAction";
import DisableUserAction from "components/ObjectActions/User/DisableUserAction";
import EnableUserAction from "components/ObjectActions/User/EnableUserAction";
import ObjectIndexActionBar from "components/ObjectIndexActionBar";
import { RequireAuthorization } from "components/Authorization";
import useApiResource from "hooks/useApiResource";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { useUserActions } from "hooks/useUserActions";
import { useMemo, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Subject, debounceTime, distinctUntilChanged, map, tap } from "rxjs";

export const UserIndexView: React.FC = props => {
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "username", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const usersUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const url = `/v1/system/users?search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
        return url;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, sortModel]);

    const users = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.SystemUser>>(usersUrl);

    const userActions = useUserActions({ onUpdate: () => users.reload() });

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => users.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.SystemUser[], [users?.data, selectedIds]);

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const columns: DataGridColDef<Dto.RdmsApiV1.SystemUser>[] = useMemo(() => [
        {
            field: "username",
            headerName: "Username",
            flex: 1,
            renderCell: params => (
                <Box sx={{ color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon size="lg" fixedWidth icon={["fad", "user"]} />
                    <Typography variant="body2" color="primary" sx={{ textDecoration: "none", ml: .5 }} component={Link} to={`/users/${params.row.id}`}>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        {
            field: "displayName",
            headerName: "Display Name",
            flex: 1,
            hideOnSmallScreen: true,
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: 1,
            hideOnSmallScreen: true,
        },
        {
            field: "enabled",
            headerName: "Status",
            flex: 1,
            renderCell: params => (
                <Stack direction="row" spacing={.5} alignItems="center">
                    {params.row.enabled && <Chip variant="filled" color="success" size="small" label="Enabled" />}
                    {!params.row.enabled && <Chip variant="filled" color="default" size="small" label="Disabled" />}
                    {params.row.superAdmin && <Chip variant="outlined" color="warning" size="small" label="Super Administrator" />}
                </Stack>
            )
        },
        {
            field: "_roleCount",
            headerName: "Roles",
            flex: 1,
            hideOnSmallScreen: true,
            valueGetter: params => params.row.roleIds.length
        },
        {
            field: "lastLoginDate",
            headerName: "Last Login",
            hideOnSmallScreen: true,
            flex: 1
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            defaultVisibility: "hidden"
        }
    ], []);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        factory
            .section("Users")
            .if(auth.hasAccess("sys:user", "create")).action("user-index-create", "Create User").priority(9000).do(() => userActions.create());
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected Users`)
                .if(auth.hasAccess("sys:user", "update")).action(EnableUserAction).priority(8100).do(() => userActions.enable({ users: selectedObjects }))
                .if(auth.hasAccess("sys:user", "update")).action(DisableUserAction).priority(8200).do(() => userActions.disable({ users: selectedObjects }))
                .if(auth.hasAccess("sys:user", "delete")).action(DeleteUserAction).priority(8900).do(() => userActions.delete({ users: selectedObjects }));
            if (selectedObjects.length === 1) {
                factory.if(auth.hasAccess("sys:user", "update")).action(ChangeUserPasswordAction).priority(8300).do(() => userActions.changePassword({ user: selectedObjects[0] }))
            }
        }
        return factory;
    }, [selectedObjects, userActions, auth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <Heading>Users</Heading>
            <DefaultContainer loading={users.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        {selectedObjects.length === 1 && (
                            <RequireAuthorization object={selectedObjects[0]} operation="update">
                                <ActionMenuItem def={ChangeUserPasswordAction} action={() => userActions.changePassword({ user: selectedObjects[0] }) } />
                            </RequireAuthorization>
                        )}
                        <Divider />
                        <RequireAuthorization resource="sys:user" operation="update">
                            <ActionMenuItem def={EnableUserAction} action={() => userActions.enable({ users: selectedObjects }) } />
                            <ActionMenuItem def={DisableUserAction} action={() => userActions.disable({ users: selectedObjects }) } />
                        </RequireAuthorization>
                        <Divider />
                        <RequireAuthorization resource="sys:user" operation="delete">
                            <ActionMenuItem def={DeleteUserAction} action={() => userActions.delete({ users: selectedObjects })} />
                        </RequireAuthorization>
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                    <RequireAuthorization resource="sys:user" operation="create">
                        <Button variant="contained" disableElevation onClick={() => userActions.create()}>New</Button>
                    </RequireAuthorization>
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="userGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={users.data?.data || []}
                    rowCount={users.data?.total || 0}
                    loading={users.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    checkboxSelection={auth.hasAccess("sys:user", "update") || auth.hasAccess("sys:user", "delete")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={model => setSelectedIds(model as string[])}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting
                />
            </DefaultContainer>
        </>
    );
};
export default UserIndexView;