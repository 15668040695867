import { Collapse, Typography, useMediaQuery, useTheme } from "@mui/material";

export interface HeadingProps {
    variant?: "main";
    children?: React.ReactNode;
}

export const Heading: React.FC<HeadingProps> = props => {
    const theme = useTheme();
    const headingVisible = useMediaQuery(theme.breakpoints.up("sm"));

    if (!props.variant || props.variant === "main") {
        return (
            <Collapse in={headingVisible}>
                <Typography component="h2" variant="h4" sx={{ fontWeight: 200 }} gutterBottom>{props.children}</Typography>
            </Collapse>
        );
    }
    return null;
};

export default Heading;
