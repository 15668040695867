import { Box, CardActions, CardContent, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import moment from "moment";
import React, { ReactElement, useMemo } from "react";
import { DefaultCard } from "components/DefaultCard";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface MetricCardProps {
    title: string;
    value: number | null;
    variant?: "outlined" | "filled";
    direction?: "row" | "column";
    color?: string;
    loading?: boolean;
    description?: string;
    sx?: SxProps;
    sparkLine?: boolean;
    sparkLineType?: "line" | "bar";
    sparkLineData?: [Date, number][] | null;
    actions?: React.ReactNode[];
    children?: React.ReactNode;
    valueSx?: SxProps;
    skeletonHeight?: number;
    backgroundIcon?: IconProp;
}

export const MetricCard: React.FC<MetricCardProps> = props => {
    const theme = useTheme();

    const skeletonHeight = useMemo(() => props.skeletonHeight || (props.direction === "column" ? 145 : 110) + (props.sparkLine ? 100 : 0) + (props.description ? 20 : 0) + (props.actions ? 48 : 0) , [props.actions, props.description, props.direction, props.skeletonHeight, props.sparkLine]);
    const direction = useMemo(() => props.direction || "row", [props.direction]);
    const bgColor = useMemo(() => props.variant === "filled" ? (props.color || theme.palette.primary.main) : undefined, [props.color, props.variant, theme]);
    const fgDataColor = useMemo(() => props.variant === "filled" ? theme.palette.common.white : (props.color || theme.palette.primary.main), [props.color, props.variant, theme]);
    const fgTextColor = useMemo(() => props.variant === "filled" ? theme.palette.common.white : undefined, [props.variant, theme]);

    return (
        <DefaultCard
            raw
            loading={props.loading}
            skeletonHeight={skeletonHeight}
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                backgroundColor: bgColor,
                borderColor: bgColor,
                height: "100%",
                ...props.sx
            }}
        >
            <CardContent>
                <Stack spacing={2}>
                    <Stack spacing={direction === "column" ? 1 : 4} direction={direction}>
                        <Stack>
                            <Typography variant="h5" sx={{ color: fgTextColor }}>{props.title}</Typography>
                            {props.description && <Typography variant="body2" sx={{ color: fgTextColor, opacity: .75 }}>{props.description}</Typography>}
                        </Stack>
                        <Box flex={1} />
                        <Typography variant="h3" component="div" sx={{ color: fgDataColor, ...props.valueSx }}>{props.value || "-"}</Typography>
                    </Stack>
                    {props.sparkLine && props.sparkLineData && (props.sparkLineData.length || 0) > 0 &&
                        <SparkLineChart
                            data={props.sparkLineData.map(v => v[1]) || []}
                            xAxis={{
                                scaleType: "band",
                                data: props.sparkLineData.map(v => v[0]) || [],
                                valueFormatter: (value) => moment(value).format("Y-M-D HH:mm:ss")
                            }}
                            plotType={props.sparkLineType || "line"}
                            margin={{bottom:0, left: 0, right: 0, top: 0}}
                            colors={[fgDataColor]}
                            curve="monotoneY"
                            height={100}
                            showTooltip
                            showHighlight
                        />
                    }
                </Stack>
                {props.children}
            </CardContent>
            {props.actions &&
                <>
                    <Box sx={{ flex: 1 }} />
                    <CardActions sx={{ justifyContent: "center" }}>
                        {props.actions.map((element, i) => React.cloneElement(element as ReactElement, {key: i}))}
                    </CardActions>
                </>
            }
            {props.backgroundIcon && (
                <Box sx={{
                    position: "absolute",
                    bottom: "0px",
                    right: "8px",
                    opacity: .35,
                    color: fgTextColor,
                    fontSize: "48px"
                }}>
                    <FontAwesomeIcon icon={props.backgroundIcon} />
                </Box>
            )}
        </DefaultCard>
    );
};

export default MetricCard;