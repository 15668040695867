import { Dto } from "@varos/rdm-common";
import { useHistory, useParams } from "react-router-dom";
import React, { useCallback } from "react";
import useApiResource from "hooks/useApiResource";
import { ObjectHeader } from "components/ObjectHeader";
import { Box, Button, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from "@mui/material";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRpsProfileActions } from "hooks/useRpsProfileActions";
import { Clear, Settings } from "@mui/icons-material";
import DeleteRpsProfileAction from "components/ObjectActions/RpsProfile/DeleteRpsProfileAction";
import useObjectAction from "hooks/useObjectAction";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import SetGlobalDefaultRpsProfileAction from "components/ObjectActions/RpsProfile/SetGlobalDefaultRpsProfileAction";
import EditRpsProfileAction from "components/ObjectActions/RpsProfile/EditRpsProfileAction";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import { RequireAuthorization } from "components/Authorization";


const RpsProfileDetailView: React.FC<{}> = () => {
    const history = useHistory();
    const params = useParams<{ rpsProfileId?: string }>();

    const rpsProfile = useApiResource<Dto.RdmsApiV1.RpsProfile>(`/v1/rps/profiles/${params.rpsProfileId}`);
    const objectAuth = useObjectAuthorizationInfo(rpsProfile.data);

    const rpsProfileActions = useRpsProfileActions({ onUpdate: () => rpsProfile.reload() });
    const deleteProfileAction = useObjectAction(DeleteRpsProfileAction, () => history.push("/rps-profiles"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!rpsProfile.data) {
            return factory;
        }
        return factory
            .section(rpsProfile.data.title)
            .if(objectAuth.hasAccess("update")).action(EditRpsProfileAction).priority(8100).do(() => rpsProfileActions.edit({ profile: rpsProfile.data }))
            .if(objectAuth.hasAccess("update")).action(SetGlobalDefaultRpsProfileAction).priority(8200).do(() => rpsProfileActions.setGlobalDefault({ profile: rpsProfile.data }))
            .if(objectAuth.hasAccess("delete")).action(DeleteRpsProfileAction).priority(8900).do(() => rpsProfile.data && deleteProfileAction({ profiles: [rpsProfile.data] }));
    }, [deleteProfileAction, rpsProfile.data, rpsProfileActions, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={rpsProfile.initialOrLongLoading}
                icon="print-search"
                title={rpsProfile.data?.title || ""}
                subtitle="Remote Print Service Client Profile"
            />
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="General" loading={rpsProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem title="ID">{rpsProfile.data?.id}</InfoListItem>
                                <InfoListItem title="Title">{rpsProfile.data?.title}</InfoListItem>
                                <InfoListItem
                                    title="Global Default"
                                    tail={
                                        objectAuth.hasAccess("update") &&
                                        <IconButton sx={{ ml: 1 }} onClick={() => rpsProfile.data && rpsProfileActions.setGlobalDefault({ profile: rpsProfile.data.isGlobalDefault ? undefined : rpsProfile.data })}>
                                            {!rpsProfile.data?.isGlobalDefault && <Settings />}
                                            {rpsProfile.data?.isGlobalDefault && <Clear />}
                                        </IconButton>
                                    }
                                >
                                    {rpsProfile.data?.isGlobalDefault && (
                                        <Stack>
                                            <Stack direction="row" alignItems="center" sx={{ color: t => t.palette.primary.main }}>
                                                <FontAwesomeIcon icon={["fas", "globe"]} fixedWidth />
                                                <Typography variant="body2" sx={{ ml: .5 }}>Configured as global default</Typography>
                                            </Stack>
                                            <Typography variant="body2">
                                            This profile will be applied to all devices without direct or customer set RPS profile.
                                            </Typography>
                                        </Stack>
                                    )}
                                    {!rpsProfile.data?.isGlobalDefault && "-"}
                                </InfoListItem>
                                <InfoListItem title="Created"><Moment fromNow>{rpsProfile.data?.createDate}</Moment></InfoListItem>
                                <InfoListItem title="Updated"><Moment fromNow>{rpsProfile.data?.updateDate}</Moment></InfoListItem>
                            </InfoList>
                            <Box flex="1" />
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="Remote Print" loading={rpsProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem
                                    title="Status"
                                    tail={
                                        <>
                                            {rpsProfile.data?.remotePrintEnabled && <Box sx={{ ml: 1, color: t=> t.palette.success.main }}><FontAwesomeIcon icon="circle-check" size="lg" /></Box>}
                                        </>
                                    }
                                >
                                    {rpsProfile.data?.remotePrintEnabled && <Typography variant="body2" color="success.main">Enabled</Typography>}
                                    {!rpsProfile.data?.remotePrintEnabled && <Typography variant="body2">Disabled</Typography>}
                                </InfoListItem>
                                <InfoListItem title="Max bindings per device" stat={rpsProfile.data?.maxBindingsPerDevice}>
                                    Maximum number of simultaneous bindings active per device.
                                </InfoListItem>
                                <InfoListItem title="Max requests per minute" stat={rpsProfile.data?.maxRequestsPerMinute}>
                                    Number of requests that can be processed using single RPS binding per minute.
                                </InfoListItem>
                                <InfoListItem
                                    title="Development Mode"
                                    tail={
                                        <Box sx={{ ml: 1, color: t=> rpsProfile.data?.development ? t.palette.warning.main : t.palette.info.main }}>
                                            <FontAwesomeIcon icon={rpsProfile.data?.development ? "exclamation-triangle" : "circle-check"} size="lg" />
                                        </Box>
                                    }
                                >
                                    {rpsProfile.data?.development &&
                                        <Stack>
                                            <Typography variant="body2" color="warning.main">Enabled</Typography>
                                            <Typography variant="body2" color="text.disabled">Development mode bypasses some security checks and is not suitable for production.</Typography>
                                        </Stack>
                                    }
                                    {!rpsProfile.data?.development &&
                                        <Stack>
                                            <Typography variant="body2" color="info.main">Disabled</Typography>
                                            <Typography variant="body2" color="text.disabled">Profile is production-ready.</Typography>
                                        </Stack>
                                    }
                                </InfoListItem>
                            </InfoList>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="Access Control" loading={rpsProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem
                                    title="IP ACL Status"
                                    tail={
                                        <>
                                            {rpsProfile.data?.aclEnabled && <Box sx={{ ml: 1, color: t=> t.palette.success.main }}><FontAwesomeIcon icon="circle-check" size="lg" /></Box>}
                                            {!rpsProfile.data?.aclEnabled && <Box sx={{ ml: 1, color: t=> t.palette.warning.main }}><FontAwesomeIcon icon="exclamation-triangle" size="lg" /></Box>}
                                        </>
                                    }
                                >
                                    {rpsProfile.data?.aclEnabled && <Typography variant="body2" color="success.main">Enabled</Typography>}
                                    {!rpsProfile.data?.aclEnabled && <Typography variant="body2" color="warning.main">Disabled</Typography>}
                                </InfoListItem>
                                {rpsProfile.data?.aclEnabled &&
                                    <InfoListItem title="Allowed CIDRs">
                                        <Paper elevation={0} sx={{ maxHeight: `calc(80px * 5)`, overflowY: "auto"}}>
                                            <List dense>
                                                {rpsProfile.data.aclAllowedSources.map((cidr, index) =>
                                                    <ListItemButton key={index}>
                                                        <ListItemIcon sx={{ minWidth: "36px" }}>
                                                            <FontAwesomeIcon icon={["fas", "network-wired"]} fixedWidth />
                                                        </ListItemIcon>
                                                        <ListItemText primary={cidr} />
                                                    </ListItemButton>
                                                )}
                                            </List>
                                        </Paper>
                                    </InfoListItem>
                                }
                            </InfoList>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <RequireAuthorization object={rpsProfile.data} operation="update">
                                <Button variant="outlined" onClick={() => rpsProfile.data && rpsProfileActions.edit({ profile: rpsProfile.data })}>Modify Profile</Button>
                            </RequireAuthorization>
                            <RequireAuthorization object={rpsProfile.data} operation="delete">
                                <Button variant="outlined" color="error" onClick={() => rpsProfile.data && deleteProfileAction({ profiles: [rpsProfile.data] })}>Delete Profile</Button>
                            </RequireAuthorization>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default RpsProfileDetailView;
