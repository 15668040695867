import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, useTheme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import a11ylight from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-light";
import a11yDark from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

SyntaxHighlighter.registerLanguage("json", json);

interface ComponentProps {
    open: boolean;
    onOpenChange?: (open: boolean) => void;
    config?: string;
}

const RdmAgentConfigDialog: React.FC<ComponentProps> = (props: ComponentProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        setOpen(false);
        props.onOpenChange?.(false);
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>Recovery RDM Agent Config</DialogTitle>
            <DialogContent>
                <Typography color="text.disabled" gutterBottom>
                    This is the generated recovery configuration. For a failed device, this can be pasted to <b>/opt/varos-rdma/rdma.config.json</b>.
                </Typography>
                <SyntaxHighlighter customStyle={{ fontSize: 12 }} language="json" style={theme.palette.mode === "light" ? a11ylight : a11yDark}>
                    {props.config as string}
                </SyntaxHighlighter>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog >
    );
}
export default RdmAgentConfigDialog;