import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, Typography, ListItem, ListItemIcon, ListItemText, List, useTheme } from "@mui/material";
import { DatumValue, LineProps, ResponsiveLine, Serie, SliceTooltipProps } from "@nivo/line";
import { ValueFormat, CartesianMarkerProps } from "@nivo/core";
import { OrdinalColorScaleConfig } from "@nivo/colors";
import { useCallback, useMemo } from "react";
import { getChartColor } from "constants/chart-colors";
import Center from "components/Center";

export interface LineChartProps {
    data: Serie[];
    height?: number;
    minHeight?: number;
    colors?: { mode: "default" | "nivo", defaultScheme?: "all" | "primary" | "secondary" } & OrdinalColorScaleConfig;
    curve?: LineProps["curve"];
    markers?: CartesianMarkerProps<DatumValue>[];
    yFormat?: ValueFormat<DatumValue>;
    yScaleMax?: number | "auto";
    yScaleMin?: number | "auto";
    timeAxisTickInterval?: string;
    timeAxisTickFormat?: string;
    disabled?: boolean;
}

export const LineChart: React.FC<LineChartProps> = props => {
    const theme = useTheme();
    const [height, minHeight] = useMemo(() => [props.height || 300, props.minHeight || (props.height ? (props.height * .8) : 250)], [props.height, props.minHeight]);

    const tooltipFn = useCallback((props: SliceTooltipProps) => {
        return <Box component={Paper} sx={{ p: 1 }}>
            <Typography variant="body2" sx={{ color: t => t.palette.text.secondary }}>{props.slice.points[0].data.xFormatted}</Typography>
            <List dense>
                {props.slice.points.map(point =>
                    <ListItem key={point.id} sx={{ color: point.serieColor, height: 42 }}>
                        <ListItemIcon sx={{ color: point.serieColor }}>
                            <FontAwesomeIcon icon={["fas", "square"]} fixedWidth />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" fontWeight={600} fontSize={14}>{point.data.yFormatted}</Typography>
                            <Typography variant="body2" fontSize={12}>{point.serieId.toString()}</Typography>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
        </Box>;
    }, []);

    const data = useMemo(() => {
        let output = props.data.slice();
        if (props.data && props.colors?.mode !== "nivo") {
            output = output.map((d, i) => ({...d, color: d.color || getChartColor(theme, i, props.colors?.defaultScheme || "all") }));
        }
        return output;
    }, [props.colors?.defaultScheme, props.colors?.mode, props.data, theme]);

    const hasData = useMemo(() => data && data.some(s => s.data && s.data.length > 0), [data]);

    return (
        <Box sx={{
            height,
            minHeight,
            transition: `opacity 300ms ease`,
            opacity: props.disabled ? .25 : 1,
            pointerEvents: props.disabled ? "none" : undefined
        }}>
            {!hasData &&
                <Center sx={{ minHeight: height, color: theme.palette.text.disabled }}>
                    <FontAwesomeIcon icon={["fas", "eye-slash"]} size="2x" />
                    <Typography variant="h5" sx={{ mt: 1 }}>No data available</Typography>
                </Center>
            }
            {hasData &&
                <ResponsiveLine
                    margin={{ top: 24, right: 40, bottom: 50, left: 60 }}
                    axisBottom={{
                        format: props.timeAxisTickFormat || "%b %d",
                        tickValues: `every ${props.timeAxisTickInterval || "1 days"}`
                    }}
                    colors={props.colors || { datum: "color" }}
                    xScale={{
                        type: "time",
                        format: "%Y-%m-%dT%H:%M:%S.%L%Z",
                    }}
                    xFormat="time:%Y-%m-%d %H:%M:%S"
                    yScale={{
                        type: "linear",
                        min: props.yScaleMin !== undefined ? props.yScaleMin : "auto",
                        max: props.yScaleMax || "auto",
                        stacked: false,
                    }}
                    yFormat={props.yFormat}
                    pointSize={1}
                    useMesh={true}
                    crosshairType="cross"
                    enableSlices="x"
                    sliceTooltip={tooltipFn}
                    curve={props.curve || "monotoneY"}
                    markers={props.markers}
                    animate
                    legends={[
                        {
                            anchor: "bottom",
                            direction: "row",
                            justify: false,
                            translateX: 0,
                            translateY: 50,
                            itemsSpacing: 0,
                            itemDirection: "left-to-right",
                            itemWidth: 140,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "square",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    theme={{
                        textColor: theme.palette.text.primary,
                        fontSize: 12,
                        grid: {
                            line: {
                                stroke: theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[600],
                                opacity: .5
                            }
                        },
                        crosshair: {
                            line: {
                                stroke: theme.palette.text.disabled
                            }
                        },
                        tooltip: {
                            basic: {
                                color: theme.palette.primary.main,
                                fontSize: 14
                            },
                            chip: {
                                color: theme.palette.primary.main,
                                fontSize: 14
                            },
                            tableCell: {
                                color: theme.palette.primary.main,
                                fontSize: 14
                            },
                            tableCellValue: {
                                color: theme.palette.primary.dark,
                                fontSize: 14
                            }
                        }
                    }}
                    data={data}
                />
            }
        </Box>
    );
};
