import { Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionUserRoleTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { UserRoleDisableIcon } from "components/Icons";

interface Props {
    userRoles: Dto.RdmsApiV1.SystemUserRole[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateRole = useApiInvocation<{ id: string}, Dto.RdmsApiV1.SystemUserRole>("put", `/v1/system/user-roles/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, role] of props.userRoles.entries()) {
                try {
                    await updateRole({ id: role.id }, { enabled: false });
                    setProgress((index + 1) / props.userRoles.length);
                } catch (error) {
                    throw new Error(`Failed to update user role ${role.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Disabling a role will make it ignored when evaluating user permissions.
            </Typography>
            <ActionUserRoleTargetList userRoles={props.userRoles} />
        </Box>
    );
};

const DisableUserRoleAction: ActionDef<Props> = {
    id: "user-role-disable",
    title: "Disable User Role",
    component: Action,
    dialog: {
        confirmButtonCaption: "Disable",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Disable",
        subtitle: "Disable role and stop using it for users",
        icon: <UserRoleDisableIcon />,
        color: "warning.main",
    }
};

export default DisableUserRoleAction;