import { Dto } from "@varos/rdm-common";
import { useHistory, useParams } from "react-router-dom";
import React, { useCallback } from "react";
import useApiResource from "hooks/useApiResource";
import { ObjectHeader } from "components/ObjectHeader";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserRoleActions } from "hooks/useUserRoleActions";
import DeleteUserRoleAction from "components/ObjectActions/UserRole/DeleteUserRoleAction";
import useObjectAction from "hooks/useObjectAction";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import DisableUserRoleAction from "components/ObjectActions/UserRole/DisableUserRoleAction";
import EnableUserRoleAction from "components/ObjectActions/UserRole/EnableUserRoleAction";
import { RequireAuthorization, SortableAuthorizationRule } from "components/Authorization";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";

export const UserRoleDetailView: React.FC<{}> = () => {
    const params = useParams<{ userRoleId?: string }>();
    const history = useHistory();

    const userRole = useApiResource<Dto.RdmsApiV1.SystemUserRole>(`/v1/system/user-roles/${params.userRoleId}`);
    const objectAuth = useObjectAuthorizationInfo(userRole.data);

    const userRoleActions = useUserRoleActions({ onUpdate: () => userRole.reload() });
    const deleteRoleAction = useObjectAction(DeleteUserRoleAction, () => history.push("/user-roles"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!userRole.data) {
            return factory;
        }
        factory
            .section(userRole.data.title)
            .if(objectAuth.hasAccess("delete")).action(DeleteUserRoleAction).priority(8900).do(() => userRole.data && deleteRoleAction({ userRoles: [userRole.data] }));
        if (userRole.data.enabled) {
            factory.if(objectAuth.hasAccess("update")).action(DisableUserRoleAction).priority(8100).do(() => userRole.data && userRoleActions.disable({ userRoles: [userRole.data] }));
        } else {
            factory.if(objectAuth.hasAccess("update")).action(EnableUserRoleAction).priority(8100).do(() => userRole.data && userRoleActions.enable({ userRoles: [userRole.data] }));
        }
        return factory;
    }, [deleteRoleAction, userRole.data, userRoleActions, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={userRole.initialOrLongLoading}
                icon="user-shield"
                title={userRole.data?.title || ""}
                subtitle="User Role"
            />
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="General" loading={userRole.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem title="ID">{userRole.data?.id}</InfoListItem>
                                <InfoListItem title="Title">{userRole.data?.title}</InfoListItem>
                                <InfoListItem title="Status" tail={userRole.data?.enabled && <Box sx={{ ml: 1, color: t=> t.palette.success.main }}><FontAwesomeIcon icon="circle-check" size="lg" /></Box>}>
                                    {userRole.data?.enabled && <Typography variant="body2" color="success.main">Enabled</Typography>}
                                    {!userRole.data?.enabled && <Typography variant="body2">Disabled</Typography>}
                                </InfoListItem>
                                <InfoListItem title="Created"><Moment fromNow>{userRole.data?.createDate}</Moment></InfoListItem>
                                <InfoListItem title="Updated"><Moment fromNow>{userRole.data?.updateDate}</Moment></InfoListItem>
                            </InfoList>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                        <DefaultCard title="Rules" loading={userRole.initialOrLongLoading} skeletonHeight={595}>
                            <Box pt={2}>
                                {userRole.data?.rules.map((rule, index) => ({ ...rule, id: `rule-${index}` })).map(rule => (
                                    <SortableAuthorizationRule variant="display" key={rule.id} rule={rule} />
                                ))}
                            </Box>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <RequireAuthorization object={userRole.data} operation="update">
                                <Button variant="outlined" onClick={() => userRole.data && userRoleActions.edit({ userRole: userRole.data })}>Modify Role</Button>
                            </RequireAuthorization>
                            <RequireAuthorization object={userRole.data} operation="delete">
                                <Button variant="outlined" color="error" onClick={() => userRole.data && deleteRoleAction({ userRoles: [userRole.data] })}>Delete Role</Button>
                            </RequireAuthorization>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};