import { Box, TextField, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { useState } from "react";
import { ActionBusinessSubjectTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { SubjectSetNoteIcon } from "components/Icons";

interface Props {
    subjects: Dto.RdmsApiV1.BusinessSubject[];
    note?: string;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateSubject = useApiInvocation<{ id: string}, Dto.RdmsApiV1.BusinessSubject>("put", `/v1/business-subjects/:id`, { silentError: true, throwError: true });
    const [note, setNote] = useState<string>(props.note || "");

    const state = useActionState({
        ...props,
        process: async setProgress => {
            for (const [index, subject] of props.subjects.entries()) {
                try {
                    await updateSubject({ id: subject.id }, { note: note || null });
                    setProgress((index + 1) / props.subjects.length);
                } catch (error) {
                    throw new Error(`Failed to update business subject ${subject.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                This action allows for update of business subject notes. Notes are auxillary information attached to subjects.
            </Typography>
            <ActionBusinessSubjectTargetList subjects={props.subjects} />
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Set a note for business subject:</Typography>
            <TextField
                autoFocus
                disabled={state.blocked}
                sx={{ width: "100%" }}
                label="Note"
                variant="outlined"
                value={note}
                onChange={evt => setNote(evt.target.value)}
                multiline
                minRows={4}
            />
        </Box>
    );
};

const SetSubjectNoteAction: ActionDef<Props> = {
    id: "subject-set-note",
    title: "Set Subject Note",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Note",
    },
    menu: {
        title: "Set note",
        subtitle: "Set management note for subject",
        icon: <SubjectSetNoteIcon />
    }
};

export default SetSubjectNoteAction;