import { ActionDef } from "components/Action";
import { Action } from "./EditUserRoleAction";

const CreateUserRoleAction: ActionDef<void> = {
    id: "user-role-create",
    title: "Create User Role",
    component: Action,
    dialog: {
        maxWidth: "md",
        confirmButtonCaption: "Create"
    },
    menu: {
        title: "Create User Role",
        subtitle: "Create a new user role"
    }
};

export default CreateUserRoleAction;
