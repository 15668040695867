import { useEffect, useState } from "react";

const suffixTable = ["k", "M", "G", "T", "P", "E"];

export const humanReadableSize = (value: number, suffix: string = "", precision: number = 2) => {
    let data = value;
    if (Math.abs(data) < 1024) {
        return `${data} ${suffix || "-"}`;
    }
    let u = -1;
    const r = 10 ** precision;
    do {
        data /= 1024;
        ++u;
    } while (Math.round(Math.abs(data) * r) / r >= 1024 && u < suffixTable.length - 1);
    return data.toFixed(precision) + " " + suffixTable[u] + suffix;
};

const useHumanReadableSize = (value: number, suffix: string = "", precision: number = 2) => {
    const [output, setOutput] = useState<string>("-");
    useEffect(() => {
        setOutput(humanReadableSize(value, suffix, precision));
    }, [value, precision, suffix]);

    return output;
};

export default useHumanReadableSize;
