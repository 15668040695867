import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemIcon, ListItemText, SxProps, Typography } from "@mui/material";

export interface InfoListItemProps {
    title: React.ReactNode;
    children?: React.ReactNode;
    stat?: string | number;
    icon?: IconProp;
    tail?: React.ReactNode;
    sx?: SxProps;
}

export const InfoListItem: React.FC<InfoListItemProps> = props => {
    return (
        <ListItem sx={props.sx} disableGutters>
            {props.icon &&
                <ListItemIcon>
                    <FontAwesomeIcon icon={props.icon} fixedWidth size="lg" />
                </ListItemIcon>
            }
            <ListItemText primary={props.title} secondary={props.children} secondaryTypographyProps={{ component: "div", sx: { wordWrap: "break-word" } }} />
            {props.stat !== undefined && <Typography variant="body1" color="text.secondary" component="p" sx={{ pl: 1 }}>{props.stat}</Typography>}
            {props.tail}
        </ListItem>
    );
};
