import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDef, ActionUserTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { UserDeleteIcon } from "components/Icons";

interface Props {
    users: Dto.RdmsApiV1.SystemUser[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const deleteUser = useApiInvocation<{ id: string}>("delete", `/v1/system/users/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, user] of props.users.entries()) {
                try {
                    await deleteUser({ id: user.id });
                    setProgress((index + 1) / props.users.length);
                } catch (error) {
                    throw new Error(`Failed to delete user ${user.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Removing user will disallow them form logging in and using the system.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="error" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                This is a destructive action that cannot be undone. Proceed with caution!
            </Alert>
            <ActionUserTargetList users={props.users} operation="deleted" />
        </Box>
    );
};

const DeleteUserAction: ActionDef<Props> = {
    id: "user-delete",
    title: "Delete User",
    component: Action,
    dialog: {
        confirmButtonCaption: "Delete",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Delete User",
        subtitle: "Remove user from system",
        icon: <UserDeleteIcon />,
        color: "error.main"
    }
};

export default DeleteUserAction;