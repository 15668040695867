import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import Api, { ApiRequest } from "../services/api.service";
import { BehaviorSubject, delay, tap } from "rxjs";
import ApiResourceCache from "services/api-resource-cache.service";

interface ApiResourceHookOpts {
    disable?: boolean;
    disableAutoLoad?: boolean;
    ignoreNotFound?: boolean;
    ignoreForbidden?: boolean;
    reloadInterval?: number;
    longLoadingThreshold?: number;
    cache?: boolean;
    cacheExpire?: number;
}

const useApiResource = <T,>(url: string, opts?: ApiResourceHookOpts) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [longLoading, setLongLoading] = useState(false);
    const activeRequest$ = useMemo(() => new BehaviorSubject<ApiRequest<T> | undefined>(undefined), []);
    const initialOrLongLoading = useMemo(() => initialLoading || longLoading, [initialLoading, longLoading]);
    const permissionDenied = useMemo(() => error && (error as any).statusCode === 403, [error]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setLongLoading(false);
        let gotResponse = false;
        try {
            if (!opts?.disable) {
                if (opts?.cache) {
                    try {
                        const cached = await ApiResourceCache.resolve<T>(`apiResource:${url}`, async () => {
                            const request = Api.invoke<T>("get", url);
                            return request.response$ as T;
                        }, opts.cacheExpire);
                        if (cached) {
                            setData(cached as T);
                            gotResponse = true;
                            return;
                        }
                    } catch (error) {
                        setData(undefined);
                        setError(error);
                        gotResponse = true;
                        return;
                    }
                }

                if (activeRequest$.value) {
                    activeRequest$.value.abort();
                }

                const request = Api.invoke<T>("get", url);
                activeRequest$.next(request);

                if (activeRequest$.value === request) {
                    const data = await request.response$;
                    setData(data);
                    gotResponse = true;
                }
            }
        } catch (error) {
            if (error.name !== "AbortError" && !(opts?.ignoreNotFound && error.statusCode === 404) && !(opts?.ignoreForbidden && error.statusCode === 403)) {
                enqueueSnackbar(`Failed to load resource: ${error.message}.`, { variant: "error" });
            }
            if (opts?.ignoreForbidden && error.statusCode === 403) {
                gotResponse = true;
            }
            setError(error);
            setData(undefined);
            if (opts?.ignoreNotFound && error.statusCode === 404) {
                setInitialLoading(false);
            }
        } finally {
            activeRequest$.next(undefined);
            setLoading(false);
            setLongLoading(false);
            if (gotResponse) {
                setInitialLoading(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opts?.disable, opts?.ignoreNotFound, activeRequest$, url, enqueueSnackbar]);

    useEffect(() => {
        if (!opts?.disableAutoLoad) {
            fetchData();
        }
    }, [fetchData, opts?.disableAutoLoad]);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null;
        if (opts?.reloadInterval) {
            interval = setInterval(fetchData, opts?.reloadInterval);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [fetchData, opts?.reloadInterval]);

    useEffect(() => {
        const subscription = activeRequest$.pipe(
            delay(opts?.longLoadingThreshold || 3000),
            tap(() => {
                if (loading) {
                    setLongLoading(true);
                }
            })
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [activeRequest$, loading, opts?.longLoadingThreshold]);

    return {
        loading,
        initialLoading,
        initialOrLongLoading,
        data,
        error,
        permissionDenied,
        reload: fetchData
    };
};
export default useApiResource;