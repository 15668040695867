import { Grid, Link } from "@mui/material";
import { Link  as RouterLink } from "react-router-dom";
import { Dto } from "@varos/rdm-common";
import useApiResource from "hooks/useApiResource";
import { DefaultCard } from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import Api from "services/api.service";
import { MetricCard } from "components/Charts";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { RequireAuthorization } from "components/Authorization";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ObjectStatMetricCard: React.FC<{ title: string, linkTo: string, loading?: boolean, value?: number, backgroundIcon?: IconProp }> = props => {
    if (!props.loading && props.value === 0) {
        return <></>;
    }
    return (
        <Grid item xs={12} md={6} lg={3}>
            <Link sx={{ textDecoration: "none" }} component={RouterLink} to={props.linkTo}>
                <MetricCard
                    direction="column"
                    title={props.title}
                    value={props.value || null}
                    loading={props.loading}
                    backgroundIcon={props.backgroundIcon}
                />
            </Link>
        </Grid>
    );
};

export const DashboardOverviewView: React.FC = () => {
    const auth = useAuthenticationInfo();

    const platformStats = useApiResource<Dto.RdmsApiV1.PlatformStats>("/v1/stats/platform", { reloadInterval: 10000 });
    const clientConnectionStats = useApiResource<Dto.RdmsApiV1.ClientConnectionStats>(`/v1/stats/client/connection?lookbehind=${24 * 3600 * 1000}&step=3600`, { disable: !auth.hasAccess("sys:system", "system:read-client-connection-stats"), reloadInterval: 10000, ignoreForbidden: true });

    return (
        <Grid container spacing={2} alignItems="stretch">
            <RequireAuthorization resource="sys:system" operation="system:read-client-connection-stats">
                <Grid item xs={12} lg={6}>
                    <MetricCard
                        title="DCM Clients"
                        description="Devices connected using DCM protocol currently and in the past 24 hours."
                        value={clientConnectionStats.data?.dcm.current || null}
                        variant="filled"
                        sparkLine
                        sparkLineData={clientConnectionStats.data?.dcm.history?.map(v => [new Date(v[0]), v[1]]) || null}
                        loading={clientConnectionStats.initialOrLongLoading}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MetricCard
                        title="VPN Clients"
                        description="Devices connected to management VPN network currently and in the past 24 hours."
                        value={clientConnectionStats.data?.vpn.current || null}
                        variant="filled"
                        sparkLine
                        sparkLineData={clientConnectionStats.data?.vpn.history?.map(v => [new Date(v[0]), v[1]]) || null}
                        loading={clientConnectionStats.initialOrLongLoading}
                    />
                </Grid>
            </RequireAuthorization>
            <ObjectStatMetricCard title="Devices" linkTo="/devices" value={platformStats.data?.databaseObjects.devices} backgroundIcon={["fal", "hdd"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="Business Subjects" linkTo="/business-subjects" value={platformStats.data?.databaseObjects.businessSubjects} backgroundIcon={["fal", "building"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="DCM Profiles" linkTo="/dcm-profiles" value={platformStats.data?.databaseObjects.dcmProfiles} backgroundIcon={["fal", "badge-check"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="RPS Profiles" linkTo="/rps-profiles" value={platformStats.data?.databaseObjects.rpsProfiles} backgroundIcon={["fal", "print-search"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="RPS Bindings" linkTo="/devices" value={platformStats.data?.databaseObjects.rpsBindings} backgroundIcon={["fal", "link-simple"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="Users" linkTo="/users" value={platformStats.data?.databaseObjects.systemUsers} backgroundIcon={["fal", "user"]} loading={platformStats.initialOrLongLoading} />
            <ObjectStatMetricCard title="User Roles" linkTo="/user-roles" value={platformStats.data?.databaseObjects.systemUserRoles} backgroundIcon={["fal", "user-shield"]} loading={platformStats.initialOrLongLoading} />
            <Grid item xs={12} lg={6}>
                <DefaultCard title="Application" skeletonHeight={230} loading={platformStats.initialOrLongLoading}>
                    <InfoList>
                        <InfoListItem icon="globe" title="RDM Portal">Version: {process.env.REACT_APP_VERSION}</InfoListItem>
                        <InfoListItem icon="server" title="RDM Server">
                            <span>URL: {Api.url}</span>
                            <br/>
                            <span>Version: {Api.serverVersion}</span>
                        </InfoListItem>
                    </InfoList>
                </DefaultCard>
            </Grid>
        </Grid>
    );
};

export default DashboardOverviewView;