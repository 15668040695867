import { Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { DcmProfileClearIcon } from "components/Icons";

interface Props {
    devices: Dto.RdmsApiV1.Device[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateDevice = useApiInvocation<{ id: string}, Dto.RdmsApiV1.Device>("put", `/v1/devices/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, device] of props.devices.entries()) {
                try {
                    await updateDevice({ id: device.id }, { dcmProfileId: null });
                    setProgress((index + 1) / props.devices.length);
                } catch (error) {
                    throw new Error(`Failed to update device ${device.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Unlinking a device DCM profile will make it use customer assigned profile or global default.
            </Typography>
            <ActionDeviceTargetList devices={props.devices} />
        </Box>
    );
};

const ClearDeviceDcmProfileAction: ActionDef<Props> = {
    id: "device-clear-dcm-profile",
    title: "Clear Device DCM Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Clear Profile",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Clear DCM profile",
        subtitle: "Remove individual DCM profile and use inherited",
        icon: <DcmProfileClearIcon />,
        color: "warning.main"
    }
};

export default ClearDeviceDcmProfileAction;