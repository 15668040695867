import { Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionBusinessSubjectTargetList, ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { RpsProfileClearIcon } from "components/Icons";

interface Props {
    subjects: Dto.RdmsApiV1.BusinessSubject[];
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const updateSubject = useApiInvocation<{ id: string}, Dto.RdmsApiV1.BusinessSubject>("put", `/v1/business-subjects/:id`, { silentError: true, throwError: true });

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            for (const [index, subject] of props.subjects.entries()) {
                try {
                    await updateSubject({ id: subject.id }, { defaultRpsProfileId: null });
                    setProgress((index + 1) / props.subjects.length);
                } catch (error) {
                    throw new Error(`Failed to update business subject ${subject.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Unlinking a subject default RPS profile will make devices use either individually configured one or global one.
            </Typography>
            <ActionBusinessSubjectTargetList subjects={props.subjects} />
        </Box>
    );
};

const ClearSubjectDefaultRpsProfileAction: ActionDef<Props> = {
    id: "subject-clear-default-rps-profile",
    title: "Clear Subject Default RPS Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Clear Profile",
        confirmButtonColor: "error",
    },
    menu: {
        title: "Clear default RPS profile",
        subtitle: "Unset default RPS profile and switch to global",
        icon: <RpsProfileClearIcon />,
        color: "warning.main"
    }
};

export default ClearSubjectDefaultRpsProfileAction;
