import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { ActionDef } from "components/Action";

interface Props {
    action: () => void;
    def?: ActionDef<any>;
    title?: string;
    subtitle?: string;
    icon?: IconProp | React.ReactNode;
    color?: string;
}
export const ActionMenuItem: React.FC<Props> = props => {

    if (!props.def && !props.title) {
        throw new Error("Invalid action menu item configuration - either def or title needs to be set.");
    } else if (props.def && !props.def?.menu) {
        throw new Error("Invalid action menu item configuration - def is missing menu config.");
    }

    const iconEl = useMemo(() => {
        if (props.def?.menu?.icon) {
            return props.def.menu.icon;
        }
        if (props.icon) {
            if (Array.isArray(props.icon) || props.icon === "string") {
                return <FontAwesomeIcon icon={props.icon as IconProp} fixedWidth />;
            } else {
                return props.icon as React.ReactNode;
            }
        }
        return undefined;
    }, [props.def, props.icon]);

    return (
        <MenuItem onClick={props.action}>
            {iconEl && (
                <ListItemIcon sx={{ color: t => props.def?.menu?.color || props.color || t.palette.primary.main }}>
                    {iconEl}
                </ListItemIcon>
            )}
            <ListItemText primary={props.def?.menu?.title || props.title} secondary={props.def?.menu?.subtitle || props.subtitle} />
        </MenuItem>
    );
}

export default ActionMenuItem;