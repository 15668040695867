import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, CircularProgress, Skeleton, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { Dto, Security } from "@varos/rdm-common";
import useApiResource from "hooks/useApiResource";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import { useState, useMemo, useEffect, KeyboardEventHandler } from "react";

type CommonProps<T> = {
    label: string;
    url: string;
    getOptionLabel: (option: T) => string;
    icon?: IconProp;
    searchLimit?: number;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    readOnly?: boolean;
    autoFocus?: boolean;
    requireAuthorization?: [Security.Authorization.ResourceName, Security.Authorization.OperationName];
    disableCloseOnSelect?: boolean;
    onKeyDown?: KeyboardEventHandler<T>;
    onLoadingChange?: (loading: boolean) => void;
    onInitialLoadingChange?: (initialLoading: boolean) => void;
};
type SingleValueProps<T> = {
    multiple?: never;
    value: T | null;
    onChange?: (value: T | null) => void;
};
type MultipleValueProps<T> = {
    multiple: true;
    value: T[] | null;
    onChange?: (value: T[]) => void;
};
type ValueProps<T> = SingleValueProps<T> | MultipleValueProps<T>;
export type ApiResourceAutocompleteProps<T> = CommonProps<T> & ValueProps<T>;
export type ApiResourceAutocompletePreparedProps<T> = (Omit<CommonProps<T>, "url" | "label" | "getOptionLabel"> & Partial<Pick<CommonProps<T>, "url" | "label" | "getOptionLabel">>) & ValueProps<T>;

export function ApiResourceAutocomplete<T extends { id: string }>(props: ApiResourceAutocompleteProps<T>): JSX.Element {
    const auth = useAuthenticationInfo();

    const [filterValue, setFilterValue] = useState<string | null>(null);
    const objects = useApiResource<Dto.RdmsApiV1.WindowedList<T>>(`${props.url}?search=${filterValue || ""}&windowed=true&limit=${props.searchLimit || 100}`, { ignoreForbidden: true });
    const [selected, setSelected] = useState<T[]>([]);
    const sanitisedObjects = useMemo(() => !selected || selected.length === 0 ? objects.data?.data : [...selected, ...(objects.data?.data.filter(p => !selected.find(s => s.id === p.id)) || [])], [objects.data?.data, selected]);
    const access = useMemo(() => !props.requireAuthorization || auth.hasAccess(props.requireAuthorization[0], props.requireAuthorization[1]), [auth, props.requireAuthorization]);

    useEffect(() => setSelected(props.value ? Array.isArray(props.value) ? props.value : [props.value] : []), [props.value]);

    const handleChange = (value: T | T[] | null) => {
        const newSelected = value ? Array.isArray(value) ? value : [value] : [];
        setSelected(newSelected);
        if (props.multiple) {
            props.onChange?.(newSelected);
        } else {
            props.onChange?.(newSelected[0]);
        }
    };

    useEffect(() => props.onLoadingChange?.(objects.loading), [objects.loading, props]);
    useEffect(() => props.onInitialLoadingChange?.(objects.initialLoading), [objects.initialLoading, props]);

    if (objects.initialLoading) {
        return <Skeleton variant="rounded" animation="wave" sx={{ height: 56, width: "100%" }} />;
    }
    
    return (
        <Autocomplete
            autoHighlight={props.autoFocus}
            disableCloseOnSelect={props.disableCloseOnSelect}
            multiple={props.multiple}
            disabled={props.disabled || !access}
            readOnly={props.readOnly}
            sx={{ width: "100%", ...props.sx }}
            options={sanitisedObjects || []}
            value={(props.multiple ? selected : (selected[0] || null)) as any}
            onChange={(_, value) => handleChange(value)}
            onKeyDown={props.onKeyDown as any}
            getOptionLabel={props.getOptionLabel}
            renderOption={(roProps, option) => (
                <Box component="li" {...roProps}>
                    <Stack direction="row">
                        {props.icon && <FontAwesomeIcon icon={props.icon} fixedWidth />}
                        <Typography sx={{ ml: .5 }}>{props.getOptionLabel(option)}</Typography>
                    </Stack>
                </Box>
            )}
            renderInput={params =>
                <TextField
                    {...params}
                    autoFocus={props.autoFocus}
                    label={access ? (props.label || "Select object") : "Permission Denied"}
                    onChange={evt => setFilterValue(evt.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                        endAdornment: (
                            <>
                                {objects.loading ? <CircularProgress size={20} /> : null}
                                {!props.readOnly ? params.InputProps.endAdornment : undefined}
                            </>
                        ),
                    }}
                />
            }
        />
    );
}