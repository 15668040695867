import { createTheme } from "@mui/material";
import { deepPurple, grey, lightBlue } from "@mui/material/colors";
import { BehaviorSubject } from "rxjs";

const primaryThemeColor = lightBlue;
const primaryChartColors = [
    primaryThemeColor[500],
    primaryThemeColor[600],
    primaryThemeColor[700],
    primaryThemeColor[800],
    primaryThemeColor[200],
    primaryThemeColor[300],
    primaryThemeColor[400],
];

const secondaryThemeColor = deepPurple;
const secondaryChartColors = [
    secondaryThemeColor[400],
    secondaryThemeColor[500],
    secondaryThemeColor[600],
    secondaryThemeColor[700],
    secondaryThemeColor[800],
    secondaryThemeColor[200],
    secondaryThemeColor[300],
];
export const lightTheme = Object.assign(createTheme({
    palette: {
        mode: "light",
        primary: {
            main: primaryThemeColor[800]
        },
        secondary: {
            main: deepPurple[600]
        }
    }
}), {
    chartColors: {
        primary: primaryChartColors,
        secondary: secondaryChartColors,
        all: [...primaryChartColors, ...secondaryChartColors]
    }
});

export const darkTheme = Object.assign(createTheme({
    palette: {
        mode: "dark",
        background: {
            paper: grey[900]
        },
        primary: {
            main: lightBlue[400]
        },
        secondary: {
            main: deepPurple[300]
        }
    }
}), {
    chartColors: {
        primary: primaryChartColors,
        secondary: secondaryChartColors,
        all: [...primaryChartColors, ...secondaryChartColors]
    }
});

export const currentTheme$ = new BehaviorSubject(window.localStorage.getItem("rdm-portal.theme") === "dark" ? darkTheme : lightTheme);
currentTheme$.subscribe(theme => window.localStorage.setItem("rdm-portal.theme", theme === darkTheme ? "dark" : "light"));
