import { faDiceD20 } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { MainMenuEnvironmentNoticeBox } from "./MainMenuEnvironmentNoticeBox";
import { BreadcrumbConfig } from "./Breadcrumb";
import { Breadcrumbs } from "./Breadcrumbs";
import { MainMenuSearchBox } from "./MainMenuSearchBox";

export const MainMenu: React.FC<{ breadcrumbs?: BreadcrumbConfig[], onOpenChange?: (open: boolean) => void, children?: React.ReactNode[] }> = props => {
    const theme = useTheme();
    const location = useLocation();
    const shouldMenuBePermanent = useMediaQuery(theme.breakpoints.up("md"));
    
    const [isManuallyOpen, setIsManuallyOpen] = useState(false);
    const menuOpen = useMemo(() => shouldMenuBePermanent || isManuallyOpen, [shouldMenuBePermanent, isManuallyOpen]);

    useEffect(() => props.onOpenChange?.(menuOpen), [menuOpen, props]);
    useEffect(() => { !shouldMenuBePermanent && setIsManuallyOpen(false); }, [shouldMenuBePermanent]);
    useEffect(() => { location.pathname && setIsManuallyOpen(false); }, [location.pathname]);

    return (
        <>
            <Drawer
                variant={shouldMenuBePermanent ? "permanent" : "temporary"}
                onClose={() => setIsManuallyOpen(false)}
                open={menuOpen}
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        background: t => t.palette.background.paper,
                        width: "260px"
                    },
                }}
            >
                <Box sx={{ p: t => t.spacing(0, 2), minHeight: "64px", display: "flex", alignItems: "center", justifyContent: "center", color: t => t.palette.primary.main }}>
                    <FontAwesomeIcon icon={faDiceD20} size="2x" />
                    <Typography component="h1" variant="h5" sx={{ ml: 1, fontWeight: 300 }}>Varos RDM</Typography>
                </Box>
                <MainMenuEnvironmentNoticeBox />
                <MainMenuSearchBox onOpen={() => setIsManuallyOpen(false)} />
                {props.children}
            </Drawer>
            <Box sx={{ flex: "0 0 auto", minHeight: "64px", display: "flex", alignItems: "center", pt: 1, pb: 1 }}>
                {!shouldMenuBePermanent &&
                    <IconButton sx={{ mr: t => t.spacing(1) }} onClick={() => setIsManuallyOpen(v => !v)}>
                        <Menu />
                    </IconButton>
                }
                <Breadcrumbs maxItems={shouldMenuBePermanent ? undefined : 3} breadcrumbs={props.breadcrumbs} />
            </Box>
        </>
    );
};
