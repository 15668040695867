import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, AccordionDetails, Stack, useTheme, useMediaQuery, Fade } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    title: string;
    description?: React.ReactNode;
    icon?: IconProp;
    children: React.ReactNode;
    expanded?: boolean;
    disabled?: boolean;
    onChange?: (expanded: boolean) => void;
}

export const FormAccordion: React.FC<Props> = props => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [titleWidth, setTitleWidth] = useState<string | undefined>("40%");
    const [descriptionVisible, setDescriptionVisible] = useState(true);
    useEffect(() => {
        if (smallScreen) {
            setTitleWidth("40%");
            setTimeout(() => setDescriptionVisible(true), 100);
        } else {
            setTimeout(() => setTitleWidth(undefined), 300);
            setDescriptionVisible(false);
        }
    }, [smallScreen]);

    return (
        <Accordion
            elevation={0}
            sx={{
                backgroundColor: "inherit",
                "> .MuiAccordionSummary-root": {
                    transition: "border-radius 300ms ease, background 300ms ease"
                },
                "&.Mui-expanded > .MuiAccordionSummary-root": {
                    backgroundColor: t => t.palette.mode === "light" ? t.palette.grey[200] : t.palette.background.paper,
                    borderRadius: t => t.shape.borderRadius
                }
            }}
            expanded={props.expanded}
            disabled={props.disabled}
            onChange={(_, state) => props.onChange?.(state)}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack direction="row" width={titleWidth} flexShrink={0} alignItems="center" sx={{ color: t => t.palette.primary.main }}>
                    {props.icon && <FontAwesomeIcon icon={props.icon} fixedWidth size="lg" />}
                    <Typography sx={{ ml: props.icon ? 1 : undefined }} fontWeight={600}>{props.title}</Typography>
                </Stack>
                {props.description &&
                    <Fade in={descriptionVisible} timeout={300}>
                        <Typography component="div" sx={{ color: "text.secondary" }}>
                            {props.description}
                        </Typography>
                    </Fade>
                }
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 2 }}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
};