import { useTheme } from "@mui/material";
import { StatusLine, StatusLineProps } from "./StatusLine";

export type ConnectionStatusLineProps = { status: "connected" | "offline" } & Pick<StatusLineProps, "chips" | "loading" | "sx">
export const ConnectionStatusLine: React.FC<ConnectionStatusLineProps> = props => {
    const theme = useTheme();

    return (
        <StatusLine
            icon={props.status === "connected" ? "circle" : "circle-dashed"}
            color={props.status === "connected"  ? theme.palette.success.main : theme.palette.text.secondary}
            pulse={props.status === "connected"}
            title={props.status === "connected" ? "Online" : "Offline"}
            {...props}
        />
    );
};
