import { SxProps } from "@mui/material";
import {Box} from "@mui/system";

const Center: React.FC<{sx?: SxProps, children: React.ReactNode}> = props => {
    return (
        <Box 
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                ...props.sx
            }}
        >
            {props.children}
        </Box>
    );
};

export default Center;