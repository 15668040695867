import { Alert, AlertTitle, Box, Collapse, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { useEffect, useState } from "react";
import { RpsProfileAutocomplete } from "components/Autocomplete";
import useApiResource from "hooks/useApiResource";
import useConfirmDialog from "hooks/useConfirmDialog";
import { firstValueFrom } from "rxjs";
import { RpsProfileSetGlobalIcon } from "components/Icons";

interface Props {
    profile?: Dto.RdmsApiV1.RpsProfile;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const confirm = useConfirmDialog();

    const rpsProfiles = useApiResource<Dto.RdmsApiV1.RpsProfile[]>("/v1/rps/profiles");
    const updateProfile = useApiInvocation<{ id: string}, Dto.RdmsApiV1.RpsProfile>("put", `/v1/rps/profiles/:id`, { silentError: true, throwError: true });
    const [loading, setLoading] = useState(true);

    const [profile, setProfile] = useState<Dto.RdmsApiV1.RpsProfile | null>(null);
    useEffect(() => setProfile(props.profile || null), [props.profile]);

    const state = useActionState({
        ...props,
        loading: loading || rpsProfiles.loading,
        animationDelay: 500,
        process: async () => {
            let target: Dto.RdmsApiV1.RpsProfile | null = profile;
            let setGlobalDefault = true;
            if (!target) {
                for (const p of rpsProfiles?.data || []) {
                    if (p.isGlobalDefault) {
                        target = p;
                        setGlobalDefault = false;
                    }
                }
                const confirmation = await firstValueFrom(
                    confirm({
                        title: "Clear global default RPS profile?",
                        text: "Removing default RPS profile will make all customers using default RPS profile unable to use remote printing service. Only do this if you know what you are doing. Are you sure?"
                    })
                );
                if (!confirmation) {
                    throw new Error("Action canceled by user.");
                }
            }
            if (target) {
                try {
                    await updateProfile({ id: target.id }, { isGlobalDefault: setGlobalDefault });
                } catch (error) {
                    throw new Error(`Failed to set global RPS profile ${target.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Global RPS profile determines which profile to use for devices that have no direct or customer-inherited RPS profile configuration.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Changing default global RPS profile may affect many devices and break real-time production operations. Please, proceed with caution!
            </Alert>
            <Collapse in={!profile}>
                <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                    <AlertTitle>No profile selected!</AlertTitle>
                    If no profile is selected, submitting this action will unset current global default profile and therefore remove the profile from all devices without specific configuration.
                </Alert>
            </Collapse>
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose RPS profile to use as global default:</Typography>
            <RpsProfileAutocomplete disabled={state.blocked} value={profile} onChange={setProfile} onInitialLoadingChange={setLoading} />
        </Box>
    );
};

const SetGlobalDefaultRpsProfileAction: ActionDef<Props> = {
    id: "rps-profile-set-global-default",
    title: "Set Global Default RPS Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Global Default",
        confirmButtonColor: "warning",
    },
    menu: {
        title: "Set Global Default RPS Profile",
        subtitle: "Configure profile to be used for all devices by default",
        icon: <RpsProfileSetGlobalIcon />
    }
};

export default SetGlobalDefaultRpsProfileAction;
