import { Dto } from "@varos/rdm-common";
import { useHistory, useParams } from "react-router-dom";
import React, { useCallback } from "react";
import useApiResource from "hooks/useApiResource";
import { ObjectHeader } from "components/ObjectHeader";
import { Box, Button, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography, useTheme } from "@mui/material";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem, InfoListStatusItem } from "components/InfoList";
import Moment from "react-moment";
import { Clear, Settings } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DcmProfileUpdateProfileCard from "./DcmProfileUpdateProfileCard";
import { useDcmProfileActions } from "hooks/useDcmProfileActions";
import DeleteDcmProfileAction from "components/ObjectActions/DcmProfile/DeleteDcmProfileAction";
import useObjectAction from "hooks/useObjectAction";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import SetGlobalDefaultDcmProfileAction from "components/ObjectActions/DcmProfile/SetGlobalDefaultDcmProfileAction";
import EditDcmProfileAction from "components/ObjectActions/DcmProfile/EditDcmProfileAction";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import { RequireAuthorization } from "components/Authorization";

const DcmProfileDetailView: React.FC<{}> = () => {
    const theme = useTheme();
    const history = useHistory();
    const params = useParams<{ dcmProfileId?: string }>();

    const dcmProfile = useApiResource<Dto.RdmsApiV1.DcmProfile>(`/v1/dcm-profiles/${params.dcmProfileId}`);
    const objectAuth = useObjectAuthorizationInfo(dcmProfile.data);

    const dcmProfileActions = useDcmProfileActions({ onUpdate: () => dcmProfile.reload() });
    const deleteProfileAction = useObjectAction(DeleteDcmProfileAction, () => history.push("/dcm-profiles"));

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!dcmProfile.data) {
            return factory;
        }
        return factory
            .section(dcmProfile.data.title)
            .if(objectAuth.hasAccess("update")).action(EditDcmProfileAction).priority(8100).do(() => dcmProfileActions.edit({ profile: dcmProfile.data }))
            .if(objectAuth.hasAccess("update")).action(SetGlobalDefaultDcmProfileAction).priority(8100).do(() => dcmProfileActions.setGlobalDefault({ profile: dcmProfile.data }))
            .if(objectAuth.hasAccess("delete")).action(DeleteDcmProfileAction).priority(8900).do(() => dcmProfile.data && deleteProfileAction({ profiles: [dcmProfile.data] }));
    }, [dcmProfile.data, dcmProfileActions, deleteProfileAction, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            <ObjectHeader
                loading={dcmProfile.initialOrLongLoading}
                icon="badge-check"
                title={dcmProfile.data?.title || ""}
                subtitle="Device Configuration &amp; Management Client Profile"
            />
            <Box sx={{ pt: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="General" loading={dcmProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem title="ID">{dcmProfile.data?.id}</InfoListItem>
                                <InfoListItem title="Title">{dcmProfile.data?.title}</InfoListItem>
                                <InfoListItem
                                    title="Global Default"
                                    tail={
                                        objectAuth.hasAccess("update") &&
                                        <IconButton sx={{ ml: 1 }} onClick={() => dcmProfile.data && dcmProfileActions.setGlobalDefault({ profile: dcmProfile.data.isGlobalDefault ? undefined : dcmProfile.data })}>
                                            {!dcmProfile.data?.isGlobalDefault && <Settings />}
                                            {dcmProfile.data?.isGlobalDefault && <Clear />}
                                        </IconButton>
                                    }
                                >
                                    {dcmProfile.data?.isGlobalDefault && (
                                        <Stack>
                                            <Stack direction="row" alignItems="center" sx={{ color: t => t.palette.primary.main }}>
                                                <FontAwesomeIcon icon={["fas", "globe"]} fixedWidth />
                                                <Typography variant="body2" sx={{ ml: .5 }}>Configured as global default</Typography>
                                            </Stack>
                                            <Typography variant="body2">
                                                This profile will be applied to all devices without direct or customer set DCM profile.
                                            </Typography>
                                        </Stack>
                                    )}
                                    {!dcmProfile.data?.isGlobalDefault && "-"}
                                </InfoListItem>
                                <InfoListItem title="Created"><Moment fromNow>{dcmProfile.data?.createDate}</Moment></InfoListItem>
                                <InfoListItem title="Updated"><Moment fromNow>{dcmProfile.data?.updateDate}</Moment></InfoListItem>
                            </InfoList>
                            <Box flex="1" />
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="Platform Connection" loading={dcmProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListItem title="DCM Endpoints">
                                    {(dcmProfile.data?.dcmAddresses.length || 0) > 0 &&
                                        <Paper elevation={0} sx={{ maxHeight: `calc(80px * 5)`, overflowY: "auto"}}>
                                            <List dense>
                                                {dcmProfile.data?.dcmAddresses.map((endpoint, index) =>
                                                    <ListItemButton key={index}>
                                                        <ListItemIcon sx={{ minWidth: "36px" }}>
                                                            <FontAwesomeIcon icon={["fas", "cloud"]} fixedWidth />
                                                        </ListItemIcon>
                                                        <ListItemText primary={endpoint} />
                                                    </ListItemButton>
                                                )}
                                            </List>
                                        </Paper>
                                    }
                                    {(dcmProfile.data?.dcmAddresses.length || 0) <= 0 && "Not configured. Only fallback endpoints will be used."}
                                </InfoListItem>
                                <InfoListItem title="VPN Gateways">
                                    {(dcmProfile.data?.vpnGatewayAddresses.length || 0) > 0 &&
                                        <Paper elevation={0} sx={{ maxHeight: `calc(80px * 5)`, overflowY: "auto"}}>
                                            <List dense>
                                                {dcmProfile.data?.vpnGatewayAddresses.map((endpoint, index) =>
                                                    <ListItemButton key={index}>
                                                        <ListItemIcon sx={{ minWidth: "36px" }}>
                                                            <FontAwesomeIcon icon={["fas", "lock"]} fixedWidth />
                                                        </ListItemIcon>
                                                        <ListItemText primary={endpoint} />
                                                    </ListItemButton>
                                                )}
                                            </List>
                                        </Paper>
                                    }
                                    {(dcmProfile.data?.dcmAddresses.length || 0) <= 0 && "Not configured. Only fallback endpoints will be used."}
                                </InfoListItem>
                            </InfoList>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultCard title="Info Reporting" loading={dcmProfile.initialOrLongLoading} skeletonHeight={595}>
                            <InfoList>
                                <InfoListStatusItem
                                    title="Report Device Info"
                                    text={dcmProfile.data?.infoReportingEnabled ? "Enabled" : "Disabled"}
                                    color={dcmProfile.data?.infoReportingEnabled ? theme.palette.success.main : undefined}
                                    icon={dcmProfile.data?.infoReportingEnabled ? "circle-check" : undefined}
                                    subtext={dcmProfile.data?.infoReportingEnabled ? "Device will periodically report basse system and health information." : undefined}
                                />
                                <InfoListStatusItem
                                    title="Report Fiscal Storage Info"
                                    text={dcmProfile.data?.fiscalStorageInfoReportingEnabled ? "Enabled" : "Disabled"}
                                    color={dcmProfile.data?.fiscalStorageInfoReportingEnabled ? theme.palette.success.main : undefined}
                                    icon={dcmProfile.data?.fiscalStorageInfoReportingEnabled ? "circle-check" : undefined}
                                    subtext={dcmProfile.data?.fiscalStorageInfoReportingEnabled ? "If possible, device will include fiscal storage information in reports." : undefined}
                                />
                                <InfoListStatusItem
                                    title="Report Printer Info"
                                    text={dcmProfile.data?.printerInfoReportingEnabled ? "Enabled" : "Disabled"}
                                    color={dcmProfile.data?.printerInfoReportingEnabled ? theme.palette.success.main : undefined}
                                    icon={dcmProfile.data?.printerInfoReportingEnabled ? "circle-check" : undefined}
                                    subtext={dcmProfile.data?.printerInfoReportingEnabled ? "If possible, device will include printer information in reports." : undefined}
                                />
                                <InfoListItem title="Reporting Interval">
                                    <Moment utc format="HH:mm:ss">{dcmProfile.data?.infoReportingInterval}</Moment>
                                </InfoListItem>
                                <InfoListStatusItem
                                    title="PPEKK API Gateway"
                                    text={dcmProfile.data?.ppekkApiGatewayEnabled ? "Enabled" : "Disabled"}
                                    color={dcmProfile.data?.ppekkApiGatewayEnabled ? theme.palette.success.main : undefined}
                                    icon={dcmProfile.data?.ppekkApiGatewayEnabled ? "circle-check" : undefined}
                                    subtext={dcmProfile.data?.ppekkApiGatewayEnabled ? "Device will attempt to gain access to PPEKK API for information scraping." : undefined}
                                />
                                <InfoListStatusItem
                                    title="PPEKK Vault Extensions Injection"
                                    text={dcmProfile.data?.ppekkApiVaultExtensionsInjectionEnabled ? "Enabled" : "Disabled"}
                                    color={dcmProfile.data?.ppekkApiVaultExtensionsInjectionEnabled ? theme.palette.warning.main : undefined}
                                    icon={dcmProfile.data?.ppekkApiVaultExtensionsInjectionEnabled ? "exclamation-triangle" : undefined}
                                    subtext={dcmProfile.data?.ppekkApiVaultExtensionsInjectionEnabled ? "RDM Agent will attempt to patch supported PPEKK API application components to gain access to fiscal storage vault." : undefined}
                                />
                            </InfoList>
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DcmProfileUpdateProfileCard title="RDM Agent Updates" loading={dcmProfile.initialOrLongLoading} defaultBaseUrl="https://update.varos.sk/varos-rdma/app-dist" updateProfile={dcmProfile.data?.updateProfiles.find(p => p.type === "rdma")} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DcmProfileUpdateProfileCard title="EFT5000 App Updates" loading={dcmProfile.initialOrLongLoading} defaultBaseUrl="https://update.varos.sk/eft5000b/app-dist" updateProfile={dcmProfile.data?.updateProfiles.find(p => p.type === "app")} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DcmProfileUpdateProfileCard title="EFT5000 Environment Updates" loading={dcmProfile.initialOrLongLoading} defaultBaseUrl="https://update.varos.sk/eft5000-env-orangepipcplus/app-dist" updateProfile={dcmProfile.data?.updateProfiles.find(p => p.type === "environment")} />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <RequireAuthorization object={dcmProfile.data} operation="update">
                                <Button variant="outlined" onClick={() => dcmProfile.data && dcmProfileActions.edit({ profile: dcmProfile.data })}>Modify Profile</Button>
                            </RequireAuthorization>
                            <RequireAuthorization object={dcmProfile.data} operation="delete">
                                <Button variant="outlined" color="error" onClick={() => dcmProfile.data && deleteProfileAction({ profiles: [dcmProfile.data] })}>Delete Profile</Button>
                            </RequireAuthorization>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default DcmProfileDetailView;
