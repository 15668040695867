import { Security } from "@varos/rdm-common";
import { useMemo } from "react";

export const useObjectAuthorizationInfo = (object?: { _meta?: { currentAuthorization?: Security.Authorization.UserObjectAuthorizationInfo } }) => {
    const info = useMemo(() => object?._meta?.currentAuthorization, [object?._meta?.currentAuthorization]);

    return {
        info,
        hasAccess: (operation: Security.Authorization.OperationName) => info?.operations?.includes(operation) || false
    };
};