import { Box, Fade, Skeleton, useTheme } from "@mui/material";
import QRCodeStyling from "qr-code-styling";
import { useMemo, useEffect, useRef } from "react";

export interface QrCodeProps {
    data: string;
    loading?: boolean;
    width?: number;
    height?: number;
    color?: string;
    bgColor?: string;
}

export const QrCode: React.FC<QrCodeProps> = props => {
    const theme = useTheme();
    const qrCodeContainerRef = useRef<HTMLElement>(null);

    const qrCode = useMemo(() => new QRCodeStyling({
        width: props.width || 128,
        height: props.height || 128,
        type: "svg",
        data: "",
        qrOptions: {
            errorCorrectionLevel: "M"
        },
        shape: "square",
        dotsOptions: {
            color: props.color || theme.palette.text.primary,
            type: "dots"
        },
        cornersDotOptions: {
            type: "dot"
        },
        cornersSquareOptions: {
            type: "extra-rounded"
        },
        backgroundOptions: {
            color: props.bgColor || theme.palette.background.default
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
        }
    }), [props.bgColor, props.color, props.height, props.width, theme.palette.background.default, theme.palette.text.primary]);

    useEffect(() => {
        if (!props.data || !qrCodeContainerRef.current) {
            return;
        }
        qrCode.update({ data: props.data });
        if (qrCodeContainerRef.current.children.length > 0) {
            qrCodeContainerRef.current.removeChild(qrCodeContainerRef.current.children[0]);
        }
        qrCode.append(qrCodeContainerRef.current);
    }, [props.loading, props.data, props.width, props.height, props.color, qrCode]);

    if (props.loading) {
        return <Skeleton variant="rounded" width={props.width || 128} height={props.height || 128} />
    }

    return (
        <Fade in={true} mountOnEnter unmountOnExit>
            <Box sx={{ flex: 0, display: { xs: "none", sm: "block" } }} ref={qrCodeContainerRef} />
        </Fade>
    );
};