import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Zoom, useMediaQuery, useTheme, ThemeProvider } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { of, Subject } from "rxjs";
import { take } from "rxjs/operators";

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => <Zoom ref={ref} {...props} />);

const confirmDialogRoot = ReactDOM.createRoot(document.getElementById("confirmDialogRoot") as HTMLElement)

const useConfirmDialog = () => {
    const [title, setTitle] = useState("Are you sure?")
    const [text, setText] = useState("Please confirm the requested action.");
    const [cancelText, setCancelText] = useState("Cancel");
    const [confirmText, setConfirmText] = useState("Confirm");
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const close$ = useMemo(() => new Subject<boolean>(), []);
    const handleClose = useCallback((response: boolean = false) => {
        setOpen(false);
        close$.next(response);
    }, [close$]);

    useEffect(() => {
        confirmDialogRoot.render(
            <ThemeProvider theme={theme}>
                <Dialog TransitionComponent={Transition} fullScreen={fullScreen} open={open} onClose={() => handleClose()}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary">{cancelText}</Button>
                        <Button onClick={() => handleClose(true)} color="primary" autoFocus>{confirmText}</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        );
    }, [title, text, cancelText, confirmText, open, handleClose, fullScreen, theme]);

    const showFunction = (config: { title: string, text: string, confirmText?: string, cancelText?: string }) => {
        if (open) {
            return of(false);
        }
        setTitle(config.title);
        setText(config.text);
        setConfirmText(config.confirmText || "Confirm");
        setCancelText(config.cancelText || "Cancel");
        setOpen(true);

        return close$.pipe(take(1));
    };

    return showFunction;
};

export default useConfirmDialog;
