import { KBarResults, useKBar, useMatches } from "kbar";
import { CommandBarResultItem } from "./CommandBarResultItem";
import { useMemo } from "react";

const groupNameStyle = {
    padding: "8px 16px",
    fontSize: "10px",
    textTransform: "uppercase" as const,
    opacity: 0.5,
};

export const CommandBarResults: React.FC = props => {
    const { actions } = useKBar(state => ({ actions: state.actions }));
    const { results, rootActionId } = useMatches();

    const sanitisedResults = useMemo(() => {
        const resultsToRemove = [];
        for (const result of results) {
            if (typeof result !== "string" && result.id.startsWith("search-group-")) {
                resultsToRemove.push(result);
            }
        }
        for (const result of resultsToRemove) {
            results.splice(results.indexOf(result), 1);
        }

        const searchResultsIndex = results.indexOf("Search Results");
        const actionsToAppend = Object.keys(actions).filter(k => k.startsWith("search-result-") && !results.find(r => typeof r !== "string" && r.id === k)).map(k => actions[k]);

        if (searchResultsIndex === -1) {
            if (actionsToAppend.length > 0) {
                results.push("Search Results", ...actionsToAppend);
            }
        } else {
            results.splice(searchResultsIndex + 1, 0, ...actionsToAppend);
        }
        
        return results;
    }, [actions, results]);

    return (
        <KBarResults
            items={sanitisedResults}
            onRender={({ item, active }) =>
                typeof item === "string" ? (
                    <div style={groupNameStyle}>{item}</div>
                ) : (
                    <CommandBarResultItem action={item} active={active} currentRootActionId={rootActionId} />
                )
            }
        />
    );
};