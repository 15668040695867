import CreateRpsProfileAction from "components/ObjectActions/RpsProfile/CreateRpsProfileAction";
import useObjectAction from "./useObjectAction";
import EditRpsProfileAction from "components/ObjectActions/RpsProfile/EditRpsProfileAction";
import DeleteRpsProfileAction from "components/ObjectActions/RpsProfile/DeleteRpsProfileAction";
import SetGlobalDefaultRpsProfileAction from "components/ObjectActions/RpsProfile/SetGlobalDefaultRpsProfileAction";

export const useRpsProfileActions = (opts: { onUpdate?: () => void }) => {
    const create = useObjectAction(CreateRpsProfileAction, opts.onUpdate);
    const edit = useObjectAction(EditRpsProfileAction, opts.onUpdate);
    const deleteProfile = useObjectAction(DeleteRpsProfileAction, opts.onUpdate);
    const setGlobalDefault = useObjectAction(SetGlobalDefaultRpsProfileAction, opts.onUpdate);

    return {
        create,
        edit,
        deleteProfile,
        setGlobalDefault
    };
};
