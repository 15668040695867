import { Alert, AlertTitle, Box, Collapse, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionDef } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { useEffect, useState } from "react";
import { DcmProfileAutocomplete } from "components/Autocomplete";
import useApiResource from "hooks/useApiResource";
import useConfirmDialog from "hooks/useConfirmDialog";
import { firstValueFrom } from "rxjs";
import { DcmProfileSetGlobalIcon } from "components/Icons";

interface Props {
    profile?: Dto.RdmsApiV1.DcmProfile;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const confirm = useConfirmDialog();

    const dcmProfiles = useApiResource<Dto.RdmsApiV1.DcmProfile[]>("/v1/dcm-profiles");
    const updateProfile = useApiInvocation<{ id: string}, Dto.RdmsApiV1.DcmProfile>("put", `/v1/dcm-profiles/:id`, { silentError: true, throwError: true });
    const [loading, setLoading] = useState(true);

    const [profile, setProfile] = useState<Dto.RdmsApiV1.DcmProfile | null>(null);
    useEffect(() => setProfile(props.profile || null), [props.profile]);

    const state = useActionState({
        ...props,
        loading: loading || dcmProfiles.loading,
        animationDelay: 500,
        process: async () => {
            let target: Dto.RdmsApiV1.DcmProfile | null = profile;
            let setGlobalDefault = true;
            if (!target) {
                for (const p of dcmProfiles?.data || []) {
                    if (p.isGlobalDefault) {
                        target = p;
                        setGlobalDefault = false;
                    }
                }
                const confirmation = await firstValueFrom(
                    confirm({
                        title: "Clear global default DCM profile?",
                        text: "Removing default DCM profile will disconnect all devices using configured default global DCM profile. Devices will not report stats and will be unable to use any platform services. Are you sure?"
                    })
                );
                if (!confirmation) {
                    throw new Error("Action canceled by user.");
                }
            }
            if (target) {
                try {
                    await updateProfile({ id: target.id }, { isGlobalDefault: setGlobalDefault });
                } catch (error) {
                    throw new Error(`Failed to set global DCM profile ${target.id} - ${error.message}`);
                }
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Global default DCM profile is applied to all devices without device-specific or customer-default profile.
                DCM profiles manage the way devices interact with Varos RDM platform.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Changing default global DCM profile may affect many devices and break real-time production operations. Please, proceed with caution!
            </Alert>
            <Collapse in={!profile}>
                <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                    <AlertTitle>No profile selected!</AlertTitle>
                    If no profile is selected, submitting this action will unset current global default profile and therefore remove the profile from all devices without specific configuration.
                </Alert>
            </Collapse>
            <Typography sx={{ m: t => t.spacing(2, 0) }}>Choose DCM profile to use as global default:</Typography>
            <DcmProfileAutocomplete disabled={state.blocked} value={profile} onChange={setProfile} onInitialLoadingChange={setLoading} />
        </Box>
    );
};

const SetGlobalDefaultDcmProfileAction: ActionDef<Props> = {
    id: "dcm-profile-set-global-default",
    title: "Set Global Default DCM Profile",
    component: Action,
    dialog: {
        confirmButtonCaption: "Set Global Default",
        confirmButtonColor: "warning",
    },
    menu: {
        title: "Set Global Default DCM Profile",
        subtitle: "Choose profile to use for devices by default",
        icon: <DcmProfileSetGlobalIcon />
    }
};

export default SetGlobalDefaultDcmProfileAction;
