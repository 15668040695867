import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Button, Box, Menu } from "@mui/material";
import { useState } from "react";

interface Props {
    selectedIds: string[];
}
export const DataGridSelectActionButton: React.FC<Props & React.PropsWithChildren> = props => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <>
            <Collapse in={props.selectedIds.length > 0} sx={{ "& .MuiCollapse-wrapperInner": { display: "flex" } }} orientation="horizontal" mountOnEnter unmountOnExit>
                <Button variant="contained" onClick={evt => setMenuAnchorEl(evt.currentTarget)} disableElevation sx={{ whiteSpace: "nowrap" }}>
                    {props.selectedIds.length} selected
                    <Box sx={{ ml: 1 }}>
                        <FontAwesomeIcon icon={["fas", "caret-down"]} size="lg" />
                    </Box>
                </Button>
            </Collapse>
            <Menu
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                onClick={() => setMenuAnchorEl(null)}
                onClose={() => setMenuAnchorEl(null)}
                sx={{
                    "& .MuiDivider-root:first-child/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */, & .MuiDivider-root:last-child": {
                        display: "none"
                    }
                }}
            >
                {props.children}
            </Menu>
        </>
    );
};

export default DataGridSelectActionButton;