import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, TextField, useTheme, Divider, Tooltip, Stack, Chip } from "@mui/material";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { Dto } from "@varos/rdm-common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Subject, debounceTime, distinctUntilChanged, map, tap } from "rxjs";
import useApiResource from "../hooks/useApiResource";
import { DataGrid, DataGridColDef, DataGridSelectActionButton } from "./DataGrid";
import ObjectIndexActionBar from "./ObjectIndexActionBar";
import DefaultContainer from "./DefaultContainer";
import Heading from "./Heading";
import { ActionMenuItem } from "components/Action";
import DeviceStatusIcons from "./DeviceStatusIcons";
import ObjectReference from "./ObjectReference";
import { useDeviceActions } from "hooks/useDeviceActions";
import EnableDeviceAction from "./ObjectActions/Device/EnableDeviceAction";
import DisableDeviceAction from "./ObjectActions/Device/DisableDeviceAction";
import SetDeviceCustomerAction from "./ObjectActions/Device/SetDeviceCustomerAction";
import SetDeviceServicePartnerAction from "./ObjectActions/Device/SetDeviceServicePartnerAction";
import SetDeviceDcmProfileAction from "./ObjectActions/Device/SetDeviceDcmProfileAction";
import SetDeviceRpsProfileAction from "./ObjectActions/Device/SetDeviceRpsProfileAction";
import ClearDeviceCustomerAction from "./ObjectActions/Device/ClearDeviceCustomerAction";
import ClearDeviceServicePartnerAction from "./ObjectActions/Device/ClearDeviceServicePartnerAction";
import ClearDeviceDcmProfileAction from "./ObjectActions/Device/ClearDeviceDcmProfileAction";
import ClearDeviceRpsProfileAction from "./ObjectActions/Device/ClearDeviceRpsProfileAction";
import { CommandBarActionFactory, useCommandBarActions } from "./CommandBar";
import { useDeviceTypes } from "hooks/useDeviceTypes";
import { useAuthenticationInfo } from "hooks/useAuthenticationInfo";
import GetDeviceRecoveryRdmaConfigAction from "./ObjectActions/Device/GetDeviceRecoveryRdmaConfigAction";
import { RequireAuthorization } from "./Authorization";

interface ComponentProps {
    customerBusinessSubjectId?: string;
    linkPrefix?: string;
    disableHeading?: boolean;
}
const DeviceGrid: React.FC<ComponentProps> = props => {
    const theme = useTheme();
    const auth = useAuthenticationInfo();

    const filterValueUpdate$ = useMemo(() => new Subject<string>(), []);
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "shortId", sort: "asc" }]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 100 });

    const devicesUrl = useMemo(() => {
        const sort = sortModel.length > 0 ? `&sortBy=${sortModel[0].field}&sortDir=${sortModel[0].sort}` : "";
        const businessSubject = props.customerBusinessSubjectId ? `/business-subjects/${props.customerBusinessSubjectId}` : "";
        return `/v1${businessSubject}/devices?status=true&search=${filterValue}&windowed=true&limit=${paginationModel.pageSize}&offset=${paginationModel.pageSize * paginationModel.page}${sort}`;
    }, [filterValue, paginationModel.page, paginationModel.pageSize, props.customerBusinessSubjectId, sortModel]);

    const devices = useApiResource<Dto.RdmsApiV1.WindowedList<Dto.RdmsApiV1.Device>>(devicesUrl);
    const deviceTypes = useDeviceTypes();

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const selectedObjects = useMemo(() => selectedIds.map(id => devices.data?.data.find(d => d.id === id)).filter(d => d) as Dto.RdmsApiV1.Device[], [devices?.data, selectedIds]);
    const deviceActions = useDeviceActions({ onUpdate: () => devices.reload() });

    const renderShortIdCell = useCallback((device: Dto.RdmsApiV1.Device) => {
        const typeSpec = deviceTypes.getForDevice(device);
        return (
            <Stack direction="row" spacing={.5} sx={{ color: t => typeSpec.style.color }}>
                <FontAwesomeIcon size="lg" fixedWidth icon={["fad", typeSpec.style.icon]} />
                <Typography variant="body2" sx={{ color: t => typeSpec.style.color, textDecoration: "none" }} component={Link} to={`${props.linkPrefix || ""}/devices/${device.id}`}>
                    {device.shortId}
                </Typography>
                {typeSpec.style.badge && <Chip size="small" variant="outlined" color="warning" label={typeSpec.style.badge} />}
            </Stack>
        );
    }, [deviceTypes, props.linkPrefix]);

    const renderBusinessSubjectCell = useCallback((mode: "customer" | "service-partner", device: Dto.RdmsApiV1.Device) => {
        return <ObjectReference type="business-subject" icon id={mode === "customer" ? device.customerBusinessSubjectId : device.servicePartnerBusinessSubjectId} />;
    }, []);

    const renderDcmProfileCell = useCallback((device: Dto.RdmsApiV1.Device) => {
        const source = device._meta.configuredDcmProfile?.source || null;
        return (
            <ObjectReference
                type="dcm-profile"
                icon
                id={device._meta.configuredDcmProfile?.id}
                sx={{ color: t => source !== "device" ? t.palette.text.secondary : undefined }}
                tail={
                    <>
                        {source === "customer" && <Tooltip title="Inherited from customer"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "building"]} fixedWidth /></Tooltip>}
                        {source === "global-default" && <Tooltip title="Global default"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "globe"]} fixedWidth /></Tooltip>}
                    </>
                }
            />
        );
    }, [theme]);

    const renderRpsProfileCell = useCallback((device: Dto.RdmsApiV1.Device) => {
        const source = device._meta.configuredRpsProfile?.source || null;
        return (
            <ObjectReference
                type="rps-profile"
                icon
                id={device._meta.configuredRpsProfile?.id}
                sx={{ color: t => source !== "device" ? t.palette.text.secondary : undefined }}
                tail={
                    <>
                        {source === "customer" && <Tooltip title="Inherited from customer"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "building"]} fixedWidth /></Tooltip>}
                        {source === "global-default" && <Tooltip title="Global default"><FontAwesomeIcon style={{ marginLeft: theme.spacing(.25), color: theme.palette.text.secondary }} icon={["fal", "globe"]} fixedWidth /></Tooltip>}
                    </>
                }
            />
        );
    }, [theme]);

    const renderStatusCell = useCallback((device: Dto.RdmsApiV1.Device) => {
        const typeSpec = deviceTypes.getForDevice(device);
        return <DeviceStatusIcons device={device} enabled connection={typeSpec.type?.capabilities.supportsPlatformConnection} size="small" />;
    }, [deviceTypes]);

    const columns: DataGridColDef[] = useMemo(() => [
        {
            field: "shortId",
            headerName: "SID",
            flex: 1,
            filterable: false,
            renderCell: params => renderShortIdCell(params.row)
        },
        {
            field: "deviceTypeId",
            headerName: "Type",
            flex: 1,
            filterable: false,
            hideOnSmallScreen: true,
            valueGetter: params => deviceTypes.getForDevice(params.row).type?.title || "Unknown"
        },
        {
            field: "_fsSerialNumber",
            headerName: "FS Serial Number",
            flex: 1,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true,
            renderCell: params => params.row.fsFactoryInfo?.serialNumber || params.row.fsLastBriefStatus?.serialNumber || "-"
        },
        {
            field: "alias",
            headerName: "Alias",
            flex: 1,
            filterable: false,
        },
        {
            field: "_customerBusinessSubject",
            headerName: "Customer",
            flex: 1,
            sortable: false,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true,
            renderCell: params => renderBusinessSubjectCell("customer", params.row)
        },
        {
            field: "_servicePartnerBusinessSubject",
            headerName: "Service Partner",
            flex: 1,
            sortable: false,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true,
            renderCell: params => renderBusinessSubjectCell("service-partner", params.row)
        },
        {
            field: "_dcmProfile",
            headerName: "DCM Profile",
            flex: 1,
            sortable: false,
            filterable: false,
            hideOnSmallScreen: true,
            renderCell: params => renderDcmProfileCell(params.row)
        },
        {
            field: "_rpsProfile",
            headerName: "RPS Profile",
            flex: 1,
            sortable: false,
            filterable: false,
            hideOnSmallScreen: true,
            renderCell: params => renderRpsProfileCell(params.row)
        },
        {
            field: "vpnAddress",
            headerName: "VPN Address",
            flex: 1,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true
        },
        {
            field: "lastVpnAuthorizationDate",
            headerName: "Last VPN Authorization",
            flex: 1,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true
        },
        {
            field: "updateDate",
            headerName: "Last Update",
            flex: 1,
            filterable: false,
            defaultVisibility: "hidden",
            hideOnSmallScreen: true
        },
        {
            field: "_status",
            headerName: "Status",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 200,
            renderCell: params => renderStatusCell(params.row)
        }
    ], [deviceTypes, renderBusinessSubjectCell, renderDcmProfileCell, renderRpsProfileCell, renderShortIdCell, renderStatusCell]);

    useEffect(() => {
        const subscription = filterValueUpdate$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(value => value.toLowerCase()),
            tap(value => setFilterValue(value))
        ).subscribe();
        return () => subscription.unsubscribe();
    }, [filterValueUpdate$]);

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (selectedObjects.length > 0) {
            factory
                .section(`${selectedObjects.length} Selected Devices`)
                .if(auth.hasAccess("rdm:device", "update")).action(EnableDeviceAction).priority(8305).do(() => deviceActions.enable({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(DisableDeviceAction).priority(8305).do(() => deviceActions.disable({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(SetDeviceCustomerAction).priority(8310).do(() => deviceActions.setCustomer({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(SetDeviceServicePartnerAction).priority(8320).do(() => deviceActions.setServicePartner({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(SetDeviceDcmProfileAction).priority(8350).do(() => deviceActions.setDcmProfile({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(SetDeviceRpsProfileAction).priority(8360).do(() => deviceActions.setRpsProfile({ devices: selectedObjects  }))
                .if(auth.hasAccess("rdm:device", "update")).action(ClearDeviceCustomerAction).priority(8371).do(() => deviceActions.clearCustomer({ devices: selectedObjects }))    
                .if(auth.hasAccess("rdm:device", "update")).action(ClearDeviceServicePartnerAction).priority(8372).do(() => deviceActions.clearServicePartner({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(ClearDeviceDcmProfileAction).priority(8373).do(() => deviceActions.clearDcmProfile({ devices: selectedObjects }))
                .if(auth.hasAccess("rdm:device", "update")).action(ClearDeviceRpsProfileAction).priority(8374).do(() => deviceActions.clearRpsProfile({ devices: selectedObjects }))
                .if(selectedObjects.length === 1 && auth.hasAccess("rdm:device", "device:recover-rdma-config")).action(GetDeviceRecoveryRdmaConfigAction).priority(8380).do(() => deviceActions.getRecoveryRdmAgentConfig({ device: selectedObjects[0]  }));
            return factory;
        }
        return factory;
    }, [auth, deviceActions, selectedObjects]);
    useCommandBarActions(cbActionFactory);

    return (
        <>
            {!props.disableHeading && <Heading>Devices</Heading>}
            <DefaultContainer loading={devices.initialLoading}>
                <ObjectIndexActionBar>
                    <DataGridSelectActionButton selectedIds={selectedIds}>
                        <RequireAuthorization resource="rdm:device" operation="update">
                            <ActionMenuItem def={EnableDeviceAction} action={() => deviceActions.enable({ devices: selectedObjects})} />
                            <ActionMenuItem def={DisableDeviceAction} action={() => deviceActions.disable({ devices: selectedObjects})} />
                            <Divider />
                            <ActionMenuItem def={SetDeviceCustomerAction} action={() => deviceActions.setCustomer({ devices: selectedObjects })} />
                            <ActionMenuItem def={SetDeviceServicePartnerAction} action={() => deviceActions.setServicePartner({ devices: selectedObjects })} />
                            <ActionMenuItem def={SetDeviceDcmProfileAction} action={() => deviceActions.setDcmProfile({ devices: selectedObjects })} />
                            <ActionMenuItem def={SetDeviceRpsProfileAction} action={() => deviceActions.setRpsProfile({ devices: selectedObjects })} />
                            <Divider />
                            <ActionMenuItem def={ClearDeviceCustomerAction} action={() => deviceActions.clearCustomer({ devices: selectedObjects })} />
                            <ActionMenuItem def={ClearDeviceServicePartnerAction} action={() => deviceActions.clearServicePartner({ devices: selectedObjects })} />
                            <ActionMenuItem def={ClearDeviceDcmProfileAction} action={() => deviceActions.clearDcmProfile({ devices: selectedObjects })} />
                            <ActionMenuItem def={ClearDeviceRpsProfileAction} action={() => deviceActions.clearRpsProfile({ devices: selectedObjects })} />
                        </RequireAuthorization>
                        <Divider />
                        {selectedObjects.length === 1 && (
                            <RequireAuthorization object={selectedObjects[0]} operation="device:recover-rdma-config">
                                <ActionMenuItem def={GetDeviceRecoveryRdmaConfigAction} action={() => deviceActions.getRecoveryRdmAgentConfig({ device: selectedObjects[0] })} />
                            </RequireAuthorization>
                        )}
                    </DataGridSelectActionButton>
                    <TextField variant="outlined" size="small" placeholder="Search..." autoFocus hiddenLabel sx={{ flex: "1 1 auto", minWidth: 100 }} defaultValue={filterValue} onChange={evt => filterValueUpdate$.next(evt.target.value)} />
                </ObjectIndexActionBar>
                <DataGrid
                    uiStateId="deviceGrid"
                    getRowId={r => r.id}
                    columns={columns}
                    rows={devices.data?.data || []}
                    rowCount={devices.data?.total || 0}
                    loading={devices.loading}

                    pagination
                    paginationMode="server"
                    pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}

                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableChildrenSorting

                    checkboxSelection={auth.hasAccess("rdm:device", "update") || auth.hasAccess("rdm:device", "device:recover-rdma-config")}
                    rowSelectionModel={selectedIds}
                    onRowSelectionModelChange={m => setSelectedIds(m as string[])}
                />
            </DefaultContainer>
        </>
    );
};
export default DeviceGrid;