import { List, ListSubheader, SxProps } from "@mui/material";

export interface InfoListProps {
    title?: string;
    children: React.ReactNode;
    sx?: SxProps;
    invertColors?: boolean;
    lineColor?: string;
}

export const InfoList: React.FC<InfoListProps> = props => {
    return (
        <List
            dense
            subheader={props.title ? <ListSubheader disableGutters>{props.title}</ListSubheader> : undefined}
            sx={{
                "& > .MuiListItem-root:not(:last-child)": {
                    borderBottom: t => `1px ${t.palette.divider} solid`
                },
                "& .MuiListItem-padding": {
                    paddingLeft: "0px"
                },
                "& .MuiListItemIcon-root, & .MuiListItemText-root, & .MuiTypography-root": {
                    color: t => props.invertColors ? t.palette.grey[100] : undefined
                },
                ...props.sx
            }}
        >
            {props.children}
        </List>
    );
};
