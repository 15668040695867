import { Alert, Box, Typography } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import { ActionDef, ActionDeviceTargetList } from "components/Action";
import { ActionBaseProps } from "components/Action";
import useApiInvocation from "hooks/useApiInvocation";
import useActionState from "hooks/useActionState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RdmAgentConfigDialog from "components/RdmAgentConfigDialog";
import { useMemo, useState } from "react";
import { Subject, firstValueFrom } from "rxjs";
import { DeviceRecoverIcon } from "components/Icons";

interface Props {
    device: Dto.RdmsApiV1.Device;
}

const Action: React.FC<ActionBaseProps & Props> = props => {
    const getRecoveryRdmAgentConfig = useApiInvocation<{ id: string }, any>("get", "/v1/devices/:id/recovery-rdm-agent-config", { silentError: true, throwError: true });

    const [configString, setConfigString] = useState("");
    const [configDialogOpen, setConfigDialogOpen] = useState(false);
    const configDialogClose$ = useMemo(() => new Subject<void>(), []);

    useActionState({
        ...props,
        animationDelay: 500,
        process: async setProgress => {
            try {
                const config = await getRecoveryRdmAgentConfig({ id: props.device.id });
                setConfigString(JSON.stringify(config, null, 2));
                setConfigDialogOpen(true);
                setProgress(1);
                await firstValueFrom(configDialogClose$);
            } catch (error) {
                throw new Error(`Failed to get recovery RDM agent configuration ${props.device.id} - ${error.message}`);
            }
        }
    });

    return (
        <Box>
            <Typography color="text.disabled" gutterBottom>
                Recovery RDM agent configuration is generated by RDM server based on platform device profile.
                This configuration can be used for devices with filesystem failure or other issues with RDM agent configuration.
            </Typography>
            <Alert sx={{ mt: 1 }}  color="warning" icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />}>
                Keep in mind that RDM agent configuration contains sensitive information and should not be shared with anybody.
                Disclosing this information may present security vulnerabilities.
            </Alert>
            <ActionDeviceTargetList devices={[props.device]} operation="generated" />
            <RdmAgentConfigDialog open={configDialogOpen} onOpenChange={open => !open && configDialogClose$.next()} config={configString} />
        </Box>
    );
};

const GetDeviceRecoveryRdmaConfigAction: ActionDef<Props> = {
    id: "device-get-recovery-rdma-config",
    title: "Get Device Recovery RDMA Config",
    component: Action,
    dialog: {
        confirmButtonCaption: "Get Config",
        confirmButtonColor: "warning",
    },
    menu: {
        title: "Get Device Recovery RDMA Config",
        subtitle: "Retrieve factory configuration for RDM Agent",
        icon: <DeviceRecoverIcon />,
        color: "warning.main"
    }
};

export default GetDeviceRecoveryRdmaConfigAction;