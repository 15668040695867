import { Dto, Security } from "@varos/rdm-common";
import { useCallback } from "react";
import useApiResource from "./useApiResource";

export const useAuthenticationInfo = () => {
    const info = useApiResource<Dto.RdmsApiV1.AuthenticationInfo>("/v1/authentication", { cache: true, cacheExpire: 30000 });

    return {
        loading: info.loading,
        info: info.data,
        hasAccess: useCallback((resource: Security.Authorization.ResourceName, operation: Security.Authorization.OperationName) => info.data?.authorizationSummary[resource]?.includes(operation) || false, [info.data?.authorizationSummary])
    };
};