import { Box, Typography, useTheme } from "@mui/material";
import { ActionId } from "kbar";
import React from "react";
import { CommandBarActionImpl } from "./CommandBarAction";

export const CommandBarResultItem = React.forwardRef(
    (
        {
            action,
            active,
            currentRootActionId,
        }: {
            action: CommandBarActionImpl;
            active: boolean;
            currentRootActionId?: ActionId | null;
        },
        ref: React.Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();

        const ancestors = React.useMemo(() => {
            if (!currentRootActionId) return action.ancestors;
            const index = action.ancestors.findIndex(
                (ancestor) => ancestor.id === currentRootActionId
            );
            // +1 removes the currentRootAction; e.g.
            // if we are on the "Set theme" parent action,
            // the UI should not display "Set theme… > Dark"
            // but rather just "Dark"
            return action.ancestors.slice(index + 1);
        }, [action.ancestors, currentRootActionId]);
  
        return (
            <Box
                ref={ref}
                sx={{
                    padding: "12px 16px",
                    background: active ? theme.palette.divider : "transparent",
                    borderLeft: `4px solid`,
                    borderLeftColor: active ? (action.color || theme.palette.primary.main) : "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        fontSize: 14,
                    }}
                >
                    {action.icon && 
                        <Box sx={{ mr: 2, color: action.color || theme.palette.primary.main, fontSize: 16 }}>{action.icon}</Box>
                    }
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {ancestors.length > 0 &&
                  ancestors.map((ancestor) => (
                      <React.Fragment key={ancestor.id}>
                          <span
                              style={{
                                  opacity: 0.5,
                                  marginRight: 8,
                              }}
                          >
                              {ancestor.name}
                          </span>
                          <span
                              style={{
                                  marginRight: 8,
                              }}
                          >
                        &rsaquo;
                          </span>
                      </React.Fragment>
                  ))}
                            <Typography component="span" variant="body2" sx={{ color:  action.color || undefined }}>{action.name}</Typography>
                        </div>
                        {action.subtitle && (
                            <span style={{ fontSize: 12 }}>{action.subtitle}</span>
                        )}
                    </div>
                </div>
                {action.shortcut?.length ? (
                    <div
                        aria-hidden
                        style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
                    >
                        {action.shortcut.map((sc) => (
                            <kbd
                                key={sc}
                                style={{
                                    padding: "4px 6px",
                                    background: "rgba(0 0 0 / .1)",
                                    borderRadius: "4px",
                                    fontSize: 14,
                                }}
                            >
                                {sc}
                            </kbd>
                        ))}
                    </div>
                ) : null}
            </Box>
        );
    }
);
