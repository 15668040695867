import { CardContent, Chip, Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import Moment from "react-moment";
import { useMemo } from "react";
import useApiResource from "hooks/useApiResource";
import DefaultCard from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import { PlatformComponentStyle, getPlatformComponentStyle } from "constants/platform-component-style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusLine } from "components/StatusLine";
import { MetricCard, PieChartCard, PieChartCardProps } from "components/Charts";
import { useDeviceTypes } from "hooks/useDeviceTypes";

export const DashboardPlatformView: React.FC = props => {
    const theme = useTheme();
    const deviceTypes = useDeviceTypes();

    const platformStats = useApiResource<Dto.RdmsApiV1.PlatformStats>("/v1/stats/platform", { reloadInterval: 5000 });

    const serviceDescriptors = useMemo(() => {
        const list: { service: Dto.RdmsApiV1.PlatformStats["services"][0], style: PlatformComponentStyle, state: "online" | "heartbeatMissed" }[] = platformStats.data?.services.map(service => 
            ({
                service,
                style: getPlatformComponentStyle(theme, service.type),
                state: new Date().getTime() - new Date(service.info.updateDate).getTime() <= 10000 ? "online" : "heartbeatMissed"
            })
        ) || [];
        list.sort((a, b) => a.style.order < b.style.order ? -1 : a.style.order > b.style.order ? 1 : a.service.info.hostname < b.service.info.hostname ? -1 : a.service.info.hostname > b.service.info.hostname ? 1 : 0);
        return list;
    }, [platformStats.data?.services, theme]);

    const servicesByTypeChartData = useMemo<PieChartCardProps["data"]>(() => {
        return serviceDescriptors
            .reduce((a, c) => {
                let d = a.find(t => t.id === c.style.name);
                if (!d) {
                    d = { id: c.style.name, label: c.style.name, value: 0, color: c.style.color, legendIcon: c.style.icon };
                    a.push(d);
                }
                d.value = (d.value || 0) + 1;
                return a;
            }, [] as PieChartCardProps["data"]) || [];
    }, [serviceDescriptors]);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <MetricCard
                    variant="filled"
                    title="Services"
                    description="Services currently running on platform. Below is a service breakdown by type."
                    value={platformStats.data?.services.length || null}
                    loading={platformStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <MetricCard
                    variant="filled"
                    title="Device Types"
                    description="Device types currently recognized by platform. Below is a device type breakdown by type."
                    value={platformStats.data?.deviceTypes.length || null}
                    loading={platformStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <PieChartCard
                    title="Services"
                    description="Number of services by component type currently running."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Component"
                    data={servicesByTypeChartData || []}
                    loading={platformStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <PieChartCard
                    title="Device Types"
                    description="Number of devices registered in platform by their type."
                    height={300}
                    minHeight={250}
                    legend
                    legendHeader="Type"
                    sortByValue
                    data={platformStats.data?.deviceTypes.map(t => ({ id: t.title, label: t.title, value: t.count, legendIcon: deviceTypes.getByName(t.name).style.icon })) || []}
                    loading={platformStats.initialOrLongLoading}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {platformStats.initialOrLongLoading && [...Array(6).keys()].map(i => <Grid item key={`skeleton:${i}`} xs={12} md={6} lg={3}><Skeleton variant="rounded" height={290} /></Grid>)}
                    {!platformStats.initialOrLongLoading && serviceDescriptors.map(sd => 
                        <Grid item key={`${sd.service.type}:${sd.service.info.hostname}`} xs={12} md={6} lg={3}>
                            <DefaultCard raw sx={{ display: "flex", flexDirection: "column" }}>
                                <CardContent>
                                    <Stack sx={{ alignItems: "center", color: sd.style.color }}>
                                        <FontAwesomeIcon icon={["fad", sd.style.icon]} size="2x" />
                                        <Typography sx={{ mt: 1 }}>{sd.style.name}</Typography>
                                        <Typography variant="caption" sx={{ textAlign: "center", color: t => t.palette.text.secondary }}>{sd.service.info.hostname}</Typography>
                                    </Stack>
                                    <InfoList>
                                        <InfoListItem title="Status">
                                            <Stack direction="row">
                                                <StatusLine
                                                    icon={sd.state === "online" ? ["fas", "circle"] : ["fas", "exclamation-triangle"]}
                                                    color={sd.state === "online" ? theme.palette.success.main : theme.palette.warning.main}
                                                    title={sd.state === "online" ? "Online" : "Degraded"}
                                                    pulse={sd.state === "online"}
                                                    tooltip={<span>Last seen <Moment duration={sd.service.info.updateDate} interval={1000} format="s[s] [ago]" /></span>}
                                                />
                                            </Stack>
                                        </InfoListItem>
                                        <InfoListItem title="Application">{sd.service.info.appName} ({sd.service.info.appVersion})</InfoListItem>
                                        <InfoListItem title="Uptime">
                                            <Moment duration={sd.service.info.startDate} interval={1000} format="d [days] HH:mm:ss" />
                                        </InfoListItem>
                                        {sd.service.type === "dcm-edge" &&
                                            <InfoListItem title="Edge Details">
                                                <Stack direction="row" flexWrap="wrap">
                                                    <Chip sx={{ mt: .5, mr: .5 }} size="small" label={`${sd.service.info.dcmEdgeInfo?.clientsConnected} clients connected`} />
                                                    <Chip sx={{ mt: .5, mr: .5 }} size="small" label={`${sd.service.info.dcmEdgeInfo?.routeCacheSize} routes cached`} />
                                                </Stack>
                                            </InfoListItem>
                                        }
                                    </InfoList>
                                </CardContent>
                            </DefaultCard> 
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardPlatformView;