import { Chip, Grid, IconButton, LinearProgress, List, ListSubheader, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import Moment from "react-moment";
import React, { useCallback, useMemo } from "react";
import { Edit } from "@mui/icons-material";
import DefaultCard, { DefaultCardIconAppendix } from "components/DefaultCard";
import InfoList, { InfoListItem } from "components/InfoList";
import ObjectReference from "components/ObjectReference";
import { ConnectionStatusLine } from "components/StatusLine";
import useApiResource from "hooks/useApiResource";
import useHumanReadableSize from "hooks/useHumanReadableSize";
import AssociatedDeviceListItem from "views/DeviceDetail/AssociatedDeviceListItem";
import InfoAgeAlerts from "views/DeviceDetail/InfoAgeAlerts";
import InfoTimeFooter from "views/DeviceDetail/InfoTimeFooter";
import PpekkApiGatewayStatusInfoListItem from "views/DeviceDetail/PpekkApiGatewayStatusInfoListItem";
import PpekkApiVaultExtensionsStatusInfoListItem from "views/DeviceDetail/PpekkApiVaultExtensionsStatusInfoListItem";
import PrinterStatusInfoListItem from "views/DeviceDetail/PrinterStatusInfoListItem";
import SignatureInfoListItem from "views/DeviceDetail/SignatureInfoListItem";
import { SettingsCard } from "views/DeviceDetail/Cards/SettingsCard";
import SetDeviceAliasAction from "components/ObjectActions/Device/SetDeviceAliasAction";
import { QrCode } from "components/QrCode";
import Center from "components/Center";
import ToolsCard from "./Cards/ToolsCard";
import { CommandBarActionFactory, useCommandBarActions } from "components/CommandBar";
import { useDeviceActions } from "hooks/useDeviceActions";
import ClearDeviceDcmProfileAction from "components/ObjectActions/Device/ClearDeviceDcmProfileAction";
import SetDeviceDcmProfileAction from "components/ObjectActions/Device/SetDeviceDcmProfileAction";
import ClearDeviceRpsProfileAction from "components/ObjectActions/Device/ClearDeviceRpsProfileAction";
import SetDeviceRpsProfileAction from "components/ObjectActions/Device/SetDeviceRpsProfileAction";
import SetDeviceCustomerAction from "components/ObjectActions/Device/SetDeviceCustomerAction";
import SetDeviceServicePartnerAction from "components/ObjectActions/Device/SetDeviceServicePartnerAction";
import DisableDeviceAction from "components/ObjectActions/Device/DisableDeviceAction";
import EnableDeviceAction from "components/ObjectActions/Device/EnableDeviceAction";
import GetDeviceRecoveryRdmaConfigAction from "components/ObjectActions/Device/GetDeviceRecoveryRdmaConfigAction";
import ClearDeviceCustomerAction from "components/ObjectActions/Device/ClearDeviceCustomerAction";
import ClearDeviceServicePartnerAction from "components/ObjectActions/Device/ClearDeviceServicePartnerAction";
import { useDeviceType } from "hooks/useDeviceType";
import { useObjectAuthorizationInfo } from "hooks/useObjectAuthorizationInfo";
import { RequireAuthorization } from "components/Authorization";

export const DeviceDetailGeneralView: React.FC<{ device?: Dto.RdmsApiV1.Device, onUpdate?: () => void }> = props => {
    const theme = useTheme();
    const objectAuth = useObjectAuthorizationInfo(props.device);

    const deviceType = useDeviceType(props.device);
    const deviceStatus = useApiResource<Dto.RdmsApiV1.DeviceStatus>(`/v1/devices/${props.device?.id}/status`, { disable: !props.device?.id, reloadInterval: 5000, ignoreForbidden: true });
    const deviceInfo = useApiResource<Dto.RdmsApiV1.DeviceInfo>(`/v1/devices/${props.device?.id}/info/latest`, { disable: !props.device?.id || !deviceType.type?.capabilities.supportsDeviceInfo, ignoreNotFound: true, reloadInterval: 30000, ignoreForbidden: true });
    const fsInfo = useApiResource<Dto.RdmsApiV1.FiscalStorageInfo>(`/v1/devices/${props.device?.id}/fs-info/latest`, { disable: !props.device?.id || !deviceType.type?.capabilities.supportsFiscalStorageInfo, ignoreNotFound: true, reloadInterval: 30000, ignoreForbidden: true });
    const associatedDevices = useApiResource<Dto.RdmsApiV1.DeviceAssociatedDevicesResponse>(`/v1/devices/${props.device?.id}/associated-devices`, { disable: !props.device?.id, reloadInterval: 30000 });
    const dcmProfile = useApiResource<Dto.RdmsApiV1.DcmProfile>(`/v1/dcm-profiles/${props.device?._meta.configuredDcmProfile?.id}`, { disable: !deviceType.type?.capabilities.supportsDcmProfile || !props.device?._meta.configuredDcmProfile?.id, ignoreForbidden: true });
    const loading = useMemo(
        () =>
            !!(
                !props.device ||
                deviceStatus.initialOrLongLoading ||
                (deviceType.type?.capabilities.supportsDeviceInfo && deviceInfo.initialOrLongLoading) ||
                (deviceType.type?.capabilities.supportsFiscalStorageInfo && fsInfo.initialOrLongLoading) ||
                (deviceType.type?.capabilities.supportsDcmProfile && props.device?._meta.configuredDcmProfile?.id && dcmProfile.initialOrLongLoading)
            ),
        [
            props.device,
            deviceType,
            dcmProfile.initialOrLongLoading,
            deviceInfo.initialOrLongLoading,
            deviceStatus.initialOrLongLoading,
            fsInfo.initialOrLongLoading
        ]
    );
    const isDeviceInfoCurrent = useMemo(() => {
        if (deviceInfo.data && dcmProfile.data) {
            return new Date().getTime() - new Date(deviceInfo.data?.createDate).getTime() <= 2*dcmProfile.data?.infoReportingInterval;
        } else if (deviceInfo.data) {
            return new Date().getTime() - new Date(deviceInfo.data?.createDate).getTime() <= 15 * 60 * 1000;
        }
        return false;
    }, [dcmProfile.data, deviceInfo.data]);
    const cpuLoadAverage = useMemo(() => deviceInfo.data ? [Math.round(deviceInfo.data.info.cpuLoadAverage[0] * 100) / 100, Math.round(deviceInfo.data.info.cpuLoadAverage[1] * 100) / 100, Math.round(deviceInfo.data.info.cpuLoadAverage[2] * 100) / 100] : [0, 0, 0], [deviceInfo.data]);
    const cpuLoadPercent = useMemo(() => deviceInfo.data ? deviceInfo.data.info.cpuLoadAverage[1] / deviceInfo.data.info.cpuCount * 100 : 0, [deviceInfo.data]);
    const memoryUsagePercent = useMemo(() => deviceInfo.data ? deviceInfo.data.info.memoryUsed / deviceInfo.data.info.memoryTotal * 100 : 0, [deviceInfo.data]);
    const rootStorageUsagePercent = useMemo(() => deviceInfo.data ? deviceInfo.data.info.rootStorageUsed / deviceInfo.data.info.rootStorageTotal * 100 : 0, [deviceInfo.data]);
    const [memoryUsed, memoryTotal] = [useHumanReadableSize(deviceInfo.data?.info.memoryUsed || 0, "B"), useHumanReadableSize(deviceInfo.data?.info.memoryTotal || 0, "B")];
    const [rootStorageUsed, rootStorageTotal] = [useHumanReadableSize(deviceInfo.data?.info.rootStorageUsed || 0, "B"), useHumanReadableSize(deviceInfo.data?.info.rootStorageTotal || 0, "B")];

    const isFiscalStorageInfoCurrent = useMemo(() => fsInfo.data && new Date().getTime() - new Date(fsInfo.data?.createDate).getTime() <= 24 * 60 * 60 * 1000, [fsInfo.data]);
    const isFiscalStorageInfoHot = useMemo(() => fsInfo.data && new Date().getTime() - new Date(fsInfo.data?.createDate).getTime() <= 5 * 60 * 1000, [fsInfo.data]);
    const fsStorageUsagePercent = useMemo(() => fsInfo.data ? (fsInfo.data.info.capacityTotal - fsInfo.data.info.capacityAvailable) / fsInfo.data.info.capacityTotal * 100 : 0, [fsInfo.data]);
    const [fsStorageUsed, fsStorageTotal] = [useHumanReadableSize((fsInfo.data?.info.capacityTotal || 0) - (fsInfo.data?.info.capacityAvailable || 0), "B"), useHumanReadableSize(fsInfo.data?.info.capacityTotal || 0, "B")];

    const deviceActions = useDeviceActions({ onUpdate: props.onUpdate });

    const getPackageTypeName = (type: string) => ({
        rdma: "Varos RDM Agent",
        app: "Varos EFT5000 App",
        environment: "Varos EFT5000 App Environment"
    })[type] || type;

    const cbActionFactory = useCallback((factory: CommandBarActionFactory) => {
        if (!props.device) {
            return factory;
        }
        factory
            .section(props.device.shortId)
            .if(objectAuth.hasAccess("update")).action(SetDeviceAliasAction).priority(8300).do(() => props.device && deviceActions.setAlias({ device: props.device }))
            .if(objectAuth.hasAccess("update")).action(SetDeviceCustomerAction).priority(8310).do(() => props.device && deviceActions.setCustomer({ devices: [props.device] }))
            .if(objectAuth.hasAccess("update")).action(SetDeviceServicePartnerAction).priority(8320).do(() => props.device && deviceActions.setServicePartner({ devices: [props.device] }));

        if (objectAuth.hasAccess("update")) {
            if (props.device.enabled) {
                factory.action(DisableDeviceAction).priority(8305).do(() => props.device && deviceActions.disable({ devices: [props.device] }));
            } else {
                factory.action(EnableDeviceAction).priority(8305).do(() => props.device && deviceActions.enable({ devices: [props.device] }));   
            }
            if (props.device.customerBusinessSubjectId) {
                factory.action(ClearDeviceCustomerAction).priority(8371).do(() => props.device && deviceActions.clearCustomer({ devices: [props.device] }));
            }
            if (props.device.servicePartnerBusinessSubjectId) {
                factory.action(ClearDeviceServicePartnerAction).priority(8372).do(() => props.device && deviceActions.clearServicePartner({ devices: [props.device] }));
            }
            if (deviceType.type?.capabilities.supportsDcmProfile) {
                factory.action(SetDeviceDcmProfileAction).priority(8350).do(() => props.device && deviceActions.setDcmProfile({ devices: [props.device] }));
                if (props.device.dcmProfileId) {
                    factory.action(ClearDeviceDcmProfileAction).priority(8373).do(() => props.device && deviceActions.clearDcmProfile({ devices: [props.device] }))
                }
            }
            if (deviceType.type?.capabilities.supportsRpsProfile) {
                factory.action(SetDeviceRpsProfileAction).priority(8360).do(() => props.device && deviceActions.setRpsProfile({ devices: [props.device]  }));
                if (props.device.rpsProfileId) {
                    factory.action(ClearDeviceRpsProfileAction).priority(8374).do(() => props.device && deviceActions.clearRpsProfile({ devices: [props.device]  }))
                }
            }
        }
        if (objectAuth.hasAccess("device:recover-rdma-config") && deviceType.type?.capabilities.supportsPlatformConnection) {
            factory.action(GetDeviceRecoveryRdmaConfigAction).priority(8380).do(() => props.device && deviceActions.getRecoveryRdmAgentConfig({ device: props.device }));
        }
        return factory;
    }, [deviceActions, deviceType, props.device, objectAuth]);
    useCommandBarActions(cbActionFactory);

    return (
        <Grid container spacing={2}>

            {/* General Information */}
            <Grid item xs={12} sm={6} lg={4}>
                <DefaultCard title="General" loading={loading} skeletonHeight={595}>
                    <InfoList>
                        <InfoListItem title="Type">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <ObjectReference type="device-type" id={props.device?.deviceTypeId} />
                                {deviceType.style.badge && <Chip size="small" variant="filled" color="warning" label={deviceType.style.badge} />}
                            </Stack>
                        </InfoListItem>
                        <InfoListItem title="ID">{props.device?.id}</InfoListItem>
                        <InfoListItem title="Short ID">{props.device?.shortId}</InfoListItem>
                        <InfoListItem
                            title="Alias"
                            tail={objectAuth.hasAccess("update") && <IconButton title="Change alias" onClick={() => props.device && deviceActions.setAlias({ device: props.device })}><Edit /></IconButton>}
                        >
                            {props.device?.alias || "-"}
                        </InfoListItem>
                        <InfoListItem title="Hardware Type">{props.device?.hardwareType}</InfoListItem>
                        <InfoListItem title="Hardware ID">{props.device?.hardwareId}</InfoListItem>
                        <InfoListItem title="Created"><Moment fromNow>{props.device?.createDate}</Moment></InfoListItem>
                        <InfoListItem title="Updated"><Moment fromNow>{props.device?.updateDate}</Moment></InfoListItem>
                    </InfoList>
                    <Center>
                        <QrCode loading={loading} width={96} height={96} color={theme.palette.primary.main} bgColor={theme.palette.background.paper} data={`https://go.varos.sk/sid/${props.device?.shortId}`} />
                    </Center>
                </DefaultCard>
            </Grid>

            {/* Connection and Security (connected devices) */}
            {(deviceType.type?.capabilities.supportsPlatformConnection || objectAuth.hasAccess("device:read-info")) && (
                <Grid item xs={12} sm={6} lg={4}>
                    <DefaultCard title="Connection & Security" loading={loading} skeletonHeight={595}>
                        <InfoList>
                            {deviceType.type?.capabilities.supportsPlatformConnection && (
                                <>
                                    <RequireAuthorization object={props.device} operation="device:read-status">
                                        <InfoListItem title="DCM Connection">
                                            <ConnectionStatusLine
                                                sx={{ mt: .5 }}
                                                status={deviceStatus.data?.dcmSessionInfo ? "connected" : "offline"}
                                                loading={deviceStatus.initialOrLongLoading}
                                                chips={(deviceStatus.data?.dcmSessionInfo && [
                                                    { icon: "clock", label: <Moment fromNow ago>{deviceStatus.data.dcmSessionInfo.connectDate}</Moment> },
                                                    { icon: "ethernet", label: deviceStatus.data.dcmSessionInfo.remote },
                                                ]) || undefined}
                                            />
                                        </InfoListItem>
                                        <InfoListItem title="VPN Connection">
                                            <ConnectionStatusLine
                                                sx={{ mt: .5 }}
                                                status={deviceStatus.data?.vpnSessionInfo ? "connected" : "offline"}
                                                loading={deviceStatus.initialOrLongLoading}
                                                chips={(deviceStatus.data?.vpnSessionInfo && [
                                                    { icon: "clock", label: <Moment fromNow ago>{deviceStatus.data.vpnSessionInfo.sessionStartDate}</Moment> },
                                                    { icon: "ethernet", label: deviceStatus.data.vpnSessionInfo.clientIpAddress },
                                                ]) || undefined}
                                            />
                                        </InfoListItem>
                                    </RequireAuthorization>
                                    <InfoListItem title="VPN Address">{props.device?.vpnAddress}</InfoListItem>
                                    <InfoListItem title="VPN Last Authentication"><Moment fromNow>{props.device?.lastVpnAuthorizationDate}</Moment></InfoListItem>
                                </>
                            )}
                            {deviceType.type?.capabilities.supportsFiscalStorageInfo && (
                                <RequireAuthorization object={props.device} operation="device:read-info">
                                    <InfoListItem title="Fiscal Storage Data Feed">
                                        <ConnectionStatusLine
                                            sx={{ mt: .5 }}
                                            status={isFiscalStorageInfoHot ? "connected" : "offline"}
                                            loading={fsInfo.initialOrLongLoading}
                                        />
                                    </InfoListItem>
                                    <InfoListItem title="Fiscal Storage Data Relay">
                                        <ObjectReference icon type="device" id={fsInfo.data?.senderDeviceId} />
                                    </InfoListItem>
                                </RequireAuthorization>
                            )}
                            <RequireAuthorization object={props.device} operation="device:read-info">
                                {deviceType.type?.capabilities.supportsDeviceInfo && deviceInfo.data && <SignatureInfoListItem info={deviceInfo.data} />}
                                {deviceType.type?.capabilities.supportsFiscalStorageInfo && fsInfo.data && <SignatureInfoListItem info={fsInfo.data} />}
                            </RequireAuthorization>
                        </InfoList>
                    </DefaultCard>
                </Grid>
            )}

            {/* Device Runtime Info (connected devices) */}
            {deviceType.type?.capabilities.supportsDeviceInfo && (
                <RequireAuthorization object={props.device} operation="device:read-info">
                    <Grid item xs={12} sm={6} lg={4}>
                        <DefaultCard
                            title="Device Runtime Status"
                            titleAppendix={<DefaultCardIconAppendix icon={["fal", "print"]} color={theme.palette.secondary.main} tooltip="Runtime information only available for fiscal printer devices." />}
                            loading={loading}
                            skeletonHeight={300}
                        >
                            <InfoAgeAlerts info={deviceInfo.data} stale={!isDeviceInfoCurrent} />
                            {deviceInfo.data && 
                        <>
                            <InfoList sx={{ filter: !isDeviceInfoCurrent ? "blur(3px) grayscale(100%)" : undefined, transition: "filter 300ms ease" }}>
                                <InfoListItem title="CPU Usage" tail={<LinearProgress sx={{ width: 96, mr: 2 }} variant="determinate" value={cpuLoadPercent} color={cpuLoadPercent < 50 ? "primary" : "warning"} />}>
                                    {cpuLoadAverage[0]} / {cpuLoadAverage[1]} / {cpuLoadAverage[2]}
                                </InfoListItem>
                                <InfoListItem title="Memory Usage" tail={<LinearProgress sx={{ width: 96, mr: 2 }} variant="determinate" value={memoryUsagePercent} color={memoryUsagePercent < 90 ? "primary" : "warning"} />}>
                                    {memoryUsed} / {memoryTotal}
                                </InfoListItem>
                                <InfoListItem title="Root Storage Usage" tail={<LinearProgress sx={{ width: 96, mr: 2 }} variant="determinate" value={rootStorageUsagePercent} color={rootStorageUsagePercent < 75 ? "primary" : "warning"} />}>
                                    {rootStorageUsed} / {rootStorageTotal}
                                </InfoListItem>               
                                <PrinterStatusInfoListItem info={deviceInfo.data} />
                                <PpekkApiGatewayStatusInfoListItem info={deviceInfo.data} />
                                <PpekkApiVaultExtensionsStatusInfoListItem info={deviceInfo.data} />
                                <InfoListItem title="CPU Count">{deviceInfo.data.info.cpuCount}</InfoListItem>
                                <InfoListItem title="Hostname">{deviceInfo.data.info.hostname}</InfoListItem>
                                <InfoListItem title="Uptime">
                                    <Moment duration={new Date(deviceInfo.data.createDate).getTime() - deviceInfo.data.info.uptime} format="Y [years], M [months], d [days] hh:mm:ss" interval={1000} />
                                </InfoListItem>
                            </InfoList>
                            {isDeviceInfoCurrent && <InfoTimeFooter info={deviceInfo.data} interval={dcmProfile.data?.infoReportingInterval || 30000} />}
                        </>
                            }
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <DefaultCard
                            title="Device Network"
                            titleAppendix={<DefaultCardIconAppendix icon={["fal", "print"]} color={theme.palette.secondary.main} tooltip="Runtime information only available for fiscal printer devices." />}
                            loading={loading}
                            skeletonHeight={300}
                        >
                            <InfoAgeAlerts info={deviceInfo.data} stale={!isDeviceInfoCurrent} />
                            <InfoList sx={{ filter: !isDeviceInfoCurrent ? "opacity(50%)" : undefined, transition: "filter 300ms ease" }}>
                                {deviceInfo.data?.info.networkInterfaces.map(iface =>
                                    <InfoListItem key={iface.name} title={iface.name} icon="ethernet">
                                        <Stack>
                                            <Typography variant="body2"><b>mac:</b> {iface.addresses[0]?.macAddress || "-"}</Typography>
                                            {iface.addresses.map(address => 
                                                <Typography variant="body2" key={address.cidr}><b>{address.family}:</b> {address.cidr}</Typography>
                                            )}
                                        </Stack>
                                    </InfoListItem>
                                )}
                            </InfoList>
                            {isDeviceInfoCurrent && <InfoTimeFooter info={deviceInfo.data} interval={dcmProfile.data?.infoReportingInterval || 30000} />}
                        </DefaultCard>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <DefaultCard
                            title="Device Packages"
                            titleAppendix={<DefaultCardIconAppendix icon={["fal", "print"]} color={theme.palette.secondary.main} tooltip="Runtime information only available for fiscal printer devices." />}
                            loading={loading}
                            skeletonHeight={300}
                        >
                            <InfoAgeAlerts info={deviceInfo.data} stale={!isDeviceInfoCurrent} />
                            <InfoList sx={{ filter: !isDeviceInfoCurrent ? "opacity(50%)" : undefined, transition: "filter 300ms ease" }}>
                                {deviceInfo.data?.info.packages.map(pkg =>
                                    <InfoListItem key={pkg.type} title={getPackageTypeName(pkg.type)} icon="box">
                                        <Stack>
                                            <Typography variant="body2">{pkg.current?.version}</Typography>
                                            {pkg.updatable && <Typography variant="body2" color="primary.main">Updatable to {pkg.latest?.version}</Typography>}
                                            {!pkg.updatable && <Typography variant="body2" color="text.disabled">Up-to-date</Typography>}
                                        </Stack>
                                    </InfoListItem>
                                )}
                            </InfoList>
                            {isDeviceInfoCurrent && <InfoTimeFooter info={deviceInfo.data} interval={dcmProfile.data?.infoReportingInterval || 30000} />}
                        </DefaultCard>
                    </Grid>
                </RequireAuthorization>
            )}

            {/* Fiscal Storage Status */}
            {deviceType.type?.capabilities.supportsFiscalStorageInfo && (
                <RequireAuthorization object={props.device} operation="device:read-info">
                    <Grid item xs={12} sm={6} lg={4}>
                        <DefaultCard
                            title="Fiscal Storage Status"
                            titleAppendix={<DefaultCardIconAppendix icon={["fal", "microchip"]} color={theme.palette.secondary.main} tooltip="Only available for fiscal storage devices." />}
                            loading={loading}
                            skeletonHeight={595}
                        >
                            <InfoAgeAlerts info={fsInfo.data} stale={!isFiscalStorageInfoCurrent} />
                            {fsInfo.data && (
                                <>
                                    <InfoList>
                                        <InfoListItem title="Device Vendor">{fsInfo.data.info.vendor}</InfoListItem>
                                        {fsInfo.data.info.vendorType === "varos" && <InfoListItem title="Device Family">{fsInfo.data.info.deviceFamily}</InfoListItem>}
                                        <InfoListItem title="Device Model">{fsInfo.data.info.model}</InfoListItem>
                                        {fsInfo.data.info.vendorType === "varos" && <InfoListItem title="Device MCU Type">{fsInfo.data.info.deviceProcessorType}</InfoListItem>}
                                        <InfoListItem title="Firmware Version">{fsInfo.data.info.firmwareVersion}</InfoListItem>
                                        <InfoListItem title="Serial Number">{fsInfo.data.info.serialNumber}</InfoListItem>
                                        <InfoListItem title="Storage Usage" tail={<LinearProgress sx={{ width: 96, mr: 2 }} variant="determinate" value={fsStorageUsagePercent} color={fsStorageUsagePercent < 75 ? "primary" : "warning"} />}>
                                            {fsStorageUsed} / {fsStorageTotal}
                                        </InfoListItem>
                                        <InfoListItem
                                            title="Certificate Expiration"
                                            tail={
                                                <Typography variant="body2" sx={{ mr: 2, color: t => new Date().getTime() - new Date(fsInfo.data?.info.certificateExpiryDate || 0).getTime() < 30 * 24 * 3600 * 1000 ? t.palette.primary.main : t.palette.warning.main }}>
                                                    <Moment to={new Date(fsInfo.data.info.certificateExpiryDate)} />
                                                </Typography>
                                            }
                                        >
                                            <Moment format="LLL">{new Date(fsInfo.data.info.certificateExpiryDate)}</Moment>
                                        </InfoListItem>
                                        <InfoListItem title="Unsent Bills">{fsInfo.data.info.unsentBillCount}</InfoListItem>
                                        {fsInfo.data.info.vendorType === "varos" && (
                                            <InfoListItem title="Varos Device Configuration & Status">
                                                <Stack>
                                                    <Typography variant="body2">EKS Interface Mode: <b>{fsInfo.data.info.configuredEksInterfaceMode}</b></Typography>
                                                    <Typography variant="body2">EKS Interface BAUD Rate: <b>{fsInfo.data.info.configuredEksBaudRate}</b></Typography>
                                                    <Typography variant="body2">PRN Interface BAUD Rate: <b>{fsInfo.data.info.configuredPrinterBaudRate}</b></Typography>
                                                    <Typography variant="body2">Print Test Lock: <b>{fsInfo.data.info.printTestLock}</b></Typography>
                                                    <Typography variant="body2">Vault Secure Channel: <b>{fsInfo.data.info.vaultSecureChannelStatus}</b></Typography>
                                                </Stack>
                                            </InfoListItem>
                                        )}
                                    </InfoList>
                                    {isFiscalStorageInfoCurrent && <InfoTimeFooter info={fsInfo.data} />}
                                </>
                            )}
                        </DefaultCard>
                    </Grid>
                </RequireAuthorization>
            )}

            {/* Fiscal Storage Factory Information */}
            {deviceType.type?.capabilities.supportsFiscalStorageInfo && props.device?.fsFactoryInfo &&
            <Grid item xs={12} sm={6} lg={4}>
                <DefaultCard
                    title="Fiscal Storage Factory Info"
                    titleAppendix={<DefaultCardIconAppendix icon={["fad", "building-shield"]} color={theme.palette.success.main} tooltip="Fiscal storage manufactured by Varos." />}
                    loading={loading}
                    skeletonHeight={595}
                >
                    <InfoList>
                        <InfoListItem title="Family">{props.device?.fsFactoryInfo?.deviceFamily}</InfoListItem>
                        <InfoListItem title="Model">{props.device?.fsFactoryInfo?.deviceName}</InfoListItem>
                        <InfoListItem title="Factory Firmware">{props.device?.fsFactoryInfo?.deviceFirmwareVersion}</InfoListItem>
                        <InfoListItem title="Serial Number">{props.device?.fsFactoryInfo?.serialNumber}</InfoListItem>
                        <InfoListItem title="Capacity Total">{props.device?.fsFactoryInfo?.deviceFirmwareVersion}</InfoListItem>
                        <InfoListItem title="Trusted Crypto Public Key">{props.device?.fsFactoryInfo?.trustedCryptoPublicKey}</InfoListItem>
                    </InfoList>
                </DefaultCard>
            </Grid>
            }

            {/* Associated Devices */}
            {(loading || (associatedDevices.data?.children.length || 0) > 0 || (associatedDevices.data?.parents.length || 0) > 0) &&
            <Grid item xs={12} sm={6} lg={4}>
                <DefaultCard title="Associated Devices" loading={loading} skeletonHeight={300}>
                    {deviceType.type?.capabilities.supportsDeviceInfo && <Typography variant="body2">The following fiscal storage units were seen connected via this device.</Typography>}
                    {deviceType.type?.capabilities.supportsFiscalStorageInfo && <Typography variant="body2">The following devices were seen hosting this fiscal storage unit.</Typography>}
                    <Paper elevation={0} sx={{ maxHeight: `calc(80px * 5)`, overflowY: "auto"}}>
                        {(associatedDevices.data?.children.length || 0) > 0 &&
                        <List dense subheader={<ListSubheader component="div">Fiscal Storage Units</ListSubheader>}>
                            {associatedDevices.data?.children.map(r =>
                                <AssociatedDeviceListItem key={r.id} deviceId={r.fsDeviceId} presenceRecord={r} />   
                            )}
                        </List>
                        }
                        {(associatedDevices.data?.parents.length || 0) > 0 &&
                        <List dense subheader={<ListSubheader component="div">Host Devices</ListSubheader>}>
                            {associatedDevices.data?.parents.map(r =>
                                <AssociatedDeviceListItem key={r.id} deviceId={r.deviceId} presenceRecord={r} />   
                            )}
                        </List>
                        }
                    </Paper>
                </DefaultCard>
            </Grid>
            }

            {/* Settings */}
            <SettingsCard loading={loading} device={props.device} onUpdate={props.onUpdate} />
            
            {/* Tools */}
            <ToolsCard loading={loading} device={props.device} onUpdate={props.onUpdate} />

        </Grid>
    );
};

export default DeviceDetailGeneralView;