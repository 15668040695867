import { ComputedDatum, DatumId, PieLayer, ResponsivePie } from "@nivo/pie";
import { OrdinalColorScaleConfig } from "@nivo/colors";
import { Margin } from "@nivo/core";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getChartColor } from "constants/chart-colors";
import Center from "components/Center";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export interface PieChartProps {
    data: { id: string; label: string; value: number; color?: string; legendIcon?: IconName  }[];
    height?: number;
    minHeight?: number;
    margin?: Partial<Margin>;
    colors?: { mode: "default", scheme: "all" | "primary" | "secondary" } | ({ mode: "nivo" } & OrdinalColorScaleConfig<Omit<ComputedDatum<any>, "fill" | "color" | "arc">>);
    total?: number;
    enableArcLinkLabels?: boolean;
    maxItems?: number;
    disabled?: boolean;
    activeId?: DatumId;
    sortByValue?: boolean;
    onMouseEnter?: (datum: ComputedDatum<any>, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
    onMouseLeave?: (datum: ComputedDatum<any>, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
    onClick?: (datum: ComputedDatum<any>, event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

export const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
    const theme = useTheme();

    const [height, minHeight] = useMemo(() => [props.height || 300, props.minHeight || (props.height ? (props.height * .8) : 250)], [props.height, props.minHeight]);
    
    const [init, setInit] = useState(false);
    const data = useMemo(() => {
        if (!init) {
            return [];
        }
        let output = props.data;
        if (props.data && props.data.length > 0 && props.maxItems) {
            return props.data.filter((_, i) => i < (props.maxItems || 15));
        }
        if (props.sortByValue) {
            output = output.sort((a, b) => a.value < b.value ? 1 : a.value > b.value ? -1 : 0);
        }
        if (props.data && ((props.colors && props.colors.mode === "default") || !props.colors)) {
            output = output.map((d, i) => ({...d, color: d.color || getChartColor(theme, i, (props.colors && ((props.colors) as any).scheme) || "all") }));
        }
        return output;
    }, [init, props.colors, props.data, props.maxItems, props.sortByValue, theme]);

    /* ensure entry animation */
    useEffect(() => {
        if (!init && props.data && props.data.length) {
            const timeout = setTimeout(() => setInit(true), 25);
            return () => clearTimeout(timeout);
        }
    }, [init, props.data]);

    const CenteredMetric: PieLayer<any> = useMemo(() => ({ dataWithArc, centerX, centerY }) => {
        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: "32px",
                    fontWeight: 300,
                    fill: theme.palette.text.primary
                }}
            >
                {props.total || "No data"}
            </text>
        )
    }, [props.total, theme]);

    const layers = useMemo(() => {
        const list: PieLayer<any>[] = ["arcs", "arcLabels", "arcLinkLabels"];
        if (props.total !== undefined) {
            list.push(CenteredMetric);
        }
        return list;
    }, [CenteredMetric, props.total]);

    return (
        <Box sx={{
            height,
            minHeight,
            transition: `opacity 300ms ease`,
            opacity: props.disabled ? .25 : 1,
            pointerEvents: props.disabled ? "none" : undefined
        }}>
            {(!props.data || props.data.length === 0) &&
                <Center sx={{ minHeight: height, color: theme.palette.text.disabled }}>
                    <FontAwesomeIcon icon={["fas", "eye-slash"]} size="2x" />
                    <Typography variant="h5" sx={{ mt: 1 }}>No data available</Typography>
                </Center>
            }
            <ResponsivePie
                animate
                forceActiveId={props.activeId}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
                data={data}
                margin={props.margin || (props.enableArcLinkLabels ? { top: 40, right: 100, bottom: 40, left: 100 } : { top: 10, bottom: 10, left: 10, right: 10 })}
                colors={props.colors?.mode === "nivo" ? props.colors : { datum: "data.color" }}
                innerRadius={0.7}
                padAngle={3}
                cornerRadius={8}
                activeOuterRadiusOffset={8}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [
                        [
                            "brighter",
                            4
                        ]
                    ]
                }}
                enableArcLinkLabels={props.enableArcLinkLabels !== undefined ? props.enableArcLinkLabels : false}
                layers={layers}
                theme={{
                    textColor: theme.palette.text.primary,
                    fontSize: 12,
                    tooltip: {
                        container: {
                            backgroundColor: theme.palette.mode === "light" ? theme.palette.background.paper : theme.palette.grey[900]
                        },
                        basic: { 
                            color: theme.palette.text.secondary,
                            fontSize: 14
                        }
                    }
                }}
            />
        </Box>
    );
};
