import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Box, Typography } from "@mui/material";
import Moment from "react-moment";

export const InfoTimeFooter: React.FC<{ info?: { createDate: string }, interval?: number}> = props => {
    if (!props.info) {
        return <></>;
    }
    return (
        <Stack flex="1 1 auto" sx={{ mt: 2 }}>
            <Box flex="1 1 auto" />
            <Stack direction="row" alignItems="center">
                <Box sx={{ color: t => t.palette.text.disabled, pr: 2 }}>
                    <FontAwesomeIcon icon={["fas", "clock"]} />
                </Box>
                <Typography variant="body2" color="text.disabled">
                    Information received <Moment from={new Date(props.info.createDate)} ago /> ago.
                    {props.interval && <> Expecting fresh data <Moment to={new Date(new Date(props.info.createDate).getTime() + (props.interval))} />.</>}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default InfoTimeFooter;