import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle } from "@mui/material";
import { Dto } from "@varos/rdm-common";
import Moment from "react-moment";


export const InfoAgeAlerts: React.FC<{info?: Dto.RdmsApiV1.DeviceInfo | Dto.RdmsApiV1.FiscalStorageInfo, stale?: boolean}> = props => {
    return (
        <>
            {!props.info && 
            <Alert color="warning" icon={<FontAwesomeIcon icon={["fas", "file-minus"]} />}>
                <AlertTitle>No data</AlertTitle>
                No data is cached for this device. Device may have not yet connected to the platform.
            </Alert>
            }
            {props.info && props.stale &&
            <Alert color="info" icon={<FontAwesomeIcon icon={["fas", "history"]} />}>
                <AlertTitle>Stale data</AlertTitle>
                Last device information were received <Moment from={new Date(props.info.createDate)} ago /> ago. This is most likely caused by device not being connected for longer period.
            </Alert>
            }
        </>
    );
};

export default InfoAgeAlerts;