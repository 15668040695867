import { useSortable } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Delete, ExpandMore } from "@mui/icons-material";
import { useTheme, Grid, Accordion, AccordionSummary, Stack, Typography, Tooltip, Box, AccordionDetails, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, CircularProgress, Collapse, IconButton } from "@mui/material";
import { Dto, Security } from "@varos/rdm-common";
import { BusinessSubjectAutocomplete } from "components/Autocomplete";
import { useState, useEffect, useMemo } from "react";
import { resourceStyles, operationStyles, selectorStyles } from "./constants";
import { CSS } from "@dnd-kit/utilities";
import Api from "services/api.service";

export type SortableAuthorizationRuleData = Dto.RdmsApiV1.SystemUserRoleRule & { id: string; }

export interface SortableAuthorizationRuleProps {
    variant?: "editor" | "display";
    rule: SortableAuthorizationRuleData;
    onChange?: (rule: SortableAuthorizationRuleData) => void;
    onDelete?: () => void;
    expanded?: boolean;
    onExpandChange?: (expanded: boolean) => void;
    loading?: boolean;
}

export const SortableAuthorizationRule: React.FC<SortableAuthorizationRuleProps> = props => {
    const theme = useTheme();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.rule.id });
    const style = { transform: CSS.Transform.toString(transform), transition };
    const variant = useMemo(() => props.variant || "editor", [props.variant]);
    const allResources = useMemo(() => Security.Authorization.resourceNames.map(name => ({ name, config: Security.Authorization.resources[name], style: resourceStyles[name]})), []);

    const [rule, setRule] = useState(props.rule);
    useEffect(() => setRule(props.rule), [props.rule]);

    const [selectorObjects, setSelectorObjects] = useState<any[]>([]);
    const [selectorObjectsLoading, setSelectorObjectsLoading] = useState(true);
    const [selectorAuthorizationSufficient, setSelectorAuthorizationSufficient] = useState(true);

    const resourceStyle = useMemo(() => resourceStyles[rule.resource], [rule.resource]);
    const operationSpecs = useMemo<{ operation: Security.Authorization.OperationName, style: typeof operationStyles["create"], active: boolean }[]>(() => {
        const resource = Security.Authorization.resources[rule.resource];
        return resource.supportedOperations.map(operation => ({
            operation,
            style: operationStyles[operation],
            active: rule.operations.indexOf(operation) > -1
        }));
    }, [rule.operations, rule.resource]);
    const supportedSelectors = useMemo(() => 
        Security.Authorization.selectorNames
            .filter(name => Security.Authorization.resources[rule.resource].supportedSelectors.indexOf(name) > -1)
            .map(name => ({ name, style: selectorStyles[name] })),
    [rule.resource]);
    const supportedOperations = useMemo(() =>
        Security.Authorization.operationNames
            .filter(name => Security.Authorization.resources[rule.resource].supportedOperations.indexOf(name) > -1)
            .map(name => ({ name, style: operationStyles[name] })),
    [rule.resource]);
    const selectedOperations = useMemo(() => supportedOperations.filter(({ name }) => rule.operations.indexOf(name) > -1), [rule.operations, supportedOperations]);

    useEffect(() => {
        const fetchToleratingForbidden = async (url: string) => {
            try {
                return await Api.invoke("get", url).response$;
            } catch (error) {
                if ((error as any).statusCode === 403) {
                    return null;
                }
            }
        };
        setSelectorObjectsLoading(true);
        if (rule.selector === "all") {
            setSelectorObjects([]);
            setSelectorObjectsLoading(false);
        } else if (rule.selector === "assigned-customer" || rule.selector === "assigned-service-partner") {
            (async () => {
                const objects = [];
                for (const id of rule.selectorObjectIds) {
                    objects.push(await fetchToleratingForbidden(`/v1/business-subjects/${id}`));
                }
                const filtered = objects.filter(o => o);
                setSelectorObjects(filtered);
                setSelectorAuthorizationSufficient(filtered.length === objects.length);
                setSelectorObjectsLoading(false);
            })(); 
        } else if (rule.selector === "specific" && resourceStyle.selectorApiUrlBase) {
            (async () => {
                const objects = [];
                for (const id of rule.selectorObjectIds) {
                    objects.push(await fetchToleratingForbidden(`${resourceStyle.selectorApiUrlBase}/${id}`));
                }
                const filtered = objects.filter(o => o);
                setSelectorObjects(filtered);
                setSelectorAuthorizationSufficient(filtered.length === objects.length);
                setSelectorObjectsLoading(false);
            })();
        }
    }, [resourceStyle.selectorApiUrlBase, rule.selector, rule.selectorObjectIds]);

    useEffect(() => {
        if (!props.loading && !selectorObjectsLoading && selectorAuthorizationSufficient) {
            props.onChange?.({ ...rule, selectorObjectIds: selectorObjects.map(object => object.id) });
        }
    }, [props, rule, selectorAuthorizationSufficient, selectorObjects, selectorObjectsLoading]);

    return (
        <Accordion
            elevation={0}
            sx={{
                backgroundColor: "inherit",
                "> .MuiAccordionSummary-root": {
                    transition: "border-radius 300ms ease, background 300ms ease",
                    pointerEvents: variant === "editor" || rule.selector !== "all" ? undefined : "none",
                    "&:hover": {
                        backgroundColor: t => (variant === "display" && rule.selector !== "all") ? t.palette.mode === "light" ? t.palette.grey[100] : t.palette.background.default : undefined,
                    }
                },
                "&.Mui-expanded > .MuiAccordionSummary-root": {
                    backgroundColor: t => variant === "editor" ? (t.palette.mode === "light" ? t.palette.grey[100] : t.palette.background.paper) : undefined,
                    borderRadius: t => variant === "editor" ? t.shape.borderRadius : undefined
                }
            }}
            ref={setNodeRef}
            style={style}
            expanded={props.expanded}
            onChange={(_, state) => props.onExpandChange?.(state)}
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
            <AccordionSummary expandIcon={variant === "editor" || rule.selector !== "all" ? <ExpandMore /> : undefined}>
                <Stack direction="row" flex="1" spacing={1} alignItems="center">
                    {variant === "editor" && <FontAwesomeIcon {...attributes} {...listeners} style={{ outline: "none" }} size="lg" fixedWidth icon={["fal", "grip-dots-vertical"]} />}
                    <FontAwesomeIcon color={rule.action === "grant" ? theme.palette.success.main : theme.palette.error.main} icon={["fas", rule.action === "grant" ? "unlock" : "lock"]} fixedWidth />
                    <Typography color={rule.action === "grant" ? theme.palette.success.main : theme.palette.error.main}>{rule.action === "grant" ? "Grant" : "Revoke"}</Typography>
                    <Stack direction="row" spacing={.5} color="text.secondary">
                        {operationSpecs.filter(os => os.active).map(os => (
                            <Tooltip key={os.operation} title={os.style.title}>
                                <Box sx={{ fontSize: 12, border: t => `1px solid ${t.palette.text.secondary}`, borderRadius: 1, p: t => t.spacing(.25, .5) }}>
                                    <FontAwesomeIcon icon={["fal", os.style.icon]} fixedWidth />
                                </Box>
                            </Tooltip>
                        ))}
                    </Stack>
                    {["all", "specific"].indexOf(rule.selector) > -1 && (
                        <Typography color="text.secondary">
                            on
                            {rule.selector === "all" && <span> all </span>}
                            {rule.selector === "specific" && <span> {rule.selectorObjectIds.length} specific </span>}
                            <Typography component="span" color="text.primary">{rule.selector === "specific" && rule.selectorObjectIds.length === 1 ? resourceStyle.singular : resourceStyle.plural}</Typography>
                        </Typography>
                    )}
                    {["assigned-customer", "assigned-service-partner"].indexOf(rule.selector) > -1 && (
                        <Typography color="text.secondary">
                            on <Typography component="span" color="text.primary">{resourceStyle.plural}</Typography> assigned to {rule.selectorObjectIds.length}
                            {rule.selector === "assigned-customer" && (rule.selectorObjectIds.length === 1 ? " specific customer" : " specific customers")}
                            {rule.selector === "assigned-service-partner" && (rule.selectorObjectIds.length === 1 ? " specific service partner" : " specific service partners")}
                        </Typography>
                    )}
                    <Box flex="1 1 auto"></Box>
                    {!selectorAuthorizationSufficient && (
                        <Tooltip title="Data display or edit capabilities may be limited due to insufficient permissions for related objects.">
                            <Box color="warning.main">
                                <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} fixedWidth size="lg" />
                            </Box>
                        </Tooltip>
                    )}
                    {variant === "editor" && props.onDelete && <IconButton disabled={props.loading || !selectorAuthorizationSufficient} size="small" color="error" onClick={evt => { evt.stopPropagation(); props.onDelete?.(); }}><Delete /></IconButton>}
                </Stack>
            </AccordionSummary>
            {variant === "display" && (
                <AccordionDetails>
                    <Collapse in={rule.selector === "assigned-customer" || rule.selector === "assigned-service-partner"}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <BusinessSubjectAutocomplete sx={{ mt: 1 }} label="Related Business Subjects" multiple disableCloseOnSelect readOnly value={selectorObjects} />
                            </Grid>
                        </Grid>
                    </Collapse>
                    <Collapse in={rule.selector === "specific" && !!resourceStyle.selectorAutocompleteComponent}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                {resourceStyle.selectorAutocompleteComponent && <resourceStyle.selectorAutocompleteComponent sx={{ mt: 1 }} label={`Selected ${resourceStyle.plural}`} multiple disableCloseOnSelect readOnly value={selectorObjects} />}
                            </Grid>
                        </Grid>
                    </Collapse>
                </AccordionDetails>
            )}
            {variant === "editor" && (
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel>Action</InputLabel>
                                <Select
                                    value={rule.action}
                                    label="Action"
                                    disabled={props.loading || !selectorAuthorizationSufficient}
                                    onChange={evt => setRule(r => ({ ...r, action: evt.target.value as Dto.RdmsApiV1.SystemUserRoleRule["action"] }))}
                                >
                                    <MenuItem value="grant">
                                        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: t => t.palette.success.main }}>
                                            <FontAwesomeIcon icon={["fal", "unlock"]} fixedWidth />
                                            <Typography>Grant</Typography>
                                        </Stack>
                                    </MenuItem>
                                    <MenuItem value="revoke">
                                        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: t => t.palette.error.main }}>
                                            <FontAwesomeIcon icon={["fal", "lock"]} fixedWidth />
                                            <Typography>Revoke</Typography>
                                        </Stack>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel>Resource</InputLabel>
                                <Select
                                    value={rule.resource}
                                    label="Resource"
                                    disabled={props.loading || !selectorAuthorizationSufficient}
                                    onChange={evt => setRule(r => ({ ...r, resource: evt.target.value as Security.Authorization.ResourceName }))}
                                >
                                    {allResources.map(resource => (
                                        <MenuItem key={resource.name} value={resource.name}>
                                            <Stack direction="row" spacing={1} alignItems="center" sx={{ color: t => t.palette.text.primary }}>
                                                <FontAwesomeIcon icon={["fal", resource.style.icon]} fixedWidth />
                                                <Typography>{resource.style.singular}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={props.loading || !selectorAuthorizationSufficient}
                                sx={{ width: "100%" }}
                                multiple
                                disableCloseOnSelect
                                options={supportedOperations || []}
                                value={selectedOperations}
                                onChange={(_, value) => setRule({ ...rule, operations: value.map(operation => operation.name) })}
                                getOptionLabel={option => option.style.title}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <FontAwesomeIcon icon={["fal", option.style.icon]} fixedWidth />
                                            <Typography>{option.style.title}</Typography>
                                        </Stack>
                                    </Box>
                                )}
                                renderInput={params =>
                                    <TextField
                                        {...params}
                                        label="Operations"
                                        InputProps={{
                                            ...params.InputProps,
                                            autoComplete: "new-password",
                                            endAdornment: (
                                                <>
                                                    {props.loading ? <CircularProgress size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel>Selector</InputLabel>
                                <Select
                                    value={rule.selector}
                                    label="Selector"
                                    disabled={props.loading || !selectorAuthorizationSufficient}
                                    onChange={evt => setRule(r => ({ ...r, selector: evt.target.value as Security.Authorization.SelectorName }))}
                                >
                                    {supportedSelectors.map(selector => (
                                        <MenuItem key={selector.name} value={selector.name}>
                                            <Stack direction="row" spacing={1} alignItems="center" sx={{ color: t => t.palette.text.primary }}>
                                                <FontAwesomeIcon icon={["fal", selector.style.icon]} fixedWidth />
                                                <Typography>{selector.style.title}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Collapse in={rule.selector === "assigned-customer" || rule.selector === "assigned-service-partner"}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <BusinessSubjectAutocomplete sx={{ mt: 1 }} label="Related Business Subjects" multiple disableCloseOnSelect disabled={props.loading || !selectorAuthorizationSufficient} value={selectorObjects} onChange={setSelectorObjects} />
                            </Grid>
                        </Grid>
                    </Collapse>
                    <Collapse in={rule.selector === "specific" && !!resourceStyle.selectorAutocompleteComponent}>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                {resourceStyle.selectorAutocompleteComponent && <resourceStyle.selectorAutocompleteComponent sx={{ mt: 1 }} label={`Selected ${resourceStyle.plural}`} multiple disableCloseOnSelect disabled={props.loading || !selectorAuthorizationSufficient} value={selectorObjects} onChange={setSelectorObjects} />}
                            </Grid>
                        </Grid>
                    </Collapse>
                </AccordionDetails>
            )}
        </Accordion>
    );
};
