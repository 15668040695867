import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useMemo } from "react";

export interface ActionTargetListProps<T extends { id: string }> {
    targets: T[];
    singular: string;
    plural: string;
    icon: IconProp;
    getPrimary: (object: T) => string;
    getSecondary?: (object: T) => string;
    maxItems?: number;
    operation?: string;
}

type ActionTargetListType<T extends {id : string}> = React.FC<ActionTargetListProps<T>>;

export const ActionTargetList: ActionTargetListType<any> = props => {
    const maxItems = useMemo(() => props.maxItems || 10, [props.maxItems]);

    return (
        <>
            <Typography sx={{ mt: 2 }}>{props.targets.length === 1 ? `The following ${props.singular}` : `${props.targets.length} ${props.plural}`} will be {props.operation || "updated"}:</Typography>
            <List dense>
                {props.targets.filter((_, i) => i < maxItems).map(target => 
                    <ListItem key={target.id}>
                        <ListItemIcon sx={{ color: t => t.palette.primary.main }}>
                            <FontAwesomeIcon icon={props.icon} fixedWidth size="lg" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: t => t.palette.primary.main }} primary={props.getPrimary(target)} secondary={props.getSecondary?.(target)} />
                    </ListItem>
                )}
                {props.targets.length > maxItems && <ListItem><ListItemText color="text.disabled">and {props.targets.length - maxItems} more {props.plural}...</ListItemText></ListItem>}
            </List>
        </>
    );
};

export default ActionTargetList;