import { Dto } from "@varos/rdm-common";
import { ActionTargetList, ActionTargetListProps } from "components/Action";

export const ActionDcmProfileTargetList: React.FC<{ profiles: Dto.RdmsApiV1.DcmProfile[] } & Partial<ActionTargetListProps<Dto.RdmsApiV1.DcmProfile>>> = props => 
    <ActionTargetList
        {...props}
        targets={props.profiles}
        singular="profile"
        plural="profiles"
        icon={["fas", "badge-check"]}
        getPrimary={(profile: Dto.RdmsApiV1.DcmProfile) => profile.title}
    />;
